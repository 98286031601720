/** @format */

.large_circle {
  position: fixed;
  width: 40px;
  height: 40px;
  border: 1px solid #e9dbd2;
  border-radius: 50%;
  pointer-events: none;
  /* transition: transform 0.15s; */
  mix-blend-mode: difference;
  z-index: 9999;
}

.small_circle {
  width: 64px;
  z-index: 9999;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #e9dbd2;
  background-color: #e9dbd2;
  position: fixed;
  pointer-events: none;
  transition: ease 0.15s;
  mix-blend-mode: difference;
}
.small_circle {
    width: 8px;
    height: 8px;
    background-color: #e9dbd2;
  /* transition: transform 0.15s; */

  /* background-color: rgb(90, 176, 247); */
}
