.main {
  max-width: 100%;
}
.odd-slide {
  transform: translateY(50px); /* Move odd-numbered slides 250px below */
}

.even-slide {
  transform: translateY(0px); /* Move even-numbered slides 50px above */
}

.swiper_wrapper {
  height: max-content;
}
@media only screen and (max-width:350px) {
  .slider-img {
    max-width: 50%;
    margin: auto;
  }
  .swiper-slide{
    text-align: center;
  }
}

.swiper-slide{
  text-align: center;
}
.team__info{
  
  text-align: left;
}