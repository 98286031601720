.contact-area {
  display: none;
  /* Add other styles as needed */
}

.contact-area.visible {
  display: block;
}
@media (max-width: 991px) {
  .contact-area {
    display: none !important;
  }
}
.off-canvas {
  background-color: #4c5a77;
  scroll-behavior: smooth;
}
.off-canvas .sidebar ul li {
  position: relative;
  float: left;
  width: 100%;
  padding: 10px 0 10px 10px;
  border-top: 1px solid #32436e;
}
.off-canvas .dropdown ul li a:active {
  position: relative;
  float: left;
  width: 100%;
  padding: 10px 0 10px 10px;
  border-top: 1px solid #32436e;
}
.off-canvas .sidebar ul li a {
  color: #fff;
  font-size: 1.2rem;
  width: 100%;

  font-weight: 400;
}
.off-canvas .sidebar ul li a:hover {
  letter-spacing: 1.3px;
}
.offcanvas-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.offcanvas-body::-webkit-scrollbar {
  width: 1.5px; /* Adjust the width as needed */
}

.offcanvas-body::-webkit-scrollbar-track {
  background-color: transparent; /* Set track color to transparent */
}

.offcanvas-body::-webkit-scrollbar-thumb {
  background-color: white; /* Set thumb color to white */
  border-radius: 3px; /* Add some border-radius for aesthetics */
}

.sidebar-sub-menu {
  padding-left: 1px;
}
.sub-menu-list {
  padding-left: 1px;
  background-color: #fff;
}
.sub-menu-list li a {
  color: black !important;
  margin-left: 20px;
  font-size: 0.9rem !important;
}
.sidebar-sub-menu li a {
  left: 20px !important;
  text-transform: uppercase;
}
.pagenav a {
  text-transform: uppercase;
}
.pagenav {
  position: relative;
}
.icons {
  height: 49px;
  width: 50px;
  display: flex;
  padding: 15px 0px 0px !important;
  position: absolute;
  background-color: #5e6a84;
  right: 0px !important;
  top: 0px;
  justify-content: center;
}
.icons:hover {
  height: 49px;
  width: 50px;
  display: flex;
  padding: 15px 0px 0px !important;
  position: absolute;
  background-color: #2c2c2c;
  right: 0px !important;
  top: 0px;
  justify-content: center;
  cursor: pointer;
}
.offcanvas-header .btn-close {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 05px 02px 10px 1px rgb(29, 29, 29);
}
.sub-menu-font-size {
  font-size: 1rem;
}
.Linkactive-color {
  color: #e9e9e9 !important;
  position: relative;
}

.Linkactive-color::before {
  content: "";
  position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #e9e9e9;
}

