@charset "UTF-8";

:root {
  --dark-theme: #4c5a77;
  --dark-theme2: #32436e;
  --primary: #e9dbd2;
  --secondary: #2c2c2c;
  --black: #000;
  --black-2: #121212;
  --black-3: #555;
  --black-4: #1d1d1d;
  --black-5: #343434;
  --black-6: #262626;
  --black-7: #555555;
  --black-13: #211e1d;
  --white: #fff;
  --white-2: #efefef;
  --white-3: #e9e9e9;
  --white-4: #f0f0f0;
  --white-5: #fbfbfb;
  --white-6: #d7d7d7;
  --gray: #c2c2c2;
  --gray-2: #e9dbd2;
  --gray-3: #a8a8a8;
  --gray-4: #f6f6f6;
  --gray-5: #bbb;
  --gray-6: #2b2b2b;
  --gray-7: #b9b9b9;
  --gray-8: #e9dbd3;
  --gray-9: #aaa;
  --gray-10: #7c7c7c;
  --gray-11: #d9d9d9;
  --gray-12: #383838;
  --gray-13: #ccc;
  --gray-14: #dfdfdf;
  --gray-15: #c0c0c0;
  --pink: #faede9;
  --pink-2: #ff9776;
  --pink-3: #f3ecec;
  --pink-4: #fffaf0;
  --pink-5: #e0e3cc;
  --bg-line: rgb(50, 67, 110);
  --d-gray: #6a6a6a;
  --d-black: #1a1a1a;
  --d-black-2: #171717;
  --d-black-3: #1a1a1a;
}

.pb-100 {
  padding-bottom: 100px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-80 {
  padding-bottom: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-100 {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-100 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 60px;
  }
}

.pb-110 {
  padding-bottom: 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-110 {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-110 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-110 {
    padding-bottom: 60px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-120 {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-130 {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-130 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-130 {
    padding-bottom: 60px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-140 {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-140 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-140 {
    padding-bottom: 60px;
  }
}

.pb-150 {
  padding-bottom: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-150 {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-150 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-150 {
    padding-bottom: 60px;
  }
}

.pb-200 {
  padding-bottom: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-200 {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-200 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-200 {
    padding-bottom: 60px;
  }
}

.pt-42 {
  padding-top: 40px;
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-100 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-100 {
    padding-top: 90px;
  }
}

.pt-110 {
  padding-top: 110px;
}

@media only screen and (max-width: 767px) {
  .pt-110 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-110 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-110 {
    padding-top: 90px;
  }
}

.pt-120 {
  padding-top: 120px;
}

@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-120 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-120 {
    padding-top: 90px;
  }
}

.pt-130 {
  padding-top: 130px;
}

@media only screen and (max-width: 767px) {
  .pt-130 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-130 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-130 {
    padding-top: 90px;
  }
}

.pt-140 {
  padding-top: 140px;
}

@media only screen and (max-width: 767px) {
  .pt-140 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-140 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-140 {
    padding-top: 90px;
  }
}

.pt-150 {
  padding-top: 150px;
}

@media only screen and (max-width: 767px) {
  .pt-150 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-150 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-150 {
    padding-top: 90px;
  }
}

.sp-x {
  padding-left: 100px;
  padding-right: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sp-x {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sp-x {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .sp-x {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.mt-60 {
  margin-top: 60px;
}

.ht-200 {
  padding-top: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-200 {
    padding-top: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-200 {
    padding-top: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .ht-200 {
    padding-top: 130px;
  }
}

@-webkit-keyframes wcRotate {
  0% {
    -webkit-transform: rotate(0deg) scale(1.1);
    transform: rotate(0deg) scale(1.1);
  }

  50% {
    -webkit-transform: rotate(5deg) scale(1.17);
    transform: rotate(5deg) scale(1.17);
  }

  100% {
    -webkit-transform: rotate(0deg) scale(1.1);
    transform: rotate(0deg) scale(1.1);
  }
}

@keyframes wcRotate {
  0% {
    -webkit-transform: rotate(0deg) scale(1.1);
    transform: rotate(0deg) scale(1.1);
  }

  50% {
    -webkit-transform: rotate(5deg) scale(1.17);
    transform: rotate(5deg) scale(1.17);
  }

  100% {
    -webkit-transform: rotate(0deg) scale(1.1);
    transform: rotate(0deg) scale(1.1);
  }
}

@-webkit-keyframes wcBubble {
  0% {
    scale: 1;
  }

  50% {
    scale: 1.5;
  }

  100% {
    scale: 1;
  }
}

@keyframes wcBubble {
  0% {
    scale: 1;
  }

  50% {
    scale: 1.5;
  }

  100% {
    scale: 1;
  }
}

@-webkit-keyframes wcZoom {
  0% {
    scale: 1;
  }

  50% {
    scale: 0.5;
  }

  100% {
    scale: 1;
  }
}

@keyframes wcZoom {
  0% {
    scale: 1;
  }

  50% {
    scale: 0.5;
  }

  100% {
    scale: 1;
  }
}

@-webkit-keyframes wcSlideBottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes wcSlideBottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes reveal {
  to {
    opacity: 1;
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }
}

@keyframes reveal {
  to {
    opacity: 1;
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }
}

@-webkit-keyframes wcfadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes wcfadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes wcSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes wcSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Kanit", sans-serif;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  position: relative;
  z-index: 1;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
}

button {
  background-color: transparent;
  border: 0;
}

.btn-theme-primary {
  color: var(--white);
  background-color: var(--dark-theme2);
  border-color: var(--dark-theme2);
  padding: 0.7rem 3rem;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(99, 127, 139, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(99, 127, 139, 0.3);
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 1.5rem;
}

.btn-theme-primary:hover {
  color: var(--white);
  background-color: #141f3d;
  border-color: var(--dark-theme2);
}

.list-group-item {
  color: var(--white);
  background: var(--dark-theme);
}

/* .btn-theme-primary:focus {
  color: var(--theme-text-color-1);
  background-color: var(--theme-primary-color);
  border-color: #71c9ec;
  -webkit-box-shadow: none;
  box-shadow: none
} */

form option {
  background: var(--dark-theme);
}

p {
  padding: 0;
  margin: 0;
  font-style: normal;
  line-height: 1.5;
  font-weight: 400;
  font-size: 1rem;
  color: var(--black-3);
  position: relative;
  z-index: 1;
}

video,
iframe,
img {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
}

table {
  font-size: 1rem;
}

@media (max-width: 500px) {
  table {
    font-size: 10px;
  }
}

.pb-30 {
  padding-bottom: 30px !important;
}

/* .table> :not(caption)>*>* {
  background-color: #141f3d;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #1a2b56;
}

.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: var(--dark-theme);
} */

.line {
  position: relative;
}

.line::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--white-4);
}

.line::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: calc(50% - 1px);
  top: 0;
  background: var(--white-4);
}

.line-3 {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  right: 0;
  top: 0;
  background: var(--white-4);
}

.shape {
  position: absolute;
  left: -90px;
  bottom: -350px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shape {
    left: 200%;
    bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .shape {
    left: 70%;
    bottom: 0;
  }
}

.shape .primary {
  width: 54px;
  height: 56px;
  background-color: var(--primary);
  margin-top: -37px;
  margin-left: 24px;
}

.shape .secondary {
  width: 53px;
  height: 56px;
  background-color: var(--secondary);
}

.pos-inherit {
  position: inherit;
}

@media only screen and (max-width: 767px) {
  .g-0 {
    padding-right: 15px;
    padding-left: 15px;
  }

  br {
    display: none;
  }
}

.sec-title-wrapper {
  position: relative;
  z-index: 9;
}

.footer-line {
  position: relative;
}

.footer-line::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 500px;
  left: 0;
  bottom: -400px;
  background: var(--white-4);
}

@media (max-width: 1023px) {
  .footer-line::after {
    height: 0;
  }
}

.scroll-top {
  /* width: 50px;
  height: 50px; */
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 12;
  /* border-radius: 2rem; */
  color: var(--white);
  background: var(--white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /* display: none; */
  /* mix-blend-mode: exclusion; */
  font-size: 1.125rem;
  color: var(--black-2);
  padding: 0.5rem 1rem;
}

.scroll-top i {
  font-size: 1.125rem;
  color: var(--black-2);
}

.scroll-top:hover {
  color: var(--gray-2);
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {
  .scroll-top {
    bottom: 80px;
  }
} */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .scroll-top {
    right: 10px;
    bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .scroll-top {
    right: 5px;
    bottom: 55px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .scroll-top {
    font-size: 0.7rem;
  }
}

.logo-light {
  display: none;
}

.logo-dark {
  display: block;
}

.font-20 {
  font-size: 1.25rem !important;
}

.dark {
  background-color: var(--dark-theme);
}

.dark h5 {
  color: var(--white);
}

.dark .logo-light {
  display: block;
}
.dark .logo-light img{
  width: 7.5rem;
}

@media (max-width: 720px) {
  .dark .logo-light img {
    width: 6rem;
  }
}

.dark .logo-dark {
  display: none;
}

.dark .portfolio__page section {
  margin-top: 0;
}

.dark .portfolio__page .wc-btn-dark:hover {
  color: var(--black-2);
  background-color: var(--white);
  border-color: var(--white);
}

.dark .wc-btn-primary,
.dark .wc-btn-black,
.dark .wc-btn-light,
.dark .wc-btn-pink,
.dark .wc-btn-secondary {
  border-color: var(--white);
  color: var(--gray-2);
}

.dark .wc-btn-black:hover {
  border-color: var(--white);
  color: var(--dark-theme);
}

.dark .wc-btn-black:hover span {
  background-color: var(--white);
}

.dark .line::before,
.dark .line::after,
.dark .line-3 {
  background-color: var(--bg-line);
}

.dark p {
  color: var(--gray-2);
}

.dark ul li {
  color: var(--gray-2);
}

.dark .sec-title,
.dark .sec-title-2,
.dark .sec-title-3,
.dark .sec-sub-title {
  color: var(--white);
}

.dark .main-dropdown {
  background-color: var(--white);
}

.dark .main-dropdown .sub-dropdown {
  background-color: var(--white);
}

.dark .main-dropdown li a {
  color: var(--black-2) !important;
}

.dark .main-dropdown li a .menu-text {
  text-shadow: 0 16px 0 var(--black-2) !important;
}

.dark .main-dropdown li a:hover {
  letter-spacing: 1px;
}

.dark .mega-menu,
.dark .mega-menu-2 {
  background-color: var(--white);
}

.dark .mega-menu a:not([href]),
.dark .mega-menu-2 a:not([href]),
.dark .mega-menu a:not([href]):hover {
  color: var(--black-2) !important;
}

.dark .mega-menu li a,
.dark .mega-menu-2 li a {
  color: var(--black-2) !important;
}

.dark .mega-menu li a .menu-text,
.dark .mega-menu-2 li a .menu-text {
  text-shadow: 0 16px 0 var(--black-2) !important;
}

.dark .mega-menu li a:hover,
.dark .mega-menu-2 li a:hover {
  letter-spacing: 1px;
}

.dark .header__area-3.sticky-3 {
  background-color: var(--black-2);
  border-color: var(--secondary);
}

.dark .header__area-5.sticky-5 {
  background-color: var(--black-2);
  border-color: var(--secondary);
}

.dark .hero__area-3 {
  background-color: var(--d-black-2);
}

.dark .hero__area-3::before {
  -webkit-filter: invert(0.9);
  filter: invert(0.9);
}

.dark .hero__area-3 .sec-sub-title,
.dark .hero__area-3 .sec-title,
.dark .hero__area-3 .intro-title .video-title {
  color: var(--white);
}

.dark .hero__area-3 .scroll-down img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .hero__area-3 .sec-sub-title::after {
  background-color: var(--white);
}

.dark .hero__area-5 {
  background-color: var(--black-2);
}

.dark .hero__area-5 img {
  -webkit-filter: invert(0);
  filter: invert(0);
}

.dark .hero__title-5,
.dark .hero__content-5 p {
  color: var(--white);
}

.dark .hero-title {
  color: var(--white);
}

.dark .hero__inner-3 .video-intro-title {
  color: var(--gray-2);
}

.dark .hero__inner-3 .video-intro-title span {
  color: var(--white);
}

.dark .hero__about {
  background-color: var(--d-black-2);
  background-blend-mode: color-burn;
}

.dark .hero__about-award::before {
  background-color: var(--secondary);
}

.dark .hero__about-award img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .about__area {
  background: var(--black-2);
}

.dark .about__area .sec-title {
  color: var(--white);
}

.dark .about__area-2 {
  background-color: var(--d-black-2);
}

.dark .about__content p {
  color: var(--gray-2);
}

.dark .counter__area {
  background-color: var(--black-2);
}

@media (max-width: 1200px) {
  .dark .counter__area-3 {
    background-color: var(--black-2);
  }
}

.dark .counter__area-3 .counter__number {
  color: var(--white);
}

.dark .counter__about {
  background-color: var(--d-black-2);
}

.dark .counter__number {
  color: var(--white);
}

.dark .counter__item {
  border-color: var(--secondary);
}

.dark .counter__item::before {
  background-color: transparent;
}

.dark .counter__item::after {
  background-color: transparent;
}

.dark .counter__img-3 img {
  -webkit-filter: contrast(0.5);
  filter: contrast(0.5);
}

.dark .workflow__area {
  background-color: var(--black-2);
}

.dark .workflow__area .sec-title {
  color: var(--white);
}

.dark .workflow__area-3 {
  background-color: var(--dark-theme);
}

.dark .workflow__area-6 {
  background-color: var(--d-black-2);
}

.dark .workflow__slide::before {
  background-color: var(--bg-line);
}

.dark .workflow__slide::after {
  border-color: var(--black-2);
}

.dark .workflow__wrapper-6 {
  border-color: var(--secondary);
}

.dark .workflow__number {
  color: var(--d-black-3);
}

.dark .workflow__title {
  color: var(--white);
}

.dark .workflow__title-6 {
  color: var(--white);
}

.dark .workflow__step {
  color: var(--white);
}

.dark .brand__area {
  padding: 0 0 30px 0;
  background-color: var(--dark-theme);
}

.dark .brand__area .sec-title {
  color: var(--white);
}

.dark .brand__about {
  background-color: var(--d-black-2);
}

.dark .brand__title-3 {
  color: var(--white);
}

.dark .brand__list,
.dark .brand__list-3,
.dark .brand__list-2 {
  background-color: transparent;
  border-color: var(--bg-line);
}

.dark .brand__item {
  border-color: var(--bg-line);
}

.dark .service__area {
  background-color: var(--dark-theme2);
}

.dark .service__area-2 {
  background-color: var(--black-2);
}

.dark .service__area-2.service-v3 {
  background-color: var(--d-black-2);
}

.dark .service__area-2 .sec-text p {
  color: var(--white);
}

.dark .service__area-3 {
  background-color: var(--d-black-2);
}

.dark .service__area-3 .sec-title-wrapper::after {
  background-color: var(--secondary);
}

.dark .service__area-3 .sec-sub-title {
  background-color: var(--d-black-2);
}

.dark .service__area-3 .sec-title {
  background-color: var(--d-black-2);
}

.dark .service__area-6 {
  background-color: var(--dark-theme);
}

.dark .service__item-2 p {
  color: var(--white);
}

.dark .service__item-2 ul li {
  color: var(--gray-2);
}

.dark .service__item-3,
.dark .service__item-3:first-child {
  border-color: var(--secondary);
}

.dark .service__title-3:hover,
.dark .service__title-2,
.dark .service__title-3 {
  color: var(--white);
}

.dark .service__content-3 ul li {
  color: var(--gray-2);
}

.dark .service__content-3 p {
  color: var(--white);
}

.dark .service__detail {
  background-color: var(--d-black-2);
}

.dark .service-v4.pb-150 {
  padding-bottom: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .service-v4.pb-150 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .service-v4.pb-150 {
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .dark .service-v4.pb-150 {
    padding-bottom: 60px;
  }
}

.dark .portfolio__area {
  background-color: var(--dark-theme2);
}

.dark .portfolio__area-2 {
  background-color: var(--black-2);
}

.dark .portfolio__area-2::after {
  background: var(--secondary);
}

.dark .portfolio__area-5 {
  background-color: var(--dark-theme);
  border-color: var(--dark-theme2);
}

.dark .portfolio__area-5::before,
.dark .portfolio__area-5::after,
.dark .portfolio__area-5 .sec-line-1,
.dark .portfolio__area-5 .sec-line-2 {
  -webkit-filter: invert(0);
  filter: invert(0);
}

.dark .portfolio__area-6 {
  background-color: var(--black-2);
}

.dark .portfolio__area-6 .pb-140 {
  padding-bottom: 0;
}

.dark .portfolio__area-6 .sec-title-wrapper p::before {
  background-color: var(--white);
}

.dark .portfolio-v4 {
  background-color: var(--d-black-2);
}

.dark .portfolio__slider-2::after {
  background: var(--secondary);
}

.dark .portfolio__slider-2-pagination .swiper-pagination-bullet {
  border-color: var(--secondary);
}

.dark .portfolio__slider-2-pagination .swiper-pagination-bullet::after {
  background-color: var(--secondary);
}

.dark .portfolio__slider-2-pagination .swiper-pagination-bullet-active,
.dark .portfolio__slider-2-pagination .swiper-pagination-bullet:hover {
  border-color: var(--white);
}

.dark .portfolio__slider-2-pagination .swiper-pagination-bullet-active::after,
.dark .portfolio__slider-2-pagination .swiper-pagination-bullet:hover::after {
  background-color: var(--white);
}

.dark .portfolio__slide-2 {
  background-color: var(--black-2);
}

.dark .portfolio__slide-2 .sec-title a {
  color: var(--white);
}

.dark .portfolio__slide-2 .btn-common {
  color: var(--gray);
}

.dark .portfolio__slide-2 .btn-common:hover {
  color: var(--white);
}

.dark .portfolio__detail {
  background-color: var(--black-2);
}

.dark .portfolio__detail-info ul li {
  color: var(--gray-2);
}

.dark .portfolio__detail-info ul li a,
.dark .portfolio__detail-info ul li span {
  color: var(--gray-2);
}

.dark .portfolio__detail-title {
  color: var(--white);
}

.dark .portfolio__detail-text p,
.dark .portfolio__detail-text li {
  color: var(--gray-2);
}

.dark .portfolio__detail-text .fonts img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .portfolio__detail-text .fonts ul .semibold,
.dark .portfolio__detail-text .fonts ul .medium {
  color: var(--gray-2);
}

.dark .portfolio__title-6,
.dark .portfolio__current {
  color: var(--white);
}

.dark .portfolio__date,
.dark .portfolio__pagination-6 {
  color: var(--gray-2);
}

.dark .portfolio__project {
  background-color: var(--dark-theme2);
}

.dark .portfolio__project::after {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .portfolio__project .pp-title,
.dark .portfolio__project .pp-slide-title,
.dark .portfolio__project .swipper-btn {
  color: var(--white);
}

.dark .portfolio__project .pp-slide-thumb p {
  color: var(--gray-2);
}

.dark .portfolio__project .pp-prev::after,
.dark .portfolio__project .pp-next::after {
  background-color: var(--white);
}

.dark .portfolio__footer {
  border-color: var(--secondary);
}

.dark .portfolio__footer-area {
  background-color: var(--black-2);
  background-blend-mode: overlay;
}

.dark .portfolio__footer-area::before {
  -webkit-filter: invert(0.8);
  filter: invert(0.8);
}

.dark .portfolio__footer-area::after {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .portfolio__footer-area .pf-title,
.dark .portfolio__footer-area .pf-contact h3,
.dark .portfolio__footer-area .pf-contact ul li a,
.dark .portfolio__footer-area .pf-social ul li a {
  color: var(--white);
}

.dark .portfolio__footer-area .pf-social ul li a:hover {
  color: var(--black-2);
  background-color: var(--white);
}

.dark .portfolio__service {
  background-color: var(--dark-theme);
  background-blend-mode: overlay;
}

.dark .portfolio__service .sec-text,
.dark .portfolio__service-item {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .portfolio__service .ps-btn a {
  color: var(--white);
  border-color: var(--white);
}

.dark .portfolio__hero .title {
  color: var(--white);
}

.dark .portfolio__hero .title.text-stroke {
  -webkit-text-stroke: 1px var(--white);
}

.dark .portfolio__hero .title.shape-circle::after {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .portfolio__hero-area {
  background-color: var(--black-2);
}

.dark .portfolio__hero-area::after {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .portfolio__about {
  background-color: var(--d-black-2);
}

.dark .portfolio__about::after {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .portfolio__about .sec-text p {
  color: var(--gray-2);
}

.dark .portfolio__about .sec-title span::before {
  background-color: var(--white);
}

.dark .portfolio__about-left img {
  -webkit-filter: invert(0.5);
  filter: invert(0.5);
}

.dark .portfolio__about .brand-title {
  color: var(--gray-2);
}

.dark .portfolio__about .about-row,
.dark .portfolio__about .brand-title-wrap {
  border-color: var(--secondary);
}

.dark .wc-btn-dark {
  color: var(--white);
  border-color: var(--white);
}

.dark .testimonial__area {
  background-color: var(--black-2);
}

.dark .testimonial__area .sec-title {
  color: var(--white);
}

.dark .testimonial__area-2 {
  background-color: var(--d-black-2);
}

.dark .testimonial__area-3 {
  background-color: var(--d-black-2);
  background-image: none;
}

.dark .testimonial__area-3 p::before {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .testimonial__pagination .next-button,
.dark .testimonial__pagination .prev-button {
  border-color: var(--secondary);
}

.dark .testimonial__pagination .next-button i,
.dark .testimonial__pagination .prev-button i {
  color: var(--secondary);
}

.dark .testimonial__pagination .next-button:hover,
.dark .testimonial__pagination .prev-button:hover {
  border-color: var(--white);
}

.dark .testimonial__pagination .next-button:hover i,
.dark .testimonial__pagination .prev-button:hover i {
  color: var(--white);
}

.dark .testimonial__title,
.dark .testimonial__title-2,
.dark .testimonial__author {
  color: var(--white);
}

.dark .testimonial__img.b-right::after {
  border-color: var(--secondary);
  z-index: 0;
}

.dark .testimonial__img.b-left::before {
  border-color: var(--bg-line);
  z-index: 1;
}

.dark .testimonial__role {
  color: var(--gray-2);
}

.dark .testimonial__slide-3 p {
  color: var(--white);
}

.dark .testimonial__inner-2 {
  background-color: var(--d-black-2);
}

.dark .testimonial__slider-3 .swipper-btn:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-color: var(--white);
}

.dark .client__name-3 {
  color: var(--white);
}

.dark .client__role-3 {
  color: var(--gray-2);
}

.dark .blog__area {
  background-color: var(--black-2);
}

.dark .blog__area.no-pb {
  margin-bottom: -150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .blog__area.no-pb {
    margin-bottom: -90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .blog__area.no-pb {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dark .blog__area.no-pb {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .blog__area .pb-140 {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .blog__area .pb-140 {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dark .blog__area .pb-140 {
    padding-bottom: 0;
  }
}

.dark .blog__area-2,
.dark .blog__area-6 {
  background-color: var(--d-black-2);
}

.dark .blog__area .sec-title {
  color: var(--white);
}

.dark .blog__area-3 {
  background-color: var(--d-black-2);
}

.dark .blog__area-3 .sec-sub-title,
.dark .blog__area-3 .sec-title {
  background-color: var(--d-black-2);
}

.dark .blog__area-3 .sec-title-wrapper::after {
  background-color: var(--secondary);
}

.dark .blog__info-3 {
  background-color: var(--d-black-2);
}

.dark .blog__title,
.dark .blog__title-3,
.dark .blog__title-2 {
  color: var(--white);
  border-color: var(--dark-theme2);
}

.dark .blog__title:hover,
.dark .blog__title-3:hover,
.dark .blog__title-2:hover {
  color: var(--gray-2);
}

.dark .blog__btn {
  color: var(--gray-2);
}

.dark .blog__btn:hover {
  color: var(--white);
}

.dark .blog__meta,
.dark .blog__meta-2 {
  color: var(--gray-2);
}

.dark .blog__meta a,
.dark .blog__meta-2 a {
  color: var(--gray-2);
}

.dark .blog__meta a:hover,
.dark .blog__meta-2 a:hover {
  color: var(--white);
}

.dark .blog__detail {
  background-color: var(--dark-theme);
}

.dark .blog__detail-title,
.dark .blog__detail-date {
  color: var(--white);
}

.dark .blog__detail-date span,
.dark .blog__detail ul li {
  color: var(--gray-2);
}

.dark .blog__detail-tags p {
  color: var(--white);
}

.dark .blog__detail-tags p a {
  color: var(--gray-2);
}

.dark .blog__detail-content h1,
.dark .blog__detail-content h2,
.dark .blog__detail-content h3,
.dark .blog__detail-content h4,
.dark .blog__detail-content h5,
.dark .blog__detail-content h6 {
  color: var(--white);
}

.dark .blog__detail-meta p span {
  color: var(--gray-2);
}

.dark .blog__related {
  background-color: var(--dark-theme);
}

.dark .team__area {
  background-color: var(--d-black-2);
}

.dark .team__area-6 {
  background-color: var(--dark-theme2);
  background-blend-mode: color-burn;
}

.dark .team__about {
  background-color: var(--black-2);
}

.dark .team__btm {
  background-color: var(--black-2);
}

.dark .team__join-btn {
  background-color: var(--d-black-2);
}

.dark .team__member-name-6,
.dark .team__member-name-7 {
  color: var(--white);
  /* margin-bottom: 1rem; */
}

.dark .team__member-role-6,
.dark .team__member-role-7 {
  color: var(--gray-2);
  /* margin-bottom: 1rem; */
}

.dark .team__member-role-7 span {
  color: var(--white);
}

.dark .team__member-role-7::after {
  background-color: var(--white);
}

.dark .team__member-work li a {
  color: var(--white);
}

.dark .team__member-work li a:hover {
  color: var(--black-2);
  background-color: var(--white);
}

.dark .team__member-social li a:hover {
  color: var(--white);
}

.dark .team__detail {
  background-color: var(--dark-theme) !important;
}

.dark .team__detail.pb-140 {
  padding-bottom: 60px;
}

.dark .team__detail-page .line-1,
.dark .team__detail-page .line-2,
.dark .team__detail-page .line-3 {
  background: var(--bg-line);
}

.dark .team__detail .work-title {
  color: var(--white);
}

.dark .cta__area {
  background-color: var(--black-2);
}

.dark .cta__area .line {
  padding-top: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .cta__area .line {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .cta__area .line {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .dark .cta__area .line {
    padding-top: 60px;
  }
}

.dark .cta__area .line.no-p {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .cta__area .line.no-p {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .dark .cta__area .line.no-p {
    padding-top: 60px;
  }
}

.dark .cta__area .dark-p {
  padding-top: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .cta__area .dark-p {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .cta__area .dark-p {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .dark .cta__area .dark-p {
    padding-top: 60px;
  }
}

.dark .cta__area-2 {
  background-color: var(--black-2);
}

.dark .cta__area-2 .wc-btn-pink {
  color: var(--gray-2);
}

.dark .cta__area-3 {
  background-color: var(--dark-theme2);
}

.dark .cta__area-5 {
  background-color: var(--black-2);
  -webkit-filter: invert(0);
  filter: invert(0);
  margin: 0;
}

.dark .cta__title {
  color: var(--white);
}

.dark .cta__title-2 {
  color: var(--white);
}

.dark .cta__sub-title {
  color: var(--white);
}

@media only screen and (max-width: 767px) {
  .dark .cta__content {
    padding-top: 0px;
  }
}

.dark .footer__area {
  background-color: var(--dark-theme);
}

.dark .footer__area-2 {
  background-color: var(--black-2);
  background-blend-mode: multiply;
  background-position: 100% 1px;
}

.dark .footer__area-5 {
  background-color: var(--black-2);
}

.dark .footer__inner {
  background-color: #141f3d;
}

.dark .footer__middle-2 {
  border-color: var(--secondary);
}

.dark .footer__subscribe-2::before {
  background-color: var(--secondary);
}

.dark .footer__subscribe-2 input {
  color: var(--white);
  border-color: var(--white);
  background-color: transparent;
}

.dark .footer__subscribe-2 input::-webkit-input-placeholder {
  color: var(--white);
}

.dark .footer__subscribe-2 input::-moz-placeholder {
  color: var(--white);
}

.dark .footer__subscribe-2 input:-ms-input-placeholder {
  color: var(--white);
}

.dark .footer__subscribe-2 input::-ms-input-placeholder {
  color: var(--white);
}

.dark .footer__subscribe-2 input::placeholder {
  color: var(--white);
}

.dark .footer__subscribe-2 img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .footer-menu li a,
.dark .footer-menu-2 li a {
  color: var(--white);
}

.dark .footer-menu li a .menu-text,
.dark .footer-menu-2 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}

.dark .footer__copyright-2 p a {
  color: var(--white);
}

.dark .footer__copyright-4 a {
  color: var(--white);
}

.dark .footer-line::after {
  background: var(--bg-line);
}

.dark .footer__location-2 .location h3 {
  color: var(--white);
}

.dark .feature__area-2 {
  background-color: var(--d-black-2);
}

.dark .feature__top {
  border-color: var(--secondary);
}

.dark .feature__item {
  border-color: var(--secondary);
}

.dark .feature__item:nth-child(odd) {
  border-color: var(--secondary);
}

.dark .feature__item img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .feature__item p {
  color: var(--gray-2);
}

.dark .feature__title {
  color: var(--white);
}

.dark .feature__text p {
  color: var(--white);
}

.dark .award__area {
  background-color: var(--d-black-2);
}

.dark .main-menu-3 li a {
  color: var(--white);
}

.dark .main-menu .mega-menu li a,
.dark .main-menu .mega-menu-2 li a,
.dark .main-menu-4 .mega-menu li a,
.dark .main-menu-4 .mega-menu-2 li a {
  color: var(--black-2);
}

.dark .header__nav-icon-3 button {
  color: var(--white);
}

.dark .header__nav-icon-3 img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .choose-title {
  color: var(--white);
}

.dark .research__area {
  background-color: var(--dark-theme2);
  background-blend-mode: exclusion;
}

@media (max-width: 1200px) {
  .dark .research__area {
    background-color: var(--black-2);
    padding-bottom: 0;
  }
}

.dark .research__area .sec-sub-title,
.dark .research__area .sec-title-wrapper p {
  color: var(--white);
}

@media only screen and (max-width: 767px) {
  .dark .research__list {
    padding-bottom: 0;
  }
}

.dark .research__item p,
.dark .research__number span,
.dark .research__title {
  color: var(--white);
}

.dark .research__tools li a {
  color: var(--white);
  border-color: var(--white);
}

.dark .research__tools li a:hover {
  color: var(--black-2);
  background-color: var(--white);
}

.dark .price__area {
  background-color: var(--black-2);
}

.dark .price__item:first-child {
  background-color: var(--d-black-2);
}

.dark .story__area {
  background-color: var(--d-black-2);
}

.dark .story__area .line {
  padding-bottom: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dark .story__area .line {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .story__area .line {
    padding-bottom: 90px;
  }
}

.dark .story__text p {
  color: var(--gray-2);
}

.dark .error__page {
  background-color: var(--d-black-2);
}

.dark .error__content h2 {
  color: var(--white);
}

.dark .error__content img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .career__top {
  background-color: var(--black-2);
}

.dark .career__gallery {
  background-color: var(--black-2);
}

.dark .career__gallery ul li {
  color: var(--white);
}

.dark .career__gallery ul li::after {
  background-color: var(--white);
}

.dark .career__gallery p {
  color: var(--gray-2);
}

.dark .career__benefits {
  background-color: var(--black-2);
}

.dark .career__benefits-list li {
  color: var(--white);
  border-color: var(--bg-line);
}

.dark .contact__area-6 {
  background-color: var(--dark-theme);
}

.dark .contact__form input::-webkit-input-placeholder,
.dark .contact__form textarea::-webkit-input-placeholder {
  color: var(--white);
  border-color: var(--bg-line);
}

.dark .contact__form input::-moz-placeholder,
.dark .contact__form textarea::-moz-placeholder {
  color: var(--white);
  border-color: var(--bg-line);
}

.dark .contact__form input:-ms-input-placeholder,
.dark .contact__form textarea:-ms-input-placeholder {
  color: var(--white);
  border-color: var(--bg-line);
}

.dark .contact__form input::-ms-input-placeholder,
.dark .contact__form textarea::-ms-input-placeholder {
  color: var(--white);
  border-color: var(--bg-line);
}

.dark .contact__form textarea,
.dark .contact__form input,
.dark .contact__form select,
.dark .contact__form input::placeholder,
.dark .contact__form textarea::placeholder {
  color: var(--white);
  background: transparent;
  border-color: var(--bg-line);
}

.dark .contact__form textarea:focus,
.dark .contact__form input:focus {
  background: var(--dark-theme) !important;
  border-color: var(--dark-theme);
}

.dark .contact__info h3,
.dark .contact__info ul li span,
.dark .contact__info ul li a {
  color: var(--white);
}

.dark .faq__area {
  background-color: var(--d-black-2);
}

.dark .faq__area-6 {
  background-color: var(--black-2);
}

.dark .faq__btm {
  background-color: var(--black-2);
}

.dark .faq__list .accordion-button,
.dark .faq__list-3 .accordion-button,
.dark .faq__list-6 .accordion-button {
  color: var(--white);
}

.dark .faq__title {
  color: var(--white);
}

.dark .faq__list .accordion-item,
.dark .faq__list-6 .accordion-item,
.dark .faq__list-3 .accordion-item {
  background-color: transparent;
}

.dark .faq__list-3 .accordion-item {
  border-color: var(--bg-line);
}

.dark .faq__list-3 .accordion-item:first-child {
  border-color: var(--bg-line);
}

.dark .accordion-item {
  background-color: var(--black-2);
  border-color: var(--bg-line);
}

.dark .service__faq .accordion-item {
  background-color: var(--d-black-2);
}

.dark .job__detail {
  background-color: var(--dark-theme) !important;
}

.dark .job__detail-top {
  background-color: var(--d-black-2);
}

.dark .job__detail-content {
  border-color: var(--bg-line);
}

.dark .job__detail-content ul li {
  color: var(--gray-2);
}

.dark .job__detail-content h1,
.dark .job__detail-content h2,
.dark .job__detail-content h3,
.dark .job__detail-content h4,
.dark .job__detail-content h5,
.dark .job__detail-content h6 {
  color: var(--white);
}

.dark .job__detail-meta li {
  color: var(--gray-2);
}

.dark .job__detail-sidebar {
  background-color: var(--dark-theme2) !important;
}

.dark .job__detail-sidebar ul li {
  color: var(--gray-2);
}

.dark .development__area {
  background-color: var(--d-black-2);
}

.dark .development__wrapper ul li {
  color: var(--gray-2);
}

.dark .development__content {
  border-color: var(--secondary);
}

.dark .solution__area {
  background-color: var(--black-2);
}

.dark .solution__title {
  color: var(--white);
}

.dark .solution__mid p {
  color: var(--gray-2);
}

.dark .solution__mid p::before {
  background-color: var(--white);
}

.dark .solution__btm li {
  color: var(--white);
}

.dark .solution__img-1::after,
.dark .solution__img-2::after,
.dark .solution__img-3::after {
  border-color: var(--secondary);
  z-index: 0;
}

.dark .solution__shape .shape-5 {
  z-index: 0;
}

.dark .solution__shape .shape-2 {
  -webkit-filter: invert(1);
  filter: invert(1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dark .solution__shape .shape-4 {
    top: 55%;
  }
}

.dark .modal__apply {
  background-color: var(--d-black-2);
}

.dark .modal__apply .input-apply p,
.dark .modal__apply .form-top p {
  color: var(--white);
}

.dark .modal__apply .input-apply-2 p,
.dark .modal__apply .input-apply-2 input[type="file"] {
  color: var(--white);
}

.dark .modal__apply .form-top img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .modal .close_btn-2:hover {
  color: var(--white);
}

.dark .modal__close-2:hover {
  color: var(--white);
}

.dark .menu-text-5 {
  color: var(--white);
}

.dark .circle-pagination button.swiper-pagination-bullet {
  border-color: var(--white);
}

.dark .circle-pagination button.swiper-pagination-bullet span {
  background-color: var(--white);
}

.dark .circle-pagination .circle-origin {
  stroke: var(--white);
}

.dark .circle-pagination-2 .swiper-pagination-bullet {
  border-color: var(--white);
}

.dark .circle-pagination-2 .swiper-pagination-bullet::after {
  background-color: var(--white);
}

.dark .bg-white {
  background-color: var(--dark-theme) !important;
}

.dark .main-menu-3 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}

.dark .header__nav-icon-5 img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .menu-text-pp {
  color: var(--white);
}

.dark .job__area {
  background-color: var(--d-black-2);
}

.dark .portfolio__area-5 .sec-line {
  background-color: #d3d3d3;
}

.dark .modal__apply .input-apply textarea,
.dark .modal__apply .input-apply-2 input {
  color: var(--white);
  background-color: var(--d-black-2);
  border-color: var(--black-3);
}

.dark .modal__apply .input-apply textarea:focus,
.dark .modal__apply .input-apply-2 input:focus {
  border-color: var(--white);
}

.dark .error__content::before {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .sticky-2 .header__nav-icon-5 {
  -webkit-filter: invert(0);
  filter: invert(0);
}

.dark .footer__menu-5 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}

.dark .hero__area-3::after {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .service__hero-2 {
  background-color: var(--dark-theme);
  background-image: none;
}

.dark .service__hero-2 .shape-1 {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .service__hero-right-2 .title {
  color: var(--white);
}

.dark .service__hero-right-2 .scroll {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.wc-btn-primary,
.wc-btn-black,
.wc-btn-light,
.wc-btn-pink,
.wc-btn-secondary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3;
  text-transform: capitalize;
  color: var(--black-3);
  border: 1px solid var(--gray);
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .wc-btn-primary,
  .wc-btn-black,
  .wc-btn-light,
  .wc-btn-pink,
  .wc-btn-secondary {
    padding: 30px;
  }
}

.wc-btn-primary:hover,
.wc-btn-black:hover,
.wc-btn-light:hover,
.wc-btn-pink:hover,
.wc-btn-secondary:hover {
  color: var(--black-2);
  border: 1px solid var(--primary);
}

.wc-btn-primary:hover span,
.wc-btn-black:hover span,
.wc-btn-light:hover span,
.wc-btn-pink:hover span,
.wc-btn-secondary:hover span {
  width: 350px;
  height: 350px;
}

.wc-btn-primary i,
.wc-btn-black i,
.wc-btn-light i,
.wc-btn-pink i,
.wc-btn-secondary i {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  padding-left: 5px;
}

.wc-btn-primary span,
.wc-btn-black span,
.wc-btn-light span,
.wc-btn-pink span,
.wc-btn-secondary span {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  background-color: var(--primary);
  z-index: -1;
  border-radius: 100%;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.wc-btn-secondary {
  border: 1px solid var(--secondary);
  color: var(--gray-2);
}

.wc-btn-pink {
  border: 1px solid var(--secondary);
  color: var(--gray-2);
}

.wc-btn-pink:hover {
  border-color: var(--pink-2);
}

.wc-btn-pink span {
  background-color: var(--pink-2);
}

.wc-btn-light {
  border: 1px solid var(--secondary);
  color: var(--gray-2);
}

.wc-btn-light:hover {
  border-color: var(--white);
}

.wc-btn-light span {
  background-color: var(--white);
}

.wc-btn-black:hover {
  color: var(--white);
  border-color: var(--black-2);
}

.wc-btn-black span {
  background-color: var(--black-2);
}

.wc-btn-dark {
  display: inline-block;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  color: var(--black-2);
  border: 1.3px solid var(--black-2);
  border-radius: 33px;
  padding: 20px 54px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.wc-btn-dark:hover {
  color: var(--black-2);
  background-color: var(--white);
  border-color: var(--white);
}

.btn_wrapper,
#btn_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  height: 250px;
  width: 250px;
  border-radius: 100%;
  margin-left: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .btn_wrapper,
  #btn_wrapper {
    width: 220px;
    height: 220px;
    margin-left: -25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn_wrapper,
  #btn_wrapper {
    width: 190px;
    height: 190px;
    margin-left: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn_wrapper,
  #btn_wrapper {
    width: 180px;
    height: 180px;
    margin-left: -5px;
  }
}

@media only screen and (max-width: 767px) {
  .btn_wrapper,
  #btn_wrapper {
    height: 175px;
    width: 175px;
    margin-left: -2.5px;
  }
}

.btn-item {
  position: absolute;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__top-btn .btn_wrapper,
  .service__top-btn #btn_wrapper {
    width: 170px;
    height: 170px;
  }

  .service__top-btn .btn_wrapper .btn-item,
  .service__top-btn #btn_wrapper .btn-item {
    top: 0;
    left: 0;
  }
}

.about_btn {
  font-size: 1.25rem !important;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .about_btn {
    font-size: 1.125rem !important;
  }
}

.about_btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  bottom: -10px;
  left: 0;
  background-color: var(--black-7);
}

.sec-title {
  font-weight: 600;
  font-size: 1.75rem;
  margin-top: 3rem;
  line-height: 1;
  padding-bottom: 20px !important;
  color: var(--black-2);
  /* text-transform: uppercase !important; */
}
.onerem {
  margin-top: 1rem;
}
.pointer-events-none {
  pointer-events: none;
}
.sub-section-title {
  font-weight: 600;
  font-size: 1.75rem;
  margin-top: 0rem !important;
  line-height: 1;
  padding-bottom: 20px !important;
  color: var(--black-2);
  /* text-transform: uppercase !important; */
}

.sec-title-2 {
  font-weight: 500;
  font-size: 120px;
  line-height: 0.9;
  color: var(--black-2);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .sec-title-2 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sec-title-2 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-2 {
    font-size: 72px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-2 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .sec-title-2 {
    font-size: 45px;
  }
}

.sec-title-3 {
  font-weight: 500;
  font-size: 90px;
  line-height: 1;
  color: var(--black-2);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .sec-title-3 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sec-title-3 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-3 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-3 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .sec-title-3 {
    font-size: 38px;
  }
}

.sec-title-4 {
  font-size: 150px;
  line-height: 1;
  color: var(--black-2);
  text-transform: uppercase;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sec-title-4 {
    font-size: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-4 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-4 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .sec-title-4 {
    font-size: 48px;
  }
}

.sec-title-4 span {
  font-family: "newYork";
  padding-left: 298px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .sec-title-4 span {
    padding-left: 0;
  }
}

.sec-title-4 img {
  margin-top: -30px;
  -webkit-animation: wcSpinner 3s infinite linear;
  animation: wcSpinner 3s infinite linear;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-4 img {
    max-width: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .sec-title-4 img {
    display: none;
  }
}

.sec-title-5 {
  padding-left: 98px;
}

@media only screen and (max-width: 767px) {
  .sec-title-5 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-5 {
    font-size: 120px;
    line-height: 8.3rem;
  }
}

.sec-title-7 {
  padding-left: 185px;
}

.sec-title-8 {
  font-size: 130px;
  line-height: 1;
  font-weight: 500;
  color: var(--black-2);
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-8 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-8 {
    font-size: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .sec-title-8 {
    font-size: 60px;
  }
}

.sec-title-8 a {
  font-family: "newYork";
  text-transform: capitalize;
}

.sec-title-16 {
  font-weight: 500;
  font-size: 120px;
  line-height: 0.9;
  color: var(--black-2);
  text-indent: 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sec-title-16 {
    font-size: 100px;
    text-indent: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title-16 {
    font-size: 90px;
    text-indent: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-title-16 {
    text-indent: 60px;
    font-size: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .sec-title-16 {
    text-indent: 20px;
    font-size: 48px;
  }
}

.sec-title-16 span {
  color: var(--black-2);
  font-family: "newYork";
  position: relative;
}

.sec-title-16 span::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 10px;
  left: 0;
  background-color: var(--black-2);
}

@media only screen and (max-width: 767px) {
  .sec-title-16 span::after {
    bottom: 0;
  }
}

.sec-sub-title {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.2;
  color: var(--black-3);
  padding-bottom: 0px;
  margin-bottom: 0px !important;
  text-transform: capitalize;
}
.our-pillar-sec-sub-title {
  padding-bottom: 15px;
}
.hero-title {
  font-weight: 500;
  font-size: 80px;
  line-height: 1;
  color: var(--black);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-title {
    font-size: 36px;
  }
}

.title-line {
  overflow: hidden;
}

@media (min-width: 1200px) {
  .img-anim {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 565px;
  }

  .img-anim img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 120%;
    margin-left: auto;
    margin-right: auto;
  }
}

.dis_port_4 {
  max-width: 300px;
  text-indent: 90px;
  margin-left: 100px;
  margin-top: 40px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-3);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dis_port_4 {
    text-indent: 50px;
    margin-left: 60px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dis_port_4 {
    text-indent: 30px;
    margin-left: 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dis_port_4 {
    margin-left: 20px;
    text-indent: 30px;
    margin-top: 20px;
  }
}

.section_title_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 130px 50px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section_title_wrapper {
    padding: 110px 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_title_wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .section_title_wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 120px 15px 0;
  }
}

.section_title_wrapper .title_right {
  padding-top: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_title_wrapper .title_right {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .section_title_wrapper .title_right {
    padding-top: 50px;
  }
}

.section_title_wrapper .title_right__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.section_title_wrapper .title_right__inner__left {
  margin-right: 60px;
}

.section_title_wrapper .title_right__inner__left span {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.7;
  text-align: right;
  color: var(--black-3);
  display: inline-block;
  position: relative;
}

.section_title_wrapper .title_right__inner__left span::before {
  content: "";
  position: absolute;
  right: 80px;
  top: 50%;
  background-color: #d9d9d9;
  width: 1090px;
  height: 1px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .section_title_wrapper .title_right__inner__left span::before {
    width: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section_title_wrapper .title_right__inner__left span::before {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section_title_wrapper .title_right__inner__left span::before {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_title_wrapper .title_right__inner__left span::before {
    width: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .section_title_wrapper .title_right__inner__left span::before {
    display: none;
  }
}

.section_title_wrapper .title_right__inner__right ul li {
  margin-bottom: 10px;
}

.section_title_wrapper .title_right__inner__right ul li a {
  font-size: 1.125rem;
  color: var(--black-13);
  text-transform: capitalize;
}

.section_title_wrapper .title_right__inner__right ul li a:hover {
  letter-spacing: 1px;
  color: var(--black-2);
}

.dark .sec-title-4 {
  color: var(--white);
}

.dark .sec-title-4 img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .sec-title-8 {
  color: var(--white);
}

.dark .sec-title-16 {
  color: var(--white);
}

.dark .sec-title-16 span {
  color: var(--white);
}

.dark .sec-title-16 span::after {
  background-color: var(--white);
}

.dark .section_title_wrapper .title_right__inner__left span {
  color: var(--gray-2);
}

.dark .section_title_wrapper .title_right__inner__left span::before {
  background-color: var(--gray-2);
}

.dark .section_title_wrapper .title_right__inner__right ul li a {
  color: var(--gray-2);
}

.dark .section_title_wrapper .title_right__inner__right ul li a:hover {
  color: var(--white);
}

.main-menu > li,
.main-menu-4 > li {
  display: inline-block;
  margin: 0 6px;
}

.main-menu-4 li:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 1199px) and (max-width: 1300px) {
  .main-menu > li,
  .main-menu-4 > li {
    margin: 0 6px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > li,
  .main-menu-4 > li {
    margin: 0 19px;
  }
}

.main-menu > li.has-megamenu,
.main-menu-4 > li.has-megamenu {
  position: static;
}

.main-menu > li:hover .main-dropdown,
.main-menu-4 > li:hover .main-dropdown {
  top: 75px;
  opacity: 1;
  visibility: visible;
}

.main-menu > li:hover .mega-menu,
.main-menu > li:hover .mega-menu-2,
.main-menu-4 > li:hover .mega-menu,
.main-menu-4 > li:hover .mega-menu-2 {
  top: 75px;
  opacity: 1;
  visibility: visible;
}

.main-menu > li > a,
.main-menu-4 > li > a {
  display: block;
  font-weight: 400;
  font-size: 1rem;
  line-height: 16px;
  color: var(--white);
  padding: 29px 0;
  outline: none;
  text-transform: capitalize;
  -webkit-transform: translateY(var(--y)) translateZ(0);
  transform: translateY(var(--y)) translateZ(0);
  -webkit-transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease,
    -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
}

.main-menu > li > a .menu-text,
.main-menu-4 > li > a .menu-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--white);
}

.main-menu > li > a .menu-text span,
.main-menu-4 > li > a .menu-text span {
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-style: normal;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translateY(var(--m)) translateZ(0);
  transform: translateY(var(--m)) translateZ(0);
}

.main-menu > li > a .menu-text span:nth-child(1),
.main-menu-4 > li > a .menu-text span:nth-child(1) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.main-menu > li > a .menu-text span:nth-child(2),
.main-menu-4 > li > a .menu-text span:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.main-menu > li > a .menu-text span:nth-child(3),
.main-menu-4 > li > a .menu-text span:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.main-menu > li > a .menu-text span:nth-child(4),
.main-menu-4 > li > a .menu-text span:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.main-menu > li > a .menu-text span:nth-child(5),
.main-menu-4 > li > a .menu-text span:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.main-menu > li > a .menu-text span:nth-child(6),
.main-menu-4 > li > a .menu-text span:nth-child(6) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.main-menu > li > a .menu-text span:nth-child(7),
.main-menu-4 > li > a .menu-text span:nth-child(7) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.main-menu > li > a .menu-text span:nth-child(8),
.main-menu-4 > li > a .menu-text span:nth-child(8) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.main-menu > li > a .menu-text span:nth-child(9),
.main-menu-4 > li > a .menu-text span:nth-child(9) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.main-menu > li > a .menu-text span:nth-child(10),
.main-menu-4 > li > a .menu-text span:nth-child(10) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.main-menu > li > a .menu-text span:nth-child(11),
.main-menu-4 > li > a .menu-text span:nth-child(11) {
  -webkit-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

.main-menu > li > a .menu-text span:nth-child(12),
.main-menu-4 > li > a .menu-text span:nth-child(12) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.main-menu > li > a .menu-text span:nth-child(13),
.main-menu-4 > li > a .menu-text span:nth-child(13) {
  -webkit-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.main-menu > li > a .menu-text span:nth-child(14),
.main-menu-4 > li > a .menu-text span:nth-child(14) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.main-menu > li > a .menu-text span:nth-child(15),
.main-menu-4 > li > a .menu-text span:nth-child(15) {
  -webkit-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.main-menu > li > a .menu-text span:nth-child(16),
.main-menu-4 > li > a .menu-text span:nth-child(16) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.main-menu > li > a .menu-text span:nth-child(17),
.main-menu-4 > li > a .menu-text span:nth-child(17) {
  -webkit-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

.main-menu > li > a .menu-text span:nth-child(18),
.main-menu-4 > li > a .menu-text span:nth-child(18) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.main-menu > li > a .menu-text span:nth-child(19),
.main-menu-4 > li > a .menu-text span:nth-child(19) {
  -webkit-transition-delay: 0.95s;
  transition-delay: 0.95s;
}

.main-menu > li > a .menu-text span:nth-child(20),
.main-menu-4 > li > a .menu-text span:nth-child(20) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.main-menu > li > a .menu-text span:nth-child(21),
.main-menu-4 > li > a .menu-text span:nth-child(21) {
  -webkit-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

.main-menu > li > a .menu-text span:nth-child(22),
.main-menu-4 > li > a .menu-text span:nth-child(22) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.main-menu > li > a .menu-text span:nth-child(23),
.main-menu-4 > li > a .menu-text span:nth-child(23) {
  -webkit-transition-delay: 1.15s;
  transition-delay: 1.15s;
}

.main-menu > li > a .menu-text span:nth-child(24),
.main-menu-4 > li > a .menu-text span:nth-child(24) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.main-menu > li > a .menu-text span:nth-child(25),
.main-menu-4 > li > a .menu-text span:nth-child(25) {
  -webkit-transition-delay: 1.25s;
  transition-delay: 1.25s;
}

.main-menu > li > a .menu-text span:nth-child(26),
.main-menu-4 > li > a .menu-text span:nth-child(26) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.main-menu > li > a .menu-text span:nth-child(27),
.main-menu-4 > li > a .menu-text span:nth-child(27) {
  -webkit-transition-delay: 1.35s;
  transition-delay: 1.35s;
}

.main-menu > li > a .menu-text span:nth-child(28),
.main-menu-4 > li > a .menu-text span:nth-child(28) {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.main-menu > li > a .menu-text span:nth-child(29),
.main-menu-4 > li > a .menu-text span:nth-child(29) {
  -webkit-transition-delay: 1.45s;
  transition-delay: 1.45s;
}

.main-menu > li > a .menu-text span:nth-child(30),
.main-menu-4 > li > a .menu-text span:nth-child(30) {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.main-menu > li > a .menu-text span:nth-child(31),
.main-menu-4 > li > a .menu-text span:nth-child(31) {
  -webkit-transition-delay: 1.55s;
  transition-delay: 1.55s;
}

.main-menu > li > a .menu-text span:nth-child(32),
.main-menu-4 > li > a .menu-text span:nth-child(32) {
  -webkit-transition-delay: 1.6s;
  transition-delay: 1.6s;
}

.main-menu > li > a .menu-text span:nth-child(33),
.main-menu-4 > li > a .menu-text span:nth-child(33) {
  -webkit-transition-delay: 1.65s;
  transition-delay: 1.65s;
}

.main-menu > li > a .menu-text span:nth-child(34),
.main-menu-4 > li > a .menu-text span:nth-child(34) {
  -webkit-transition-delay: 1.7s;
  transition-delay: 1.7s;
}

.main-menu > li > a .menu-text span:nth-child(35),
.main-menu-4 > li > a .menu-text span:nth-child(35) {
  -webkit-transition-delay: 1.75s;
  transition-delay: 1.75s;
}

.main-menu > li > a .menu-text span:nth-child(36),
.main-menu-4 > li > a .menu-text span:nth-child(36) {
  -webkit-transition-delay: 1.8s;
  transition-delay: 1.8s;
}

.main-menu > li > a .menu-text span:nth-child(37),
.main-menu-4 > li > a .menu-text span:nth-child(37) {
  -webkit-transition-delay: 1.85s;
  transition-delay: 1.85s;
}

.main-menu > li > a .menu-text span:nth-child(38),
.main-menu-4 > li > a .menu-text span:nth-child(38) {
  -webkit-transition-delay: 1.9s;
  transition-delay: 1.9s;
}

.main-menu > li > a .menu-text span:nth-child(39),
.main-menu-4 > li > a .menu-text span:nth-child(39) {
  -webkit-transition-delay: 1.95s;
  transition-delay: 1.95s;
}

.main-menu > li > a .menu-text span:nth-child(40),
.main-menu-4 > li > a .menu-text span:nth-child(40) {
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}

.main-menu > li > a .menu-text span:nth-child(41),
.main-menu-4 > li > a .menu-text span:nth-child(41) {
  -webkit-transition-delay: 2.05s;
  transition-delay: 2.05s;
}

.main-menu > li > a .menu-text span:nth-child(42),
.main-menu-4 > li > a .menu-text span:nth-child(42) {
  -webkit-transition-delay: 2.1s;
  transition-delay: 2.1s;
}

.main-menu > li > a .menu-text span:nth-child(43),
.main-menu-4 > li > a .menu-text span:nth-child(43) {
  -webkit-transition-delay: 2.15s;
  transition-delay: 2.15s;
}

.main-menu > li > a .menu-text span:nth-child(44),
.main-menu-4 > li > a .menu-text span:nth-child(44) {
  -webkit-transition-delay: 2.2s;
  transition-delay: 2.2s;
}

.main-menu > li > a .menu-text span:nth-child(45),
.main-menu-4 > li > a .menu-text span:nth-child(45) {
  -webkit-transition-delay: 2.25s;
  transition-delay: 2.25s;
}

.main-menu > li > a .menu-text span:nth-child(46),
.main-menu-4 > li > a .menu-text span:nth-child(46) {
  -webkit-transition-delay: 2.3s;
  transition-delay: 2.3s;
}

.main-menu > li > a .menu-text span:nth-child(47),
.main-menu-4 > li > a .menu-text span:nth-child(47) {
  -webkit-transition-delay: 2.35s;
  transition-delay: 2.35s;
}

.main-menu > li > a .menu-text span:nth-child(48),
.main-menu-4 > li > a .menu-text span:nth-child(48) {
  -webkit-transition-delay: 2.4s;
  transition-delay: 2.4s;
}

.main-menu > li > a .menu-text span:nth-child(49),
.main-menu-4 > li > a .menu-text span:nth-child(49) {
  -webkit-transition-delay: 2.45s;
  transition-delay: 2.45s;
}

.main-menu > li > a:hover,
.main-menu-4 > li > a:hover {
  --y: -4px;
}

.main-menu > li > a:hover span,
.main-menu-4 > li > a:hover span {
  --m: calc(16px * -1);
}

.main-menu-3 {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .main-menu-3 {
    display: none;
  }
}

.main-menu-3 > li {
  display: inline-block;
  padding: 0 40px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-3 > li {
    padding: 25px 0;
  }
}

.main-menu-3 > li.has-megamenu {
  position: static;
}

.main-menu-3 > li:hover .main-dropdown {
  top: 80px;
  opacity: 1;
  visibility: visible;
}

.main-menu-3 > li:hover .mega-menu,
.main-menu-3 > li:hover .mega-menu-2 {
  top: 80px;
  opacity: 1;
  visibility: visible;
}

.main-menu-3 > li > a {
  display: block;
  font-weight: 400;
  font-size: 1rem;
  line-height: 16px;
  color: var(--black-2);
  padding: 27px 0;
  outline: none;
  text-transform: capitalize;
  -webkit-transform: translateY(var(--y)) translateZ(0);
  transform: translateY(var(--y)) translateZ(0);
  -webkit-transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease,
    -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
}

.main-menu-3 > li > a .menu-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--black-2);
}

.main-menu-3 > li > a .menu-text span {
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-style: normal;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translateY(var(--m)) translateZ(0);
  transform: translateY(var(--m)) translateZ(0);
}

.main-menu-3 > li > a .menu-text span:nth-child(1) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.main-menu-3 > li > a .menu-text span:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.main-menu-3 > li > a .menu-text span:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.main-menu-3 > li > a .menu-text span:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.main-menu-3 > li > a .menu-text span:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.main-menu-3 > li > a .menu-text span:nth-child(6) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.main-menu-3 > li > a .menu-text span:nth-child(7) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.main-menu-3 > li > a .menu-text span:nth-child(8) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.main-menu-3 > li > a .menu-text span:nth-child(9) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.main-menu-3 > li > a .menu-text span:nth-child(10) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.main-menu-3 > li > a .menu-text span:nth-child(11) {
  -webkit-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

.main-menu-3 > li > a .menu-text span:nth-child(12) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.main-menu-3 > li > a .menu-text span:nth-child(13) {
  -webkit-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.main-menu-3 > li > a .menu-text span:nth-child(14) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.main-menu-3 > li > a .menu-text span:nth-child(15) {
  -webkit-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.main-menu-3 > li > a .menu-text span:nth-child(16) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.main-menu-3 > li > a .menu-text span:nth-child(17) {
  -webkit-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

.main-menu-3 > li > a .menu-text span:nth-child(18) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.main-menu-3 > li > a .menu-text span:nth-child(19) {
  -webkit-transition-delay: 0.95s;
  transition-delay: 0.95s;
}

.main-menu-3 > li > a .menu-text span:nth-child(20) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.main-menu-3 > li > a .menu-text span:nth-child(21) {
  -webkit-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

.main-menu-3 > li > a .menu-text span:nth-child(22) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.main-menu-3 > li > a .menu-text span:nth-child(23) {
  -webkit-transition-delay: 1.15s;
  transition-delay: 1.15s;
}

.main-menu-3 > li > a .menu-text span:nth-child(24) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.main-menu-3 > li > a .menu-text span:nth-child(25) {
  -webkit-transition-delay: 1.25s;
  transition-delay: 1.25s;
}

.main-menu-3 > li > a .menu-text span:nth-child(26) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.main-menu-3 > li > a .menu-text span:nth-child(27) {
  -webkit-transition-delay: 1.35s;
  transition-delay: 1.35s;
}

.main-menu-3 > li > a .menu-text span:nth-child(28) {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.main-menu-3 > li > a .menu-text span:nth-child(29) {
  -webkit-transition-delay: 1.45s;
  transition-delay: 1.45s;
}

.main-menu-3 > li > a .menu-text span:nth-child(30) {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.main-menu-3 > li > a .menu-text span:nth-child(31) {
  -webkit-transition-delay: 1.55s;
  transition-delay: 1.55s;
}

.main-menu-3 > li > a .menu-text span:nth-child(32) {
  -webkit-transition-delay: 1.6s;
  transition-delay: 1.6s;
}

.main-menu-3 > li > a .menu-text span:nth-child(33) {
  -webkit-transition-delay: 1.65s;
  transition-delay: 1.65s;
}

.main-menu-3 > li > a .menu-text span:nth-child(34) {
  -webkit-transition-delay: 1.7s;
  transition-delay: 1.7s;
}

.main-menu-3 > li > a .menu-text span:nth-child(35) {
  -webkit-transition-delay: 1.75s;
  transition-delay: 1.75s;
}

.main-menu-3 > li > a .menu-text span:nth-child(36) {
  -webkit-transition-delay: 1.8s;
  transition-delay: 1.8s;
}

.main-menu-3 > li > a .menu-text span:nth-child(37) {
  -webkit-transition-delay: 1.85s;
  transition-delay: 1.85s;
}

.main-menu-3 > li > a .menu-text span:nth-child(38) {
  -webkit-transition-delay: 1.9s;
  transition-delay: 1.9s;
}

.main-menu-3 > li > a .menu-text span:nth-child(39) {
  -webkit-transition-delay: 1.95s;
  transition-delay: 1.95s;
}

.main-menu-3 > li > a .menu-text span:nth-child(40) {
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}

.main-menu-3 > li > a .menu-text span:nth-child(41) {
  -webkit-transition-delay: 2.05s;
  transition-delay: 2.05s;
}

.main-menu-3 > li > a .menu-text span:nth-child(42) {
  -webkit-transition-delay: 2.1s;
  transition-delay: 2.1s;
}

.main-menu-3 > li > a .menu-text span:nth-child(43) {
  -webkit-transition-delay: 2.15s;
  transition-delay: 2.15s;
}

.main-menu-3 > li > a .menu-text span:nth-child(44) {
  -webkit-transition-delay: 2.2s;
  transition-delay: 2.2s;
}

.main-menu-3 > li > a .menu-text span:nth-child(45) {
  -webkit-transition-delay: 2.25s;
  transition-delay: 2.25s;
}

.main-menu-3 > li > a .menu-text span:nth-child(46) {
  -webkit-transition-delay: 2.3s;
  transition-delay: 2.3s;
}

.main-menu-3 > li > a .menu-text span:nth-child(47) {
  -webkit-transition-delay: 2.35s;
  transition-delay: 2.35s;
}

.main-menu-3 > li > a .menu-text span:nth-child(48) {
  -webkit-transition-delay: 2.4s;
  transition-delay: 2.4s;
}

.main-menu-3 > li > a .menu-text span:nth-child(49) {
  -webkit-transition-delay: 2.45s;
  transition-delay: 2.45s;
}

.main-menu-3 > li > a:hover {
  --y: -4px;
}

.main-menu-3 > li > a:hover span {
  --m: calc(16px * -1);
}

.main-menu-4 > li > a {
  color: var(--black-2);
}

.main-menu-4 > li > a .menu-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--black-2);
}

.main-menu-4 > li > a .menu-text span {
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-style: normal;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translateY(var(--m)) translateZ(0);
  transform: translateY(var(--m)) translateZ(0);
}

.main-menu-4 > li > a .menu-text span:nth-child(1) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.main-menu-4 > li > a .menu-text span:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.main-menu-4 > li > a .menu-text span:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.main-menu-4 > li > a .menu-text span:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.main-menu-4 > li > a .menu-text span:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.main-menu-4 > li > a .menu-text span:nth-child(6) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.main-menu-4 > li > a .menu-text span:nth-child(7) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.main-menu-4 > li > a .menu-text span:nth-child(8) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.main-menu-4 > li > a .menu-text span:nth-child(9) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.main-menu-4 > li > a .menu-text span:nth-child(10) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.main-menu-4 > li > a .menu-text span:nth-child(11) {
  -webkit-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

.main-menu-4 > li > a .menu-text span:nth-child(12) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.main-menu-4 > li > a .menu-text span:nth-child(13) {
  -webkit-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.main-menu-4 > li > a .menu-text span:nth-child(14) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.main-menu-4 > li > a .menu-text span:nth-child(15) {
  -webkit-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.main-menu-4 > li > a .menu-text span:nth-child(16) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.main-menu-4 > li > a .menu-text span:nth-child(17) {
  -webkit-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

.main-menu-4 > li > a .menu-text span:nth-child(18) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.main-menu-4 > li > a .menu-text span:nth-child(19) {
  -webkit-transition-delay: 0.95s;
  transition-delay: 0.95s;
}

.main-menu-4 > li > a .menu-text span:nth-child(20) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.main-menu-4 > li > a .menu-text span:nth-child(21) {
  -webkit-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

.main-menu-4 > li > a .menu-text span:nth-child(22) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.main-menu-4 > li > a .menu-text span:nth-child(23) {
  -webkit-transition-delay: 1.15s;
  transition-delay: 1.15s;
}

.main-menu-4 > li > a .menu-text span:nth-child(24) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.main-menu-4 > li > a .menu-text span:nth-child(25) {
  -webkit-transition-delay: 1.25s;
  transition-delay: 1.25s;
}

.main-menu-4 > li > a .menu-text span:nth-child(26) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.main-menu-4 > li > a .menu-text span:nth-child(27) {
  -webkit-transition-delay: 1.35s;
  transition-delay: 1.35s;
}

.main-menu-4 > li > a .menu-text span:nth-child(28) {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.main-menu-4 > li > a .menu-text span:nth-child(29) {
  -webkit-transition-delay: 1.45s;
  transition-delay: 1.45s;
}

.main-menu-4 > li > a .menu-text span:nth-child(30) {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.main-menu-4 > li > a .menu-text span:nth-child(31) {
  -webkit-transition-delay: 1.55s;
  transition-delay: 1.55s;
}

.main-menu-4 > li > a .menu-text span:nth-child(32) {
  -webkit-transition-delay: 1.6s;
  transition-delay: 1.6s;
}

.main-menu-4 > li > a .menu-text span:nth-child(33) {
  -webkit-transition-delay: 1.65s;
  transition-delay: 1.65s;
}

.main-menu-4 > li > a .menu-text span:nth-child(34) {
  -webkit-transition-delay: 1.7s;
  transition-delay: 1.7s;
}

.main-menu-4 > li > a .menu-text span:nth-child(35) {
  -webkit-transition-delay: 1.75s;
  transition-delay: 1.75s;
}

.main-menu-4 > li > a .menu-text span:nth-child(36) {
  -webkit-transition-delay: 1.8s;
  transition-delay: 1.8s;
}

.main-menu-4 > li > a .menu-text span:nth-child(37) {
  -webkit-transition-delay: 1.85s;
  transition-delay: 1.85s;
}

.main-menu-4 > li > a .menu-text span:nth-child(38) {
  -webkit-transition-delay: 1.9s;
  transition-delay: 1.9s;
}

.main-menu-4 > li > a .menu-text span:nth-child(39) {
  -webkit-transition-delay: 1.95s;
  transition-delay: 1.95s;
}

.main-menu-4 > li > a .menu-text span:nth-child(40) {
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}

.main-menu-4 > li > a .menu-text span:nth-child(41) {
  -webkit-transition-delay: 2.05s;
  transition-delay: 2.05s;
}

.main-menu-4 > li > a .menu-text span:nth-child(42) {
  -webkit-transition-delay: 2.1s;
  transition-delay: 2.1s;
}

.main-menu-4 > li > a .menu-text span:nth-child(43) {
  -webkit-transition-delay: 2.15s;
  transition-delay: 2.15s;
}

.main-menu-4 > li > a .menu-text span:nth-child(44) {
  -webkit-transition-delay: 2.2s;
  transition-delay: 2.2s;
}

.main-menu-4 > li > a .menu-text span:nth-child(45) {
  -webkit-transition-delay: 2.25s;
  transition-delay: 2.25s;
}

.main-menu-4 > li > a .menu-text span:nth-child(46) {
  -webkit-transition-delay: 2.3s;
  transition-delay: 2.3s;
}

.main-menu-4 > li > a .menu-text span:nth-child(47) {
  -webkit-transition-delay: 2.35s;
  transition-delay: 2.35s;
}

.main-menu-4 > li > a .menu-text span:nth-child(48) {
  -webkit-transition-delay: 2.4s;
  transition-delay: 2.4s;
}

.main-menu-4 > li > a .menu-text span:nth-child(49) {
  -webkit-transition-delay: 2.45s;
  transition-delay: 2.45s;
}

.main-menu-4 > li > a:hover {
  --y: -4px;
}

.main-menu-4 > li > a:hover span {
  --m: calc(16px * -1);
}

.main-menu .mega-menu li a,
.main-menu .mega-menu-2 li a,
.main-menu-4 .mega-menu li a,
.main-menu-4 .mega-menu-2 li a {
  color: var(--white);
}

.main-dropdown {
  position: absolute;
  width: 300px;
  left: 0;
  top: 85px;
  z-index: 9;
  background-color: var(--black-2);
  text-align: left;
  padding: 15px 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.main-dropdown .sub-dropdown {
  position: absolute;
  width: 300px;
  left: 100%;
  top: 0;
  z-index: 9;
  background-color: var(--black-2);
  text-align: left;
  padding: 15px 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.main-dropdown li {
  display: block;
  margin: 0;
  padding: 0;
}

.main-dropdown li:hover .sub-dropdown {
  opacity: 1;
  visibility: visible;
}

.main-dropdown li a {
  color: var(--white);
  display: block;
  padding: 10px 20px;
  text-transform: capitalize;
}

.main-dropdown li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}

.main-dropdown li a:hover {
  letter-spacing: 1px;
}

.mega-menu,
.mega-menu-2 {
  position: absolute;
  width: 100%;
  left: 0;
  top: 90px;
  z-index: 9;
  background-color: var(--black-2);
  text-align: left;
  padding: 40px 200px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mega-menu,
  .mega-menu-2 {
    padding: 40px 100px;
  }
}

.mega-menu li,
.mega-menu-2 li {
  padding: 0;
  margin: 0;
}

.mega-menu li a,
.mega-menu-2 li a {
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.mega-menu li a .menu-text,
.mega-menu-2 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}

.mega-menu li a:hover,
.mega-menu-2 li a:hover {
  letter-spacing: 1px;
}

.mega-menu li a:not([href]):not([class]),
.mega-menu-2 li a:not([href]):not([class]) {
  color: var(--white);
}

.mega-menu li a:not([href]):not([class]):hover,
.mega-menu-2 li a:not([href]):not([class]):hover {
  letter-spacing: 0;
  background-color: transparent;
}

.mega-menu li ul,
.mega-menu-2 li ul {
  text-align: left;
}

.mega-menu li ul li,
.mega-menu-2 li ul li {
  display: block;
  margin: 0;
}

.mega-menu li ul li a,
.mega-menu-2 li ul li a {
  font-weight: 400;
  font-size: 1rem;
  padding: 10px 12px;
  display: block;
  margin-bottom: 0;
  text-transform: capitalize;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mega-menu li ul li a,
  .mega-menu-2 li ul li a {
    padding: 6px 12px;
  }
}

.mega-menu-2 {
  width: 1160px;
  padding: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr;
  grid-template-columns: repeat(4, 1fr);
}

.menu-heading {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--white);
  padding-bottom: 10px;
  text-transform: capitalize;
}

.footer-menu,
.footer-menu-2 {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .footer-menu,
  .footer-menu-2 {
    text-align: center;
  }
}

.footer-menu li,
.footer-menu-2 li {
  display: inline-block;
  padding-left: 80px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer-menu li,
  .footer-menu-2 li {
    padding-left: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-menu li,
  .footer-menu-2 li {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu li,
  .footer-menu-2 li {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu li,
  .footer-menu-2 li {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.footer-menu li a,
.footer-menu-2 li a {
  display: block;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
  color: var(--black-2);
  text-transform: capitalize;
}

.footer-menu li a .menu-text,
.footer-menu-2 li a .menu-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--black-2);
}

.footer-menu li a .menu-text span,
.footer-menu-2 li a .menu-text span {
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-style: normal;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translateY(var(--m)) translateZ(0);
  transform: translateY(var(--m)) translateZ(0);
}

.footer-menu li a .menu-text span:nth-child(1),
.footer-menu-2 li a .menu-text span:nth-child(1) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.footer-menu li a .menu-text span:nth-child(2),
.footer-menu-2 li a .menu-text span:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.footer-menu li a .menu-text span:nth-child(3),
.footer-menu-2 li a .menu-text span:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.footer-menu li a .menu-text span:nth-child(4),
.footer-menu-2 li a .menu-text span:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.footer-menu li a .menu-text span:nth-child(5),
.footer-menu-2 li a .menu-text span:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.footer-menu li a .menu-text span:nth-child(6),
.footer-menu-2 li a .menu-text span:nth-child(6) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.footer-menu li a .menu-text span:nth-child(7),
.footer-menu-2 li a .menu-text span:nth-child(7) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.footer-menu li a .menu-text span:nth-child(8),
.footer-menu-2 li a .menu-text span:nth-child(8) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.footer-menu li a .menu-text span:nth-child(9),
.footer-menu-2 li a .menu-text span:nth-child(9) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.footer-menu li a .menu-text span:nth-child(10),
.footer-menu-2 li a .menu-text span:nth-child(10) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.footer-menu li a .menu-text span:nth-child(11),
.footer-menu-2 li a .menu-text span:nth-child(11) {
  -webkit-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

.footer-menu li a .menu-text span:nth-child(12),
.footer-menu-2 li a .menu-text span:nth-child(12) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.footer-menu li a .menu-text span:nth-child(13),
.footer-menu-2 li a .menu-text span:nth-child(13) {
  -webkit-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.footer-menu li a .menu-text span:nth-child(14),
.footer-menu-2 li a .menu-text span:nth-child(14) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.footer-menu li a .menu-text span:nth-child(15),
.footer-menu-2 li a .menu-text span:nth-child(15) {
  -webkit-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.footer-menu li a .menu-text span:nth-child(16),
.footer-menu-2 li a .menu-text span:nth-child(16) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.footer-menu li a .menu-text span:nth-child(17),
.footer-menu-2 li a .menu-text span:nth-child(17) {
  -webkit-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

.footer-menu li a .menu-text span:nth-child(18),
.footer-menu-2 li a .menu-text span:nth-child(18) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.footer-menu li a .menu-text span:nth-child(19),
.footer-menu-2 li a .menu-text span:nth-child(19) {
  -webkit-transition-delay: 0.95s;
  transition-delay: 0.95s;
}

.footer-menu li a .menu-text span:nth-child(20),
.footer-menu-2 li a .menu-text span:nth-child(20) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.footer-menu li a .menu-text span:nth-child(21),
.footer-menu-2 li a .menu-text span:nth-child(21) {
  -webkit-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

.footer-menu li a .menu-text span:nth-child(22),
.footer-menu-2 li a .menu-text span:nth-child(22) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.footer-menu li a .menu-text span:nth-child(23),
.footer-menu-2 li a .menu-text span:nth-child(23) {
  -webkit-transition-delay: 1.15s;
  transition-delay: 1.15s;
}

.footer-menu li a .menu-text span:nth-child(24),
.footer-menu-2 li a .menu-text span:nth-child(24) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.footer-menu li a .menu-text span:nth-child(25),
.footer-menu-2 li a .menu-text span:nth-child(25) {
  -webkit-transition-delay: 1.25s;
  transition-delay: 1.25s;
}

.footer-menu li a .menu-text span:nth-child(26),
.footer-menu-2 li a .menu-text span:nth-child(26) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.footer-menu li a .menu-text span:nth-child(27),
.footer-menu-2 li a .menu-text span:nth-child(27) {
  -webkit-transition-delay: 1.35s;
  transition-delay: 1.35s;
}

.footer-menu li a .menu-text span:nth-child(28),
.footer-menu-2 li a .menu-text span:nth-child(28) {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.footer-menu li a .menu-text span:nth-child(29),
.footer-menu-2 li a .menu-text span:nth-child(29) {
  -webkit-transition-delay: 1.45s;
  transition-delay: 1.45s;
}

.footer-menu li a .menu-text span:nth-child(30),
.footer-menu-2 li a .menu-text span:nth-child(30) {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.footer-menu li a .menu-text span:nth-child(31),
.footer-menu-2 li a .menu-text span:nth-child(31) {
  -webkit-transition-delay: 1.55s;
  transition-delay: 1.55s;
}

.footer-menu li a .menu-text span:nth-child(32),
.footer-menu-2 li a .menu-text span:nth-child(32) {
  -webkit-transition-delay: 1.6s;
  transition-delay: 1.6s;
}

.footer-menu li a .menu-text span:nth-child(33),
.footer-menu-2 li a .menu-text span:nth-child(33) {
  -webkit-transition-delay: 1.65s;
  transition-delay: 1.65s;
}

.footer-menu li a .menu-text span:nth-child(34),
.footer-menu-2 li a .menu-text span:nth-child(34) {
  -webkit-transition-delay: 1.7s;
  transition-delay: 1.7s;
}

.footer-menu li a .menu-text span:nth-child(35),
.footer-menu-2 li a .menu-text span:nth-child(35) {
  -webkit-transition-delay: 1.75s;
  transition-delay: 1.75s;
}

.footer-menu li a .menu-text span:nth-child(36),
.footer-menu-2 li a .menu-text span:nth-child(36) {
  -webkit-transition-delay: 1.8s;
  transition-delay: 1.8s;
}

.footer-menu li a .menu-text span:nth-child(37),
.footer-menu-2 li a .menu-text span:nth-child(37) {
  -webkit-transition-delay: 1.85s;
  transition-delay: 1.85s;
}

.footer-menu li a .menu-text span:nth-child(38),
.footer-menu-2 li a .menu-text span:nth-child(38) {
  -webkit-transition-delay: 1.9s;
  transition-delay: 1.9s;
}

.footer-menu li a .menu-text span:nth-child(39),
.footer-menu-2 li a .menu-text span:nth-child(39) {
  -webkit-transition-delay: 1.95s;
  transition-delay: 1.95s;
}

.footer-menu li a .menu-text span:nth-child(40),
.footer-menu-2 li a .menu-text span:nth-child(40) {
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}

.footer-menu li a .menu-text span:nth-child(41),
.footer-menu-2 li a .menu-text span:nth-child(41) {
  -webkit-transition-delay: 2.05s;
  transition-delay: 2.05s;
}

.footer-menu li a .menu-text span:nth-child(42),
.footer-menu-2 li a .menu-text span:nth-child(42) {
  -webkit-transition-delay: 2.1s;
  transition-delay: 2.1s;
}

.footer-menu li a .menu-text span:nth-child(43),
.footer-menu-2 li a .menu-text span:nth-child(43) {
  -webkit-transition-delay: 2.15s;
  transition-delay: 2.15s;
}

.footer-menu li a .menu-text span:nth-child(44),
.footer-menu-2 li a .menu-text span:nth-child(44) {
  -webkit-transition-delay: 2.2s;
  transition-delay: 2.2s;
}

.footer-menu li a .menu-text span:nth-child(45),
.footer-menu-2 li a .menu-text span:nth-child(45) {
  -webkit-transition-delay: 2.25s;
  transition-delay: 2.25s;
}

.footer-menu li a .menu-text span:nth-child(46),
.footer-menu-2 li a .menu-text span:nth-child(46) {
  -webkit-transition-delay: 2.3s;
  transition-delay: 2.3s;
}

.footer-menu li a .menu-text span:nth-child(47),
.footer-menu-2 li a .menu-text span:nth-child(47) {
  -webkit-transition-delay: 2.35s;
  transition-delay: 2.35s;
}

.footer-menu li a .menu-text span:nth-child(48),
.footer-menu-2 li a .menu-text span:nth-child(48) {
  -webkit-transition-delay: 2.4s;
  transition-delay: 2.4s;
}

.footer-menu li a .menu-text span:nth-child(49),
.footer-menu-2 li a .menu-text span:nth-child(49) {
  -webkit-transition-delay: 2.45s;
  transition-delay: 2.45s;
}

.footer-menu li a:hover,
.footer-menu-2 li a:hover {
  --y: -4px;
}

.footer-menu li a:hover span,
.footer-menu-2 li a:hover span {
  --m: calc(16px * -1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu-2 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu-2 {
    margin-bottom: 30px;
  }
}

.footer-menu-2 li a {
  color: var(--white);
  text-transform: uppercase;
}

.footer-menu-2 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}

.offcanvas__menu-wrapper.mean-container {
  padding-top: 48px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__menu-wrapper.mean-container {
    padding-top: 48px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__menu-wrapper.mean-container {
    padding-top: 48px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__menu-wrapper.mean-container {
    padding-top: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container {
    padding-top: 40px;
  }
}

.offcanvas__menu-wrapper.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.offcanvas__menu-wrapper.mean-container .mean-nav > ul > li:last-child > a {
  border-bottom: 1px solid var(--black-4);
}

.offcanvas__menu-wrapper.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 20px 0 20px 100px;
  font-weight: 400;
  font-size: 80px;
  line-height: 1;
  color: var(--white);
  text-transform: capitalize;
  border-top: 1px solid var(--dark-theme2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  outline: none;
  -webkit-transform: translateY(var(--y)) translateZ(0);
  transform: translateY(var(--y)) translateZ(0);
  -webkit-transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease,
    -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-shadow: 0 80px 0 var(--white);
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li a .menu-text span {
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-style: normal;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translateY(var(--m)) translateZ(0);
  transform: translateY(var(--m)) translateZ(0);
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(1) {
  -webkit-transition-delay: 0.0333333333s;
  transition-delay: 0.0333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(2) {
  -webkit-transition-delay: 0 667s;
  transition-delay: 0.0666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(4) {
  -webkit-transition-delay: 0.1333333333s;
  transition-delay: 0.1333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(5) {
  -webkit-transition-delay: 0.1666666667s;
  transition-delay: 0.1666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(6) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(7) {
  -webkit-transition-delay: 0.2333333333s;
  transition-delay: 0.2333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(8) {
  -webkit-transition-delay: 0.2666666667s;
  transition-delay: 0.2666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(9) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(10) {
  -webkit-transition-delay: 0.3333333333s;
  transition-delay: 0.3333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(11) {
  -webkit-transition-delay: 0.3666666667s;
  transition-delay: 0.3666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(12) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(13) {
  -webkit-transition-delay: 0.4333333333s;
  transition-delay: 0.4333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(14) {
  -webkit-transition-delay: 0.4666666667s;
  transition-delay: 0.4666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(15) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(16) {
  -webkit-transition-delay: 0.5333333333s;
  transition-delay: 0.5333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(17) {
  -webkit-transition-delay: 0.5666666667s;
  transition-delay: 0.5666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(18) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(19) {
  -webkit-transition-delay: 0.6333333333s;
  transition-delay: 0.6333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(20) {
  -webkit-transition-delay: 0.6666666667s;
  transition-delay: 0.6666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(21) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(22) {
  -webkit-transition-delay: 0.7333333333s;
  transition-delay: 0.7333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(23) {
  -webkit-transition-delay: 0.7666666667s;
  transition-delay: 0.7666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(24) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(25) {
  -webkit-transition-delay: 0.8333333333s;
  transition-delay: 0.8333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(26) {
  -webkit-transition-delay: 0.8666666667s;
  transition-delay: 0.8666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(27) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(28) {
  -webkit-transition-delay: 0.9333333333s;
  transition-delay: 0.9333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(29) {
  -webkit-transition-delay: 0.9666666667s;
  transition-delay: 0.9666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(30) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(31) {
  -webkit-transition-delay: 1.0333333333s;
  transition-delay: 1.0333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(32) {
  -webkit-transition-delay: 1.0666666667s;
  transition-delay: 1.0666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(33) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(34) {
  -webkit-transition-delay: 1.1333333333s;
  transition-delay: 1.1333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(35) {
  -webkit-transition-delay: 1.1666666667s;
  transition-delay: 1.1666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(36) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(37) {
  -webkit-transition-delay: 1.2333333333s;
  transition-delay: 1.2333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(38) {
  -webkit-transition-delay: 1.2666666667s;
  transition-delay: 1.2666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(39) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(40) {
  -webkit-transition-delay: 1.3333333333s;
  transition-delay: 1.3333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(41) {
  -webkit-transition-delay: 1.3666666667s;
  transition-delay: 1.3666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(42) {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(43) {
  -webkit-transition-delay: 1.4333333333s;
  transition-delay: 1.4333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(44) {
  -webkit-transition-delay: 1.4666666667s;
  transition-delay: 1.4666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(45) {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(46) {
  -webkit-transition-delay: 1.5333333333s;
  transition-delay: 1.5333333333s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(47) {
  -webkit-transition-delay: 1.5666666667s;
  transition-delay: 1.5666666667s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(48) {
  -webkit-transition-delay: 1.6s;
  transition-delay: 1.6s;
}

.offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  a
  .menu-text
  span:nth-child(49) {
  -webkit-transition-delay: 1.6333333333s;
  transition-delay: 1.6333333333s;
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li a:hover {
  --y: -0px;
  background-color: transparent;
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li a:hover span {
  --m: calc(80px * -1);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    font-size: 60px;
    padding-left: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    font-size: 48px;
    padding-left: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    font-size: 40px;
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    font-size: 30px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    font-size: 1.25rem;
    padding-left: 15px;
  }
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
  width: 60px;
  height: 96px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 300;
  border: none !important;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 76px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 64px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 57px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 46px;
    width: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 36px;
    width: 36px;
    padding: 11px 12px 13px !important;
  }
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand:hover {
  background: var(--secondary);
  opacity: 1;
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li li:first-child {
  border-top: 1px solid var(--black-4);
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
  font-size: 1.5rem;
  text-transform: capitalize;
  border-top: none !important;
  padding: 20px 0 20px 150px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li li a:hover {
  letter-spacing: 1px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-left: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-left: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-left: 60px;
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    font-size: 1.25rem;
    padding: 15px 0 15px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    font-size: 1.125rem;
    padding: 15px 0 15px 30px;
  }
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li li a.mean-expand {
  height: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a.mean-expand {
    height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a.mean-expand {
    height: 23px;
  }
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li li li:last-child {
  border-bottom: 1px solid var(--black-4);
}

.offcanvas__menu-wrapper.mean-container .mean-nav ul li li li a {
  padding-left: 180px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__menu-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 30px;
  }
}

.offcanvas__menu-wrapper.mean-container .mean-bar {
  padding: 0;
  background: none;
}

.offcanvas__menu-wrapper.mean-container a.meanmenu-reveal {
  display: none !important;
}

.header__area-2 .main-dropdown {
  background-color: var(--white);
}

.header__area-2 .main-dropdown li a {
  color: var(--black-2);
}

.header__area-2 .main-dropdown li a:hover {
  letter-spacing: 1px;
}

.header__area-2 .main-dropdown li a .menu-text {
  text-shadow: 0 16px 0 var(--black-2);
}

.header__area-2 .main-dropdown .sub-dropdown {
  background-color: var(--white);
}

.header__area-2 .mega-menu,
.header__area-2 .mega-menu-2 {
  background-color: var(--white);
}

.header__area-2 .mega-menu li a,
.header__area-2 .mega-menu-2 li a {
  color: var(--black-2);
}

.header__area-2 .mega-menu li a:hover,
.header__area-2 .mega-menu-2 li a:hover {
  letter-spacing: 1px;
}

.header__area-2 .mega-menu li a:not([href]):not([class]),
.header__area-2 .mega-menu-2 li a:not([href]):not([class]) {
  color: var(--black-2);
}

.header__area-2 .mega-menu li a .menu-text,
.header__area-2 .mega-menu-2 li a .menu-text {
  text-shadow: 0 16px 0 var(--black-2);
}

.dark .menu-heading {
  color: var(--black-2);
}

.modal__testimonial {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 9991;
  -webkit-transition: visibility 0s linear 0.25s, opacity 0.25s 0s,
    -webkit-transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s,
    -webkit-transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s,
    -webkit-transform 0.25s;
}

.modal__testimonial-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: var(--white);
  border-radius: 5px;
  width: 760px;
  height: 500px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal__testimonial-content {
    width: 700px;
    height: 480px;
  }
}

@media only screen and (max-width: 767px) {
  .modal__testimonial-content {
    width: 320px;
    height: 300px;
  }
}

.modal__testimonial-content iframe {
  width: 100%;
  height: 100%;
}

.modal__close {
  background: var(--secondary);
  color: var(--white);
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 100%;
  font-size: 1.125rem;
  position: absolute;
  right: -15px;
  top: -15px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .modal__close {
    right: -5px;
    top: -5px;
  }
}

.modal__close:hover {
  color: var(--primary);
}

.modal__close-2 {
  background-color: transparent;
  color: var(--black-3);
  width: 30px;
  height: 30px;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .modal__close-2 {
    right: 20px;
    top: 15px;
  }
}

.modal__close-2:hover {
  color: var(--black-2);
}

.modal__application {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: visibility 0s linear 0.25s, opacity 0.25s 0s,
    -webkit-transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s,
    -webkit-transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s,
    -webkit-transform 0.25s;
  z-index: 9991;
}

.modal__apply {
  padding: 100px 50px;
  max-width: 1130px;
  height: 96vh;
  margin: 0 auto;
  margin-top: 2vh;
  overflow-y: auto;
  position: relative;
  background-color: var(--white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal__apply {
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal__apply {
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .modal__apply {
    padding: 50px 20px;
    margin-top: 0;
    height: 100vh;
  }
}

.modal__apply .wc-btn-primary,
.modal__apply .wc-btn-secondary,
.modal__apply .wc-btn-pink,
.modal__apply .wc-btn-light,
.modal__apply .wc-btn-black {
  width: 120px;
  height: 120px;
}

.modal__apply .form-top {
  text-align: center;
  padding-bottom: 50px;
}

.modal__apply .form-top img {
  margin-bottom: 40px;
}

.modal__apply .form-top p {
  font-size: 1.125rem;
  color: var(--black-2);
  border: 1px solid var(--black-3);
  border-radius: 30px;
  display: inline-block;
  padding: 5px 35px;
  margin-top: 20px;
}

.modal__apply .input-apply {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 90px 2.2fr;
  grid-template-columns: 1fr 2.2fr;
  grid-gap: 90px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .modal__apply .input-apply {
    -ms-grid-columns: 1fr 30px 2fr;
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal__apply .input-apply {
    -ms-grid-columns: 1fr 30px 2fr;
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal__apply .input-apply {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .modal__apply .input-apply {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 30px;
  }
}

.modal__apply .input-apply-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 50px 4fr;
  grid-template-columns: 1fr 4fr;
  grid-gap: 50px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .modal__apply .input-apply-2 {
    -ms-grid-columns: 1fr 30px 2fr;
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal__apply .input-apply-2 {
    -ms-grid-columns: 1fr 30px 2fr;
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal__apply .input-apply-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .modal__apply .input-apply-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 30px;
  }
}

.modal__apply .input-apply-2 p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black);
}

.modal__apply .input-apply-2 input {
  width: 100%;
  border: 1px solid var(--white-4);
  outline: none;
  padding: 15px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.modal__apply .input-apply-2 input:focus {
  border: 1px solid var(--black-3);
}

.modal__apply .input-apply p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black);
}

.modal__apply .input-apply textarea {
  width: 100%;
  height: 125px;
  border: 1px solid var(--white-4);
  outline: none;
  padding: 15px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal__apply .input-apply textarea {
    height: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .modal__apply .input-apply textarea {
    height: 90px;
  }
}

.modal__apply .input-apply textarea:focus {
  border: 1px solid var(--black-3);
}

.modal__apply .form-btn button {
  margin-left: auto;
  margin-top: 30px;
  background-color: transparent;
  z-index: 1;
}

.modal__apply .form-btn-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}

.modal__apply .form-btn-2 button {
  background-color: transparent;
  z-index: 1;
}

.modal-show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: visibility 0s linear 0s, opacity 0.25s 0s,
    -webkit-transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s,
    -webkit-transform 0.25s;
}

.cursor {
  position: fixed;
  left: 0;
  pointer-events: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  color: var(--white);
  background: var(--black-2);
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
  opacity: 0;
  mix-blend-mode: hard-light;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cursor.large {
  width: 180px;
  height: 180px;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
}

.cursor1 {
  position: fixed;
  width: 40px;
  height: 40px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.15s;
  transition: 0.15s;
  z-index: 999;
  mix-blend-mode: difference;
}

@media (max-width: 1200px) {
  .cursor1 {
    display: none;
  }
}

.cursor1.hide {
  opacity: 0;
  visibility: hidden;
}

.cursor2 {
  position: fixed;
  width: 8px;
  height: 8px;
  background-color: var(--primary);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  z-index: 999;
  mix-blend-mode: difference;
}

@media (max-width: 1200px) {
  .cursor2 {
    display: none;
  }
}

.cursor2.hide {
  opacity: 0;
  visibility: hidden;
}

.cursor2.circle {
  width: 60px;
  height: 60px;
}

.cursor-testi {
  position: fixed;
  width: 80px;
  height: 80px;
  background-color: var(--black-2);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  z-index: 999;
}

@media (max-width: 1200px) {
  .cursor-testi {
    display: none;
  }
}

.cursor-testi.hide {
  opacity: 0;
  visibility: hidden;
}

.cursor-testi.play {
  width: 100px;
  height: 100px;
  mix-blend-mode: unset;
  background-color: var(--black-2);
}

.cursor-testi.play::after {
  position: absolute;
  content: "Play";
  color: var(--white);
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#featured_cursor {
  background: var(--white);
  color: var(--black-2);
}

@media (max-width: 1200px) {
  #client_cursor {
    display: none;
  }
}

.switcher__area {
  position: relative;
  direction: ltr;
}

@media only screen and (max-width: 767px) {
  .switcher__area {
    display: none;
  }
}

.switcher__icon {
  position: fixed;
  width: 50px;
  height: 50px;
  background: var(--white);
  right: 0;
  top: 40%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  mix-blend-mode: exclusion;
}

.switcher__icon button {
  font-size: 1.5rem;
  color: var(--black-2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.switcher__icon button:hover {
  color: var(--gray-2);
}

.switcher__icon button#switcher_open {
  -webkit-animation: wcSpinner 5s infinite linear;
  animation: wcSpinner 5s infinite linear;
}

.switcher__items {
  width: 280px;
  padding: 50px 30px;
  background: #515968;
  position: fixed;
  right: -280px;
  top: 40%;
  z-index: 99;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Kanit", sans-serif;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.switcher__items .wc-col-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.switcher__item {
  margin-bottom: 30px;
}

.switcher__item:nth-child(2) {
  margin-bottom: 0;
}

.switcher__item:last-child {
  margin-bottom: 0;
  display: none;
}

.switcher__title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5;
  color: var(--white);
  text-transform: capitalize;
  padding-bottom: 10px;
}

.switcher__btn button {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: var(--gray-2);
  background: #2b2b2f;
  border-radius: 4px;
  padding: 10px 15px;
  text-transform: capitalize;
}

.switcher__btn button:hover,
.switcher__btn button.active {
  color: var(--white);
}

.switcher__btn select {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: var(--gray-2);
  width: 100%;
  border: none;
  padding: 9px 10px;
  border-radius: 4px;
  background: #2b2b2f;
  text-transform: capitalize;
  outline: none;
  cursor: pointer;
}

#switcher_close {
  display: none;
}

.header__area {
  position: fixed;
  width: 100px;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9991;
  border-right: 1px solid rgba(240, 240, 240, 0.07);
  mix-blend-mode: exclusion;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .header__area {
    width: 62px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__area {
    width: 62px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__area {
    padding: 0 15px;
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__area {
    padding: 0 15px;
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .header__area {
    padding: 0 15px;
    width: 100%;
    height: auto;
  }
}

.header__area button {
  display: block;
  margin-top: -58px;
  padding: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__area button {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__area button {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__area button {
    margin-top: 0;
  }
}

.header__area-2 {
  padding: 3px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}

.header__area-3 {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}

.header__area-3.sticky-3 {
  background-color: var(--white);
  border-bottom: 1px solid var(--white-2);
}

.header__area-7 {
  background: var(--white);
  padding: 3px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}

.dark .header__area-7 {
  background: var(--dark-theme);
  box-shadow: 0px 4px 7px -2px rgba(0, 0, 0, 0.2);
}

.header__area-7 .contact-area {
  border-bottom: 5px solid var(--black-2);
  padding: 1rem;
}

.header__area-7 .contact-area li:first-child {
  margin-left: 0 !important;
}

@media (max-width: 991px) {
  .contact-area {
    display: none;
  }
}

.header__area-7 .contact-area .contact-details li {
  padding-left: 1rem;
}

.header__area-7 .contact-area .contact-details li:first-child {
  padding-left: 0;
}

.header__area-7 .contact-area .contact-details li:last-child {
  border-left: 2px solid var(--black-2);
}

.header__area-7 .contact-area ul li {
  display: inline;
  margin-left: 1rem;
}

.header__area-7 .contact-area ul li {
  color: var(--black-2);
}

.header__area-7 .contact-area ul li a {
  color: var(--black-2);
}

.header__inner {
  padding: 50px 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__inner {
    padding: 10px 0;
    display: -ms-grid;
    display: grid;
    /* -ms-grid-columns: (1fr) [12]; */
    grid-template-columns: repeat(12, 1fr);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__inner {
    padding: 10px 0;
    display: -ms-grid;
    display: grid;
    /* -ms-grid-columns: (1fr) [12]; */
    grid-template-columns: repeat(12, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .header__inner {
    padding: 5px 0;
    display: -ms-grid;
    display: grid;
    /* /* -ms-grid-columns: (1fr) [12]; */
    grid-template-columns: repeat(12, 1fr);
  }
}
.header__inner-2,
.header__inner-3 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 150px auto 128px;
  grid-template-columns: 150px auto 128px;
}

/* @media (max-width: 1368px) {
  .header__inner-2 {
    -ms-grid-columns: 150px auto 132px;
    grid-template-columns: 150px auto 132px;
  }*/

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__inner-2,
  .header__inner-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* padding: 20px; */
    /* margin-right: 1rem; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__inner-2,
  .header__inner-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* padding: 20px;
    margin-right: 1rem; */
  }
}

@media only screen and (max-width: 767px) {
  .header__inner-2,
  .header__inner-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* padding: 20px 15px;
    margin-right: 1rem; */
  }
}

.header__inner-2 .mgmu-logo a img {
  height: 74px;
}

@media (max-width: 720px) {
  .header__inner-2 .mgmu-logo a img {
    width: 5rem;
  }
}

.header__inner-3 {
  padding: 10px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__inner-3 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__inner-3 {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header__inner-3 {
    padding: 20px 15px;
  }
}

.header__nav-2 {
  text-align: right;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__nav-2 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__nav-2 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header__nav-2 {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__nav-icon {
    grid-column: -1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__nav-icon {
    grid-column: -1;
  }
}

@media only screen and (max-width: 767px) {
  .header__nav-icon {
    grid-column: -1;
  }
}

.header__nav-icon-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
}

.header__nav-icon-2 .menu-icon-2::after {
  position: absolute;
  content: "";
  width: 180px;
  height: 180px;
  right: -60px;
  top: -60px;
  border: 1px solid var(--black-4);
  border-radius: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__nav-icon-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
}

.header__nav-icon-3 .search-icon {
  padding-top: 3px;
  padding-right: 50px;
  position: relative;
  font-size: 1.125rem;
}

.header__nav-icon-3 .search-icon::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 20px;
  right: 25px;
  top: 5px;
  background-color: var(--white-2);
}

.header__nav-icon-3 #search_close {
  display: none;
  padding-right: 55px;
}

.header__nav-icon-3 img {
  margin-top: -5px;
}

.header__nav-icon-7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
}

/* .header__nav-icon-7 .menu-icon-2::after {
  position: absolute;
  content: "";
  width: 180px;
  height: 180px;
  right: -60px;
  top: -60px;
  border: 1px solid var(--gray);
  border-radius: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
} */

.header__search {
  position: fixed;
  width: 300px;
  right: 60px;
  top: 90px;
  padding: 15px;
  background-color: var(--white-4);
  border-radius: 3px;
  z-index: 99991;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .header__search {
    right: 10px;
    top: 100px;
  }
}

.header__search.open-search {
  opacity: 1;
  visibility: visible;
  top: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__search.open-search {
    top: 85px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__search.open-search {
    top: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .header__search.open-search {
    top: 85px;
  }
}

.header__search input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--white-2);
  border-radius: 5px;
  outline: none;
  font-size: 1rem;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__support {
    -ms-grid-column: 11;
    grid-column: 11/11;
    -ms-grid-row: 1;
    grid-row: 1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__support {
    -ms-grid-column: 11;
    grid-column: 11/11;
    -ms-grid-row: 1;
    grid-row: 1;
  }
}

@media only screen and (max-width: 767px) {
  .header__support {
    display: none;
  }
}

.header__support p {
  width: 215px;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.3;
  color: var(--gray-2);
  text-align: right;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__support p {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__support p {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 767px) {
  .header__support p {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.header__support p a {
  display: inline-block;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.3;
  color: var(--white);
}

.header__logo-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sticky-2 .header__nav-icon-2 .menu-icon-2::after {
  width: 100px;
  height: 100px;
  right: -20px;
  top: -30px;
}

.sticky-2 .header__logo-5 img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.sticky-2 .header__nav-icon-5 {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.sticky-7 .header__nav-icon-7 .menu-icon-2::after {
  width: 100px;
  height: 100px;
  right: -20px;
  top: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-primary {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo-primary {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .logo-primary {
    display: none;
  }
}

.logo-secondary {
  display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-secondary {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo-secondary {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .logo-secondary {
    display: block;
  }
}

.dark .menu-icon img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.offcanvas__area {
  background-color: var(--dark-theme);
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.offcanvas__body {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.9fr 3fr 1.3fr;
  grid-template-columns: 0.9fr 3fr 1.3fr;
  height: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__body {
    -ms-grid-columns: 1.2fr 3fr 1.5fr;
    grid-template-columns: 1.2fr 3fr 1.5fr;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__body {
    -ms-grid-columns: 1.2fr 2fr 1.5fr;
    grid-template-columns: 1.2fr 2fr 1.5fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__body {
    -ms-grid-columns: 1.2fr 2.3fr 1.8fr;
    grid-template-columns: 1.2fr 2.3fr 1.8fr;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__body {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.offcanvas__left {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 0.5fr 2.1fr 1fr;
  grid-template-rows: 0.5fr 2.1fr 1fr;
  border-right: 1px solid var(--dark-theme2);
}

@media only screen and (max-width: 767px) {
  .offcanvas__left {
    display: none;
  }
}

.offcanvas__mid {
  overflow: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: 767px) {
  .offcanvas__mid {
    margin-top: 75px;
  }
}

.offcanvas__mid::-webkit-scrollbar {
  width: 5px;
}

.offcanvas__mid::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px var(--black);
  box-shadow: inset 0 0 5px var(--black);
  border-radius: 10px;
}

.offcanvas__mid::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

.offcanvas__right {
  background-color: var(--dark-theme2);
  padding: 200px 50px;
  position: relative;
}

/* @media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__right {
    padding: 0 30px;
  }
} */

/* @media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__right {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__right {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__right {
    padding: 0 15px;
  }
} */

@media only screen and (max-width: 767px) {
  .offcanvas__right {
    display: none;
  }
}

.offcanvas__right .shape-1 {
  position: absolute;
  bottom: 0;
  left: 125px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__right .shape-1 {
    left: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__right .shape-1 {
    left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__right .shape-1 {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__right .shape-1 {
    left: 0;
  }
}

.offcanvas__right .shape-2 {
  position: absolute;
  bottom: 90px;
  right: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__right .shape-2 {
    right: 30px;
  }
}

.offcanvas__logo {
  padding: 55px 0 55px 50px;
  border-bottom: 1px solid var(--dark-theme2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__logo {
    padding: 30px 0 30px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__logo {
    padding: 30px 0 30px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__logo {
    padding: 30px 0 30px 15px;
  }
}

.offcanvas__social {
  padding-left: 50px;
  padding-top: 60px;
  border-bottom: 1px solid var(--black-4);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__social {
    padding-left: 50px;
    padding-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__social {
    padding-left: 30px;
    padding-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__social {
    padding-left: 15px;
    padding-top: 50px;
  }
}

.offcanvas__social .social-title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__social .social-title {
    font-size: 1.25rem;
  }
}

.offcanvas__social ul li {
  padding-bottom: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__social ul li {
    padding-bottom: 5px;
  }
}

.offcanvas__social ul li a {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--white);
}

.offcanvas__social ul li a:hover {
  color: var(--gray-2);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__social ul li a {
    font-size: 1rem;
  }
}

.offcanvas__links {
  padding-left: 50px;
  padding-top: 110px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__links {
    padding-left: 30px;
    padding-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__links {
    padding-left: 30px;
    padding-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__links {
    padding-left: 15px;
    padding-top: 40px;
  }
}

.offcanvas__links ul li {
  padding-bottom: 2px;
}

.offcanvas__links ul li a {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--white);
}

.offcanvas__links ul li a:hover {
  color: var(--gray-2);
}

.offcanvas__close {
  position: absolute;
  right: 40px;
  top: 30px;
}

@media only screen and (max-width: 767px) {
  .offcanvas__close {
    right: 15px;
    top: 15px;
  }
}

.offcanvas__close button {
  font-size: 30px;
  width: 60px;
  height: 60px;
  color: var(--white);
  background: #232323;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.offcanvas__close button:hover {
  color: var(--gray-2);
}

@media only screen and (max-width: 767px) {
  .offcanvas__close button {
    font-size: 1.5rem;
    width: 48px;
    height: 48px;
  }
}

.offcanvas__search {
  padding-top: 190px;
  padding-bottom: 140px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__search {
    padding-top: 140px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__search {
    padding-top: 140px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__search {
    padding-top: 140px;
    padding-bottom: 60px;
  }
}

.offcanvas__search form {
  position: relative;
}

.offcanvas__search input {
  color: #585858;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 20px 45px 20px 20px;
  border-radius: 30px;
  border: 1px solid #3c3c3c;
  background-color: transparent;
  outline: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__search input {
    padding: 15px 40px 15px 15px;
  }
}

.offcanvas__search input::-webkit-input-placeholder {
  opacity: 1;
  color: #585858;
}

.offcanvas__search input::-moz-placeholder {
  opacity: 1;
  color: #585858;
}

.offcanvas__search input:-ms-input-placeholder {
  opacity: 1;
  color: #585858;
}

.offcanvas__search input::-ms-input-placeholder {
  opacity: 1;
  color: #585858;
}

.offcanvas__search input::placeholder {
  opacity: 1;
  color: #585858;
}

.offcanvas__search button {
  color: var(--white);
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 100%;
  border-radius: 0 100% 100% 0;
}

.offcanvas__contact h3 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__contact h3 {
    font-size: 1.25rem;
  }
}

.offcanvas__contact li {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 7px;
  max-width: 260px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__contact li {
    font-size: 1rem;
  }
}

.offcanvas__contact li a {
  color: var(--white);
}

.offcanvas__contact li a:hover {
  color: var(--gray-2);
}

.hero__area {
  background-color: var(--black);
  position: relative;
  overflow: hidden;
}

.hero__area::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.hero__area .hero1_bg {
  position: absolute;
  bottom: 0;
  z-index: 0;
  left: 0;
  width: 100%;
  height: auto;
  -webkit-animation: wcRotate 18s infinite linear;
  animation: wcRotate 18s infinite linear;
}

.hero__area-2 {
  background-image: url(../imgs/bg/lines.png?v=0.1.8);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--black-2);
  overflow: hidden;
  position: relative;
}

.hero__area-2 .hero2-shape {
  position: absolute;
  left: 30%;
  top: 41%;
  max-width: 41%;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-2 .hero2-shape {
    top: 37%;
    left: 33%;
    max-width: 36%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-2 .hero2-shape {
    top: 32%;
    left: 31%;
    max-width: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-2 .hero2-shape {
    left: 30%;
    top: 34%;
    max-width: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-2 .hero2-shape {
    left: 5%;
    top: 30%;
    max-width: 90%;
  }
}

.hero__area-2 .hero2-shape img {
  max-width: 100%;
}

.hero__area-2 .sec-title-3 {
  color: var(--white);
  padding-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-2 .sec-title-3 {
    padding-top: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-2 .sec-title-3 {
    padding-bottom: 40px;
    padding-right: 75px;
  }
}

.hero__area-2 .sec-title-3:last-child {
  text-align: right;
  padding-top: 110px;
  padding-right: 0;
  margin-top: 30px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-2 .sec-title-3:last-child {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-2 .sec-title-3:last-child {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-2 .sec-title-3:last-child {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-2 .sec-title-3:last-child {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-2 .sec-title-3:last-child {
    padding-top: 0;
  }
}

.hero__area-2 .sec-title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .hero__area-2 .sec-title-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.hero__area-2 .sec-title-wrapper .bg-shape {
  background-image: url(../imgs/shape/10.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: right top;
}

.hero__area-3 {
  padding-top: 190px;
  padding-bottom: 125px;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-3 {
    padding-top: 160px;
    padding-bottom: 110px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-3 {
    padding-top: 140px;
    padding-bottom: 110px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-3 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-3 {
    padding-top: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-3 {
    padding-top: 130px;
    padding-bottom: 0;
  }
}

.hero__area-3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url(../imgs/shape/5.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: left;
  z-index: -1;
}

.hero__area-3::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  right: 50px;
  top: 140px;
  background-image: url(../imgs/shape/6.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: right top;
}

.hero__area-3 .hero3-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-3 .hero3-img {
    max-width: 1000px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-3 .hero3-img {
    max-width: 1000px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-3 .hero3-img {
    max-width: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-3 .hero3-img {
    max-width: 760px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-3 .hero3-img {
    position: unset;
  }
}

.hero__area-3 .hero3-img-anim {
  overflow: hidden;
  position: relative;
  width: 350px;
  height: 100%;
}

.hero__area-3 .hero3-img-anim img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 150%;
  margin-left: auto;
  margin-right: auto;
}

.hero__area-3 .sec-title-wrapper {
  display: inline-block;
  position: unset;
}

.hero__area-3 .sec-sub-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--black-2);
  position: relative;
  display: inline-block;
  padding-right: 30px;
  padding-bottom: 0px;
  text-transform: uppercase;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .hero__area-3 .sec-sub-title {
    padding-bottom: 15px;
  }
}

.hero__area-3 .sec-sub-title::after {
  position: absolute;
  content: "";
  width: 90px;
  height: 1px;
  left: 100%;
  top: 12px;
  background-color: var(--black-2);
}

.hero__area-3 .sec-title {
  z-index: 2;
  font-weight: 600;
  font-size: 306px;
  line-height: 0.82;
  text-transform: uppercase;
  color: var(--black-2);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-3 .sec-title {
    font-size: 260px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-3 .sec-title {
    font-size: 260px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-3 .sec-title {
    font-size: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-3 .sec-title {
    font-size: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-3 .sec-title {
    font-size: 90px;
  }
}

.hero__area-3 .title-right {
  position: absolute;
  right: 0;
}

.hero__area-3 .wrapper {
  --clip-path: circle(65px at left);
  --clip-path-hover: circle(70px at left);
  --clip-path-clicked: circle(100vw at left);
  --duration: 0.4s;
  --timing-function: ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-3 .wrapper {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-3 .wrapper {
    display: none;
  }
}

.hero__area-3 .video-info {
  width: 300px;
  position: relative;
  left: 500px;
  top: -115px;
  height: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-3 .video-info {
    left: 200px;
    top: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-3 .video-info {
    left: 280px;
    top: -235px;
  }
}

.hero__area-3 .video {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-clip-path: var(--clip-path);
  clip-path: var(--clip-path);
  -webkit-transition: -webkit-clip-path var(--duration) var(--timing-function);
  transition: -webkit-clip-path var(--duration) var(--timing-function);
  transition: clip-path var(--duration) var(--timing-function);
  transition: clip-path var(--duration) var(--timing-function),
    -webkit-clip-path var(--duration) var(--timing-function);
  position: relative;
  top: 55px;
  left: 100px;
}

.hero__area-3 .video::before {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 40px;
  height: 40px;
  background: url(../imgs/icon/play-icon.png?v=0.1.8);
  content: "";
  z-index: 999999999999;
  background-size: 100%;
}

.hero__area-3 .video video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: auto;
  width: auto;
  height: auto;
}

.hero__area-3 .intro-title {
  position: relative;
  left: 175px;
  top: 70px;
}

.hero__area-3 .intro-title .video-title {
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.3;
}

.hero__area-3 .intro-title .video-title span {
  display: block;
  font-size: 1.125rem;
}

.hero__area-3 .intro-title .video-title.close-video-title {
  display: none;
}

.hero__area-3 #video_check {
  width: 200px;
  height: 200px;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-radius: 40px;
  outline: none;
  z-index: 2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  z-index: 9999999;
}

.hero__area-3 #video_check:focus {
  outline: 0;
}

.hero__area-3 #video_check:hover ~ .video {
  -webkit-clip-path: var(--clip-path-hover);
  clip-path: var(--clip-path-hover);
}

.hero__area-3 #video_check:hover ~ .text::before {
  --r: 25px;
  --opacity: 1;
}

.hero__area-3 #video_check:hover ~ .text::after {
  -webkit-clip-path: var(--clip-path-hover);
  clip-path: var(--clip-path-hover);
}

.hero__area-3 #video_check:hover ~ .text > span::after {
  -webkit-clip-path: var(--clip-path-hover);
  clip-path: var(--clip-path-hover);
}

.hero__area-3 #video_check:checked {
  width: 100%;
  height: 100%;
  border-radius: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.hero__area-3 #video_check:checked ~ .video {
  -webkit-clip-path: var(--clip-path-clicked);
  clip-path: var(--clip-path-clicked);
}

.hero__area-3 #video_check:checked ~ .text {
  --opacity: 0;
  -webkit-transition: opacity 0.3s var(--timing-function);
  transition: opacity 0.3s var(--timing-function);
}

.hero__area-3 #video_check:checked ~ .text::after {
  -webkit-clip-path: var(--clip-path-clicked);
  clip-path: var(--clip-path-clicked);
}

.hero__area-3 #video_check:checked ~ .text > span::after {
  -webkit-clip-path: var(--clip-path-clicked);
  clip-path: var(--clip-path-clicked);
}

.hero__area-3.start-video .hero3-img {
  opacity: 0;
}

.hero__area-3.start-video .sec-title,
.hero__area-3.start-video .sec-sub-title,
.hero__area-3.start-video .hero__text-3 p,
.hero__area-3.start-video .intro-title .video-title {
  color: var(--white-4);
}

.hero__area-3.start-video .sec-sub-title::after {
  background-color: var(--white);
}

.hero__area-4 {
  padding-top: 110px;
  height: 100%;
}

@media only screen and (min-width: 1920px) {
  .hero__area-4 {
    height: 100vh;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-4 {
    padding-top: 60px;
  }
}

.hero__area-10 {
  /* height: 100vh; */
  padding: 30px 30px 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-10 {
    padding: 30px 30px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-10 {
    padding: 30px 30px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-10 {
    padding: 30px 20px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-10 {
    padding: 30px 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-10 {
    padding: 30px 15px 0;
  }
}

.hero__content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 1;
}

.hero__content-wrapper .text {
  mix-blend-mode: difference;
  opacity: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.hero__plane {
  width: 100%;
  height: 100vh;
}

.hero__plane img {
  display: none;
}

.hero__inner-2 {
  padding: 220px 100px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__inner-2 {
    padding: 140px 100px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__inner-2 {
    padding: 120px 50px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__inner-2 {
    padding: 160px 30px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__inner-2 {
    padding: 120px 15px 60px;
  }
}

.hero__inner-3 {
  position: relative;
}

.hero__inner-3 .scroll-down {
  margin-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__inner-3 .scroll-down {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__inner-3 .scroll-down {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .hero__inner-3 .scroll-down {
    display: none;
  }
}

.hero__inner-3 .scroll-down button {
  border: 1px solid var(--gray-3);
  padding: 25px 10px;
  border-radius: 30px;
  -webkit-animation: wcSlideBottom 7s infinite linear;
  animation: wcSlideBottom 7s infinite linear;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__inner-3 .scroll-down button {
    border-color: var(--white);
  }

  .hero__inner-3 .scroll-down button img {
    -webkit-filter: invert(1);
    filter: invert(1);
  }
}

.hero__text-2 {
  background-image: url(../imgs/icon/7.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: 64% 58%;
  padding-left: 32%;
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__text-2 {
    padding-left: 30%;
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__text-2 {
    padding-left: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__text-2 {
    padding-left: 15%;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__text-2 {
    padding-left: 5%;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@media only screen and (max-width: 767px) {
  .hero__text-2 {
    padding-left: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.hero__text-2 p {
  max-width: 330px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--white);
  padding-bottom: 5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__text-2 p {
    max-width: 380px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__text-2 p {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__text-2 p {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__text-2 p {
    padding-bottom: 40px;
  }
}

.hero__text-3 {
  max-width: 320px;
  padding-top: 90px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__text-3 {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__text-3 {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__text-3 {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__text-3 {
    padding-bottom: 80px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__text-3 {
    padding-bottom: 30px;
  }
}

.hero__text-3 p {
  font-size: 1.125rem;
  line-height: 1.4;
}

.hero__content {
  padding-top: 200px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__content {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__content {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__content {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content {
    padding-top: 125px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__content {
    padding-top: 150px;
  }
}

.hero__content a {
  display: inline-block;
  max-width: 300px;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 15px;
  border-bottom: 1px solid var(--white);
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
}

.hero__content a:hover i {
  -webkit-transform: translate(20px, -20px) rotate(-45deg);
  transform: translate(20px, -20px) rotate(-45deg);
}

.hero__content a:hover span::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(20px, -20px) rotate(-45deg);
  transform: translate(20px, -20px) rotate(-45deg);
}

.hero__content a span {
  position: absolute;
  top: 0;
  right: 0;
}

.hero__content a span i {
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.hero__content a span::after {
  position: absolute;
  content: "\f061";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: -20px;
  bottom: -20px;
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__content img {
    max-width: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content img {
    max-width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__content img {
    max-width: 60px;
  }
}

.hero__content .experience {
  position: absolute;
  right: 30px;
  bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content .experience {
    bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__content .experience {
    position: unset;
  }
}

.hero__content .experience .title {
  font-weight: 400;
  font-size: 50px;
  line-height: 1.3;
  color: var(--white);
}

.hero__content .experience p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--white);
}

.hero__title {
  font-weight: 500;
  font-size: 150px;
  line-height: 1;
  color: var(--white);
  margin-top: 55px;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title {
    font-size: 100px;
    line-height: 0.9;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title {
    font-size: 100px;
    line-height: 0.9;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title {
    font-size: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__title {
    font-size: 48px;
  }
}

.hero__title::after {
  position: absolute;
  content: "Pro";
  width: 70px;
  height: 45px;
  right: -80px;
  top: 70px;
  border: 2px solid var(--white);
  border-radius: 15px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.3;
  color: var(--white);
  text-align: center;
  padding-top: 4px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title::after {
    right: -30px;
    top: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title::after {
    right: 60px;
    top: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title::after {
    right: 95px;
    top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__title::after {
    right: 0;
    top: -40px;
  }
}

.hero__title-wrapper {
  max-width: 990px;
  position: relative;
  margin-bottom: 105px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title-wrapper {
    max-width: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title-wrapper {
    max-width: 800px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title-wrapper {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__title-wrapper {
    margin-bottom: 0;
  }
}

.hero__sub-title {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--gray-3);
  max-width: 410px;
  position: absolute;
  right: 60px;
  bottom: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__sub-title {
    bottom: -10px;
    right: -30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__sub-title {
    bottom: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__sub-title {
    font-size: 1rem;
    bottom: -10px;
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__sub-title {
    position: unset;
    padding-top: 15px;
  }
}

.hero__sub-title span {
  color: var(--white);
}

.hero__about {
  background-image: url(../imgs/about/about-bg.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: top right;
  overflow: hidden;
}

.hero__about-content {
  padding-top: 170px;
}

@media only screen and (max-width: 767px) {
  .hero__about-content {
    padding-top: 250px;
  }
}

.hero__about-content .hero-title {
  padding-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .hero__about-content .hero-title {
    padding-bottom: 30px;
  }
}

.hero__about-info {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 2.5fr 30px 2.5fr;
  grid-template-columns: 1fr 2.5fr 2.5fr;
  padding-bottom: 80px;
  grid-gap: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__about-info {
    -ms-grid-columns: 1fr 30px 2.5fr 30px 2fr;
    grid-template-columns: 1fr 2.5fr 2fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__about-info {
    -ms-grid-columns: 1fr 20px 2.5fr 20px 1fr;
    grid-template-columns: 1fr 2.5fr 1fr;
    grid-gap: 20px;
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__about-info {
    padding-bottom: 60px;
  }
}

.hero__about-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .hero__about-text {
    grid-column: 1/-1;
    -ms-grid-row: 1;
    grid-row: 1;
    margin-bottom: 20px;
  }
}

.hero__about-text p {
  font-size: 1.125rem;
  line-height: 1.4;
  padding-left: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__about-text p {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__about-text p {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .hero__about-text p {
    padding-left: 0;
  }
}

.hero__about-award {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.hero__about-award::before {
  position: absolute;
  content: "";
  left: 75px;
  top: 50%;
  width: 250px;
  height: 1px;
  background-color: var(--white-4);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__about-award::before {
    left: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__about-award::before {
    width: 150px;
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .hero__about-award {
    position: absolute;
    top: 150px;
  }
}

.hero__about-video {
  margin-bottom: -10px;
  height: 800px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__about-video {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__about-video {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__about-video {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__about-video {
    height: 470px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__about-video {
    height: auto;
  }
}

.hero__about-video iframe,
.hero__about-video video {
  width: 100%;
  height: auto;
}

.hero__about-row {
  position: inherit;
  margin-right: -310px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__about-row {
    margin-right: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__about-row {
    margin-right: -150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__about-row {
    margin-right: -150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__about-row {
    margin-right: -120px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__about-row {
    margin-right: calc(-0.5 * var(--bs-gutter-x));
  }
}

.hero__link {
  display: inline-block;
  max-width: 300px;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 15px;
  border-bottom: 1px solid var(--white);
  position: absolute;
  top: 190px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
}

.hero__link:hover i {
  -webkit-transform: translate(20px, -20px) rotate(-45deg);
  transform: translate(20px, -20px) rotate(-45deg);
}

.hero__link:hover span::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(20px, -20px) rotate(-45deg);
  transform: translate(20px, -20px) rotate(-45deg);
}

.hero__link span {
  position: absolute;
  top: 0;
  right: 0;
}

.hero__link span i {
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.hero__link span::after {
  position: absolute;
  content: "\f061";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: -20px;
  bottom: -20px;
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#hero_texture {
  position: relative;
}

#hero_canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero__content-8 {
  padding-top: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__content-8 {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content-8 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__content-8 {
    padding-top: 80px;
    text-align: center;
  }
}

.hero__content-8 .discription {
  padding-top: 40px;
  font-size: 1.125rem;
  max-width: 330px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content-8 .discription {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__content-8 .discription {
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 100%;
  }
}

.hero__content-8 .scroll-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 300px auto;
  grid-template-columns: 300px auto;
}

@media only screen and (max-width: 767px) {
  .hero__content-8 .scroll-wrapper {
    display: block;
  }
}

.hero__content-8 .scroll-down {
  position: relative;
  padding-left: 100px;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .hero__content-8 .scroll-down {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content-8 .scroll-down {
    padding-left: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__content-8 .scroll-down {
    padding-left: 120px;
  }
}

.hero__content-8 .scroll-down span {
  position: absolute;
  left: 105px;
  top: -45px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-size: 14px;
  text-transform: uppercase;
}

.hero__content-8 .scroll-down img {
  -webkit-animation: wcSlideBottom 3s infinite;
  animation: wcSlideBottom 3s infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__content-8 .scroll-down img {
    max-height: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content-8 .scroll-down img {
    max-height: 100px;
  }
}

.portfolio__big {
  text-align: center;
  padding-bottom: 395px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__big {
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__big {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__big {
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__big {
    padding-bottom: 60px;
  }
}

.portfolio__big img {
  margin-top: -90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__big img {
    margin-top: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__big img {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__big img {
    margin-top: 0;
  }
}

.portfolio__big-inner {
  background-position: center center;
  background-size: cover;
  height: calc(100vh - 80px);
  width: 600px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: -100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__big-inner {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__big-inner {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__big-inner {
    margin-top: 10px;
  }
}

.portfolio__big-inner img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.bodypadding {
  padding: 110px 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bodypadding {
    padding: 70px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .bodypadding {
    padding: 30px 15px;
  }
}

.bodypadding-2 {
  padding: 120px 50px;
}

@media only screen and (max-width: 767px) {
  .bodypadding-2 {
    padding: 30px 15px;
  }
}

.dark .hero__content-8 .scroll-down img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .hero__content-8 .scroll-down span {
  color: var(--white);
}

.roll__area {
  background-color: var(--primary);
  padding: 31px 10px;
}

.roll__area-2 {
  padding: 31px 10px;
  background-color: var(--black-2);
}

.roll__area-2 .roll__slide h2 {
  color: var(--white);
}

.roll__area-3 {
  padding: 31px 10px;
  background-color: var(--pink-2);
}

.roll__area-3 .roll__slide h2 {
  color: var(--black-2);
}

.roll__wrapper {
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.roll__slide {
  text-align: center;
}

.roll__slide h2 {
  font-weight: 400;
  font-size: 26px;
  line-height: 1.1;
  text-transform: uppercase;
  color: var(--black);
}

.contact_wrap {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact_wrap {
    margin-top: 60px;
  }
}

.contact_wrap:hover .link {
  opacity: 1;
  visibility: visible;
}

.contact_wrap .link {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 1.125rem;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 160px;
  height: 160px;
  background: var(--black-2);
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--white);
  text-transform: capitalize;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media (max-width: 1365px) {
  .contact_wrap .link {
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 1365px) {
  .contact_wrap .link {
    opacity: 1;
    visibility: visible;
  }
}

.rollslide_wrap {
  text-align: center;
  overflow: hidden;
  margin-bottom: -10px;
}

.rollslide_title {
  color: var(--black-2);
  font-size: 250px;
  line-height: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: uppercase;
}

.rollslide_title-1 {
  color: var(--black-2);
  font-size: 245px;
  line-height: 0.75;
  text-transform: uppercase;
}

.rollslide_title-1 span {
  font-family: "newYork";
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .rollslide_title-1 {
    font-size: 160px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rollslide_title-1 {
    font-size: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .rollslide_title-1 {
    font-size: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rollslide_title-1 {
    font-size: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .rollslide_title-1 {
    font-size: 48px;
  }
}

.rollslide_title:hover {
  color: var(--black-3);
}

.rollslide_title span {
  font-weight: 400;
  font-family: "newYork";
}

@media only screen and (max-width: 767px) {
  .rollslide_title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rollslide_title {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rollslide_title {
    font-size: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .rollslide_title {
    font-size: 180px;
  }
}

@media only screen and (max-width: 767px) {
  footer .contact_wrap {
    margin-top: 40px;
  }
}

.dark .rollslide_title-1 {
  color: var(--white);
}

.dark .contact_wrap:hover .link {
  color: var(--black-2);
  background-color: var(--white);
}

@media (max-width: 1365px) {
  .dark .contact_wrap .link {
    color: var(--black-2);
    background-color: var(--white);
  }
}

.about__area {
  overflow: hidden;
  background-color: var(--white);
}

.about__area .sec-title {
  max-width: 770px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area .sec-title {
    max-width: 660px;
  }
}

.about__area-2 {
  overflow: hidden;
  background-color: var(--black-2);
  background-image: url(../imgs/shape/2.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: 0 31%;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .about__area-2 {
    background-position: 0 40%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__area-2 {
    background-position: 0 37%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-2 {
    background-position: 0 39%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-2 {
    background-position: -15% 47%;
  }
}

@media only screen and (max-width: 767px) {
  .about__area-2 {
    background-size: 0;
  }
}

.about__area-2 .sec-title {
  color: var(--white);
}

.about__area-2 .sec-title-wrapper {
  max-width: 770px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-2 .sec-title-wrapper {
    max-width: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .about__area-2 .sec-title-wrapper {
    padding-bottom: 50px;
  }
}

.about__area-2 .sec-text {
  max-width: 440px;
}

@media only screen and (max-width: 767px) {
  .about__area-2 .sec-text {
    padding-bottom: 40px;
  }
}

.about__area-2 .sec-text p {
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--gray-2);
}

.about__area-2 .sec-text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
  gap: 215px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-2 .sec-text-wrapper {
    gap: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-2 .sec-text-wrapper {
    gap: 50px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .about__area-2 .sec-text-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 50px;
  }
}

.about__area-2 #btn_wrapper,
.about__area-2 .btn_wrapper {
  margin-left: 0;
  margin-right: -40px;
  margin-top: -50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-2 #btn_wrapper,
  .about__area-2 .btn_wrapper {
    margin-left: auto;
    margin-right: 0;
    margin-top: -20px;
  }
}

.about__area-3 {
  background-image: url(../imgs/shape/7.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: 100% 30%;
  background-color: var(--black-2);
  position: relative;
}

.about__area-3::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: 60%;
  bottom: 0px;
  background-color: var(--black-5);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-3::after {
    left: 55%;
  }
}

@media only screen and (max-width: 767px) {
  .about__area-3::after {
    width: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-3 .pt-140 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-3 .pt-140 {
    padding-top: 90px;
  }
}

.about__area-3 .sec-title {
  color: var(--white);
  text-transform: uppercase;
  padding-bottom: 40px;
  background-color: var(--black-2);
}

.about__area-3 .sec-sub-title {
  color: var(--white);
  text-transform: uppercase;
  padding-top: 45px;
  background-color: var(--black-2);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-3 .sec-sub-title {
    padding-top: 0;
  }
}

.about__area-3 .sec-text p {
  max-width: 410px;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--gray-2);
  padding-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  .about__area-3 .sec-text p {
    padding-bottom: 50px;
  }
}

.about__area-3 .sec-text-wrapper {
  padding-left: 210px;
  padding-top: 45px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .about__area-3 .sec-text-wrapper {
    padding-left: 160px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__area-3 .sec-text-wrapper {
    padding-left: 160px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-3 .sec-text-wrapper {
    padding-left: 65px;
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-3 .sec-text-wrapper {
    padding-left: 0;
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .about__area-3 .sec-text-wrapper {
    padding-left: 0;
    padding-top: 15px;
  }
}

.about__area-8 .about-sub-right {
  color: var(--black-7);
  padding-bottom: 10px;
}

.about__title-wrapper {
  padding-left: 190px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__title-wrapper {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .about__title-wrapper {
    padding-left: 0;
  }
}

.about__content {
  padding-top: 65px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__content {
    padding-top: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .about__content {
    padding-top: 50px;
  }
}

.about__content p {
  max-width: 440px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-3);
  margin-bottom: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__content p {
    max-width: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__content p {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about__content p {
    margin-bottom: 30px;
  }
}

.about__content-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 80px 1.8fr;
  grid-template-columns: 1fr 1.8fr;
  grid-gap: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__content-wrapper {
    grid-gap: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__content-wrapper {
    -ms-grid-columns: 1fr 30px 1.5fr;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about__content-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

.about__img img {
  width: 100%;
  overflow: hidden;
}

.about__img-right {
  position: absolute;
  right: 0;
  top: 280px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-right {
    position: relative;
    top: 30px;
    right: unset;
  }
}

@media only screen and (max-width: 767px) {
  .about__img-right {
    position: relative;
    top: 30px;
    right: unset;
  }
}

.about__img-right img {
  width: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__img-right img {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-right img {
    display: none;
  }
}

.about__img-2 {
  max-width: 1720px;
  margin: 0 auto;
  overflow: hidden;
  max-height: 800px;
  margin-bottom: 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__img-2 {
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-2 {
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .about__img-2 {
    margin-bottom: 0;
  }
}

.about__img-2 img {
  width: 100%;
}

.about__img-3 {
  overflow: hidden;
  position: relative;
  width: calc(100% - 85px);
  height: 770px;
}

.about__img-3 img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 110%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__img-3 {
    overflow: hidden;
    position: relative;
    width: calc(100% - 40px);
    height: 660px;
  }

  .about__img-3 img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 120%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__img-3 {
    width: 100%;
    height: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-3 {
    width: 100%;
    height: unset;
  }
}

@media only screen and (max-width: 767px) {
  .about__img-3 {
    width: 100%;
    height: unset;
  }
}

.about__img-3 img {
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__img-3 img {
    position: unset;
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img-3 img {
    position: unset;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .about__img-3 img {
    position: unset;
    height: auto;
  }
}

.portfolio__item {
  -webkit-transform: perspective(4000px) rotateX(90deg);
  transform: perspective(4000px) rotateX(90deg);
}

.section_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  position: relative;
  margin-top: -25px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .section_wrapper {
    margin-bottom: 30px;
  }
}

.section_wrapper::before {
  content: "";
  position: absolute;
  bottom: 48%;
  left: 60px;
  height: 1px;
  width: calc(100% - 60px);
  background-color: var(--gray-11);
}

@media only screen and (max-width: 767px) {
  .section_wrapper::before {
    left: 48px;
    width: calc(100% - 48px);
  }
}

.react_border {
  position: relative;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  display: inline-block;
  background-color: transparent;
  border: 1px solid var(--gray-11);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (max-width: 767px) {
  .react_border {
    height: 40px;
    width: 40px;
    line-height: 36px;
  }
}

.react_border span {
  font-weight: 400;
  font-size: 1rem;
  color: var(--black-2);
  display: inline-block;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.about8__title {
  text-indent: 105px;
  font-weight: 500;
  font-size: 80px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--black-2);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .about8__title {
    font-size: 72px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about8__title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about8__title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about8__title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .about8__title {
    font-size: 36px;
    text-indent: 50px;
  }
}

.about8__title a {
  color: var(--black-2);
  font-family: "newYork";
  position: relative;
}

.about8__title a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 10px;
  background-color: var(--black-2);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about8__title a::after {
    bottom: 3px;
  }
}

@media only screen and (max-width: 767px) {
  .about8__title a::after {
    bottom: 3px;
  }
}

.about8__content {
  float: right;
}

.about8__content p {
  line-height: 1.3;
  max-width: 515px;
  text-indent: 65px;
  font-size: 1.5rem;
  margin-bottom: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about8__content p {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about8__content p {
    font-size: 1.125rem;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about8__content p {
    font-size: 1.125rem;
    margin-bottom: 30px;
    line-height: 1.5;
  }
}

.workitems {
  display: -ms-grid;
  display: grid;
  grid-gap: 50px;
  -ms-grid-columns: 1fr 50px 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .workitems {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workitems {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workitems {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.work8:nth-child(2) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .work8:nth-child(2) {
    margin-top: 0;
  }
}

.work8:nth-child(3) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.work8:nth-child(4) {
  margin-left: 50px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .work8:nth-child(4) {
    margin: 0;
  }
}

.work8:nth-child(5) {
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .work8:nth-child(5) {
    margin: 0;
  }
}

.work8:nth-child(6) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work8 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work8 {
    margin-bottom: 50px;
  }
}

.work8 img {
  max-width: 100%;
}

.work8__title {
  width: 320px;
  margin-bottom: 120px;
  padding-top: 90px;
  margin-left: 440px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work8__title {
    margin-bottom: 90px;
    padding-top: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work8__title {
    margin-left: 165px;
    margin-bottom: 90px;
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work8__title {
    margin-left: 40px;
    margin-bottom: 90px;
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .work8__title {
    margin-bottom: 40px;
    margin-left: 20px;
    padding-top: 40px;
  }
}

.work8__title p {
  font-size: 1.125rem;
  text-indent: 65px;
  font-weight: 400;
  color: var(--black-2);
}

.work8__content {
  padding-top: 30px;
}

@media only screen and (max-width: 767px) {
  .work8__content {
    padding-top: 20px;
  }
}

.work8__content p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--black-3);
}

.work8__content__title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  text-transform: uppercase;
  color: var(--black-2);
  padding-bottom: 5px;
}

.work8__content__title span {
  font-family: "newYork";
}

.work8__btn {
  text-align: center;
  margin-top: 115px;
  margin-bottom: 160px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work8__btn {
    margin-top: 60px;
    margin-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work8__btn {
    margin-top: 60px;
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .work8__btn {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .work__area-8 {
    margin-bottom: 50px;
  }
}

.about__area-8 {
  padding-bottom: 170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__area-8 {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area-8 {
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .about__area-8 {
    padding-bottom: 80px;
  }
}

.dark .react_border span {
  color: var(--white);
}

.dark .about8__title,
.dark .about8__title a,
.dark .work8__title p,
.dark .work8__content__title,
.dark .about__area-8 .about-sub-right {
  color: var(--white);
}

.dark .work8__content p {
  color: var(--gray-2);
}

.service__area {
  background-color: var(--black-2);
  padding: 30px 0 30px 0;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area.pb-150 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area.pb-150 {
    padding-bottom: 90px;
  }
}

.service__area a:first-child .service__item {
  border-top: 1px solid var(--secondary);
}

.service__area .sec-title,
.service__area .sec-sub-title {
  color: var(--white);
}

.service__area .sec-title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.service__area-2 {
  background-color: var(--pink-3);
}

@media only screen and (max-width: 767px) {
  .service__area-2 {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .service__area-2 .sec-text {
    padding-bottom: 30px;
  }
}

.service__area-2 .sec-text p {
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-2);
  max-width: 410px;
}

.service__area-3 {
  overflow: hidden;
}

.service__area-3 .sec-title {
  padding-bottom: 40px;
  text-transform: uppercase;
  background-color: var(--white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-3 .sec-title {
    padding-bottom: 0;
  }
}

.service__area-3 .sec-title-wrapper {
  position: relative;
  padding-left: 330px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-3 .sec-title-wrapper {
    padding-left: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-3 .sec-title-wrapper {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .service__area-3 .sec-title-wrapper {
    padding-left: 0;
  }
}

.service__area-3 .sec-title-wrapper::after {
  position: absolute;
  content: "";
  left: 40%;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: var(--white-2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-3 .sec-title-wrapper::after {
    left: 30%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-3 .sec-title-wrapper::after {
    left: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .service__area-3 .sec-title-wrapper::after {
    width: 0;
  }
}

.service__area-3 .sec-title-wrapper p {
  padding-top: 70px;
  padding-bottom: 60px;
  max-width: 410px;
  font-size: 1.125rem;
  line-height: 1.4;
  margin-left: 220px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-3 .sec-title-wrapper p {
    margin-left: 120px;
    padding-top: 30px;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-3 .sec-title-wrapper p {
    margin-left: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .service__area-3 .sec-title-wrapper p {
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.service__area-3 .sec-sub-title {
  padding-top: 50px;
  text-transform: uppercase;
  background-color: var(--white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-3 .sec-sub-title {
    padding-top: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-3 .sec-sub-title {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .service__area-3 .sec-sub-title {
    padding-top: 0;
  }
}

.service__area-6 {
  padding-left: 100px;
  background-color: var(--black-2);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__area-6 {
    padding-left: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-6 {
    padding-top: 100px;
    padding-left: 88px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-6 {
    padding-top: 90px;
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .service__area-6 {
    padding-bottom: 0;
    padding-left: 0;
  }
}

.service__area-6 .inherit-row {
  margin-left: -180px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__area-6 .inherit-row {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__area-6 .inherit-row {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-6 .inherit-row {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-6 .inherit-row {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}

@media only screen and (max-width: 767px) {
  .service__area-6 .inherit-row {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}

.service__area-6 .content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .service__area-6 .content-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.service__area-6 .left-content {
  width: 20%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-6 .left-content {
    width: 35%;
    padding-left: 50px;
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-6 .left-content {
    width: 30%;
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .service__area-6 .left-content {
    display: none;
  }
}

.service__area-6 .right-content {
  width: 58%;
  padding-left: 80px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__area-6 .right-content {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__area-6 .right-content {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-6 .right-content {
    width: 100%;
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-6 .right-content {
    width: 100%;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .service__area-6 .right-content {
    width: 100%;
    padding-left: 0;
  }
}

.service__area-6 .mid-content {
  width: 40%;
  height: 100vh;
  position: relative;
}

.service__area-6 .mid-content .service__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.service__area-6 .mid-content .service__image img {
  width: auto;
  height: 100vh;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-6 .mid-content {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-6 .mid-content {
    display: none;
  }
}

.service__area-6 .mid-content #img1 {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 767px) {
  .service__area-6 .mid-content {
    display: none;
  }

  .service__area-6 .right-content {
    padding-left: 0;
  }
}

.service__top-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.service__top-text p {
  max-width: 505px;
  color: var(--gray-2);
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.4;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__top-text p {
    font-size: 1.125rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__top-text p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .service__top-text p {
    font-size: 1.125rem;
    margin: 30px 0;
  }
}

.service__top-text p span {
  color: var(--white);
}

.service__top-btn #btn_wrapper,
.service__top-btn .btn_wrapper {
  margin-left: auto;
  margin-right: -40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__top-btn #btn_wrapper,
  .service__top-btn .btn_wrapper {
    margin-right: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__top-btn #btn_wrapper,
  .service__top-btn .btn_wrapper {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .service__top-btn #btn_wrapper,
  .service__top-btn .btn_wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}

/* .service__list-wrapper {
  margin-top: 40px;
} */

.service__img {
  /* border-radius: 2rem; */
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  position: absolute;
  /* -webkit-transform: rotate(0deg) translateX(-100px);
  transform: rotate(0deg) translateX(-100px); */
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__img {
    max-width: 260px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__img {
    max-width: 200px;
  }
}

.service__img.active {
  opacity: 1;
  /* -webkit-transform: rotate(15deg) translateX(60px) translateY(30px);
  transform: rotate(15deg) translateX(60px) translateY(30px); */
}

.service__img-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__img-wrapper {
    display: none;
  }
}

.service__img-wrapper span {
  position: absolute;
  width: 175px;
  height: 70px;
  /* border-radius: 2rem; */
  background-color: var(--primary);
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(-26deg) translate(-100%, -100%);
  transform: rotate(-26deg) translate(-100%, -100%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  z-index: 1;
}

@media only screen and (max-width: 1368px) {
  .service__img-wrapper span {
    bottom: 18%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__img-wrapper span {
    bottom: 260px;
    height: 40px;
    width: 120px;
  }
}

.service__img-wrapper span.current {
  -webkit-transform: rotate(-26deg) translate(120px, -100%);
  transform: rotate(-26deg) translate(120px, -100%);
}

.service__img-3 {
  position: relative;
}

.service__img-3 img {
  top: -95px;
  position: absolute;
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service__img-6 img {
  max-width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__items-6 {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .service__items-6 {
    padding-top: 60px;
  }
}

.service__item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.7fr 2fr 2fr;
  grid-template-columns: 0.7fr 2fr 2fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 0;
  border-bottom: 1px solid var(--dark-theme);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item {
    gap: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service__item {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    padding: 25px 0 10px;
  }
}

.service__item .service__title-wrapper img {
  display: none;
  width: 8rem;
  margin-bottom: 1rem;
}

@media (max-width: 1180px) {
  .service__item .service__title-wrapper img {
    display: block;
  }
}

@media (max-width: 720px) {
  .service__item .service__title-wrapper img {
    margin-top: 1rem;
  }
}

.service__item:hover .service__number span,
.service__item:hover .service a {
  color: var(--white);
}

.service__item:hover .service__title {
  color: var(--white);
}

.service__item:hover .service__link p {
  color: var(--white);
}

.service__item:hover .service__link p i {
  -webkit-transform: translate(60px, -60px) rotate(-45deg);
  transform: translate(60px, -60px) rotate(-45deg);
}

.service__item:hover .service__link p::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(20px, -20px) rotate(-45deg);
  transform: translate(20px, -20px) rotate(-45deg);
}

.service__item-2 .service-img {
  position: relative;
  margin-bottom: 40px;
}

@media only screen and (max-width: 912px) {
  .service__item-2 .service-img {
    margin-bottom: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .service__item-2 img {
    width: 250px;
  }
}

.service__item-2 .img-overlay {
  display: none;
  background: #000000e0;
  height: 500px;
  width: 400px;
  position: absolute;
  transform: translate(0, 0);
  z-index: 2;
}

@media (max-width: 480px) {
  .service__item-2 .img-overlay {
    height: 312.5px;
    width: 250px;
    right: 16.6%;
  }
}

@media (max-width: 413px) {
  .service__item-2 .img-overlay {
    height: 312.5px;
    width: 250px;
    right: 16.7%;
  }
}

@media (max-width: 400px) {
  .service__item-2 .img-overlay {
    right: 16%;
  }
}

.service__item-2:hover .img-overlay {
  display: block;
}

.service__item-2:hover p {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.service__item-2 p {
  position: absolute;
  top: 47%;
  left: 6%;
  opacity: 0;
  color: var(--white);
  padding-bottom: 25px;
  max-width: 300px;
  z-index: 3;
  -webkit-transform: translateX(60px);
  transform: translateX(60px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 480px) {
  .service__item-2 p {
    max-width: 230px;
    left: 18%;
  }
}

.service__item-2 ul li {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black-2);
}

.service__item-3 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 30% 45% 25%;
  grid-template-columns: 30% 45% 25%;
  border-bottom: 1px solid var(--white-2);
  padding: 55px 0 50px;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item-3 {
    -ms-grid-columns: 30% 50% 20%;
    grid-template-columns: 30% 50% 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item-3 {
    -ms-grid-columns: 30% 50% 20%;
    grid-template-columns: 30% 50% 20%;
    padding: 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-3 {
    -ms-grid-columns: 30% 2% 40% 2% 24%;
    grid-template-columns: 30% 40% 24%;
    gap: 2%;
    padding: 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .service__item-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    padding: 40px 0;
  }
}

.service__item-3:first-child {
  border-top: 1px solid var(--white-2);
}

.service__item-3:hover .service__hover-3 {
  opacity: 1;
}

.service__item-6 {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.service__item-6 .image-tab {
  display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item-6 {
    height: auto;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
    -ms-flex-direction: unset;
    flex-direction: unset;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 2fr 30px 3fr;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 30px;
    margin-bottom: 60px;
  }

  .service__item-6 .image-tab {
    display: block;
  }

  .service__item-6 .image-tab img {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-6 {
    height: auto;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .service__item-6 {
    height: auto;
    padding-bottom: 50px;
  }
}

.service__item-6 p {
  max-width: 550px;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--gray-2);
  padding-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item-6 p {
    padding-bottom: 20px;
  }
}

.service__item-6 ul {
  display: -ms-grid;
  display: grid;
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-6 ul {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .service__item-6 ul {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    padding-bottom: 20px;
  }
}

.service__item-6 ul li {
  display: inline-block;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--white);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item-6 ul li {
    font-size: 1rem;
    padding-bottom: 5px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__item-6 ul {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item-6 ul {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item-6 ul {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-6 ul {
    padding-bottom: 50px;
    -ms-grid-columns: 240px auto;
    grid-template-columns: 240px auto;
  }
}

.service__hover-3 {
  width: 300px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 75%;
  opacity: 0;
  -webkit-transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  margin: -200px 0 0 -150px;
  overflow: hidden;
  pointer-events: none;
}

@media (max-width: 1200px) {
  .service__hover-3 {
    width: 0;
    display: none;
  }
}

.service__number span {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.2;
  color: var(--gray-2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service__title {
  display: block;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.3;
  color: var(--gray-2);
}

.service__title:hover {
  color: var(--white);
}

@media only screen and (max-width: 767px) {
  .service__title {
    padding: 10px 0 20px;
  }

  .service__title br {
    display: none;
  }
}

.service__title-2,
.service__title-3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.3;
  color: var(--black-2);
  padding-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__title-2,
  .service__title-3 {
    font-size: 1.5rem;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .service__title-2,
  .service__title-3 {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 912px) {
  .service__title-2 br {
    display: none;
  }
}

.service__title-3 {
  text-transform: uppercase;
}

.service__title-3:hover {
  color: var(--black-2);
}

@media only screen and (max-width: 767px) {
  .service__title-3 {
    display: block;
    font-size: 30px;
    padding-bottom: 20px;
  }
}

.service__title-6 {
  font-weight: 500;
  font-size: 40px;
  line-height: 1.1;
  color: var(--white);
  padding-bottom: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__title-6 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__title-6 {
    font-size: 32px;
    padding-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__title-6 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service__title-6 {
    font-size: 30px;
  }
}

.service__text {
  max-width: 360px;
}

.service__text p {
  color: var(--gray-2);
}

.service__link {
  text-align: right;
  overflow: hidden;
}

.service__link p {
  display: inline-block;
  font-size: 48px;
  color: var(--gray-2);
}

.service__link p i {
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service__link p::after {
  position: absolute;
  content: "\f061";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: -20px;
  bottom: -20px;
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service__btn-2 a {
  color: var(--black-2);
  border-color: var(--black-2);
}

.service__btn-2#btn_wrapper,
.service__btn-2.btn_wrapper {
  margin: 0 auto;
  margin-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__btn-2#btn_wrapper,
  .service__btn-2.btn_wrapper {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__btn-2#btn_wrapper,
  .service__btn-2.btn_wrapper {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .service__btn-2#btn_wrapper,
  .service__btn-2.btn_wrapper {
    margin: 0;
    margin-top: 60px;
  }
}

.service__btn-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .service__btn-3 {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 30px;
  }
}

.service__btn-3 #btn_wrapper,
.service__btn-3 .btn_wrapper {
  width: 200px;
  height: 200px;
  margin-right: -15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__btn-3 #btn_wrapper,
  .service__btn-3 .btn_wrapper {
    margin-left: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .service__btn-3 #btn_wrapper,
  .service__btn-3 .btn_wrapper {
    margin-left: -15px;
  }
}

.service__list .active .service__number span,
.service__list .active .service__title,
.service__list .active .service__link p {
  color: var(--white);
}

@media (max-width: 912px) {
  .service__list-2 {
    text-align: center;
    justify-items: center;
  }
}

.service__list-3 {
  padding-top: 70px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .service__list-3 {
    padding-top: 40px;
  }
}

.service__list-6 {
  padding-top: 160px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__list-6 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__list-6 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__list-6 {
    padding-top: 60px;
    position: sticky;
    top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__list-6 {
    padding-top: 60px;
    position: sticky;
    top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .service__list-6 {
    padding-top: 60px;
  }
}

.service__list-6 li {
  padding-bottom: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__list-6 li {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__list-6 li {
    padding-bottom: 20px;
  }
}

.service__list-6 li.active a {
  color: var(--white);
}

@media only screen and (max-width: 767px) {
  .service__list-6 li {
    padding-bottom: 20px;
  }
}

.service__list-6 li a {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.5;
  color: var(--gray-2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__list-6 li a {
    line-height: 1.4;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__list-6 li a {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  .service__list-6 li a {
    font-size: 1.25rem;
  }
}

.service__content-3 {
  max-width: 340px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__content-3 {
    padding-left: 30px;
  }
}

.service__content-3 p {
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-2);
}

.service__content-3 ul {
  padding-top: 20px;
  position: relative;
  z-index: 1;
}

.service__content-3 ul li {
  font-size: 1.125rem;
  color: var(--black-2);
}

.service3__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.service3__img-wrap {
  width: 20vw;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  padding-bottom: 25vw;
}

@media only screen and (max-width: 767px) {
  .service3__img-wrap {
    display: none;
    position: unset;
  }
}

.service__detail {
  overflow: hidden;
}

.service__detail .sec-title {
  max-width: 630px;
  font-size: 36px;
  line-height: 1.3;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__detail .sec-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service__detail .sec-title {
    font-size: 30px;
    padding-bottom: 30px;
  }
}

.service__detail-circle {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .service__detail-circle {
    display: none;
  }
}

.service__detail-circle span {
  position: relative;
  display: block;
  width: 136px;
  height: 136px;
  background-color: var(--primary);
  border-radius: 100%;
}

.service__detail-circle span::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  left: calc(50% - 3px);
  top: calc(50% - 3px);
  background-color: var(--black-2);
  border-radius: 100%;
}

.service__detail-circle span::after {
  position: absolute;
  content: "";
  width: 170px;
  height: 2px;
  left: 50%;
  top: calc(50% - 1px);
  background-color: var(--black-2);
  border-radius: 100%;
}

.service__detail-img {
  padding-right: 1px;
  position: relative;
  margin-bottom: 60px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .service__detail-img {
    margin-top: 30px;
  }
}

.service__detail-img img {
  width: 100%;
}

.service__detail-img img.sd-shape {
  width: auto;
  position: absolute;
  bottom: -15px;
  right: 20px;
}

@media only screen and (max-width: 767px) {
  .service__detail-img img.sd-shape {
    right: 5px;
    bottom: -30px;
  }
}

.service__detail-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 80px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__detail-content {
    grid-gap: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__detail-content {
    grid-gap: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__detail-content {
    grid-gap: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service__detail-content {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}

.service__detail-content p {
  font-size: 1.125rem;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-v2.pt-110 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-v2.pt-110 {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .service-v2.pt-110 {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-v3.pt-130 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-v3.pt-130 {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .service-v3.pt-130 {
    padding-top: 120px;
  }
}

.service-v4.pb-150 {
  padding-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-v4 .pt-130 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-v4 .pt-130 {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .service-v4 .pt-130 {
    padding-top: 120px;
  }
}

.service-v5 .portfolio__service-item,
.service-v5.portfolio__service .sec-title,
.service-v5.portfolio__service .sec-text,
.service-v5.portfolio__service .sec-text p {
  opacity: 1;
}

.service-v5 .portfolio__service-item {
  margin-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-v5.pt-140 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-v5.pt-140 {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .service-v5.pt-140 {
    padding-top: 120px;
    padding-bottom: 40px;
  }
}

.service-v5.portfolio__service .sec-title {
  text-indent: 0;
}

.service__list-6 li a.active {
  color: red;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.service__area-6 .mid-content .service__image:nth-child(1) {
  z-index: 9;
}

.service__image:nth-child(2) {
  z-index: 8;
}

.service__image:nth-child(3) {
  z-index: 7;
}

.service__image:nth-child(4) {
  z-index: 6;
}

.service__image:nth-child(5) {
  z-index: 5;
}

.service__image:nth-child(6) {
  z-index: 4;
}

.service__image:nth-child(7) {
  z-index: 3;
}

.service__image:nth-child(8) {
  z-index: 2;
}

.service__image:nth-child(9) {
  z-index: 1;
}

.service__hero-2 {
  background: var(--pink-3);
  padding-top: 116px;
  /* padding-bottom: 100px; */
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-2 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-2 {
    padding-bottom: 90px;
    padding-top: 59px;
  }
}

@media (max-width: 720px) {
  .service__hero-2 {
    padding-top: 59px;
  }
}

.service__hero-2 .announcements {
  margin-top: 1.3rem;
  padding: 0.5rem 0;
  background: var(--dark-theme2);
}

/* .service__hero-2 .video-container { */
/* width: 99vw; */
/* height: 120vh; */
/* transform: translate(-50%, -50%); */

.service__hero-2 .video-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;
  pointer-events: none;
}

.service__hero-2 .video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  height: 100%;
}

/* @media (max-width: 1368px) {
  .service__hero-2 .video-container {
    width: 100vw;
    height: 84.5vh;
  }
}

@media (max-width: 1280px) {
  .service__hero-2 .video-container {
    height: 90vh;
  }
}

@media (max-width: 1180px) {
  .service__hero-2 .video-container {
    height: 81vh;
  }
}

@media (max-width: 1024px) {
  .service__hero-2 .video-container {
    height: 75vh;
  }
} */

/* .service__hero-2 .announcements marquee {
  animation: infinite;
} */

.service__hero-2 .announcements p {
  margin-left: 2rem;
  display: inline;
  color: var(--white);
}

.service__hero-2 .shape-1 {
  position: absolute;
  width: 132px;
  height: auto;
  right: 165px;
  top: 275px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__hero-2 .shape-1 {
    right: 100px;
    top: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-2 .shape-1 {
    right: 30px;
    top: 200px;
    width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-2 .shape-1 {
    max-width: 80px;
    right: 10px;
    top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .service__hero-2 .shape-1 {
    width: 75px;
    right: 6px;
    top: 130px;
  }
}

#text {
  position: absolute;
  top: 24%;
  left: 50%;
  /* height: 62%; */
  width: 100%;
  padding: 120px 0 100px 0;
  transform: translate(-50%, -50%);
  /* text-align: center; */
  z-index: 2;
}

@media (max-width: 1368px) {
  #text {
    top: 25%;
  }
}

@media (max-width: 1280px) {
  #text {
    top: 26%;
  }
}

@media (max-width: 1024px) {
  #text {
    top: 29%;
  }
}

@media (max-width: 912px) {
  #text {
    top: 26%;
  }
}

@media (max-width: 820px) {
  #text {
    top: 28%;
  }
}

@media (max-width: 768px) {
  #text {
    top: 30%;
  }
}

@media (max-width: 720px) {
  #text {
    top: 36%;
  }
}

@media (max-width: 540px) {
  #text {
    top: 40%;
  }
}

@media (max-width: 415px) {
  #text {
    top: 42%;
  }
}

@media (max-width: 395px) {
  #text {
    top: 43%;
  }
}

@media (max-width: 380px) {
  #text {
    top: 44%;
  }
}

@media (max-width: 370px) {
  #text {
    top: 45%;
  }
}

@media (max-width: 280px) {
  #text {
    top: 52%;
  }
}

#text img {
  width: 12rem;
}

@media (max-width: 912px) {
  #text img {
    width: 10rem;
  }
}

@media (max-width: 768px) {
  #text img {
    width: 9rem;
  }
}

@media (max-width: 720px) {
  #text img {
    width: 7rem;
  }
}

@media (max-width: 415px) {
  #text img {
    width: 5rem;
  }
}

.service__hero-inner-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 3fr;
  grid-template-columns: 1fr 3fr;
}

@media only screen and (max-width: 767px) {
  .service__hero-inner-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.service__hero-left-2 {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .service__hero-left-2 {
    -ms-grid-row: 2;
    grid-row: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 15px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 30px;
  }
}

.service__hero-left-2 img {
  margin-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-left-2 img {
    max-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-left-2 img {
    max-width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .service__hero-left-2 img {
    max-width: 160px;
    margin: 0;
  }
}

.service__hero-left-2 .image-1,
.service__hero-left-2 .image-3 {
  -webkit-transform: translateX(-100px);
  transform: translateX(-100px);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__hero-left-2 .image-1,
  .service__hero-left-2 .image-3 {
    -webkit-transform: translateX(-40px);
    transform: translateX(-40px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-left-2 .image-1,
  .service__hero-left-2 .image-3 {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-left-2 .image-1,
  .service__hero-left-2 .image-3 {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }
}

@media only screen and (max-width: 767px) {
  .service__hero-left-2 .image-1,
  .service__hero-left-2 .image-3 {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.service__hero-left-2 .image-2 {
  -webkit-transform: translateX(62px);
  transform: translateX(62px);
}

@media only screen and (max-width: 767px) {
  .service__hero-left-2 .image-2 {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.service__hero-left-2 .image-4 {
  position: absolute;
  left: 318px;
  bottom: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-left-2 .image-4 {
    left: 278px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-left-2 .image-4 {
    left: 225px;
    bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .service__hero-left-2 .image-4 {
    position: unset;
  }
}

@media only screen and (max-width: 767px) {
  .service__hero-right-2 {
    -ms-grid-row: 1;
    grid-row: 1;
  }
}

.service__hero-right-2 .scroll {
  margin-left: 350px;
  margin-top: 95px;
  -webkit-animation: wcSlideBottom 5s infinite linear;
  animation: wcSlideBottom 5s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__hero-right-2 .scroll {
    margin-left: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__hero-right-2 .scroll {
    margin-left: 220px;
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .service__hero-right-2 .scroll {
    margin-left: 65px;
    margin-top: 40px;
  }
}

.counter__area {
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__area .pt-150 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__area-3 {
    background-color: var(--pink-4);
    padding: 100px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__area-3 {
    background-color: var(--pink-4);
    padding: 90px 0;
  }
}

@media only screen and (max-width: 767px) {
  .counter__area-3 {
    background-color: var(--pink-4);
    padding: 60px 0;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .counter__area-3 .sec-title-wrapper {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__area-3 .sec-title-wrapper {
    padding-top: 120px;
  }
}

.counter__area-3 .sec-sub-title {
  font-weight: 500;
  color: var(--black);
  text-transform: uppercase;
  padding-bottom: 150px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .counter__area-3 .sec-sub-title {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__area-3 .sec-sub-title {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__area-3 .sec-sub-title {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__area-3 .sec-sub-title {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .counter__area-3 .sec-sub-title {
    display: none;
  }
}

.counter__area-3 .counter__number {
  color: var(--black-2);
  font-weight: 400;
  padding-bottom: 10px;
}

.counter__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .counter__wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 30px 10px;
  }
}

.counter__wrapper-2 {
  display: -ms-grid;
  display: grid;
  /* -ms-grid-columns: (1fr) [4]; */
  grid-template-columns: repeat(4, 1fr);
}

@media only screen and (max-width: 767px) {
  .counter__wrapper-2 {
    -ms-grid-columns: 1fr 50px 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }
}

.counter__wrapper-3 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .counter__wrapper-3 {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 50px 40px;
  }
}

.counter__item {
  width: 380px;
  height: 380px;
  border: 1px solid var(--white-2);
  border-radius: 100%;
  margin-left: -67px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__item {
    margin-left: -60px;
    width: 330px;
    height: 330px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__item {
    margin-left: -25px;
    width: 259px;
    height: 259px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__item {
    margin-left: -27px;
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .counter__item {
    margin-left: 0;
    width: 160px;
    height: 160px;
  }
}

.counter__item:first-child {
  margin-left: 0;
}

.counter__item p {
  font-size: 1.25rem;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__item p {
    font-size: 1.125rem;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 767px) {
  .counter__item p {
    font-size: 1rem;
    line-height: 1.3;
    padding: 0 7px;
  }
}

.counter__item-2 {
  text-align: center;
}

.counter__item-2 p {
  font-size: 1.25rem;
  line-height: 1.3;
}

.counter__item-3 {
  padding-bottom: 80px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .counter__item-3 {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__item-3 {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__item-3 {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .counter__item-3 {
    padding-bottom: 0;
    text-align: center;
  }
}

.counter__item-3 p {
  font-size: 1.25rem;
}

@media only screen and (max-width: 767px) {
  .counter__item-3 p {
    line-height: 1.2;
  }
}

.counter__number {
  font-weight: 300;
  font-size: 100px;
  line-height: 1.1;
  color: var(--secondary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__number {
    font-size: 72px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__number {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .counter__number {
    font-size: 40px;
  }
}

.counter__img-3 {
  margin-top: 80px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .counter__img-3 {
    margin-top: 0;
    text-align: right;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__img-3 {
    margin-top: 0;
    text-align: right;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter__img-3 {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__img-3 {
    margin-top: 0;
  }
}

.counter__img-3 img {
  width: 100%;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .counter__img-3 img {
    width: 80%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__img-3 img {
    width: 75%;
  }
}

.workflow__area {
  overflow: hidden;
}

.workflow__area .sec-title-wrapper {
  text-align: center;
  padding-bottom: 60px;
}

.workflow__area-3 {
  overflow: hidden;
  background-color: var(--pink-4);
  /* padding-top: 60px; */
  direction: ltr;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workflow__area-3 {
    padding-top: 90px;
    background-color: var(--pink-3);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workflow__area-3 {
    padding-top: 80px;
    background-color: var(--pink-3);
  }
}

@media only screen and (max-width: 767px) {
  .workflow__area-3 {
    padding-top: 60px;
    background-color: var(--pink-3);
  }
}

.workflow__area-3 .wf_panel {
  width: 100%;
  height: 100%;
  will-change: transform;
  opacity: 1 !important;
}

.workflow__area-6 {
  overflow: hidden;
  padding-top: 5px;
}

@media only screen and (max-width: 767px) {
  .workflow__area-6 .pb-130 {
    padding-bottom: 20px;
  }
}

.workflow__wrapper-3 {
  width: 400%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: no-wrap;
  flex-wrap: no-wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workflow__wrapper-3 {
    width: 100%;
    height: auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workflow__wrapper-3 {
    width: 100%;
    height: auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .workflow__wrapper-3 {
    width: 100%;
    height: auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.workflow__wrapper-6 {
  border-top: 1px solid var(--white-4);
  padding-top: 50px;
}

@media only screen and (max-width: 767px) {
  .workflow__wrapper-6 {
    border-top: none;
    padding-top: 0;
  }
}

.workflow__slide {
  text-align: center;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workflow__slide {
    padding: 0 10px;
  }
}

.workflow__slide::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  top: 58px;
  background-color: var(--black-2);
}

.workflow__slide::after {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  left: calc(50% - 9px);
  top: 50px;
  background-color: var(--primary);
  border: 3px solid var(--white);
  border-radius: 100%;
  -webkit-animation: wcBubble 1.5s 1s infinite;
  animation: wcBubble 1.5s 1s infinite;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.workflow__slide p {
  max-width: 230px;
  margin: 0 auto;
}

.workflow__slide-6 {
  max-width: 270px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .workflow__slide-6 {
    max-width: 100%;
    padding-bottom: 40px;
  }
}

.workflow__slide-6::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  left: 0;
  top: -54px;
  background-color: var(--black);
  border: 1px solid var(--white);
  border-radius: 100%;
  -webkit-animation: wcBubble 1.5s 1s infinite;
  animation: wcBubble 1.5s 1s infinite;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .workflow__slide-6::before {
    width: 0;
    height: 0;
  }
}

.workflow__step {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.3;
  color: var(--black-2);
  text-transform: capitalize;
  padding-bottom: 50px;
}

.workflow__number {
  font-size: 85px;
  font-weight: 500;
  color: var(--gray-4);
}

.workflow__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.2;
  color: var(--black-2);
  padding-bottom: 15px;
}

.workflow__title-6 {
  font-weight: 500;
  font-size: 22px;
  line-height: 1.25;
  color: var(--black);
  padding-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workflow__title-6 {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  .workflow__title-6 {
    padding-bottom: 10px;
  }
}

.choose-title {
  font-weight: 500;
  font-size: 200px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--black);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .choose-title {
    font-size: 160px;
    margin-top: -100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose-title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .choose-title {
    font-size: 40px;
  }
}

.choose-title-wrapper {
  text-align: center;
}

.choose-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.research__area {
  background-color: var(--pink-3);
  background-image: url(../imgs/shape/8.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: 200px calc(100% - 150px);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .research__area {
    background-position: 20px calc(100% - 230px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__area {
    background-position: 20px calc(100% - 220px);
  }

  .research__area.pt-150 {
    padding-top: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__area {
    background-position: 20px calc(100% - 100px);
    padding-bottom: 50px;
  }

  .research__area.pt-150 {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area {
    padding-bottom: 40px;
    background-position: 0 210px;
    background-size: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .research__area {
    background-size: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area.pt-150 {
    padding-top: 60px;
  }
}

.research__area .sec-title {
  text-transform: uppercase;
  padding-bottom: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__area .sec-title {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__area .sec-title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area .sec-title {
    padding-bottom: 30px;
  }
}

.research__area .sec-title-wrapper {
  padding-right: 80px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__area .sec-title-wrapper {
    padding-right: 40px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__area .sec-title-wrapper {
    padding-bottom: 40px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area .sec-title-wrapper {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .research__area .sec-title-wrapper {
    padding-right: 0;
    padding-bottom: 40px;
    text-align: center;
  }
}

.research__area .sec-title-wrapper p {
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-2);
  margin-left: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__area .sec-title-wrapper p {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area .sec-title-wrapper p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .research__area .sec-title-wrapper p {
    margin: 0 auto;
  }
}

.research__area .sec-sub-title {
  font-weight: 500;
  text-transform: uppercase;
  color: var(--black);
  padding-bottom: 140px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .research__area .sec-sub-title {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__area .sec-sub-title {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__area .sec-sub-title {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__area .sec-sub-title {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .research__area .sec-sub-title {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .research__list {
    padding-bottom: 35px;
  }
}

.research__tools {
  padding-left: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__tools {
    padding-left: 0;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .research__tools {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .research__tools {
    padding-left: 0;
    padding-bottom: 40px;
    text-align: center;
  }
}

.research__tools li {
  display: inline-block;
}

.research__tools li a {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 30px;
  padding: 2px 15px;
}

.research__tools li a:hover {
  color: var(--white);
  background-color: var(--black-2);
}

.research__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
  padding-bottom: 50px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .research__item {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__item {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .research__item {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.research__item:nth-child(odd) {
  padding-left: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__item:nth-child(odd) {
    padding-left: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__item:nth-child(odd) {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .research__item:nth-child(odd) {
    padding-left: 0;
  }
}

.research__item p {
  color: var(--black-2);
}

.research__number span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 400;
  font-size: 48px;
  line-height: 1.1;
  text-transform: uppercase;
  color: var(--black-2);
  width: 160px;
  height: 160px;
  border: 3px solid;
  border-radius: 100%;
}

.research__number span .light-img {
  display: none;
}

.dark .research__number span .light-img {
  display: block;
}

.dark .research__number span .dark-img {
  display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__number span {
    width: 134px;
    height: 134px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__number span {
    width: 134px;
    height: 134px;
  }
}

@media only screen and (max-width: 767px) {
  .research__number span {
    width: 100px;
    height: 100px;
    font-size: 36px;
  }
}

.research__number span img {
  width: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .research__number span img {
    width: 66px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__number span img {
    width: 66px;
  }
}

@media only screen and (max-width: 767px) {
  .research__number span img {
    width: 50px;
  }
}

.research__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--black-2);
  padding-bottom: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .research__title {
    padding-bottom: 5px;
  }
}

.brand__area {
  overflow: hidden;
}

.brand__area .pb-100 {
  padding-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__area .pb-100 {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .brand__area .pb-100 {
    padding-bottom: 0;
  }
}

.brand__area .sec-title-wrapper {
  padding-bottom: 60px;
}

.brand__list,
.brand__list-3,
.brand__list-2 {
  /* display: -ms-grid;
  display: grid; */
  /* -ms-grid-columns: (1fr)[5];
  grid-template-columns: repeat(5, 1fr); */
  background-color: var(--white);
  border-bottom: 1px solid var(--white-3);
  position: relative;
  z-index: 1;
  margin-left: 1px;
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {

  .brand__list,
  .brand__list-3,
  .brand__list-2 {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
} */

/* @media only screen and (min-width: 768px) and (max-width: 991px) {

  .brand__list,
  .brand__list-3,
  .brand__list-2 {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
} */

@media only screen and (max-width: 767px) {
  .brand__list,
  .brand__list-3,
  .brand__list-2 {
    /* -ms-grid-columns: (2fr)[2];
    grid-template-columns: repeat(2, 2fr); */
    margin-left: 0;
  }
}

.brand__list-2 {
  gap: 40px;
  background-color: transparent;
  border-bottom: none;
  margin-left: 0;
}

.brand__list-3 {
  background-color: transparent;
  border-bottom: none;
  margin-left: 0;
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {
  .brand__list-3 {
    gap: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__list-3 {
    gap: 50px;
  }
} */

/* @media only screen and (max-width: 767px) {
  .brand__list-3 {
    gap: 50px;
  }
} */

.brand__item {
  padding: 40px;
  text-align: center;
  border-top: 1px solid var(--white-3);
  border-right: 1px solid var(--white-3);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .brand__item {
    padding: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__item {
    padding: 30px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .brand__item {
    padding: 30px 0;
    border-left: 1px solid var(--white-3);
  }

  .brand__item:nth-child(even) {
    border-left: none;
  }
}

.brand__item-2 {
  text-align: center;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__item-2 {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .brand__item-2 {
    padding-bottom: 20px;
  }
}

.brand__title-2 {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 50px;
  text-align: center;
}

.brand__title-3 {
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-2);
  text-align: center;
  padding-bottom: 65px;
  font-weight: 400;
  text-transform: uppercase;
}

.brand-gap {
  gap: 50px 0;
  border: none;
  background-color: transparent;
}

.testimonial__area {
  overflow: hidden;
}

.testimonial__area .sec-title-wrapper {
  max-width: 330px;
  padding-top: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__area .sec-title-wrapper {
    width: 100%;
    padding-top: 60px;
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__area .sec-title-wrapper {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__area .sec-title-wrapper {
    padding-top: 0px;
  }
}

.testimonial__area p {
  padding-top: 30px;
  font-size: 1.125rem;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__area p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__area p {
    padding-bottom: 20px;
  }
}

.testimonial__area-2 .line-3 {
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .testimonial__area-2 .g-0 {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.testimonial__area-3 {
  /* background-image: url(../../assets/imgs/testimonial/3/bg.png?v=0.1.8); */
  background-repeat: no-repeat;
  background-position: 0 150px;
  position: relative;
  padding-top: 410px;
  padding-bottom: 330px;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__area-3 {
    padding-top: 300px;
    padding-bottom: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__area-3 {
    padding-top: 300px;
    padding-bottom: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__area-3 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .testimonial__area-3 img {
    display: none;
  }
}

@media (max-width: 1023px) {
  .testimonial__area .cursor {
    display: none;
  }
}

.testimonial__slider-3 {
  padding-top: 120px;
  padding-bottom: 120px;
  max-width: 660px;
}

@media only screen and (max-width: 767px) {
  .testimonial__slider-3 {
    padding-top: 80px;
  }
}

.testimonial__slider-3 .swipper-btn {
  width: 50px;
  height: 50px;
  border: 1px solid;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.testimonial__slider-3 .swipper-btn:hover {
  background-color: var(--black-2);
}

.testimonial__slider-3 .swipper-btn:hover i {
  color: var(--white);
}

.testimonial__slider-3 .swipper-btn i {
  font-size: 1.125rem;
  color: var(--black-3);
}

.testimonial__slider-3 .next-button {
  right: calc(50% + 10px);
}

.testimonial__slider-3 .prev-button {
  left: calc(50% + 10px);
}

.testimonial__slide {
  margin-left: -3px;
}

.testimonial__slide-3 {
  text-align: center;
}

.testimonial__slide-3 p {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--black-2);
  font-style: italic;
  max-width: 630px;
  margin: 0 auto;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__slide-3 p {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__slide-3 p {
    font-size: 1.25rem;
    padding: 0 10px;
  }
}

.testimonial__slide-3 p::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -100px;
  left: calc(50% - 23px);
  background-image: url(../imgs/icon/quote.png?v=0.1.8);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
  .testimonial__slide-3 p::before {
    top: -80px;
  }
}

.testimonial__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.testimonial__wrapper-2 {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .testimonial__item {
    padding-bottom: 30px;
  }
}

.testimonial__item .button {
  display: inline-block;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .testimonial__item.item-1 {
    text-align: center;
    margin: 0 50px;
  }

  .testimonial__item.item-1 img {
    width: 100%;
  }
}

.testimonial__item.item-2 {
  padding-top: 20px;
  text-align: center;
}

.testimonial__item.item-3 {
  padding-top: 220px;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .testimonial__item.item-3 {
    text-align: center;
    padding-top: 30px;
  }
}

.testimonial__img {
  position: relative;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__img img {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__img img {
    width: 100%;
  }
}

.testimonial__img.b-right::after {
  position: absolute;
  content: "";
  width: 100%;
  height: calc(100% - 30px);
  left: 30px;
  top: 0;
  border: 1px solid var(--white-6);
  border-radius: 180px 180px 0 0;
  z-index: -1;
}

.testimonial__img.b-left::before {
  position: absolute;
  content: "";
  width: 100%;
  height: calc(100% - 30px);
  left: -30px;
  top: 0;
  border: 1px solid var(--white-6);
  border-radius: 180px 180px 0 0;
  z-index: -1;
}

.testimonial__title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3;
  color: var(--black);
  text-transform: capitalize;
}

.testimonial__title-2 {
  font-weight: 500;
  font-size: 60px;
  line-height: 1;
  color: var(--black);
  font-style: italic;
  padding: 100px 100px 20px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial__title-2 {
    font-size: 48px;
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__title-2 {
    font-size: 48px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__title-2 {
    font-size: 36px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__title-2 {
    font-size: 36px;
    padding: 20px 0;
  }
}

.testimonial__role {
  font-size: 14px;
  color: var(--black-3);
  line-height: 1.3;
  font-weight: 400;
  text-transform: capitalize;
}

.testimonial__role-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: var(--black-3);
}

.testimonial__text-2 {
  font-weight: 300;
  font-size: 22px;
  line-height: 1.4;
  color: var(--black-3);
  padding-right: 40px;
  padding-bottom: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__text-2 {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__text-2 {
    padding-right: 0;
    padding-bottom: 30px;
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__text-2 {
    font-size: 1.125rem;
    padding-right: 0;
    padding-bottom: 30px;
  }
}

.testimonial__inner-2 {
  background-color: var(--white);
  padding: 80px;
  margin-top: 140px;
  /* background-image: url(../imgs/icon/quote-primary.png?v=0.1.8); */
  background-repeat: no-repeat;
  background-position: 80px 80px;
  margin-right: 2px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial__inner-2 {
    padding: 80px 30px;
    margin-top: 185px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__inner-2 {
    padding: 60px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__inner-2 {
    padding: 60px 30px;
    margin-top: 136px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__inner-2 {
    text-align: center;
    padding: 0;
    padding-top: 60px;
    margin-top: 30px;
    background-position: 50% 0;
  }
}

.testimonial__video {
  margin-right: -112px;
  max-height: 785px;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__video {
    max-height: 740px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__video {
    max-height: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__video {
    margin-right: 0;
    max-height: unset;
  }
}

.testimonial__video video {
  width: 100%;
}

.testimonial__author {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3;
  color: var(--black-2);
  padding-bottom: 5px;
}

.testimonial__pagination {
  position: absolute;
  right: 0;
  top: calc(50% - 105px);
  -webkit-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .testimonial__pagination {
    -webkit-transform: unset;
    transform: unset;
    z-index: 9;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial__pagination {
    -webkit-transform: unset;
    transform: unset;
    top: unset;
    bottom: 110px;
    z-index: 9;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__pagination {
    -webkit-transform: unset;
    transform: unset;
    top: unset;
    bottom: 60px;
    z-index: 9;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__pagination {
    -webkit-transform: unset;
    transform: unset;
    top: unset;
    bottom: 60px;
    z-index: 9;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__pagination {
    display: none;
  }
}

.testimonial__pagination .next-button,
.testimonial__pagination .prev-button {
  width: 60px;
  height: 60px;
  border: 1px solid var(--gray-7);
  color: var(--gray-7);
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 30px 35px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__pagination .next-button,
  .testimonial__pagination .prev-button {
    margin: 20px;
  }
}

.testimonial__pagination .next-button::after,
.testimonial__pagination .prev-button::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.testimonial__pagination .next-button:hover,
.testimonial__pagination .prev-button:hover {
  border-color: var(--black-2);
}

.testimonial__pagination .next-button:hover i,
.testimonial__pagination .prev-button:hover i {
  color: var(--black-2);
}

.testimonial3__img {
  position: absolute;
  top: 170px;
  left: 25%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial3__img {
    top: 100px;
    left: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial3__img {
    top: 60px;
    left: 10%;
  }
}

.testimonial3__img-2 {
  position: absolute;
  top: 230px;
  right: 28%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial3__img-2 {
    top: 180px;
    right: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial3__img-2 {
    top: 130px;
    right: 15%;
  }
}

.testimonial3__img-3 {
  position: absolute;
  top: 40%;
  left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial3__img-3 {
    top: 78%;
    left: 60px;
    z-index: 9;
  }
}

.testimonial3__img-4 {
  position: absolute;
  bottom: 150px;
  left: 170px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .testimonial3__img-4 {
    left: 80px;
    max-width: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial3__img-4 {
    left: 70px;
    max-width: 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial3__img-4 {
    bottom: 70px;
    left: 70px;
    max-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial3__img-4 {
    bottom: 180px;
    left: 0;
    max-width: 160px;
  }
}

.testimonial3__img-5 {
  position: absolute;
  top: 43%;
  right: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial3__img-5 {
    right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial3__img-5 {
    top: 62%;
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial3__img-5 {
    top: 60%;
    right: 0;
    width: 150px;
  }
}

.testimonial3__img-6 {
  position: absolute;
  right: 280px;
  top: 58%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial3__img-6 {
    right: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial3__img-6 {
    top: 75%;
    right: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial3__img-6 {
    top: 70%;
    right: 60px;
  }
}

.client__name-3 {
  font-weight: 500;
  font-size: 28px;
  line-height: 1.3;
  text-transform: uppercase;
  color: var(--black-2);
  padding-top: 50px;
  padding-bottom: 10px;
}

.client__role-3 {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black-3);
}

.portfolio__text {
  text-align: center;
  font-size: 150px;
  font-weight: 500;
  color: var(--black);
  text-transform: uppercase;
  padding: 120px 0 50px;
  opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__text {
    font-size: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__text {
    font-size: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__text {
    font-size: 45px;
    padding: 50px 0;
  }
}

.dark .portfolio__text {
  color: var(--pink);
}

.portfolio__area {
  position: relative;
  background-color: var(--pink);
  overflow: hidden;
}

.portfolio__area-2 {
  overflow: hidden;
  position: relative;
}

.portfolio__area-2::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: 84.6%;
  top: 0;
  background: var(--white-4);
}

@media only screen and (max-width: 767px) {
  .portfolio__area-2 {
    padding-bottom: 15px;
  }
}

.portfolio__area-2::after {
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .portfolio__area-2::after {
    z-index: unset;
  }
}

.portfolio__area-2 .swiper-slide-active .sec-title {
  -webkit-animation: wcfadeUp 1s 0.5s forwards;
  animation: wcfadeUp 1s 0.5s forwards;
}

.portfolio__area-2 .swiper-slide-active p {
  -webkit-animation: wcfadeUp 1s 0.7s forwards;
  animation: wcfadeUp 1s 0.7s forwards;
}

.portfolio__area-2 .swiper-slide-active .btn-common-wrap {
  -webkit-animation: wcfadeUp 1s 0.9s forwards;
  animation: wcfadeUp 1s 0.9s forwards;
}

.portfolio__area-2 .sec-title-wrapper {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .portfolio__area-2 .sec-title-wrapper {
    text-align: left;
  }
}

.portfolio__area-2 .sec-title-wrapper .sec-text {
  font-size: 1.5rem;
  max-width: 410px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .portfolio__area-2 .sec-title-wrapper .sec-text {
    font-size: 1.25rem;
  }
}

.portfolio__area-2 .sec-title-3 {
  font-weight: 400;
  background-image: url(../imgs/shape/1.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: 70% 25px;
  display: inline-block;
  padding-bottom: 120px;
  padding-right: 320px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-2 .sec-title-3 {
    background-size: 260px;
    background-position: 70% 20px;
    padding-right: 200px;
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-2 .sec-title-3 {
    background-size: 200px;
    background-position: 95% 0;
    padding-right: 100px;
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-2 .sec-title-3 {
    background-size: 150px;
    background-position: 60% 0;
    padding-left: 0;
    padding-bottom: 45px;
    padding-right: 0px;
  }
}

.portfolio__area-2 .sec-title-3 span {
  display: block;
  padding-left: 450px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-2 .sec-title-3 span {
    padding-right: 0;
    padding-left: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-2 .sec-title-3 span {
    padding-right: 0;
    padding-left: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-2 .sec-title-3 span {
    padding-right: 0;
    padding-left: 85px;
  }
}

/* .portfolio__area-3 {
  background-color: var(--dark-theme2);
} */

.portfolio__area-3 .sec-sub-title {
  text-transform: uppercase;
  color: var(--white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-3 .sec-sub-title {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-3 .sec-sub-title {
    padding-bottom: 45px;
  }
}

.portfolio__area-3 .swiper-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
}

.portfolio__area-3 .swiper-btn div {
  font-size: 1.25rem;
  color: var(--white);
  border: 1px solid var(--white);
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 9;

  /* opacity: 0; */
  /* visibility: hidden; */
}

@media (max-width: 1365px) {
  .portfolio__area-3 .swiper-btn div {
    /* opacity: 1;
    visibility: visible; */
    top: calc(50% - 100px);
  }
}

@media (max-width: 1023px) {
  .portfolio__area-3 .swiper-btn div {
    /* opacity: 1;
    visibility: visible; */
    top: calc(50% - 25px);
  }
}

.portfolio__area-3 .swiper-btn div:hover {
  color: var(--black-2);
  background-color: var(--white);
}

.portfolio__area-3 .swiper-btn div.pp-prev {
  animation-name: horizontalShake;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  left: 26px;
}

.portfolio__area-3 .swiper-btn div.pp-next {
  animation-name: horizontalShakes;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  right: 2%;
}

/* @media (max-width: 1365px) {
  .portfolio__area-3 .swiper-btn div.pp-next {
    right: -24px;
  }
} */

.portfolio__area-3 .swiper-pagination {
  width: 120px;
  left: 370px;
  top: 0;
  bottom: unset;
  font-weight: 400;
  font-size: 60px;
  line-height: 1;
  color: var(--black);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__area-3 .swiper-pagination {
    left: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-3 .swiper-pagination {
    left: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-3 .swiper-pagination {
    left: -22px;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-3 .swiper-pagination {
    left: 0;
  }
}

.portfolio__area-3 .swiper-pagination-total {
  font-size: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: -50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-6 .line {
    padding-top: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-6 .line {
    padding-top: 0;
  }
}

.portfolio__area-6 .zi-9 {
  position: relative;
  z-index: 9;
}

.portfolio__area-6 .sec-title {
  font-size: 120px;
  padding-bottom: 20px;
}

.portfolio__area-6 .sec-title-wrapper {
  position: sticky;
  top: 130px;
}

.portfolio__area-6 .sec-title-wrapper p {
  font-size: 1.125rem;
  line-height: 1.4;
  padding-left: 110px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-6 .sec-title-wrapper p {
    padding-left: 60px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-6 .sec-title-wrapper p {
    padding-left: 60px;
  }
}

.portfolio__area-6 .sec-title-wrapper p::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 1px;
  background-color: var(--black-2);
  left: 0;
  top: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-6 .sec-title-wrapper p::before {
    width: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-6 .sec-title-wrapper p::before {
    width: 50px;
  }
}

.portfolio__area-6 .sec-sub-title {
  font-size: 1.5rem;
  padding-bottom: 0;
}

.portfolio__slider-2 {
  position: relative;
  height: 100vh;
}

.portfolio__slider-2::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: 50%;
  top: 0;
  background: var(--white-4);
}

.portfolio__slider-2::after {
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .portfolio__slider-2::after {
    z-index: unset;
  }
}

@media (max-width: 1400px) {
  .portfolio__slider-2 {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__slider-2-pagination {
    display: none;
  }
}

.portfolio__slider-2-pagination .swiper-pagination-bullets {
  left: calc(100% - 135px);
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullets {
    left: calc(100% - 60px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullets {
    left: calc(100% - 45px);
  }
}

.portfolio__slider-2-pagination .swiper-pagination-bullet {
  display: block;
  margin: 15px 10px !important;
  width: 50px;
  height: 50px;
  background: transparent;
  border: 1px solid #d6d6d6;
  opacity: 1;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullet {
    margin: 10px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullet {
    margin: 10px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullet {
    width: 30px;
    height: 30px;
    margin: 10px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__slider-2-pagination .swiper-pagination-bullet {
    width: 25px;
    height: 25px;
    margin: 5px !important;
  }
}

.portfolio__slider-2-pagination .swiper-pagination-bullet::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  left: 50%;
  top: 50%;
  border-radius: 50px;
  background-color: #d6d6d6;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.portfolio__slider-2-pagination .swiper-pagination-bullet-active,
.portfolio__slider-2-pagination .swiper-pagination-bullet:hover {
  border-color: var(--black-2);
}

.portfolio__slider-2-pagination .swiper-pagination-bullet-active::after,
.portfolio__slider-2-pagination .swiper-pagination-bullet:hover::after {
  background-color: var(--black-2);
}

.portfolio__slider-3 {
  position: relative;
}

/* .portfolio__slider-3:hover .swiper-btn div {
  opacity: 1;
  visibility: visible;
} */

.portfolio__slide-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--white);
}

@media only screen and (max-width: 767px) {
  .portfolio__slide-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.portfolio__slide-2 .sec-title {
  line-height: 1.1;
  padding-bottom: 30px;
  padding-left: 10px;
  opacity: 0;
}

.portfolio__slide-2 .sec-title a {
  color: var(--black-2);
  text-transform: capitalize;
}

.portfolio__slide-2 .sec-title span {
  display: block;
  padding-left: 60px;
  position: relative;
}

.portfolio__slide-2 .sec-title span::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 1px;
  left: -10px;
  top: 50%;
  background-color: var(--black-2);
}

.portfolio__slide-2 .slide-img {
  padding-right: 15px;
}

@media only screen and (max-width: 767px) {
  .portfolio__slide-2 .slide-img {
    padding-right: 0;
  }
}

.portfolio__slide-2 .slide-img img {
  width: 100%;
}

.portfolio__slide-2 .slide-content {
  padding-left: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__slide-2 .slide-content {
    padding-left: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__slide-2 .slide-content {
    padding-left: 0;
    padding-right: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__slide-2 .slide-content {
    padding: 30px 15px 50px;
  }
}

.portfolio__slide-2 p {
  max-width: 330px;
  font-size: 1.125rem;
  line-height: 1.4;
  padding-bottom: 50px;
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .portfolio__slide-2 p {
    padding-bottom: 30px;
  }
}

.portfolio__slide-2 .btn-common {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black-2);
}

.portfolio__slide-2 .btn-common:hover {
  color: var(--black-3);
}

.portfolio__slide-2 .btn-common i {
  padding-left: 5px;
}

.portfolio__slide-2 .btn-common-wrap {
  opacity: 0;
}

.portfolio__slide-3 {
  text-align: right;
  padding-right: 110px;
}

.portfolio__slide-3 a {
  display: block;
  color: var(--white);
}

.portfolio__slide-3 a:hover span {
  color: var(--white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__slide-3 {
    padding-right: 0;
  }

  .portfolio__slide-3 img {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__slide-3 {
    padding-right: 0;
  }

  .portfolio__slide-3 img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__slide-3 {
    padding-right: 0;
  }

  .portfolio__slide-3 img {
    width: 100%;
  }
}

.portfolio__wrapper-6 {
  padding-left: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__wrapper-6 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__wrapper-6 {
    padding-left: 0;
    padding-top: 180px;
  }
}

.portfolio__item {
  padding-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  scale: 0.5;
  opacity: 0;
}

.portfolio__item:nth-child(even) {
  top: 50%;
}

@media only screen and (max-width: 767px) {
  .portfolio__item:nth-child(even) {
    top: 0%;
  }
}

.portfolio__item:hover .portfolio__info {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.portfolio__item .img-box {
  height: 100%;
}

.portfolio__item img {
  width: 100%;
}

.portfolio__item-6 {
  position: relative;
  padding-bottom: 80px;
  overflow: hidden;
}

.portfolio__item-6 img {
  max-width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__item-6 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__item-6 {
    padding-bottom: 50px;
  }
}

.portfolio__item-6:hover .portfolio__content-6 {
  right: 0%;
  opacity: 1;
  visibility: visible;
}

.portfolio__info {
  width: 175px;
  padding: 15px;
  border-radius: 5px;
  background-color: var(--primary);
  position: absolute;
  right: 0;
  bottom: 50px;
  z-index: 1;
  -webkit-transform: translateX(60px);
  transform: translateX(60px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__info {
    opacity: 1;
    visibility: visible;
    right: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__info {
    opacity: 1;
    visibility: visible;
    right: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__info {
    opacity: 1;
    visibility: visible;
    right: 60px;
  }
}

.portfolio__info p {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: var(--black-2);
}

.portfolio__title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--black-2);
  padding-bottom: 3px;
}

.portfolio__title-3 {
  font-weight: 500;
  font-size: 180px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--white);
  text-align: left;
  padding-top: 80px;
  position: absolute;
  z-index: 5;
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__title-3 {
    font-size: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__title-3 {
    font-size: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__title-3 {
    font-size: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__title-3 {
    font-size: 60px;
    padding-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.portfolio__title-3 span {
  display: block;
  padding-left: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__title-3 span {
    padding-left: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__title-3 span {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__title-3 span {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__title-3 span {
    padding-left: 60px;
  }
}

.portfolio__title-6 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.2;
  color: var(--white);
  padding-bottom: 5px;
}

.portfolio__date {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: var(--white);
}

.portfolio__list-1 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 0px 30px;
  position: relative;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .portfolio__list-1 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 0px;
  }
}

.portfolio__list-6 {
  overflow-x: hidden;
  overflow: hidden;
}

.portfolio__pagination-6 {
  padding-top: 430px;
  padding-left: 110px;
  font-weight: 300;
  font-size: 19px;
  line-height: 1.1;
  color: var(--black-3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__pagination-6 {
    padding-top: 330px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__pagination-6 {
    padding-top: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__pagination-6 {
    padding-top: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__pagination-6 {
    padding-top: 180px;
    padding-left: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__pagination-6 {
    padding-top: 80px;
    padding-left: 0;
  }
}

.portfolio__current {
  font-weight: 300;
  font-size: 60px;
  line-height: 1.1;
  color: var(--black);
}

.portfolio__btn {
  margin-top: 40px;
}

.portfolio__btn#btn_wrapper,
.portfolio__btn.btn_wrapper {
  margin: 0 auto;
}

.portfolio__content-6 {
  position: absolute;
  top: 0%;
  right: 5%;
  background-color: var(--black-2);
  z-index: 9;
  padding: 20px 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1200px) {
  .portfolio__content-6 {
    opacity: 1;
    visibility: visible;
    right: 0;
  }
}

.portfolio__detail {
  overflow: hidden;
}

.portfolio__detail img {
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail-top .pt-110 {
    padding-top: 140px;
  }
}

.portfolio__detail-top .sec-title {
  font-size: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail-top .sec-title {
    font-size: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail-top .sec-title {
    font-size: 72px;
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__detail-top .sec-title {
    font-size: 60px;
    padding-bottom: 50px;
    padding-top: 90px;
  }
}

.portfolio__detail-info {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.portfolio__detail-info ul {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 80px auto;
  grid-template-columns: auto auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-gap: 25px 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail-info ul {
    -ms-grid-columns: 1fr 50px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__detail-info ul {
    grid-gap: 20px 50px;
  }
}

.portfolio__detail-info ul li {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: var(--gray-8);
  text-transform: capitalize;
}

.portfolio__detail-info ul li span,
.portfolio__detail-info ul li a {
  display: block;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black-3);
  text-transform: capitalize;
}

.portfolio__detail-thumb {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 70vh;
}

.portfolio__detail-thumb img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 130%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1920px) {
  .portfolio__detail-thumb {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail-thumb {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    position: unset;
  }

  .portfolio__detail-thumb img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .portfolio__detail-thumb img {
    position: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail-thumb {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    position: unset;
  }

  .portfolio__detail-thumb img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .portfolio__detail-thumb img {
    position: unset;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__detail-thumb {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    position: unset;
  }

  .portfolio__detail-thumb img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .portfolio__detail-thumb img {
    position: unset;
  }
}

.portfolio__detail-title {
  max-width: 350px;
  font-weight: 500;
  font-size: 36px;
  line-height: 1.1;
  color: var(--black-2);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__detail-title {
    max-width: 500px;
    padding-bottom: 30px;
  }
}

.portfolio__detail-text {
  padding-bottom: 60px;
}

.portfolio__detail-text p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-3);
  padding-bottom: 15px;
}

.portfolio__detail-text li {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-2);
  padding-bottom: 5px;
}

.portfolio__detail-text .fonts {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 70px 3.5fr;
  grid-template-columns: 2fr 3.5fr;
  gap: 70px;
  padding-top: 60px;
  padding-bottom: 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail-text .fonts {
    -ms-grid-columns: 1fr 30px 2fr;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail-text .fonts {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 30px;
    padding-top: 40px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__detail-text .fonts {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 30px;
    padding-top: 40px;
    padding-bottom: 0;
  }
}

.portfolio__detail-text .fonts img {
  width: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail-text .fonts img {
    width: 100%;
  }
}

.portfolio__detail-text .fonts ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.portfolio__detail-text .fonts ul li {
  font-weight: 500;
  text-transform: capitalize;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
}

.portfolio__detail-text .fonts ul .regular {
  font-weight: 400;
  color: var(--gray-9);
}

.portfolio__detail-text .fonts ul .medium {
  color: var(--gray-10);
}

.portfolio__detail-text .fonts ul .semibold {
  color: var(--black-3);
}

.portfolio__detail .block-thumb {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 65vh;
  z-index: 9;
  margin-bottom: 20px;
}

.portfolio__detail .block-thumb img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 120%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail .block-thumb {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
  }

  .portfolio__detail .block-thumb img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .portfolio__detail .block-thumb img {
    position: unset;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__detail .block-thumb {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
  }

  .portfolio__detail .block-thumb img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .portfolio__detail .block-thumb img {
    position: unset;
  }
}

.portfolio__detail .block-gallery {
  overflow: hidden;
}

.portfolio__detail .block-gallery img {
  width: 50%;
  float: left;
  padding-bottom: 20px;
}

.portfolio__detail .block-gallery img:nth-child(odd) {
  padding-right: 10px;
}

.portfolio__detail .block-gallery img:nth-child(even) {
  padding-left: 10px;
}

.portfolio__detail .block-img-text {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1.3fr;
  grid-template-columns: 1fr 1fr 1.3fr;
  grid-gap: 20px;
}

@media only screen and (max-width: 767px) {
  .portfolio__detail .block-img-text {
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
}

.portfolio__detail .block-img-text p {
  font-size: 1.125rem;
  line-height: 1.4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__detail .block-img-text p {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__detail .block-img-text p {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__detail .block-img-text p {
    grid-column: 1/-1;
    padding-left: 0;
  }
}

.portfolio__detail .block-content {
  position: relative;
  z-index: 1;
}

.portfolio__detail-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.portfolio__detail-btns a {
  margin: 0 -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v2.pt-150 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v2.pt-150 {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-v2.pt-150 {
    padding-top: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v3 .pt-100 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v3 .pt-100 {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-v3 .pt-100 {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v4 .pt-100 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v4 .pt-100 {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-v4 .pt-100 {
    padding-top: 130px;
  }
}

.portfolio-v4 .sec-title-2 {
  color: var(--white);
}

.portfolio-v4 .blog__text p {
  color: var(--gray-2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v5 .pt-100 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v5 .pt-100 {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-v5 .pt-100 {
    padding-top: 130px;
  }
}

.portfolio-v5 .pb-100 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio-v5 .pb-100 {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v5 .pb-100 {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v5 .pb-100 {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-v5 .pb-100 {
    padding-bottom: 0;
  }
}

.portfolio-v5.portfolio__project {
  padding: 30px 0 30px 0;
}

.portfolio__project img {
  /* border-radius: 2rem; */
  margin-bottom: 0.3rem;
}

.portfolio__project h3 {
  color: var(--white);
  margin-bottom: 2rem;
  font-size: 1.25rem;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-v5.portfolio__project .line {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-v5.portfolio__project .line {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-v5.portfolio__project .line {
    padding-top: 60px;
  }
}

.portfolio-v5.portfolio__project .pp-slider-wrapper,
.portfolio-v5.portfolio__project .pp-slide-thumb,
.portfolio-v5.portfolio__project .pp-next,
.portfolio-v5.portfolio__project .pp-prev {
  opacity: 1;
}

.swiper-slide-active .portfolio__title-3 {
  -webkit-animation: wcfadeUp 1s 0.7s forwards;
  animation: wcfadeUp 1s 0.7s forwards;
}

.bg_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.bg_image::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  content: "";
}

.bg_image img {
  width: 120%;
}

.row_bottom {
  margin-top: 80vh;
}

@media only screen and (max-width: 767px) {
  .row_bottom {
    margin-top: 30px;
  }
}

:root {
  --pink-6: #eee5dc;
  --purple: #d9dbeb;
  --purple-2: #cccede;
  --green: #81c3a3;
  --green-2: #dce4e0;
}

@keyframes wcSpinner {
  from {
    -webkit-transform: rotate(0turn);
    transform: rotate(0turn);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.portfolio__hero {
  position: relative;
  padding-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .portfolio__hero {
    padding-bottom: 40px;
  }
}

.portfolio__hero .title {
  font-weight: 600;
  font-size: 230px;
  line-height: 0.9;
  text-align: center;
  text-transform: uppercase;
  color: var(--black-2);
  opacity: 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__hero .title {
    font-size: 180px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__hero .title {
    font-size: 160px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__hero .title {
    font-size: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__hero .title {
    font-size: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__hero .title {
    font-size: 52px;
  }
}

.portfolio__hero .title.shape-circle {
  position: relative;
  opacity: 0;
}

.portfolio__hero .title.shape-circle::after {
  position: absolute;
  content: "";
  width: 62px;
  height: 62px;
  right: -50px;
  top: 75px;
  background-image: url(../imgs/shape/13.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: right;
  -webkit-animation: wcSpinner 3s infinite linear;
  animation: wcSpinner 3s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__hero .title.shape-circle::after {
    top: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__hero .title.shape-circle::after {
    right: -10px;
    top: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__hero .title.shape-circle::after {
    top: 12px;
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__hero .title.shape-circle::after {
    top: -60px;
    right: 0;
  }
}

.portfolio__hero .title.text-stroke {
  -webkit-text-stroke: 1px var(--black-2);
  color: transparent;
  margin: 0 -140px;
  opacity: 0;
}

.portfolio__hero .pp-thumb {
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 14%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__hero .pp-thumb {
    max-width: 780px;
    left: 17%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__hero .pp-thumb {
    max-width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__hero .pp-thumb {
    max-width: 500px;
    left: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__hero .pp-thumb {
    left: 0;
  }
}

.portfolio__hero .btn-wrapper {
  text-align: center;
  position: relative;
  z-index: 9;
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__hero .btn-wrapper {
    margin-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__hero .btn-wrapper {
    margin-top: 120px;
  }
}

.portfolio__hero-area {
  overflow: hidden;
  position: relative;
  padding-top: 200px;
  background-color: var(--pink-6);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__hero-area {
    padding-top: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__hero-area {
    padding-top: 160px;
  }
}

.portfolio__hero-area::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 100px;
  left: 80px;
  bottom: 50px;
  background-image: url(../imgs/shape/15.png?v=0.1.8);
  background-repeat: no-repeat;
  -webkit-animation: wcZoom 3s infinite linear;
  animation: wcZoom 3s infinite linear;
}

@media only screen and (max-width: 767px) {
  .portfolio__hero-area::after {
    left: 0;
    bottom: 45px;
  }
}

.portfolio__about {
  background-color: var(--purple);
  position: relative;
  overflow: hidden;
}

.portfolio__about::after {
  position: absolute;
  content: "ABOUT";
  width: 200px;
  height: 100%;
  right: 0px;
  top: -60px;
  font-weight: 700;
  font-size: 250px;
  line-height: 1;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.03);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__about::after {
    font-size: 230px;
    top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__about::after {
    font-size: 200px;
  }
}

.portfolio__about-left {
  position: relative;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .portfolio__about-left {
    padding-bottom: 30px;
  }
}

.portfolio__about-left img {
  position: absolute;
  right: 100px;
  bottom: 0;
  opacity: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__about-left img {
    right: 0;
    max-width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__about-left img {
    display: none;
  }
}

.portfolio__about .about-row {
  margin-top: 150px;
  border-top: 1px solid var(--purple-2);
  border-bottom: 1px solid var(--purple-2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__about .about-row {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__about .about-row {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__about .about-row {
    margin-top: 60px;
    border: none;
  }
}

.portfolio__about .sec-title {
  max-width: 715px;
  text-transform: uppercase;
  opacity: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__about .sec-title {
    padding-bottom: 40px;
  }
}

.portfolio__about .sec-title span {
  display: block;
  padding-left: 120px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__about .sec-title span {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__about .sec-title span {
    padding-left: 0;
  }
}

.portfolio__about .sec-title span::before {
  position: absolute;
  content: "";
  width: 90px;
  height: 1px;
  left: 0;
  top: 30px;
  background-color: var(--black-2);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__about .sec-title span::before {
    top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__about .sec-title span::before {
    width: 0;
  }
}

.portfolio__about .sec-text p {
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-2);
  padding-bottom: 70px;
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__about .sec-text p {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__about .sec-text p {
    padding-bottom: 50px;
  }
}

.portfolio__about .brand-title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black-2);
  max-width: 220px;
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .portfolio__about .brand-title {
    max-width: 400px;
  }
}

.portfolio__about .brand-title-wrap {
  padding: 40px 0;
  border-right: 1px solid var(--purple-2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__about .brand-title-wrap {
    padding: 25px 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__about .brand-title-wrap {
    border: none;
  }
}

.portfolio__about .brand-logo img {
  opacity: 0;
}

.portfolio__about .brand-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__about .brand-list {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__about .brand-list {
    padding-left: 0px;
    gap: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__about .brand-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    gap: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__about .brand-logo img {
    max-width: 80px;
  }
}

.portfolio__project {
  padding: 60px 0;
  position: relative;
  overflow: hidden;
  background-color: var(--white);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__project {
    padding-top: 120px;
  }
}

/* .portfolio__project::after {
  position: absolute;
  content: "facilities";
  width: 200px;
  height: 100%;
  right: 0px;
  top: -100px;
  font-weight: 700;
  font-size: 250px;
  line-height: 1;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.03);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__project::after {
    top: -60px;
    font-size: 230px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__project::after {
    font-size: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__project::after {
    font-size: 180px;
    top: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__project::after {
    font-size: 180px;
  }
} */

.portfolio__project .pp-title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black);
  opacity: 0;
}

.portfolio__project .pp-slider-wrapper {
  padding-left: 200px;
  padding-right: 100px;
  position: relative;
  opacity: 0;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__project .pp-slider-wrapper {
    padding-left: 160px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__project .pp-slider-wrapper {
    padding-left: 160px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__project .pp-slider-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__project .pp-slider-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.portfolio__project .pp-slide {
  overflow: hidden;
  padding: 80px 0 80px 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__project .pp-slide {
    padding: 80px 0 80px 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__project .pp-slide {
    padding: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__project .pp-slide {
    padding: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__project .pp-slide {
    padding: 60px;
  }
}

.portfolio__project .pp-slide-img {
  background-color: var(--dark-theme);
  max-width: 470px;
  max-height: 650px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__project .pp-slide-img {
    max-width: 430px;
    max-height: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__project .pp-slide-img {
    max-width: 330px;
    max-height: 450px;
  }
}

.portfolio__project .pp-slide-img img {
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
  max-width: 100%;
}

.portfolio__project .swipper-btn {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black-2);
  position: relative;
}

.portfolio__project .pp-next {
  position: absolute;
  top: calc(50% - 12px);
  right: 0;
  z-index: 9;
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .portfolio__project .pp-next {
    top: 95%;
  }
}

.portfolio__project .pp-next::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 1px;
  top: 13px;
  left: -55px;
  background-color: var(--black-2);
}

.portfolio__project .pp-prev {
  position: absolute;
  top: calc(50% - 12px);
  left: 60px;
  z-index: 9;
  opacity: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__project .pp-prev {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__project .pp-prev {
    top: 95%;
    left: 0;
  }
}

.portfolio__project .pp-prev::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 1px;
  top: 13px;
  left: 45px;
  background-color: var(--dark-theme);
}

.portfolio__project .pp-slide-title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.3;
  text-align: right;
  text-transform: uppercase;
  color: var(--black-2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__project .pp-slide-title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__project .pp-slide-title {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__project .pp-slide-title {
    font-size: 1.5rem;
  }
}

.portfolio__project .pp-slide-thumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 300px;
  margin-left: auto;
  opacity: 0;
}

.portfolio__project .pp-slide-thumb p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-align: right;
  text-transform: uppercase;
  color: var(--black-3);
  padding-top: 15px;
}

.portfolio__project-thumbs {
  height: 100%;
  margin-right: -150px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__project-thumbs {
    margin-right: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__project-thumbs {
    margin-right: -50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__project-thumbs {
    margin-right: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__project-thumbs {
    margin-right: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__project-thumbs {
    margin-right: 0;
    margin-top: 30px;
  }
}

.portfolio__service {
  background-color: var(--green-2);
  background-image: url(../imgs/shape/17.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: bottom right;
}

.portfolio__service .sec-title {
  max-width: 350px;
  text-indent: 80px;
  text-transform: uppercase;
  opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__service .sec-title {
    text-indent: 40px;
    max-width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__service .sec-title {
    text-indent: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__service .sec-title {
    text-indent: 20px;
  }
}

.portfolio__service .sec-text {
  background-image: url(../imgs/shape/14.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: 0 80px;
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__service .sec-text {
    background-position: 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__service .sec-text {
    background-size: 100%;
    background-position: 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__service .sec-text {
    background-position: 0 30px;
    background-size: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__service .sec-text {
    background-position: 0 30px;
    background-size: 100%;
  }
}

.portfolio__service .sec-text p {
  max-width: 320px;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-13);
  padding-top: 150px;
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__service .sec-text p {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__service .sec-text p {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__service .sec-text p {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__service .sec-text p {
    padding-top: 70px;
  }
}

/* .portfolio__service-list {
  padding-top: 62px;
} */

.portfolio__service-item {
  padding: 40px;
  border: 1.3px solid #cdbc91;
  border-radius: 21px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__service-item {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__service-item {
    margin-bottom: 20px;
  }
}

.portfolio__service-item::after {
  position: absolute;
  content: "";
  width: 23px;
  height: 76px;
  right: -2px;
  top: calc(50% - 38px);
  background: #cdbc91;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 15px 0px 0px 15px;
}

/* .portfolio__service-item:hover .ps-title,
.portfolio__service-item:hover ul li {
  color: var(--white);
} */

.portfolio__service-item:hover::after {
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  right: -1px;
  top: 0;
  border-radius: 15px;
}

.portfolio__service-item .ps-title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black-2);
  padding-bottom: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__service-item .ps-title {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

.portfolio__service-item ul {
  padding-left: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__service-item ul {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__service-item ul {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__service-item ul {
    padding-left: 20px;
  }
}

.portfolio__service-item ul li {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black-2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.portfolio__service .ps-btn {
  text-align: center;
}

.portfolio__service .ps-btn a {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-2);
  border: 1.3px solid var(--black-2);
  border-radius: 35px;
  display: inline-block;
  padding: 10px 40px;
  margin-top: 70px;
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__service .ps-btn a {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__service .ps-btn a {
    margin-top: 30px;
  }
}

.portfolio__service .ps-btn a strong {
  font-weight: 400;
  text-transform: capitalize;
}

.portfolio__footer {
  margin-top: 150px;
  border-top: 1px solid #e2e2e2;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__footer {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__footer {
    margin-top: 60px;
  }
}

.portfolio__footer-area {
  background-color: #f7f7f7;
  background-image: url(../imgs/shape/18.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: top right;
  position: relative;
}

.portfolio__footer-area::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 100px;
  left: 50px;
  bottom: 20px;
  background-image: url(../imgs/shape/15.png?v=0.1.8);
  background-repeat: no-repeat;
  -webkit-animation: wcZoom 3s infinite linear;
  animation: wcZoom 3s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__footer-area::after {
    bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__footer-area::after {
    bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__footer-area::after {
    left: 10px;
    bottom: 100px;
  }
}

.portfolio__footer-area::before {
  position: absolute;
  content: "";
  width: 156px;
  height: 156px;
  left: calc(50% - 78px);
  bottom: 2px;
  background-image: url(../imgs/shape/19.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: right;
  -webkit-animation: wcSpinner 3s infinite linear;
  animation: wcSpinner 3s infinite linear;
}

@media only screen and (max-width: 767px) {
  .portfolio__footer-area::before {
    bottom: 50px;
  }
}

.portfolio__footer-area .pf-btm {
  padding: 20px 0;
}

.portfolio__footer-area .pf-title {
  font-weight: 500;
  font-size: 100px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--black-2);
  padding-bottom: 70px;
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__footer-area .pf-title {
    font-size: 72px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__footer-area .pf-title {
    font-size: 72px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__footer-area .pf-title {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__footer-area .pf-title {
    font-size: 60px;
    padding-bottom: 0;
  }
}

.portfolio__footer-area .pf-contact {
  padding-bottom: 35px;
}

@media only screen and (max-width: 767px) {
  .portfolio__footer-area .pf-contact {
    padding-top: 30px;
  }
}

.portfolio__footer-area .pf-contact h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--black-2);
  padding-bottom: 25px;
  opacity: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__footer-area .pf-contact h3 {
    font-size: 22px;
  }
}

.portfolio__footer-area .pf-contact ul li {
  padding-bottom: 8px;
  opacity: 0;
}

.portfolio__footer-area .pf-contact ul li a {
  font-size: 1.125rem;
  line-height: 26px;
  text-decoration-line: underline;
  color: var(--black-2);
}

@media only screen and (max-width: 767px) {
  .portfolio__footer-area .pf-social {
    margin-bottom: 40px;
  }
}

.portfolio__footer-area .pf-social h3 {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black-2);
  position: relative;
  display: inline-block;
  padding-bottom: 30px;
  opacity: 0;
}

.portfolio__footer-area .pf-social h3::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 1px;
  top: 15px;
  right: -60px;
  background-color: var(--black-2);
}

.portfolio__footer-area .pf-social ul li {
  display: inline-block;
  opacity: 0;
}

.portfolio__footer-area .pf-social ul li a {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black-2);
  border: 1px solid var(--gray);
  padding: 8px 21px;
  border-radius: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__footer-area .pf-social ul li a {
    padding: 7px 18px;
    margin-bottom: 7px;
  }
}

.portfolio__footer-area .pf-social ul li a:hover {
  color: var(--white);
  background-color: var(--black-2);
}

.portfolio__footer-area .pc-btn {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .portfolio__footer .pf-btm .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__main-wrapper .pp-title-wrap {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

@media (max-width: 1300px) {
  .portfolio__main-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.portfolio-section {
  height: 100vh;
}

@media (max-width: 1300px) {
  .portfolio-section {
    height: auto;
  }
}

.portfolio__page .wc-btn-dark,
.portfolio__page .contact__text,
.portfolio__page .contact__form,
.portfolio__page .footer-menu,
.portfolio__page .footer-menu-2,
.portfolio__page .footer__copyright-2 p {
  opacity: 0;
}

.portfolio__page .swiper-slide-active .wc-btn-dark {
  -webkit-animation: wcfadeUp 1s 0.7s forwards;
  animation: wcfadeUp 1s 0.7s forwards;
}

.portfolio__page .swiper-slide-active .title {
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

.portfolio__page .swiper-slide-active .shape-circle {
  -webkit-animation: wcfadeUp 1s 0.2s forwards;
  animation: wcfadeUp 1s 0.2s forwards;
}

.portfolio__page .swiper-slide-active .text-stroke {
  -webkit-animation: wcfadeUp 1s 0.4s forwards;
  animation: wcfadeUp 1s 0.4s forwards;
}

.portfolio__page .swiper-slide-active .sec-title {
  -webkit-animation: wcfadeUp 1s 0.2s forwards;
  animation: wcfadeUp 1s 0.2s forwards;
}

.portfolio__page .swiper-slide-active .sec-text p {
  -webkit-animation: wcfadeUp 1s 0.4s forwards;
  animation: wcfadeUp 1s 0.4s forwards;
}

.portfolio__page .swiper-slide-active .portfolio__service .sec-text {
  -webkit-animation: wcfadeUp 1s 0.4s forwards;
  animation: wcfadeUp 1s 0.4s forwards;
}

.portfolio__page .swiper-slide-active .brand-title {
  -webkit-animation: wcfadeUp 1s 0.4s forwards;
  animation: wcfadeUp 1s 0.4s forwards;
}

.portfolio__page .swiper-slide-active .brand-logo img {
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

.portfolio__page .swiper-slide-active .pp-title {
  -webkit-animation: wcfadeUp 1s 0.2s forwards;
  animation: wcfadeUp 1s 0.2s forwards;
}

.portfolio__page .swiper-slide-active .pp-next {
  -webkit-animation: wcfadeUp 1s 0.4s forwards;
  animation: wcfadeUp 1s 0.4s forwards;
}

.portfolio__page .swiper-slide-active .pp-prev {
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

.portfolio__page .swiper-slide-active .portfolio__service-item {
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

.portfolio__page .swiper-slide-active .ps-btn a {
  -webkit-animation: wcfadeUp 1s 0.8s forwards;
  animation: wcfadeUp 1s 0.8s forwards;
}

.portfolio__page .swiper-slide-active .pf-title {
  -webkit-animation: wcfadeUp 1s 0.2s forwards;
  animation: wcfadeUp 1s 0.2s forwards;
}

.portfolio__page .swiper-slide-active .contact__text {
  -webkit-animation: wcfadeUp 1s 0.4s forwards;
  animation: wcfadeUp 1s 0.4s forwards;
}

.portfolio__page .swiper-slide-active .pf-contact h3 {
  -webkit-animation: wcfadeUp 1s 0.4s forwards;
  animation: wcfadeUp 1s 0.4s forwards;
}

.portfolio__page .swiper-slide-active .pf-contact ul li {
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

.portfolio__page .swiper-slide-active .pf-social h3 {
  -webkit-animation: wcfadeUp 1s 0.8s forwards;
  animation: wcfadeUp 1s 0.8s forwards;
}

.portfolio__page .swiper-slide-active .pf-social ul li {
  -webkit-animation: wcfadeUp 1s 1s forwards;
  animation: wcfadeUp 1s 1s forwards;
}

.portfolio__page .swiper-slide-active .contact__form {
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

.portfolio__page .swiper-slide-active .portfolio__about-left img {
  -webkit-animation: wcfadeUp 1s 0.4s forwards;
  animation: wcfadeUp 1s 0.4s forwards;
}

.portfolio__page .swiper-slide-active .portfolio__project .pp-slider-wrapper {
  -webkit-animation: wcfadeUp 1s 0.4s forwards;
  animation: wcfadeUp 1s 0.4s forwards;
}

.portfolio__page .swiper-slide-active .portfolio__project .pp-slide-thumb {
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

.portfolio__page .swiper-slide-active .footer__copyright-2 p {
  -webkit-animation: wcfadeUp 1s 1.2s forwards;
  animation: wcfadeUp 1s 1.2s forwards;
}

.portfolio__page .swiper-slide-active .footer-menu,
.portfolio__page .swiper-slide-active .footer-menu-2 {
  -webkit-animation: wcfadeUp 1s 1.3s forwards;
  animation: wcfadeUp 1s 1.3s forwards;
}

.portfolio__page .wc-btn-dark:hover {
  color: var(--white);
  background-color: var(--black-2);
  border-color: var(--black-2);
}

@media (max-width: 1300px) {
  .swiper-slide .wc-btn-dark {
    -webkit-animation: wcfadeUp 1s 0.7s forwards;
    animation: wcfadeUp 1s 0.7s forwards;
  }

  .swiper-slide .title {
    -webkit-animation: wcfadeUp 1s 0.6s forwards;
    animation: wcfadeUp 1s 0.6s forwards;
  }

  .swiper-slide .shape-circle {
    -webkit-animation: wcfadeUp 1s 0.2s forwards;
    animation: wcfadeUp 1s 0.2s forwards;
  }

  .swiper-slide .text-stroke {
    -webkit-animation: wcfadeUp 1s 0.4s forwards;
    animation: wcfadeUp 1s 0.4s forwards;
  }

  .swiper-slide .sec-title {
    -webkit-animation: wcfadeUp 1s 0.2s forwards;
    animation: wcfadeUp 1s 0.2s forwards;
  }

  .swiper-slide .sec-text p {
    -webkit-animation: wcfadeUp 1s 0.4s forwards;
    animation: wcfadeUp 1s 0.4s forwards;
  }

  .swiper-slide .portfolio__service .sec-text {
    -webkit-animation: wcfadeUp 1s 0.4s forwards;
    animation: wcfadeUp 1s 0.4s forwards;
  }

  .swiper-slide .brand-title {
    -webkit-animation: wcfadeUp 1s 0.4s forwards;
    animation: wcfadeUp 1s 0.4s forwards;
  }

  .swiper-slide .brand-logo img {
    -webkit-animation: wcfadeUp 1s 0.6s forwards;
    animation: wcfadeUp 1s 0.6s forwards;
  }

  .swiper-slide .pp-title {
    -webkit-animation: wcfadeUp 1s 0.2s forwards;
    animation: wcfadeUp 1s 0.2s forwards;
  }

  .swiper-slide .pp-next {
    -webkit-animation: wcfadeUp 1s 0.4s forwards;
    animation: wcfadeUp 1s 0.4s forwards;
  }

  .swiper-slide .pp-prev {
    -webkit-animation: wcfadeUp 1s 0.6s forwards;
    animation: wcfadeUp 1s 0.6s forwards;
  }

  .swiper-slide .portfolio__service-item {
    -webkit-animation: wcfadeUp 1s 0.6s forwards;
    animation: wcfadeUp 1s 0.6s forwards;
  }

  .swiper-slide .ps-btn a {
    -webkit-animation: wcfadeUp 1s 0.8s forwards;
    animation: wcfadeUp 1s 0.8s forwards;
  }

  .swiper-slide .pf-title {
    -webkit-animation: wcfadeUp 1s 0.2s forwards;
    animation: wcfadeUp 1s 0.2s forwards;
  }

  .swiper-slide .contact__text {
    -webkit-animation: wcfadeUp 1s 0.4s forwards;
    animation: wcfadeUp 1s 0.4s forwards;
  }

  .swiper-slide .pf-contact h3 {
    -webkit-animation: wcfadeUp 1s 0.4s forwards;
    animation: wcfadeUp 1s 0.4s forwards;
  }

  .swiper-slide .pf-contact ul li {
    -webkit-animation: wcfadeUp 1s 0.6s forwards;
    animation: wcfadeUp 1s 0.6s forwards;
  }

  .swiper-slide .pf-social h3 {
    -webkit-animation: wcfadeUp 1s 0.8s forwards;
    animation: wcfadeUp 1s 0.8s forwards;
  }

  .swiper-slide .pf-social ul li {
    -webkit-animation: wcfadeUp 1s 1s forwards;
    animation: wcfadeUp 1s 1s forwards;
  }

  .swiper-slide .contact__form {
    -webkit-animation: wcfadeUp 1s 0.6s forwards;
    animation: wcfadeUp 1s 0.6s forwards;
  }

  .swiper-slide .portfolio__about-left img {
    -webkit-animation: wcfadeUp 1s 0.4s forwards;
    animation: wcfadeUp 1s 0.4s forwards;
  }

  .swiper-slide .portfolio__project .pp-slider-wrapper {
    -webkit-animation: wcfadeUp 1s 0.4s forwards;
    animation: wcfadeUp 1s 0.4s forwards;
  }

  .swiper-slide .portfolio__project .pp-slide-thumb {
    -webkit-animation: wcfadeUp 1s 0.6s forwards;
    animation: wcfadeUp 1s 0.6s forwards;
  }

  .swiper-slide .footer__copyright-2 p {
    -webkit-animation: wcfadeUp 1s 1.2s forwards;
    animation: wcfadeUp 1s 1.2s forwards;
  }

  .swiper-slide .footer-menu,
  .swiper-slide .footer-menu-2 {
    -webkit-animation: wcfadeUp 1s 1.3s forwards;
    animation: wcfadeUp 1s 1.3s forwards;
  }
}

.menu-text-pp {
  color: var(--black-2);
}

.circle-pagination {
  width: 90px !important;
  bottom: unset !important;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .circle-pagination {
    display: none;
  }
}

.circle-pagination.left {
  left: 50px !important;
}

.circle-pagination.right {
  left: calc(100% - 135px) !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .circle-pagination.right {
    left: calc(100% - 90px) !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .circle-pagination.right {
    left: calc(100% - 80px) !important;
  }
}

.circle-pagination button.swiper-pagination-bullet {
  position: relative;
  width: auto;
  height: auto;
  margin: 10px 0 !important;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .circle-pagination button.swiper-pagination-bullet {
    margin: 5px 0 !important;
  }
}

.circle-pagination .swiper-pagination-bullet span {
  position: absolute;
  width: 6px;
  height: 6px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: var(--black-2);
  border-radius: 100%;
}

.circle-pagination .circle-progress {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 50px;
  height: 50px;
}

.circle-pagination .circle-origin {
  fill: transparent;
  stroke: var(--black-2);
  stroke-width: 1;
  stroke-linecap: round;
  stroke-dasharray: 156px;
  stroke-dashoffset: 156px;
}

.circle-pagination .swiper-pagination-bullet-active .circle-origin {
  -webkit-animation: progress 5s backwards;
  animation: progress 5s backwards;
}

@-webkit-keyframes progress {
  0% {
    stroke-dashoffset: 156px;
  }

  90% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -156px;
  }
}

@keyframes progress {
  0% {
    stroke-dashoffset: 156px;
  }

  90% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -156px;
  }
}

.circle-pagination-2 {
  width: 90px !important;
  top: 50%;
  bottom: unset !important;
  left: 30px !important;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .circle-pagination-2 {
    left: 0 !important;
    width: 63px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .circle-pagination-2 {
    left: 0 !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .circle-pagination-2 {
    left: 0 !important;
  }
}

.circle-pagination-2 .swiper-pagination-bullet {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 1px solid var(--black-2);
  position: relative;
  margin: 10px 0 !important;
}

.circle-pagination-2 .swiper-pagination-bullet::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background-color: var(--black-2);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

.blog__area {
  overflow: hidden;
}

.blog__area .row {
  --bs-gutter-x: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog__area .row {
    --bs-gutter-x: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area .row {
    --bs-gutter-x: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area .row {
    --bs-gutter-x: 20px;
  }
}

.blog__area .sec-title-wrapper {
  text-align: center;
  padding-bottom: 60px;
}

.blog__area-2 {
  overflow: hidden;
  background-color: var(--black-2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-2 .pt-150 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-2 .pb-110 {
    padding-bottom: 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-2 .pb-110 {
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area-2 .pb-110 {
    padding-bottom: 0;
  }
}

.blog__area-2 .row {
  --bs-gutter-x: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog__area-2 .row {
    --bs-gutter-x: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-2 .row {
    --bs-gutter-x: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-2 .row {
    --bs-gutter-x: 20px;
  }
}

.blog__area-2 #btn_wrapper,
.blog__area-2 .btn_wrapper {
  margin-left: auto;
  margin-right: -40px;
  margin-top: -60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-2 #btn_wrapper,
  .blog__area-2 .btn_wrapper {
    margin-right: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-2 #btn_wrapper,
  .blog__area-2 .btn_wrapper {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area-2 #btn_wrapper,
  .blog__area-2 .btn_wrapper {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.blog__area-2 .sec-title {
  color: var(--white);
  padding-top: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-2 .sec-title {
    padding-top: 45px;
  }
}

.blog__area-2 .sec-text {
  padding-top: 30px;
}

.blog__area-2 .sec-text p {
  max-width: 340px;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--gray-2);
}

.blog__area-2 .sec-btn {
  margin-bottom: 30px;
}

.blog__area-2 .sec-btn a {
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .blog__area-2 .sec-btn a {
    margin-left: 0;
  }
}

.blog__area-3 {
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-3.pb-140 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area-3.pb-140 {
    padding-bottom: 20px;
  }
}

.blog__area-3 .sec-title {
  padding-bottom: 40px;
  text-transform: uppercase;
  background-color: var(--white);
}

.blog__area-3 .sec-title-wrapper {
  position: relative;
  padding-left: 330px;
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-3 .sec-title-wrapper {
    padding-left: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area-3 .sec-title-wrapper {
    padding-left: 0;
  }
}

.blog__area-3 .sec-title-wrapper::after {
  position: absolute;
  content: "";
  left: 39%;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: var(--white-2);
}

.blog__area-3 .sec-title-wrapper p {
  padding-top: 70px;
  padding-bottom: 60px;
  max-width: 330px;
  font-size: 1.125rem;
  line-height: 1.4;
  margin-left: 220px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-3 .sec-title-wrapper p {
    margin-left: 100px;
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-3 .sec-title-wrapper p {
    margin-left: 100px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area-3 .sec-title-wrapper p {
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.blog__area-3 .sec-sub-title {
  padding-top: 50px;
  text-transform: uppercase;
  background-color: var(--white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-3 .sec-sub-title {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area-3 .sec-sub-title {
    padding-top: 0;
  }
}

.blog__area-6 {
  overflow: hidden;
}

.blog__area-6 .pb-110 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-6 .pb-110 {
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area-6 .pb-110 {
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-6 .sec-title-wrapper {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area-6 .sec-title-wrapper {
    padding-top: 60px;
  }
}

.blog__area-6 .blog__item,
.blog__area-6 .blog__item-2 {
  padding-bottom: 55px;
  position: relative;
  z-index: 1;
}

.blog__item,
.blog__item-2 {
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .blog__item,
  .blog__item-2 {
    padding-bottom: 50px;
  }
}

.blog__item:hover .blog__img,
.blog__item-2:hover .blog__img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.blog__item-2 {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .blog__item-2 {
    padding-bottom: 30px;
  }
}

.blog__item-3 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__item-3 {
    -ms-grid-columns: 2.5fr 1fr;
    grid-template-columns: 2.5fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__item-3 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__item-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
}

.blog__info-3 {
  position: absolute;
  width: 63%;
  bottom: -1px;
  right: 0;
  margin-left: -50px;
  background: var(--white);
  z-index: 1;
  padding: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog__info-3 {
    width: 70%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__info-3 {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .blog__info-3 {
    width: 90%;
    margin-left: 0;
    padding: 30px 20px;
    border-top-left-radius: 5px;
  }
}

.blog__img {
  width: 100%;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog__img-3 {
  width: 100%;
}

.blog__img-wrapper {
  overflow: hidden;
  margin-bottom: 25px;
  position: relative;
  z-index: 9;
}

.blog__meta,
.blog__meta-2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.3;
  color: var(--black-3);
  margin-bottom: 10px;
}

.blog__meta a,
.blog__meta-2 a {
  color: var(--black-3);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog__meta a:hover,
.blog__meta-2 a:hover {
  color: var(--secondary);
}

.blog__meta-2 {
  color: var(--gray-2);
}

.blog__meta-2 a {
  color: var(--gray-2);
}

.blog__meta-2 a:hover {
  color: var(--white);
}

.blog__title,
.blog__title-3,
.blog__title-2 {
  display: block;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.2;
  color: var(--black-2);
  border-bottom: 1px solid var(--gray-5);
  margin-bottom: 10px;
  padding-bottom: 20px;
}

.blog__title:hover,
.blog__title-3:hover,
.blog__title-2:hover {
  color: var(--black-3);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__title,
  .blog__title-3,
  .blog__title-2 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__title,
  .blog__title-3,
  .blog__title-2 {
    font-size: 1.25rem;
  }
}

.blog__title-2 {
  color: var(--white);
  border-bottom: 0;
}

.blog__title-2:hover {
  color: var(--gray-2);
}

.blog__title-3 {
  padding-bottom: 50px;
}

.blog__btn {
  display: inline-block;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--black-3);
  text-transform: capitalize;
}

.blog__btn span {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: -28px;
}

.blog__btn span i {
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.blog__btn span::after {
  position: absolute;
  content: "\f061";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: -20px;
  bottom: -20px;
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.blog__btn:hover {
  color: var(--secondary);
}

.blog__btn:hover i {
  -webkit-transform: translate(20px, -20px) rotate(-45deg);
  transform: translate(20px, -20px) rotate(-45deg);
}

.blog__btn:hover span::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(20px, -20px) rotate(-45deg);
  transform: translate(20px, -20px) rotate(-45deg);
}

.blog__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.blog__text p {
  font-size: 1.125rem;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .blog__text p {
    padding-top: 30px;
  }
}

.blog__detail {
  overflow: hidden;
}

.blog__detail-top {
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-top {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__detail-top {
    padding-top: 60px;
  }
}

.blog__detail-date {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black-2);
  padding-bottom: 15px;
}

.blog__detail-date span {
  color: var(--black-3);
  position: relative;
  padding-left: 25px;
}

.blog__detail-date span::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  top: 11px;
  left: 8px;
  background-color: var(--gray-5);
  border-radius: 50px;
}

.blog__detail-title {
  font-weight: 500;
  font-size: 80px;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 35px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .blog__detail-title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog__detail-title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__detail-title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__detail-title {
    font-size: 36px;
  }
}

.blog__detail-metalist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  padding-bottom: 50px;
}

.blog__detail-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}

.blog__detail-meta p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: var(--gray-8);
}

.blog__detail-meta p span {
  display: block;
  color: var(--black-3);
  font-size: 1rem;
  font-weight: 500;
}

.blog__detail-thumb {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 70vh;
}

.blog__detail-thumb img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 120%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .blog__detail-thumb {
    padding-bottom: 40px;
  }
}

.blog__detail-thumb img {
  width: 100%;
}

.blog__detail-content {
  padding-top: 55px;
  padding-bottom: 30px;
}

.blog__detail-content p {
  font-size: 1.125rem;
  line-height: 1.4;
  padding-bottom: 25px;
}

.blog__detail-content img {
  margin-bottom: 30px;
  max-width: 100%;
}

.blog__detail-content h1,
.blog__detail-content h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
  color: var(--black);
  padding-bottom: 15px;
  padding-top: 10px;
}

.blog__detail-content h3 {
  font-weight: 500;
  font-size: 30px;
  line-height: 1.2;
  color: var(--black);
  padding-bottom: 15px;
  padding-top: 10px;
}

.blog__detail-content h4 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2;
  color: var(--black);
  padding-bottom: 15px;
  padding-top: 10px;
}

.blog__detail-content h5 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.2;
  color: var(--black);
  padding-bottom: 10px;
  padding-top: 10px;
}

.blog__detail-content h6 {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.2;
  color: var(--black);
  padding-bottom: 10px;
  padding-top: 10px;
}

.blog__detail-content ul,
.blog__detail-content ol {
  padding-bottom: 25px;
}

.blog__detail-content ul li,
.blog__detail-content ol li {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-2);
  padding-bottom: 5px;
  padding-left: 25px;
  position: relative;
}

.blog__detail-content ul li::before,
.blog__detail-content ol li::before {
  position: absolute;
  content: "+";
  left: 0;
  font-size: 1.25rem;
}

.blog__detail-tags p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-2);
  text-transform: capitalize;
}

.blog__detail-tags p a {
  color: var(--black-3);
  text-transform: lowercase;
  text-decoration-line: underline;
}

.blog__related {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .blog__related .pb-140 {
    padding-bottom: 0;
  }
}

.blog__related .sec-title-wrapper {
  padding-bottom: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__related .pb-130 {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__related .pb-130 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__related .pb-130 {
    padding-bottom: 0;
  }
}

.blog-v2 {
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v2 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-v2 {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v2 .pt-150 {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-v2 .pt-150 {
    padding-top: 130px;
  }
}

.blog-v2 .sec-text p {
  padding-bottom: 70px;
  margin-left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v2 .sec-text p {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-v2 .sec-text p {
    padding-bottom: 40px;
  }
}

.blog-v2 .blog__item-2 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v2 .blog__item-2 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-v2 .blog__item-2 {
    margin-bottom: 0;
  }
}

.blog-v3.blog__area-3 .sec-title-wrapper::after {
  position: unset;
}

.blog-v3 .line {
  padding-bottom: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-v3 .line {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v3 .line {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-v3 .line {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

.blog-v3 .blog__item-3 {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-v3 .blog__item-3 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-v3 .blog__item-3 {
    margin-bottom: 50px;
  }
}

.img-box {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .img-box {
    height: 425px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-box {
    height: 340px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .img-box {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .img-box {
    height: auto;
  }
}

.img-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-size: cover;
}

@media (max-width: 1023px) {
  .img-box img {
    position: unset;
    width: 100%;
    height: auto;
  }
}

.img-box img:nth-child(1) {
  -webkit-transform: translatex(50%) scalex(2);
  transform: translatex(50%) scalex(2);
  opacity: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

@media (max-width: 1023px) {
  .img-box img:nth-child(1) {
    display: none;
  }
}

.img-box:hover img:nth-child(2) {
  -webkit-transform: translatex(-50%) scalex(2);
  transform: translatex(-50%) scalex(2);
  opacity: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

@media (max-width: 1023px) {
  .img-box:hover img:nth-child(2) {
    opacity: 1;
  }
}

.img-box:hover img:nth-child(1) {
  -webkit-transform: translatex(0) scalex(1);
  transform: translatex(0) scalex(1);
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0);
}

.row.reset-grid {
  --bs-gutter-x: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .row.reset-grid {
    --bs-gutter-x: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row.reset-grid {
    --bs-gutter-x: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row.reset-grid {
    --bs-gutter-x: 20px;
  }
}

.cta__area {
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__area-2 .pb-120 {
    padding-bottom: 126px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__area-2 .pb-120 {
    padding-bottom: 115px;
  }
}

@media only screen and (max-width: 767px) {
  .cta__area-2 .pb-120 {
    padding-bottom: 70px;
  }
}

.cta__area-2 .wc-btn-pink {
  color: var(--black-3);
}

.cta__area-2 .wc-btn-pink:hover {
  color: var(--black-2);
}

.cta__area-3 {
  background-color: var(--pink-5);
}

@media only screen and (min-width: 1920px) {
  .cta__area-3 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .cta__area-3 {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__area-3 .pt-150 {
    padding-top: 125px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__area-3 {
    background-color: var(--white);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__area-3 {
    background-color: var(--white);
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .cta__area-3 {
    background-color: var(--white);
    padding-bottom: 10px;
  }
}

.cta__content {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__content {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__content {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .cta__content {
    width: 100%;
  }
}

.cta__content #btn_wrapper,
.cta__content .btn_wrapper {
  margin: 0 auto;
}

.cta__content-2,
.cta__content-3 {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__content-2,
  .cta__content-3 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .cta__content-2,
  .cta__content-3 {
    width: 100%;
  }
}

.cta__content-2 #btn_wrapper,
.cta__content-3 #btn_wrapper,
.cta__content-2 .btn_wrapper,
.cta__content-3 .btn_wrapper {
  position: relative;
  z-index: 1;
  width: 350px;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__content-2 #btn_wrapper,
  .cta__content-3 #btn_wrapper,
  .cta__content-2 .btn_wrapper,
  .cta__content-3 .btn_wrapper {
    width: 260px;
    height: 260px;
  }
}

@media (max-width: 1200px) {
  .cta__content-2 #btn_wrapper,
  .cta__content-3 #btn_wrapper,
  .cta__content-2 .btn_wrapper,
  .cta__content-3 .btn_wrapper {
    width: 200px;
    height: 200px;
  }
}

.cta__content-2 #btn_wrapper .btn-item,
.cta__content-3 #btn_wrapper .btn-item,
.cta__content-2 .btn_wrapper .btn-item,
.cta__content-3 .btn_wrapper .btn-item {
  top: 25px;
}

@media only screen and (max-width: 767px) {
  .cta__content-2 #btn_wrapper .btn-item,
  .cta__content-3 #btn_wrapper .btn-item,
  .cta__content-2 .btn_wrapper .btn-item,
  .cta__content-3 .btn_wrapper .btn-item {
    top: 0;
  }
}

.cta__content-2 a,
.cta__content-3 a {
  width: 300px;
  height: 300px;
  font-size: 1.5rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__content-2 a,
  .cta__content-3 a {
    width: 220px;
    height: 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__content-2 a,
  .cta__content-3 a {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__content-2 a,
  .cta__content-3 a {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .cta__content-2 a,
  .cta__content-3 a {
    width: 200px;
    height: 200px;
    font-size: 1.25rem;
  }
}

.cta__content-2 a:hover span,
.cta__content-3 a:hover span {
  width: 800px;
  height: 800px;
}

.cta__content-2 a i,
.cta__content-3 a i {
  font-size: 30px;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  padding-left: 20px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .cta__content-3 a {
    width: 220px;
    height: 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__content-3 #btn_wrapper .btn-item,
  .cta__content-3 .btn_wrapper .btn-item {
    top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__content-3 #btn_wrapper .btn-item,
  .cta__content-3 .btn_wrapper .btn-item {
    top: 0;
  }
}

.cta__content-3 a {
  border-color: var(--black-2);
}

.cta__sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
  color: var(--black-3);
  border: 1px solid var(--gray);
  display: inline-block;
  padding: 5px 17px;
  border-radius: 30px;
  margin-bottom: 10px;
}

.cta__sub-title-2 {
  font-weight: 300;
  font-size: 28px;
  line-height: 1.3;
  color: var(--black-2);
  padding-bottom: 25px;
}

.cta__title {
  font-weight: 400;
  font-size: 50px;
  line-height: 1;
  color: var(--black-2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__title {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__title {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__title {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .cta__title {
    font-size: 36px;
    padding-bottom: 40px;
  }
}

.cta__title-2 {
  font-weight: 300;
  font-size: 100px;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__title-2 {
    font-size: 75px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__title-2 {
    font-size: 70px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__title-2 {
    font-size: 60px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .cta__title-2 {
    font-size: 48px;
  }
}

.story__area {
  overflow: hidden;
}

.story__area .sec-title-wrapper {
  padding-bottom: 40px;
}

.story__area .from-text {
  position: absolute;
  top: -140px;
  right: -220px;
  width: 160px;
  height: 90px;
  background-color: var(--primary);
  padding: 20px 30px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.1;
  color: var(--black-2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .story__area .from-text {
    right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .story__area .from-text {
    right: 0;
    top: -89px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .story__area .from-text {
    right: -20px;
    top: -80px;
  }
}

@media only screen and (max-width: 767px) {
  .story__area .from-text {
    width: 120px;
    right: 0;
    top: -60px;
  }
}

.story__area .from-text span {
  display: block;
  font-size: 36px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .story__area .from-text span {
    font-size: 30px;
  }
}

.story__text p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-3);
  padding-bottom: 20px;
}

@media (max-height: 1365px) {
  .story__text p {
    padding-top: 30px;
    padding-bottom: 0;
  }
}

.story__img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 700px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .story__img-wrapper {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .story__img-wrapper {
    height: 580px;
  }
}

@media (max-width: 1200px) {
  .story__img-wrapper {
    height: 100%;
  }
}

.story__img-wrapper img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 30px;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .story__img-wrapper img {
    margin-bottom: 20px;
  }
}

.team__area {
  background-color: var(--black-2);
  overflow: hidden;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .team__area {
    padding-left: 62px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__area {
    padding-left: 62px;
  }
}

.team__area .sec-title {
  color: var(--white);
}

.team__area .sec-title-wrapper {
  text-align: center;
  padding-bottom: 60px;
}

.team__area .sec-sub-title {
  color: var(--white);
}

.faculty {
  padding: 30px 0 30px 0;
}

/* .faculty img {
  border-radius: 2rem;
} */

.team__area-6 {
  padding: 30px 0 30px 0;
  background-image: url(../imgs/bg/shape.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: 100px 180px;
  overflow: hidden;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .team__area-6 {
    background-position: 0 180px;
    /* padding-left: 62px; */
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__area-6 {
    background-position: 0 180px;
    /* padding-left: 62px; */
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__area-6 {
    background-position: 0 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__area-6 .line {
    padding-top: 150px;
  }
}

.team__area-6 .sec-title {
  line-height: 0.9;
  /* padding-bottom: 30px; */
}

@media only screen and (max-width: 767px) {
  .team__area-6 .sec-title {
    padding-top: 90px;
  }
}

/* .team__area-6 .sec-title-wrapper { */
/* max-width: 520px; */
/* padding-bottom: 20px; */
/* } */

.team__area-6 .sec-title-wrapper p {
  line-height: 1.4;
}

.team__area-6 .swiper-wrapper {
  position: relative;
}

.team__area-6 .mentor-nav {
  width: 96%;
  color: var(--white);
  position: absolute;
  top: 33%;
  z-index: 9;
}

.team__area-6 .mentor-nav img {
  filter: drop-shadow(2px 4px 6px black);
}

.team__area-6 .prev-btn {
  animation-name: horizontalShake;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.team__area-6 .next-btn {
  animation-name: horizontalShakes;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

/* .team__area-6 .prev-btn,
  background: #32436eb5;
  padding: 2rem;
  display: inline;
  border-radius: 50%;
} */

@media only screen and (min-width: 1920px) {
  .team__slider {
    margin-left: 100px;
  }
}

.team__slider {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__slider {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .team__slide {
    padding: 0 20px;
  }
}

.team__slide:nth-child(even) {
  padding-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__slide:nth-child(even) {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__slide:nth-child(even) {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .team__slide:nth-child(even) {
    padding-top: 0;
  }
}

.team__slide img {
  /* border-radius: 1.5rem; */
  width: 100%;
}

.team__member-name,
.team__member-name-6 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.3;
  color: var(--white);
  padding-top: 6px;
}

.team__member-name-6 {
  color: var(--black-2);
}

.team__member-name-7 {
  font-weight: 500;
  font-size: 80px;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__member-name-7 {
    font-size: 60px;
    padding-bottom: 20px;
  }

  .team__member-name-7 br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__member-name-7 {
    font-size: 60px;
    padding-bottom: 20px;
  }

  .team__member-name-7 br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__member-name-7 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__member-name-7 {
    font-size: 48px;
  }

  .team__member-name-7 br {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .team__member-name-7 {
    font-size: 36px;
    padding-bottom: 20px;
  }
}

.team__member-role,
.team__member-role-6 {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--gray-2);
}

.team__member-role-6 {
  color: var(--black-3);
}

.team__member-role-7 {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1;
  color: var(--black-3);
  font-style: italic;
  padding-bottom: 15px;
  margin-bottom: 40px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__member-role-7 {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__member-role-7 {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 767px) {
  .team__member-role-7 {
    font-size: 1.125rem;
  }
}

.team__member-role-7::after {
  position: absolute;
  content: "";
  width: 150px;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: var(--black-2);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__member-role-7::after {
    left: calc(50% - 75px);
  }
}

@media only screen and (max-width: 767px) {
  .team__member-role-7::after {
    left: calc(50% - 75px);
  }
}

.team__member-role-7 span {
  color: var(--black-2);
  font-weight: 500;
}

.team__join-btn {
  background-color: var(--black-2);
  padding: 300px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__join-btn {
    padding: 200px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__join-btn {
    padding: 100px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__join-btn {
    padding: 90px 0;
  }
}

@media only screen and (max-width: 767px) {
  .team__join-btn {
    padding: 60px 0;
  }
}

.team__join-btn #btn_wrapper,
.team__join-btn .btn_wrapper {
  width: 360px;
  height: 360px;
  margin: 0 auto;
}

.team__join-btn a {
  position: relative;
  color: var(--gray-2);
  font-size: 1.25rem;
  width: 342px;
  height: 342px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__join-btn a {
    width: 300px;
    height: 300px;
    padding: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .team__join-btn a {
    width: 220px;
    height: 220px;
    padding: 35px;
  }
}

.team__join-btn a:hover span {
  width: 800px;
  height: 800px;
}

.team__join-btn a i {
  position: absolute;
  right: 130px;
  top: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__join-btn a i {
    top: 130px;
    right: 88px;
  }
}

@media only screen and (max-width: 767px) {
  .team__join-btn a i {
    right: 50px;
    top: 90px;
  }
}

.team__btm {
  overflow: hidden;
}

.team__btm .sec-title-wrapper {
  padding-left: 48%;
  background-image: url(../imgs/bg/snake.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: 1px -150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__btm .sec-title-wrapper {
    background-size: 42%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__btm .sec-title-wrapper {
    background-size: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__btm .sec-title-wrapper {
    padding-left: 30%;
    background-size: 200px;
    background-position: 1px -50px;
  }
}

@media only screen and (max-width: 767px) {
  .team__btm .sec-title-wrapper {
    background-size: 0px;
    padding-left: 0;
    padding-bottom: 0;
  }
}

.team__btm p {
  font-size: 22px;
  padding-top: 30px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__btm p {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__btm p {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 767px) {
  .team__btm p {
    font-size: 1.125rem;
  }
}

.team__detail {
  overflow: hidden;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .team__detail .pb-140 {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__detail .pb-140 {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__detail {
    padding-top: 120px;
    position: relative;
  }

  .team__detail .pb-140 {
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__detail {
    text-align: center;
  }

  .team__detail .pb-140 {
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .team__detail {
    text-align: center;
  }

  .team__detail .pb-140 {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__detail .pt-120 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__detail .pt-120 {
    padding-top: 10px;
  }
}

.team__detail p {
  font-size: 1.125rem;
  line-height: 1.4;
  padding-bottom: 20px;
}

.team__detail .work-title {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-2);
  padding-bottom: 20px;
}

.team__detail-page {
  position: relative;
}

.team__member-img {
  overflow: hidden;
  position: absolute;
  width: 770px;
  height: 100vh;
  left: -192px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .team__member-img {
    left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__member-img {
    left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__member-img {
    position: unset;
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__member-img {
    position: relative;
    width: 100%;
    height: auto;
    left: 0;
    z-index: 1;
    margin-top: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .team__member-img {
    position: relative;
    width: 100%;
    height: auto;
    left: 0;
    z-index: 1;
    margin-top: 150px;
  }
}

.team__member-img img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 102%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .team__member-img img {
    left: 0;
    max-width: 80%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__member-img img {
    left: 0;
    max-width: 70%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__member-img img {
    position: unset;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__member-img img {
    position: unset;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .team__member-img img {
    position: unset;
    max-width: 100%;
  }
}

.team__member-work {
  padding-top: 40px;
}

.team__member-work li {
  display: inline-block;
  padding-right: 10px;
}

.team__member-work li a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: var(--black-2);
  padding: 5px 12px;
  border: 1px solid var(--white-4);
  border-radius: 30px;
  -webkit-transition: all 0.3;
  transition: all 0.3;
}

.team__member-work li a:hover {
  background-color: var(--black-2);
  color: var(--white);
}

.team__member-social {
  padding-top: 8px;
}

.team__member-social li {
  display: inline-block;
  padding-right: 15px;
}

.team__member-social li a {
  font-size: 1.125rem;
  color: var(--gray-15);
}

.team__member-social li a:hover {
  color: var(--black-2);
}

.solution__area {
  padding-left: 100px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__area {
    padding-left: 10px;
    padding-top: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .solution__area {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 90px;
  }
}

.solution__wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.3fr 1.7fr 1fr;
  grid-template-columns: 1.3fr 1.7fr 1fr;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__wrapper {
    /* -ms-grid-columns: (1fr) [12]; */
    grid-template-columns: repeat(12, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .solution__wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__left {
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    grid-column: 1/7;
  }
}

.solution__mid {
  padding-left: 80px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .solution__mid {
    padding-left: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__mid {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__mid {
    padding-left: 0;
    grid-column: 7/-1;
    -ms-grid-row: 1;
    grid-row: 1;
  }
}

@media only screen and (max-width: 767px) {
  .solution__mid {
    padding-left: 0;
    -ms-grid-row: 1;
    grid-row: 1;
  }
}

.solution__mid p {
  max-width: 580px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-3);
  padding-left: 190px;
  position: relative;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .solution__mid p {
    padding-left: 110px;
    max-width: 480px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .solution__mid p {
    padding-left: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__mid p {
    padding-left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__mid p {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .solution__mid p {
    padding-left: 80px;
    padding-bottom: 50px;
  }
}

.solution__mid p::before {
  position: absolute;
  content: "";
  width: 140px;
  height: 1px;
  left: 0;
  top: 10px;
  background-color: var(--black-2);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .solution__mid p::before {
    width: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .solution__mid p::before {
    width: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__mid p::before {
    width: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__mid p::before {
    width: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .solution__mid p::before {
    width: 70px;
  }
}

.solution__right {
  padding-top: 260px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__right {
    padding-top: 0;
    grid-column: 1/-1;
    -ms-grid-row: 2;
    grid-row: 2;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .solution__right {
    padding-top: 60px;
  }
}

.solution__title {
  font-weight: 600;
  font-size: 150px;
  line-height: 1;
  color: var(--black-2);
  padding-top: 280px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .solution__title {
    font-size: 120px;
    padding-top: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .solution__title {
    padding-top: 80px;
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__title {
    padding-top: 80px;
    font-size: 72px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__title {
    padding-top: 60px;
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .solution__title {
    padding-top: 30px;
    font-size: 36px;
  }
}

.solution__img-1 {
  display: inline-block;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__img-1 {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .solution__img-1 img {
    max-width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .solution__img-1 img {
    max-width: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__img-1 img {
    max-width: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__img-1 img {
    max-width: 260px;
  }
}

@media only screen and (max-width: 767px) {
  .solution__img-1 img {
    max-width: 300px;
  }
}

.solution__img-1::after {
  position: absolute;
  content: "";
  width: 100%;
  height: calc(100% + 10px);
  left: 40px;
  top: 0;
  border: 1px solid var(--white-6);
  border-radius: 0 0 290px 230px;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .solution__img-1::after {
    left: 20px;
  }
}

.solution__img-2 {
  position: relative;
  float: right;
  margin-top: 60px;
}

.solution__img-2::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  right: 30px;
  top: 0;
  border: 1px solid var(--white-6);
  border-radius: 200px;
  z-index: -1;
}

.solution__img-3 {
  position: relative;
  float: right;
  margin-top: 60px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .solution__img-3 img {
    max-width: 360px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .solution__img-3 img {
    max-width: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__img-3 img {
    max-width: 220px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__img-3 img {
    max-width: 260px;
  }
}

@media only screen and (max-width: 767px) {
  .solution__img-3 img {
    max-width: 300px;
  }
}

.solution__img-3::after {
  position: absolute;
  content: "";
  width: calc(100% + 20px);
  height: 100%;
  right: 0;
  top: -30px;
  border: 1px solid var(--white-6);
  border-radius: 280px 0 0 200px;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .solution__btm {
    padding-top: 40px;
  }
}

.solution__btm ul {
  text-align: right;
}

.solution__btm li {
  display: inline;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-2);
  padding-right: 40px;
  position: relative;
}

.solution__btm li:last-child {
  padding-right: 0;
}

@media only screen and (max-width: 767px) {
  .solution__btm li {
    padding-right: 30px;
  }
}

.solution__btm li::after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  left: -20px;
  top: 12px;
  background-color: var(--black-2);
}

.solution__btm li:first-child::after {
  position: unset;
}

.solution__shape img {
  position: absolute;
}

.solution__shape .shape-1 {
  left: 80%;
  top: 25%;
}

.solution__shape .shape-2 {
  left: 10%;
  top: 75%;
}

.solution__shape .shape-3 {
  left: 33%;
  top: 45%;
}

.solution__shape .shape-4 {
  left: 72%;
  top: 70%;
}

.solution__shape .shape-5 {
  top: 42%;
  left: 68%;
  z-index: -1;
}

.development__area {
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .development__area .pt-130 {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .development__area .pt-130 {
    padding-top: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .development__area .pb-150 {
    padding-bottom: 40px;
  }
}

.development__content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 80px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  padding-bottom: 35px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--black-3);
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .development__content {
    grid-gap: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .development__content {
    grid-gap: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .development__content {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .development__content {
    padding-top: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}

.development__content p {
  font-size: 1.125rem;
  line-height: 1.4;
}

.development__wrapper {
  padding-bottom: 55px;
}

@media only screen and (max-width: 767px) {
  .development__wrapper {
    padding-bottom: 15px;
  }
}

.development__wrapper ul {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 240px auto;
  grid-template-columns: 240px auto;
  -ms-grid-columns: 310px auto;
  grid-template-columns: 310px auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .development__wrapper ul {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .development__wrapper ul {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    padding-bottom: 20px;
  }
}

.development__wrapper ul li {
  display: inline-block;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .development__wrapper ul li {
    font-size: 1rem;
    padding-bottom: 5px;
  }
}

.development__img {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 400px;
  z-index: 9;
}

.development__img img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 150%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .development__img {
    padding-bottom: 15px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
  }

  .development__img img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .development__img img {
    position: unset;
  }
}

@media only screen and (max-width: 767px) {
  .development__img {
    padding-bottom: 15px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
  }

  .development__img img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .development__img img {
    position: unset;
  }
}

.development__img img {
  width: 100%;
}

.chairm__img {
  transition: transform 0.3s ease-out;
}

.chairm__img img {
  object-fit: cover;
  border-radius: 6px;
}

.chairm__img:hover {
  transform: scale(1.05);
}

.chairm__block {
  margin-top: 1.5rem;
  text-align: center;
}

.chairm__block h3 {
  font-size: 1.6rem;
}

.chairm__mes {
  padding: 0 1rem;
}

.chairm__mes p {
  text-align: justify;
}

.chairm__details h6 {
  font-size: 1rem;
}

.award__area {
  background-color: var(--black-2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .award__area.pb-140 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .award__area.pb-140 {
    padding-bottom: 90px;
  }
}

.award__area .sec-title {
  max-width: 850px;
  color: var(--white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .award__area .sec-title {
    max-width: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .award__area .sec-title {
    max-width: 550px;
  }
}

.award__area-8 {
  position: relative;
}

.award__left {
  height: 100%;
  margin-top: 100px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .award__left {
    margin-top: 50px;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }
}

.award__left img {
  position: absolute;
  bottom: 40%;
  right: 30%;
}

@media only screen and (max-width: 767px) {
  .award__left img {
    bottom: -40px;
  }
}

.award__text,
.award__text-2 {
  font-size: 160px;
  color: var(--white);
  font-weight: 600;
  -webkit-transform: rotate(-43deg);
  transform: rotate(-43deg);
  text-transform: uppercase;
  letter-spacing: -3px;
  position: absolute;
  top: 130px;
  left: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .award__text,
  .award__text-2 {
    font-size: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .award__text,
  .award__text-2 {
    font-size: 120px;
    top: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .award__text,
  .award__text-2 {
    font-size: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .award__text,
  .award__text-2 {
    position: unset;
    font-size: 100px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    line-height: 0.5;
  }
}

.award__text-2 {
  -webkit-text-stroke: 1px #3a3a3a;
  color: transparent;
  top: 0;
  left: 30px;
}

.award__text-8 {
  position: absolute;
  width: 300px;
  right: 50px;
  bottom: 50px;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .award__text-8 {
    width: 260px;
  }
}

@media only screen and (max-width: 767px) {
  .award__text-8 {
    width: 220px;
    right: 10px;
    bottom: 10px;
  }
}

.award__text-8 a {
  display: block;
  max-width: 100%;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 15px;
  border-bottom: 1px solid var(--white);
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .award__text-8 a {
    padding-bottom: 5px;
  }
}

.award__text-8 a:hover i {
  -webkit-transform: translate(20px, -20px) rotate(-45deg);
  transform: translate(20px, -20px) rotate(-45deg);
}

.award__text-8 a:hover span::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(20px, -20px) rotate(-45deg);
  transform: translate(20px, -20px) rotate(-45deg);
}

.award__text-8 a span {
  position: absolute;
  top: 0;
  right: 0;
}

.award__text-8 a span i {
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.award__text-8 a span::after {
  position: absolute;
  content: "\f061";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: -20px;
  bottom: -20px;
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.award__text-8 .title {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.4;
  color: var(--white);
}

@media only screen and (max-width: 767px) {
  .award__text-8 .title {
    font-size: 1rem;
  }
}

.award__right > p {
  max-width: 390px;
  margin-left: 100px;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--gray-2);
  margin-bottom: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .award__right > p {
    margin-left: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .award__right > p {
    margin-left: 0;
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .award__right > p {
    margin-left: 0;
    -webkit-transform: translateY(-160%);
    transform: translateY(-160%);
  }
}

.award__item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.5fr 1fr 0.5fr 0.5fr 0.5fr;
  grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr 0.5fr;
  border-bottom: 1px solid var(--gray-12);
  padding: 20px 0;
}

.award__item:first-child {
  border-top: 1px solid var(--gray-12);
}

.award__item p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--white);
}

.award__item p:last-child {
  text-align: right;
}

.award__video-8 {
  height: 800px;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .award__video-8 {
    height: 575px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .award__video-8 {
    height: 430px;
  }
}

@media only screen and (max-width: 767px) {
  .award__video-8 {
    height: 210px;
  }
}

.award__video-8 video {
  width: 100%;
}

.awards__area-8 .about-sub-right {
  font-weight: 400;
  color: var(--black-3);
  padding-bottom: 10px;
  text-transform: capitalize;
}

.awards__wrapper {
  padding-top: 15px;
  padding-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .awards__wrapper {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .awards__wrapper {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .awards__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.awards__content__title {
  color: var(--black-2);
  font-size: 80px;
  text-transform: uppercase;
  margin-bottom: 55px;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .awards__content__title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .awards__content__title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .awards__content__title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .awards__content__title {
    font-size: 36px;
    margin-bottom: 30px;
  }
}

.awards__content__title a {
  color: var(--black-2);
  font-family: "newYork";
  position: relative;
}

.awards__content__title a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 10px;
  background-color: var(--black-2);
}

@media only screen and (max-width: 767px) {
  .awards__content__title a::after {
    bottom: 3px;
  }
}

.awards__content p {
  font-size: 1.125rem;
  width: 240px;
  margin-left: 170px;
  color: var(--black-2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .awards__content p {
    margin-left: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .awards__content p {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .awards__content p {
    margin-left: 60px;
    padding-bottom: 30px;
  }
}

.awards__items {
  display: -ms-grid;
  display: grid;
  grid-gap: 30px;
  -ms-grid-columns: 1.2fr 30px 3fr 30px 1.5fr 30px 0.5fr;
  grid-template-columns: 1.2fr 3fr 1.5fr 0.5fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray-11);
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .awards__items {
    -ms-grid-columns: 0.3fr 30px 2fr 30px 1fr 30px 0.5fr;
    grid-template-columns: 0.3fr 2fr 1fr 0.5fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .awards__items {
    -ms-grid-columns: 0.5fr 30px 2fr 30px 1fr 30px 0.5fr;
    grid-template-columns: 0.5fr 2fr 1fr 0.5fr;
  }
}

@media only screen and (max-width: 767px) {
  .awards__items {
    grid-gap: 15px;
    -ms-grid-columns: 0.3fr 15px 2fr 15px 1fr 15px 0.5fr;
    grid-template-columns: 0.3fr 2fr 1fr 0.5fr;
  }
}

.awards__items .number {
  color: var(--black-7);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
}

.awards__items .title {
  color: var(--black-2);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .awards__items .title {
    font-size: 1rem;
  }
}

.awards__items img {
  max-width: 100px;
}

@media only screen and (max-width: 767px) {
  .awards__items img {
    max-width: 60px;
  }
}

.awards__items .date {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  text-align: right;
  color: var(--black-7);
}

@media only screen and (max-width: 767px) {
  .awards__items .date {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .about8 {
    margin-bottom: 40px;
  }
}

.dark .awards__content p,
.dark .awards__items .title,
.dark .awards__content__title a,
.dark .awards__content__title,
.dark .awards__area-8 .about-sub-right {
  color: var(--white);
}

.dark .awards__items .number,
.dark .awards__items .date {
  color: var(--gray-2);
}

.dark .awards__content__title a::after {
  background-color: var(--white);
}

.faq__area {
  overflow: hidden;
}

.faq__area-6 {
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__area-6 .line {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__area-6 .line {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .faq__area-6 .line {
    padding-top: 120px;
  }
}

.faq__area-6 .sec-title-wrapper {
  padding-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .faq__area-6 .sec-title-wrapper {
    padding-bottom: 40px;
  }
}

.faq__area-6 .sec-title-wrapper p {
  padding-left: 60px;
  font-size: 1.125rem;
  padding-top: 20px;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .faq__area-6 .sec-title-wrapper p {
    padding-left: 30px;
  }
}

.faq__img {
  padding-right: 85px;
  overflow: hidden;
  position: relative;
  width: calc(100% - 85px);
  height: 630px;
  z-index: 1;
}

.faq__img img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 120%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq__img {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__img {
    padding-right: 0;
    overflow: hidden;
    position: relative;
    width: calc(100% - 30px);
    height: auto;
  }

  .faq__img img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .faq__img img {
    position: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__img {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    padding-right: 0;
  }

  .faq__img img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .faq__img img {
    position: unset;
  }
}

@media only screen and (max-width: 767px) {
  .faq__img {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    padding-right: 0;
  }

  .faq__img img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .faq__img img {
    position: unset;
  }
}

.faq__img img {
  width: 100%;
}

.faq__content {
  padding-left: 85px;
  padding-top: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq__content {
    padding-left: 30px;
    padding-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__content {
    padding-left: 0;
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__content {
    padding-left: 0;
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .faq__content {
    padding-left: 0;
  }
}

.faq__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.1;
  color: var(--black-2);
  max-width: 315px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .faq__title {
    font-size: 30px;
  }
}

.faq__list,
.faq__list-6,
.faq__list-3 {
  padding-right: 1px;
}

.faq__list .accordion-body,
.faq__list-6 .accordion-body,
.faq__list-3 .accordion-body {
  padding-left: 0;
  min-height: 160px;
}

.faq__list .accordion-item,
.faq__list-6 .accordion-item,
.faq__list-3 .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.faq__list .accordion-button,
.faq__list-6 .accordion-button,
.faq__list-3 .accordion-button {
  background-color: transparent;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-2);
  padding-left: 0;
  padding-right: 10px;
  border-radius: 0;
}

.faq__list .accordion-button.collapsed::after,
.faq__list-6 .accordion-button.collapsed::after,
.faq__list-3 .accordion-button.collapsed::after {
  background-image: none;
  content: "+";
  font-weight: 300;
  font-size: 30px;
  top: 6px;
  position: absolute;
  right: 0;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.faq__list .accordion-button:not(.collapsed),
.faq__list-6 .accordion-button:not(.collapsed),
.faq__list-3 .accordion-button:not(.collapsed) {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.faq__list .accordion-button:not(.collapsed)::after,
.faq__list-6 .accordion-button:not(.collapsed)::after,
.faq__list-3 .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "-";
  font-weight: 300;
  font-size: 30px;
  top: 6px;
  position: absolute;
  right: 0;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.faq__list .accordion-button:focus,
.faq__list-6 .accordion-button:focus,
.faq__list-3 .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.faq__list-6 .accordion-item ul li {
  margin-bottom: 1rem;
  list-style: disc;
}

.faq__list-3 {
  padding-right: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__list-3 {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__list-3 {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .faq__list-3 {
    padding-right: 0;
  }
}

.faq__list-3 .accordion-item {
  background-color: transparent;
  border-bottom: 1px solid var(--black-2);
}

.faq__list-3 .accordion-item:first-child {
  border-top: 1px solid var(--black-2);
}

.faq__list-6 {
  padding-left: 1px;
}

.faq__list-6 .accordion-button {
  font-size: 1.25rem;
}

.faq__list-6 .accordion-button.collapsed::after,
.faq__list-6 .accordion-button:not(.collapsed)::after {
  font-size: 50px;
  top: -10px;
}

.faq__list-6 .accordion-body p {
  font-size: 1.125rem;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__list-6 .accordion-header {
    padding-right: 10px;
  }
}

.faq__btm {
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__btm .pb-130 {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__btm .pb-130 {
    padding-bottom: 120px;
  }
}

.faq__btm p {
  padding-top: 15px;
  font-size: 1.125rem;
  line-height: 1.4;
}

.faq__btm a {
  margin: 0 auto;
  margin-top: 60px;
}

@media only screen and (max-width: 767px) {
  .faq__btm a {
    margin-top: 0;
  }
}

.faq__btm #btn_wrapper,
.faq__btm .btn_wrapper {
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .faq__btm #btn_wrapper,
  .faq__btm .btn_wrapper {
    margin-top: 40px;
  }
}

.header__area-9 {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
}

.hero_layer_9 {
  position: relative;
}

#main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.content {
  color: #fff;
  position: absolute;
  bottom: 120px;
  text-align: center;
  left: 50px;
  z-index: 99;
  font-size: 0.8em;
  text-transform: uppercase;
}

.content p {
  color: var(--white);
}

.hero9_title {
  font-size: 80px;
  font-weight: 500;
  margin-bottom: 30px;
}

.hero9_title span {
  font-family: "newYork";
  position: relative;
  padding-left: 30px;
}

.hero9_title span::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -20%;
  height: 2px;
  width: 70px;
  background-color: var(--white);
}

.content a {
  color: rgba(227, 227, 227, 0.78);
  text-decoration: none;
  border-bottom: 1px solid currentColor;
}

.content a:hover {
  padding-bottom: 1px;
}

.buttons {
  position: absolute;
  right: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
}

.buttons button {
  border: none;
  background-size: contain;
  /* background: url(../../../../../../raw.githubusercontent.com/Rajacharles/GSAP-Fullscreen-Slider/master/images/down-arrow.png?v=0.1.8) no-repeat; */
  background-position: center;
  width: 10px;
  height: 30px;
  display: block;
  margin: 20px 0;
  padding: 0 15px;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.buttons button.next {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.buttons button.next:active {
  -webkit-transform: scaleY(-1) translateY(8px);
  transform: scaleY(-1) translateY(8px);
}

.buttons button.prev:active {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

#main .part {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#main .part .section {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

#main .part .section img {
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: var(--x);
  pointer-events: none;
}

.section {
  display: block;
  width: 100%;
  height: 100%;
}

.part:first-child h2 {
  display: block;
  top: 45% !important;
  left: 49% !important;
  position: absolute;
  z-index: 9999999999999 !important;
  width: 500px;
  background: transparent;
  height: 400px;
}

#header_10 {
  position: static;
  background: transparent;
}

#home10_menu li > a {
  color: var(--black);
}

.hero__area-10 .swiper.hero10_activition {
  padding-bottom: 110px;
}

.hero__area-10 .swiper-button-prev.hero_prev {
  color: var(--black-3);
  text-transform: uppercase;
  bottom: 40px;
  position: absolute;
  top: unset;
  width: 85px;
  left: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1365px) {
  .hero__area-10 .swiper-button-prev.hero_prev img {
    margin-left: 0;
  }
}

.hero__area-10 .swiper-button-prev.hero_prev:hover {
  color: var(--black-2);
}

.hero__area-10 .swiper-button-prev.hero_prev:hover img {
  margin-left: 10px;
}

@media (max-width: 1365px) {
  .hero__area-10 .swiper-button-prev.hero_prev:hover img {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-10 .swiper-button-prev.hero_prev {
    bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-10 .swiper-button-prev.hero_prev {
    width: 75px;
  }
}

.hero__area-10 .swiper-button-prev.hero_prev img {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  max-width: 100%;
}

.hero__area-10 .swiper-button-next.hero_next {
  text-transform: uppercase;
  bottom: 40px;
  position: absolute;
  top: unset;
  right: 0;
  width: 85px;
  color: var(--black-3);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1365px) {
  .hero__area-10 .swiper-button-next.hero_next img {
    margin-right: 0;
  }
}

.hero__area-10 .swiper-button-next.hero_next:hover {
  color: var(--black-2);
}

.hero__area-10 .swiper-button-next.hero_next:hover img {
  margin-right: 10px;
}

@media (max-width: 1365px) {
  .hero__area-10 .swiper-button-next.hero_next:hover img {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-10 .swiper-button-next.hero_next {
    bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-10 .swiper-button-next.hero_next {
    width: 75px;
  }
}

.hero__area-10 .swiper-button-next.hero_next img {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  max-width: 100%;
}

.hero__area-10 .swiper-button-prev:after,
.hero__area-10 .swiper-rtl .swiper-button-next:after {
  display: none;
}

.hero__area-10 .swiper-button-next:after,
.hero__area-10 .swiper-rtl .swiper-button-prev:after {
  display: none;
}

.hero__area-10 .increase-dash {
  font-size: 6px;
  width: 90px;
  background: var(--black);
  height: 1px;
  color: #1c1d20;
}

@media only screen and (max-width: 767px) {
  .hero__area-10 .increase-dash {
    width: 50px;
  }
}

.hero__area-10 .hero10_activition {
  position: relative;
}

.hero__area-10 .hero10_activition .swiper-pagination-current {
  position: relative;
  display: inline-block;
  width: 22px;
}

@media only screen and (max-width: 767px) {
  .hero__area-10 .hero10_activition .swiper-pagination-current {
    margin-left: 22px;
  }
}

.hero__area-10 .hero10_activition .swiper-pagination-current::before {
  content: "0";
  position: absolute;
  left: -5px;
}

.hero__area-10 .hero10_activition .swiper-pagination-total {
  position: relative;
  display: inline-block;
  width: 48px;
}

.hero__area-10 .hero10_activition .swiper-pagination-total::before {
  content: "0";
  position: absolute;
  left: 8px;
}

.hero__area-10 .hero10_activition .swiper-pagination {
  color: var(--black);
  width: 190px;
  left: 50%;
  bottom: 50px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-10 .hero10_activition .swiper-pagination {
    bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-10 .hero10_activition .swiper-pagination {
    width: 160px;
  }
}

.hero10 {
  position: relative;
  overflow: hidden;
  z-index: 2;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.hero10::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: rgba(0, 0, 0, 0.3);
}

.hero10:hover::after {
  opacity: 1;
  visibility: visible;
}

.hero10:hover .hero10__content i {
  opacity: 1;
  -webkit-transform: translateY(-50px) rotate(-45deg);
  transform: translateY(-50px) rotate(-45deg);
}

.hero10:hover .hero10__content__title {
  opacity: 1;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
}

.hero10:hover .hero10__content__name {
  opacity: 1;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
}

.hero10:hover .hero10__thumb img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* @media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero10__thumb {
    height: 470px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero10__thumb {
    height: 470px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero10__thumb {
    height: 470px;
  }
} */

/* @media only screen and (max-width: 767px) {
  .hero10__thumb {
    height: 70vh;
  }
} */

.hero10__thumb img {
  width: 100%;
  -webkit-transition: all 5s;
  transition: all 5s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.hero10__content {
  color: var(--white);
  position: absolute;
  left: 40px;
  z-index: 9;
  bottom: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.hero10__content i {
  color: var(--white);
  font-size: 1.5rem;
  margin-bottom: 35px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media (max-width: 1365px) {
  .hero10__content i {
    opacity: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

@media only screen and (max-width: 767px) {
  .hero10__content i {
    margin-bottom: 20px;
  }
}

.hero10__content__title {
  font-weight: 500;
  font-size: 45px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--white);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

@media (max-width: 1365px) {
  .hero10__content__title {
    font-size: 36px;
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .hero10__content__title {
    font-size: 30px;
  }
}

.hero10__content__title span {
  display: block;
  font-weight: 400;
  font-family: "newYork";
}

.hero10__content__name {
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px;
  display: inline-block;
  text-transform: uppercase;
  opacity: 0;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media (max-width: 1365px) {
  .hero10__content__name {
    opacity: 1;
  }
}

.hero10__content__date {
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
}

.showcase4 {
  margin-bottom: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .showcase4 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .showcase4 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase4 {
    margin-bottom: 40px;
  }
}

.showcase4 .showcase5_content {
  position: absolute;
  bottom: 50px;
  left: 50px;
  color: var(--white);
}

.showcase4__thumb {
  position: relative;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 500px;
}

.showcase4__thumb img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 130%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .showcase4__thumb {
    height: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .showcase4__thumb {
    height: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .showcase4__thumb {
    height: unset;
  }

  .showcase4__thumb img {
    position: unset;
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .showcase4__thumb {
    height: unset;
  }

  .showcase4__thumb img {
    position: unset;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .showcase4__thumb {
    height: unset;
  }

  .showcase4__thumb img {
    position: unset;
    height: auto;
  }
}

.showcase4__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .showcase4__content br {
    display: block;
  }
}

.showcase4__content h2 {
  color: var(--black-2);
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
}

.showcase4__content h2 span {
  color: var(--black-2);
  padding-left: 35px;
  font-family: "newYork";
  position: relative;
}

.showcase4__content h2 span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 30px;
  background-color: var(--black-13);
}

.showcase4__content h2 span.before_content_two {
  padding-left: 65px;
  color: var(--white);
}

.showcase4__content h2 span.before_content_two::before {
  width: 55px;
  background-color: var(--white);
}

.showcase4__content span {
  text-transform: uppercase;
  color: var(--black-13);
}

.plr-50 {
  padding: 0 50px;
}

.work12__title {
  margin: 0 auto 80px;
  margin-right: 0;
}

.hero__area-12 {
  overflow: hidden;
  height: 100%;
  padding: 100px 30px 0;
}

.hero__area-12 .swiper-horizontal > .swiper-pagination-bullets,
.hero__area-12 .swiper-pagination-bullets.swiper-pagination-horizontal,
.hero__area-12 .swiper-pagination-custom,
.hero__area-12 .swiper-pagination-fraction {
  bottom: 81px;
  left: 0;
  width: 100%;
}

.hero__area-12 .swiper-pagination-bullet-active {
  outline: 1px solid #121212;
  outline-offset: 4px;
  background: #121212;
}

.hero__area-12
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.hero__area-12
  .swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 11px;
}

.hero__area-12 .gallery-slider {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 80px;
}

.hero__area-12 .gallery-thumbs {
  position: absolute;
  width: 300px;
  height: 100%;
  top: 0;
  right: 0;
}

.hero__area-12 .gallery-thumbs .swiper-slide-active {
  opacity: 1;
}

.hero__area-12 .gallery-thumbs .swiper-slide img {
  width: 150px;
}

.hero__area-12 .gallery-slider .swiper-slide {
  width: auto;
}

.gallary12 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-position: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gallary12 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.gallary12__thumb {
  height: 575px;
  max-width: 865px;
}

.gallary12__thumb img {
  width: 100%;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .gallary12__thumb {
    max-width: 850px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .gallary12__thumb {
    height: 520px;
    max-width: 750px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gallary12__thumb {
    height: 460px;
    max-width: 560px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gallary12__thumb {
    height: 380px;
    max-width: 570px;
  }
}

@media only screen and (max-width: 767px) {
  .gallary12__thumb {
    max-width: 100%;
    height: 55vh;
  }
}

.gallary12__content {
  text-transform: uppercase;
  -ms-flex-preferred-size: 28%;
  flex-basis: 28%;
  opacity: 0;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .gallary12__content {
    -ms-flex-preferred-size: 32%;
    flex-basis: 32%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gallary12__content {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gallary12__content {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .gallary12__content {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

.gallary12__content img {
  display: block;
  text-align: left;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gallary12__content img {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .gallary12__content img {
    margin-bottom: 20px;
  }
}

.gallary12__content__title {
  font-size: 60px;
  color: var(--black-2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .gallary12__content__title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gallary12__content__title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gallary12__content__title {
    font-size: 30px;
  }

  .gallary12__content__title br {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .gallary12__content__title {
    font-size: 1.5rem;
  }
}

.gallary12__content__title span {
  font-family: "newYork";
}

.gallary12__content__name {
  color: var(--black-2);
}

.title_right.w-300 {
  width: 300px;
}

.pl-100 {
  padding-left: 100px;
}

.showcase5_title {
  font-size: 80px !important;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .showcase5_title {
    font-size: 30px !important;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase5_title {
    font-size: 1.25rem !important;
    margin-bottom: 20px;
  }
}

.showcase5_bottom {
  padding-left: 38px;
  position: relative;
  z-index: 2;
}

.showcase5_bottom span {
  color: var(--white);
}

@media only screen and (max-width: 767px) {
  .section_title_wrapperr {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_title_wrapperr {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
}

.showcase5__content img {
  margin-bottom: 35px;
}

.showcase5__content p {
  font-weight: 400;
  font-size: 32px;
  line-height: 1.3;
  text-align: center;
  color: var(--black-3);
  position: relative;
  padding-bottom: 190px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .showcase5__content p {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .showcase5__content p {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .showcase5__content p {
    font-size: 1.25rem;
    padding-bottom: 100px;
  }
}

.showcase5__content p::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100px;
  bottom: 0px;
  left: 50%;
  background-color: var(--black-2);
}

@media only screen and (max-width: 767px) {
  .showcase5__content p::after {
    height: 50px;
  }
}

.logos_wrapper li {
  margin-bottom: 10px;
}

.logos_wrapper li a {
  text-transform: capitalize;
  color: var(--black-2);
  font-weight: 500;
}

.logo17 {
  margin-bottom: 90px;
}

.hero__area-15 {
  padding: 0 30px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .hero__area-15 {
    padding: 0 15px;
  }
}

.hero__area-15::before {
  content: "";
  display: table;
}

.hero__area-15::after {
  content: "";
  display: table;
  clear: both;
}

.hero__area-15 .swiper-container {
  width: calc(100% - 160px);
  height: calc(100vh - 120px);
  float: left;
  -webkit-transition: opacity 0.6s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.6s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.6s ease, transform 0.3s ease;
  transition: opacity 0.6s ease, transform 0.3s ease,
    -webkit-transform 0.3s ease;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-15 .swiper-container {
    height: calc(100vh - 80px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-15 .swiper-container {
    width: calc(100% - 130px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-15 .swiper-container {
    height: calc(100vh - 60px);
    width: calc(100% - 90px);
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-15 .swiper-container {
    width: 100%;
    height: calc(100vh - 60px);
  }
}

.hero__area-15 .main-slider {
  padding-top: 160px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-15 .main-slider {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-15 .main-slider {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-15 .main-slider {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-15 .main-slider {
    padding-top: 120px;
  }
}

.hero__area-15 .main-slider .swiper-image {
  width: 100%;
  height: 100%;
}

.hero__area-15 .main-slider .swiper-pagination {
  bottom: 60px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-15 .main-slider .swiper-pagination {
    bottom: -40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-15 .main-slider .swiper-pagination {
    bottom: -40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-15 .main-slider .swiper-pagination {
    bottom: -40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-15 .main-slider .swiper-pagination {
    bottom: -40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-15 .main-slider .swiper-pagination {
    bottom: -40px;
  }
}

.hero__area-15 .main-slider .swiper-pagination-bullet {
  margin: 0 10px;
  width: 6px;
  height: 6px;
}

.hero__area-15 .main-slider .swiper-pagination-bullet-active {
  outline: 1px solid var(--black-2);
  background: var(--black-2);
  outline-offset: 6px;
}

.hero__area-15 .swiper-slide {
  overflow: hidden;
}

.hero__area-15 .swiper-slide.swiper-slide-active .gallary__thumb {
  background-color: transparent;
}

.hero__area-15 .swiper-slide.swiper-slide-active .gallary12__content {
  opacity: 1;
}

.hero__area-15 .gallary__thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: hue;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  padding: 10px 20px;
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.25rem;
  text-transform: uppercase;
}

.hero__area-15 .swiper-container.thumb-slider {
  width: 160px;
  overflow: hidden;
  height: calc(100vh - 220px);
  margin-top: 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-15 .swiper-container.thumb-slider {
    width: 130px;
    margin-top: 110px;
    height: calc(100vh - 180px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-15 .swiper-container.thumb-slider {
    width: 130px;
    margin-top: 100px;
    height: calc(100vh - 180px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-15 .swiper-container.thumb-slider {
    width: 90px;
    margin-top: 110px;
    height: calc(100vh - 180px);
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-15 .swiper-container.thumb-slider {
    width: 100%;
    margin-top: 30px;
    height: 200px;
    display: none;
  }
}

.hero__area-15 .swiper-container.thumb-slider .swiper-slide {
  cursor: pointer;
}

.hero__area-15 .gallary12 {
  -webkit-box-orient: unset;
  -webkit-box-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-15 .gallary12 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-15 .gallary12 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.showcase__top-16 {
  padding: 200px 30px 65px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .showcase__top-16 {
    padding: 160px 30px 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .showcase__top-16 {
    padding: 160px 30px 65px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .showcase__top-16 {
    padding: 160px 30px 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .showcase__top-16 {
    padding: 160px 30px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase__top-16 {
    padding: 140px 30px 40px;
  }
}

.showcase__top-16 .title-right {
  max-width: 296px;
  margin-left: auto;
  padding-top: 50px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .showcase__top-16 .title-right {
    padding-top: 40px;
  }
}

.showcase__top-16 .title-right p {
  font-size: 1.125rem;
}

.showcase__content-16 a {
  position: relative;
  display: inline-block;
}

.showcase__content-16 img {
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
}

.showcase__info-16 {
  position: absolute;
  bottom: 50px;
  left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .showcase__info-16 {
    bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase__info-16 {
    bottom: 20px;
    left: 20px;
  }
}

.showcase__title-16 {
  font-weight: 500;
  font-size: 80px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--white);
  padding-bottom: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .showcase__title-16 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .showcase__title-16 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .showcase__title-16 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase__title-16 {
    font-size: 30px;
    padding-bottom: 10px;
  }

  .showcase__title-16 br {
    display: block;
  }
}

.showcase__title-16 span {
  display: inline-block;
  text-indent: 100px;
  font-family: "newYork";
  position: relative;
}

@media only screen and (max-width: 767px) {
  .showcase__title-16 span {
    text-indent: 30px;
  }
}

.showcase__title-16 span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 2px;
  width: 70px;
  background-color: var(--white);
}

@media only screen and (max-width: 767px) {
  .showcase__title-16 span::before {
    width: 20px;
  }
}

.showcase__sub-title-16 {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: var(--white);
  padding-left: 100px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .showcase__sub-title-16 {
    padding-left: 30px;
  }
}

.showcase_info {
  display: block;
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 9999;
  background-color: var(--white);
  margin-bottom: -3px;
}

.showcase__info-16 {
  position: absolute;
  bottom: 30%;
  left: 50px;
}

.showcase__image-16 {
  overflow: hidden;
  height: 100vh;
}

.showcase__content-16 {
  position: relative;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.showcase__content-16 .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.wc-tilt {
  display: inline-block;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.wc-tilt-2 {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.dark .hero__area-10 .hero10_activition .swiper-pagination-current {
  color: var(--white);
}

.dark .hero__area-10 .hero10_activition .swiper-pagination-total {
  color: var(--white);
}

.dark .hero__area-10 .swiper-button-prev.hero_prev {
  color: var(--white);
}

.dark .hero__area-10 .swiper-button-prev.hero_prev:hover {
  color: var(--gray-3);
}

.dark .hero__area-10 .swiper-button-prev.hero_prev img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .hero__area-10 .swiper-button-next.hero_next {
  color: var(--white);
}

.dark .hero__area-10 .swiper-button-next.hero_next:hover {
  color: var(--gray-3);
}

.dark .hero__area-10 .swiper-button-next.hero_next img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .hero__area-10 .increase-dash {
  background-color: var(--white);
}

.dark .showcase4__content h2 {
  color: var(--white);
}

.dark .showcase4__content h2 span {
  color: var(--white);
}

.dark .showcase4__content span {
  color: var(--gray-2);
}

.dark .showcase5__content p {
  color: var(--gray-3);
}

.dark .showcase5__content p::after {
  background-color: var(--white);
}

.dark .showcase5__content img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .showcase_info {
  background-color: var(--black-2);
}

.dark .gallary12__content__title,
.dark .gallary12__content__name {
  color: var(--white);
}

.dark .gallary12__content img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .hero__area-15 .main-slider .swiper-pagination-bullet {
  background: var(--white);
  opacity: 0.5;
}

.dark .hero__area-15 .main-slider .swiper-pagination-bullet-active {
  outline: 1px solid var(--white);
  background: var(--white);
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .video7_layer .video-container video {
    height: 300px;
  }
}

.video_text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  max-width: 540px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  right: 100px;
  z-index: -2;
  bottom: 60px;
}

.video_text::before {
  content: "";
  position: absolute;
  bottom: -23%;
  left: 0;
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.video_text__left {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .video_text {
    top: 0;
    right: 0;
  }
}

.video_text .video_title_7 {
  font-size: 1.25rem;
  color: var(--white);
  font-family: "newYork";
}

@media only screen and (max-width: 767px) {
  .video_text .video_title_7 {
    font-size: 40px;
  }
}

.video_text .video_dis_7 {
  font-size: 1.125rem;
  color: var(--white);
  text-indent: 30px;
}

.nominated_title_7 {
  font-family: "newYork";
  font-weight: 400;
  font-size: 100px;
  color: var(--white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nominated_title_7 {
    font-size: 50px;
  }
}

.contactrow {
  padding-left: 450px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contactrow {
    padding-left: 0;
  }
}

.nominateparagraph {
  max-width: 440px;
  margin: auto;
}

.nominateparagraph p {
  font-size: 1.125rem;
  text-indent: 60px;
}

.overlay_text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 75px;
  left: 80px;
  z-index: 9;
  color: var(--white);
  font-size: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--white);
  padding-bottom: 30px;
}

.overlay_text h4 {
  margin-right: 30px;
}

.tech_title {
  font-weight: 400;
  font-size: 80px;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 45px;
  text-transform: uppercase;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tech_title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tech_title {
    font-size: 48px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tech_title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .tech_title {
    font-size: 30px;
    line-height: 1.3;
    padding-bottom: 30px;
  }
}

.tech_title a {
  color: var(--black-2);
  font-family: "newYork";
  position: relative;
}

.tech_title a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 10px;
  background-color: var(--black-2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tech_title a::after {
    bottom: 7px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tech_title a::after {
    bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .tech_title a::after {
    bottom: 3px;
  }
}

.tech_title span {
  font-family: "newYork";
}

.single_dis {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--black-3);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_dis {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_dis {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 767px) {
  .single_dis {
    font-size: 1.125rem;
  }
}

.single__thumb {
  position: relative;
}

.single__thumb img {
  max-width: 100%;
}

.single__thumb video {
  width: 100%;
}

.single__thumb .video-frame {
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  height: 100%;
  -webkit-filter: brightness(25);
  filter: brightness(25);
}

.single__thumb .shape-2 {
  position: absolute;
  bottom: -30px;
  left: 60%;
}

@media only screen and (max-width: 767px) {
  .single__thumb .shape-2 {
    bottom: -20px;
    max-width: 40px;
  }
}

.single__thumb .svgstar {
  position: absolute;
  left: 25%;
  top: 18%;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .single__thumb .svgstar {
    left: 24%;
    top: 15%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .single__thumb .svgstar {
    left: 23%;
    top: 15%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single__thumb .svgstar {
    max-width: 90px;
    left: 23%;
    top: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single__thumb .svgstar {
    max-width: 70px;
    left: 24%;
    top: 16%;
  }
}

@media only screen and (max-width: 767px) {
  .single__thumb .svgstar {
    max-width: 40px;
    left: 23%;
    top: 15%;
  }
}

.dark .tech_title,
.dark .tech_title a {
  color: var(--white);
}

.dark .tech_title a::after {
  background-color: var(--white);
}

.dark .single__thumb .shape-2,
.dark .single__thumb .svgstar {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .single__thumb .video-frame {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.client__area-8 {
  background-color: var(--black-2);
  background-image: url("../imgs/essential-img/bg-axtra.png?v=0.1.8");
  padding-top: 150px;
  padding-bottom: 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .client__area-8 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client__area-8 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .client__area-8 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.client__area-8 .section_wrapper::before {
  background-color: #313131;
}

.client__area-8 .about-sub-right {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 10px;
}

.client__area-8 .react_border {
  border-color: #313131;
}

.client__area-8 .react_border span {
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 1rem;
  color: var(--white);
  display: inline-block;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.client__section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  margin-bottom: 110px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .client__section {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
  }
}

.client__section__title {
  color: var(--white);
  font-size: 80px;
  line-height: 1;
  text-transform: uppercase;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .client__section__title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .client__section__title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client__section__title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .client__section__title {
    font-size: 40px;
  }
}

.client__section__title span {
  font-family: "newYork";
}

.client__section__dis {
  max-width: 240px;
  color: var(--gray-2);
  font-size: 1.125rem;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .client__section__dis {
    max-width: 100%;
  }
}

.client__logo {
  display: -ms-grid;
  display: grid;
  grid-gap: 120px 60px;
  -ms-grid-columns: 1fr 60px 1fr 60px 1fr 60px 1fr 60px 1fr 60px 1fr;
  grid-template-columns: repeat(6, 1fr);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .client__logo {
    grid-gap: 80px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client__logo {
    grid-gap: 60px 40px;
    -ms-grid-columns: 1fr 40px 1fr 40px 1fr 40px 1fr;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .client__logo {
    grid-gap: 50px;
    -ms-grid-columns: 1fr 50px 1fr 50px 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
}

.client__logo img {
  max-width: 100%;
}

.career__top .sec-title-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.career__top-title {
  background-color: var(--black-2);
  padding-bottom: 500px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .career__top-title {
    padding-bottom: 470px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .career__top-title {
    padding-bottom: 470px;
    padding-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .career__top-title {
    padding-bottom: 30px;
    padding-top: 80px;
  }
}

.career__top-title .btn-item {
  width: 200px;
  height: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .career__top-title .btn-item {
    width: 170px;
    height: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .career__top-title .btn-item {
    width: 170px;
    height: 170px;
  }
}

.career__top .sec-title-2 {
  color: var(--white);
}

@media only screen and (max-width: 767px) {
  .career__top .sec-title-2 {
    margin-bottom: 50px;
  }
}

.career__top-img {
  height: 800px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .career__top-img {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .career__top-img {
    height: 560px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .career__top-img {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .career__top-img {
    height: 480px;
  }
}

@media only screen and (max-width: 767px) {
  .career__top-img {
    height: auto;
  }
}

.career__top-img iframe,
.career__top-img video {
  width: 100%;
}

.career__thumb {
  margin-top: -500px;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .career__thumb {
    margin-top: -480px;
  }
}

@media only screen and (max-width: 767px) {
  .career__thumb {
    margin-top: -50px;
  }
}

.career__thumb .inherit {
  margin-right: -320px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .career__thumb .inherit {
    margin-right: -80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .career__thumb .inherit {
    margin-right: -130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .career__thumb .inherit {
    margin-right: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .career__thumb .inherit {
    margin-right: -40px;
  }
}

@media only screen and (max-width: 767px) {
  .career__thumb .inherit {
    margin-right: calc(-0.5 * var(--bs-gutter-x));
  }
}

.career__gallery {
  overflow: hidden;
}

.career__gallery ul {
  text-align: center;
  margin-bottom: 20px;
}

.career__gallery ul li {
  display: inline-block;
  font-weight: 400;
  font-size: 36px;
  line-height: 1;
  color: var(--black-2);
  position: relative;
  padding-right: 20px;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .career__gallery ul li {
    font-size: 1.5rem;
    padding-right: 10px;
    margin-right: 10px;
  }
}

.career__gallery ul li::after {
  position: absolute;
  content: "";
  width: 3px;
  height: 3px;
  right: 0;
  bottom: 10px;
  background-color: var(--black-2);
}

.career__gallery ul li:last-child::after {
  position: unset;
}

.career__gallery-img {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: self-start;
  -ms-flex-align: self-start;
  align-items: self-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 700px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .career__gallery-img {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .career__gallery-img {
    height: 580px;
  }
}

@media (max-width: 1200px) {
  .career__gallery-img {
    height: 100%;
  }
}

.career__gallery-img:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
}

@media only screen and (max-width: 767px) {
  .career__gallery-img:last-child {
    gap: 0;
  }
}

.career__gallery-img img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .career__gallery-img img {
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.career__gallery p {
  max-width: 850px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.3;
  color: var(--black-3);
  text-align: center;
  padding-bottom: 60px;
  position: relative;
  z-index: 3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .career__gallery p {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  .career__gallery p {
    font-size: 1.25rem;
  }
}

.career__benefits {
  overflow: hidden;
}

.career__benefits .sec-title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.career__benefits .sec-title-wrapper p {
  font-size: 1.125rem;
  line-height: 1.4;
  padding-right: 10px;
  padding-top: 40px;
}

@media only screen and (max-width: 767px) {
  .career__benefits .sec-title-wrapper p {
    padding-right: 0;
    padding-top: 30px;
  }
}

.career__benefits-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .career__benefits-list {
    margin-bottom: 70px;
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .career__benefits-list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 50px;
  }
}

.career__benefits-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 240px;
  height: 240px;
  text-align: center;
  border: 1px solid #c8c7c7;
  border-radius: 100%;
  margin-left: -30px;
  margin-top: -30px;
  font-weight: 400;
  font-size: 1.125rem;
  color: var(--black-2);
}

@media only screen and (min-width: 1920px) {
  .career__benefits-list li:nth-child(3n + 1) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .career__benefits-list li:nth-child(3n + 1) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .career__benefits-list li {
    width: 210px;
    height: 210px;
    margin-left: -20px;
    margin-top: -20px;
    padding: 20px;
  }

  .career__benefits-list li:nth-child(3n + 1) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .career__benefits-list li {
    width: 240px;
    height: 240px;
    margin-left: -20px;
    margin-top: -20px;
    padding: 20px;
  }

  .career__benefits-list li:nth-child(2n + 1) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .career__benefits-list li {
    width: 260px;
    height: 260px;
  }

  .career__benefits-list li:nth-child(3n + 1) {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .career__benefits-list li {
    width: 175px;
    height: 175px;
    margin-left: -10px;
    margin-top: -10px;
    padding: 20px;
    font-size: 1rem;
  }

  .career__benefits-list li:nth-child(2n + 1) {
    margin-left: 0;
  }
}

.job__area {
  background-color: var(--black-2);
  overflow: hidden;
}

.job__area .sec-title {
  color: var(--white);
}

.job__apply {
  margin-top: -20px;
}

@media (max-width: 1400px) {
  .job__apply {
    margin-top: 0;
  }
}

.job__list {
  padding-top: 60px;
}

.job__list a:first-child .job__item {
  border-top: 1px solid var(--secondary);
}

.job__item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 5fr 5fr 1fr;
  grid-template-columns: 1fr 5fr 5fr 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--secondary);
  padding: 35px 0;
}

@media only screen and (max-width: 767px) {
  .job__item {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    padding: 35px 0 20px;
  }
}

.job__item:hover .job__title {
  color: var(--white);
}

.job__item:hover .job__link i {
  -webkit-transform: rotate(-45deg) translate(60px, 2px);
  transform: rotate(-45deg) translate(60px, 2px);
  color: var(--white);
}

.job__item:hover .job__link span::after {
  right: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
  color: var(--white);
}

.job__no,
.job__open {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.3;
  color: var(--gray-3);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__no,
  .job__open {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  .job__no,
  .job__open {
    font-size: 1.125rem;
  }
}

.job__title {
  font-weight: 400;
  font-size: 36px;
  line-height: 1.5;
  color: var(--gray-3);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .job__title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__title {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .job__title {
    font-size: 1.5rem;
  }
}

.job__link {
  text-align: right;
  overflow: hidden;
  position: relative;
}

.job__link span::after {
  position: absolute;
  content: "\f061";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  right: 45px;
  bottom: -35px;
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.job__link i,
.job__link span {
  font-size: 30px;
  color: var(--gray-3);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.job__detail {
  overflow: hidden;
}

.job__detail-top {
  background-image: url(../imgs/career/6.jpg?v=0.1.8);
}
.job__detail-top-film-direction {
  background-image: url(../imgs/bg/film-direction.png?v=0.2.1);
}
.job__detail-top-acting {
  background-image: url(../imgs/bg/acting.jpg?v=0.2.1);
}
.job__detail-top-editing {
  background-image: url(../imgs/bg/editing.png?v=0.2.1);
}
.job__detail-top-sound {
  background-image: url(../imgs/bg/sound.png?v=0.2.1);
}
.job__detail-top-cinematography {
  background-image: url(../imgs/bg/cinematography.jpg?v=0.2.1);
}
.job__detail-top-vfx {
  background-image: url(../imgs/bg/vfx.jpg?v=0.2.1);
}

.job__detail-top img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1920px) {
  .job__detail-top {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .job__detail-top {
    padding-left: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    /* height: 499px; */
  }

  .job__detail-top img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .job__detail-top {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    position: unset;
  }

  .job__detail-top img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__detail-top {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    position: unset;
  }

  .job__detail-top img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .job__detail-top {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    position: unset;
    padding-top: 73px;
  }

  .job__detail-top img {
    position: absolute;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.job__detail-top img {
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .job__detail-top img {
    position: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__detail-top img {
    position: unset;
  }
}

@media only screen and (max-width: 767px) {
  .job__detail-top img {
    position: unset;
  }
}

.job__detail-top .job__heading {
  color: var(--white);
  position: absolute;
  bottom: 18%;
  right: 20%;
}

.job__detail-top .job__heading h1 {
  font-size: 90px;
}

.job__detail-top .job__heading h2 {
  font-size: 60px;
}

.job__detail-wrapper {
  padding-top: 30px;
  padding-right: 120px;
}
.job__detail-wrapper-20 {
  padding-top: 30px;
  padding-right: 120px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .job__detail-wrapper {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .job__detail-wrapper {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__detail-wrapper {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .job__detail-wrapper {
    padding-right: 0;
  }
}

.job__detail-meta {
  padding-top: 35px;
  padding-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__detail-meta {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .job__detail-meta {
    padding-bottom: 20px;
  }
}

.job__detail-meta li {
  display: inline-block;
  padding-right: 70px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black-3);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__detail-meta li {
    display: block;
    padding-right: 0;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .job__detail-meta li {
    display: block;
    padding-right: 0;
    padding-bottom: 20px;
  }
}

.job__detail-meta li span {
  color: var(--gray-8);
  font-size: 14px;
  font-weight: 400;
  display: block;
}

/* .job__detail-content {
  border-top: 1px solid var(--white-4);
  padding-top: 35px;
} */

.job__detail-content p {
  font-size: 1.125rem;
  line-height: 1.4;
  padding-bottom: 8px;
}

.job__detail-content h1,
.job__detail-content h2 {
  padding-top: 22px !important;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 15px;
}

.job__detail-content h3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 20px;
}

.job__detail-content h4 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 20px;
}

.job__detail-content h5,
.job__detail-content h6 {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1;
  color: var(--black-2);
  padding-bottom: 20px;
}

.job__detail-content ul,
.job__detail-content ol {
  padding-bottom: 30px;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  .job__detail-content ul,
  .job__detail-content ol {
    padding-left: 20px;
    padding-bottom: 30px;
  }
}

.job__detail-content ul li,
.job__detail-content ol li {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-3);
  /* padding-bottom: 5px; */
  position: relative;
}

.job__detail-content ul li::before,
.job__detail-content ol li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  left: -18px;
  top: 12px;
  background-color: var(--white);
  border-radius: 30px;
}

.job__detail-sidebar {
  margin-top: 70px;
  background: #f9f9f9;
  padding: 40px 30px;
  position: sticky;
  top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__detail-sidebar {
    padding: 30px 20px;
  }
}

.job__detail-sidebar ul li {
  display: block;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black-3);
  padding-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__detail-sidebar ul li {
    font-size: 14px;
  }
}

.job__detail-sidebar ul li:last-child {
  padding-bottom: 0;
}

.job__detail-sidebar ul li span {
  color: var(--gray-8);
  font-size: 14px;
  font-weight: 400;
  display: block;
}

.feature__area-2 {
  background-color: var(--pink);
}

.feature__top {
  padding-bottom: 65px;
  border-bottom: 1px solid var(--black);
}

@media only screen and (max-width: 767px) {
  .feature__top {
    border-bottom: none;
  }
}

@media only screen and (max-width: 767px) {
  .feature__btm {
    padding-bottom: 15px;
  }
}

.feature__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding-left: 85px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .feature__text {
    padding-left: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__text {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__text {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .feature__text {
    padding-left: 0;
    padding-top: 30px;
  }
}

.feature__text p {
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-2);
}

.feature__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 15px 1fr 15px 1fr 15px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__list {
    -ms-grid-columns: 1fr 0 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
}

@media only screen and (max-width: 767px) {
  .feature__list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.feature__item {
  border-right: 1px solid var(--black-2);
  padding: 145px 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .feature__item {
    padding: 90px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__item {
    padding: 90px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__item {
    padding: 50px 30px;
    border-right: none;
  }
}

@media only screen and (max-width: 767px) {
  .feature__item {
    border-right: none;
    padding: 0 0 50px 0;
  }
}

.feature__item:first-child {
  padding-left: 0;
}

.feature__item:last-child {
  border-right: 0;
  padding-right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__item:nth-child(odd) {
    border-right: 1px solid var(--black-2);
  }
}

.feature__item img {
  margin-bottom: 35px;
}

.feature__item p {
  color: var(--black-2);
}

.feature__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.4;
  color: var(--black-2);
  padding-bottom: 12px;
}

.contact__area-6 {
  overflow: hidden;
  background: url(../imgs/bg/2.png?v=0.1.8);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__area-6 .line {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .contact__area-6 .line {
    padding-top: 130px;
  }
}

.contact__text {
  max-width: 330px;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .contact__text {
    padding-top: 30px;
  }
}

.contact__text p {
  font-size: 1.125rem;
  line-height: 1.4;
}

.contact__btm {
  padding-top: 65px;
}

@media only screen and (max-width: 767px) {
  .contact__btm {
    padding-top: 50px;
  }
}

.contact__info {
  padding-top: 15px;
}

@media only screen and (max-width: 767px) {
  .contact__info {
    padding-bottom: 50px;
  }
}

.contact__info h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.2;
  color: var(--black-2);
  padding-bottom: 30px;
}

.contact__info ul li a {
  text-decoration-line: underline;
}

.contact__info ul li a,
.contact__info ul li span {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-2);
  display: block;
  margin-bottom: 10px;
}

.contact__form {
  position: relative;
  z-index: 9;
}

.contact__form input,
.contact__form textarea,
.contact__form select {
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black-2);
  border: none;
  border-bottom: 1px solid var(--gray);
  padding: 15px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: 0;
  position: relative;
  z-index: 1;
  background: transparent;
  margin-bottom: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__form input,
  .contact__form textarea,
  .contact__form select {
    padding: 10px 0;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__form input,
  .contact__form textarea,
  .contact__form select {
    padding: 10px 0;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .contact__form input,
  .contact__form textarea,
  .contact__form select {
    margin-bottom: 20px;
  }
}

.contact__form input:focus,
.contact__form textarea:focus,
.contact__form select:focus {
  border-bottom: 1px solid var(--black-2);
}

.contact__form input::-webkit-input-placeholder,
.contact__form textarea::-webkit-input-placeholder,
.contact__form select::-webkit-input-placeholder {
  color: var(--black-2);
  opacity: 1;
}

.contact__form input::-moz-placeholder,
.contact__form textarea::-moz-placeholder,
.contact__form select::-moz-placeholder {
  color: var(--black-2);
  opacity: 1;
}

.contact__form input:-ms-input-placeholder,
.contact__form textarea:-ms-input-placeholder,
.contact__form select:-ms-input-placeholder {
  color: var(--black-2);
  opacity: 1;
}

.contact__form input::-ms-input-placeholder,
.contact__form textarea::-ms-input-placeholder,
.contact__form select::-ms-input-placeholder {
  color: var(--black-2);
  opacity: 1;
}

.contact__form input::placeholder,
.contact__form textarea::placeholder,
.contact__form select::placeholder {
  color: var(--black-2);
  opacity: 1;
}

.contact__form textarea {
  height: 55px;
}

.contact__form #btn_wrapper,
.contact__form .btn_wrapper {
  margin-top: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__form #btn_wrapper,
  .contact__form .btn_wrapper {
    margin-top: -10px;
  }
}

@media (max-width: 1200px) {
  .contact__form #btn_wrapper,
  .contact__form .btn_wrapper {
    margin-top: 10px;
  }
}

section.contact-us-area {
  background: url(../imgs/bg/3.jpg?v=0.1.8);
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 0 30px 0;
}

.ha-text-block {
  color: var(--theme-text-color);
}

.ha-logo {
  width: 30%;
  margin-bottom: 2rem;
}

.ha-text-block h5 {
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: var(--theme-primary-color);
}

.ha-text-block h1 {
  font-weight: 800;
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
}

.ha-text-block h1 span {
  color: var(--theme-primary-color);
}

.ha-text-block p {
  font-weight: 400;
}

.ha-enquiry-block-inner {
  background: var(--dark-theme);
  padding: 3.5rem;
  -webkit-box-shadow: 5px 5px 10px rgb(112 60 25/12%);
  box-shadow: 5px 5px 10px rgb(112 60 25/12%);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2.5rem;
  -webkit-border-radius: 2.5rem;
  -moz-border-radius: 2.5rem;
  -ms-border-radius: 2.5rem;
  -o-border-radius: 2.5rem;
}

@media (max-width: 450px) {
  .ha-enquiry-block-inner {
    padding: 3.5rem 1rem;
  }
}

.ha-enquiry-block-logo {
  margin-bottom: 1.5rem;
}

.logo {
  width: 32% !important;
  padding-bottom: 2rem;
}

.ha-enquiry-block-logo img {
  width: 40%;
}

.ha-enquiry-block-heading {
  font-weight: 700;
  color: var(--white);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 1368px) {
  .ha-enquiry-block-heading {
    font-size: 1.25rem;
  }
}

.ha-enquiry-block-subheading {
  font-size: 1rem;
  color: var(--theme-text-color);
  margin-bottom: 1.5rem;
}

#formResponse .alert {
  border-radius: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.ef-auto-location {
  font-size: 0.8rem;
  background: #ffeadc;
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
}

.ef-auto-location span {
  background: #d8b198;
  padding: 0.1rem 0.5rem;
}

.ef-auto-location a {
  color: var(--theme-primary-color);
}

.ef-auto-location ion-icon {
  vertical-align: middle;
  color: #4b1e00;
}

.ha-enquiry-form .form-floating > .form-control,
.ha-enquiry-form .form-floating > .form-select {
  color: var(--white);
  padding-left: 1.5rem;
  border-color: #fff;
  background: transparent;
  border-radius: 2.5rem;
  -webkit-border-radius: 2.5rem;
  -moz-border-radius: 2.5rem;
  -ms-border-radius: 2.5rem;
  -o-border-radius: 2.5rem;
}

.ha-enquiry-form .form-floating > label {
  padding: 0.4rem 1.5rem;
  color: var(--white);
}

.ha-enquiry-form .form-floating > .form-control,
.ha-enquiry-form .form-floating > .form-select {
  height: calc(2.5rem + 2px);
}

.ha-enquiry-form .form-floating > .form-select {
  background: var(--dark-theme);

  padding-top: 0.625rem;
}

.ha-enquiry-form .form-floating > .form-select > option {
  border: var(--dark-theme2);
}

.ha-enquiry-form .form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 0.8rem;
  padding-bottom: 0.625rem;
  color: var(--white);
}

.ha-enquiry-form .form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 0.8rem;
  padding-bottom: 0.625rem;
  color: var(--theme-text-color);
}

.ha-enquiry-form .form-floating > .form-control:focus,
.ha-enquiry-form .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 0.8rem;
  padding-bottom: 0.625rem;
  color: #fff;
}

.ha-enquiry-form
  .form-floating
  > .form-control:not(:-moz-placeholder-shown)
  ~ label {
  opacity: 1;
  margin-top: -4px;
  background: var(--theme-text-color-1);
  padding: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 1rem;
  height: auto;
  color: var(--theme-text-color);
}

.ha-enquiry-form
  .form-floating
  > .form-control:not(:-ms-input-placeholder)
  ~ label {
  opacity: 1;
  margin-top: -4px;
  background: var(--theme-text-color-1);
  padding: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 1rem;
  height: auto;
  color: var(--theme-text-color);
}

.ha-enquiry-form .form-floating > .form-control:focus ~ label,
.ha-enquiry-form .form-floating > .form-control:not(:placeholder-shown) ~ label,
.ha-enquiry-form .form-floating > .form-select ~ label {
  border-radius: 13px;
  opacity: 1;
  margin-top: -4px;
  background: var(--dark-theme);
  padding: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 1rem;
  height: auto;
  color: var(--gray-2);
}

.ha-enquiry-form .form-control:focus,
.ha-enquiry-form .form-select:focus {
  -webkit-box-shadow: 2px 2px 10px 0 rgba(25, 95, 112, 0.2);
  box-shadow: 2px 2px 10px 0 rgba(25, 99, 112, 0.2);
}

.ha-enquiry-form .btn {
  padding: 0.6rem 0;
}

.tnc-checkbox label {
  font-size: 0.8rem;
  color: var(--gray-2);
}

.captcha-keyword {
  text-align: center;
  background: #fff;
  border-radius: 1rem;
  padding: 0.5rem;
  color: var(--theme-text-color-1);
}

.form-arithmetic-captcha-label {
  font-size: 0.9rem;
  font-weight: 600;
  color: #fff;
}
.form-arithmetic-captcha-label span {
  color: #fff;
}

.form-arithmetic-captcha-label span {
  color: #2b2b2b;
  font-weight: 400;
}

section.institute-overview {
  padding: 4rem 0 0;
  z-index: 99;
}

.io-text h6 {
  font-size: 1.2rem;
}

.io-text h3 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--theme-primary-color);
  margin-bottom: 1.5rem;
}

.io-text p {
  color: var(--theme-text-color);
}

.io-text a {
  padding: 0.8rem 4rem;
  margin-top: 1.5rem;
  box-shadow: 0 64.8148px 46.8519px rgba(24, 167, 185, 0.0759259),
    0 38.5185px 25.4815px rgba(24, 167, 185, 0.0607407),
    0 20px 13px rgba(24, 167, 185, 0.05),
    0 8.14815px 6.51852px rgba(24, 167, 185, 0.0392593),
    0 1.85185px 3.14815px rgba(24, 167, 185, 0.0240741);
}

.price__area {
  background-color: var(--pink-3);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .price__area.pb-140 {
    padding-bottom: 100px;
  }
}

.price__area .sec-title {
  text-transform: uppercase;
  padding-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .price__area .sec-title {
    padding-bottom: 40px;
  }
}

.price__area .sec-sub-title {
  text-transform: uppercase;
}

.price__table {
  margin-top: 80px;
}

.price__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
  background: var(--white);
  padding: 40px 30px;
  margin-bottom: 20px;
  border-radius: 9px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .price__item {
    gap: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .price__item {
    gap: 30px;
  }
}

.price__item:first-child {
  background-color: var(--black-2);
}

.price__item:first-child .price__icon span {
  color: var(--black-2);
  border-color: var(--pink-2);
  background-color: var(--pink-2);
}

.price__item:first-child .price__type {
  color: var(--gray-2);
  border: 1px solid var(--gray-14);
}

.price__item:first-child .price__title {
  color: var(--white);
}

.price__item:first-child .price__info p {
  color: var(--gray-2);
}

.price__item:first-child .price__amount p {
  color: var(--white);
}

.price__item .best-value {
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  color: var(--white);
  text-transform: uppercase;
  padding: 8px 12px;
  border-radius: 5px;
  background-color: var(--pink-2);
  position: absolute;
  top: -15px;
}

.price__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.price__icon span {
  display: block;
  width: 40px;
  height: 40px;
  color: var(--gray-2);
  border-radius: 5px;
  border: 2px solid var(--gray-13);
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.price__icon span i {
  font-size: 1.25rem;
}

.price__type {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--black-6);
  border: 1px solid var(--black-6);
  display: inline-block;
  padding: 5px 12px;
  border-radius: 30px;
  margin-bottom: 15px;
}

.price__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black-2);
  padding-bottom: 5px;
}

.price__info p {
  color: var(--black-6);
}

@media only screen and (max-width: 767px) {
  .price__amount {
    position: absolute;
    top: 0;
    right: 30px;
    padding-top: 30px;
  }
}

.price__amount p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  font-weight: 400;
  font-size: 36px;
  line-height: 1.3;
  color: var(--black-2);
}

.price__amount p span {
  font-size: 1rem;
}

.price__btn {
  padding-top: 50px;
}

@media only screen and (max-width: 767px) {
  .price__btn {
    padding-top: 0;
    margin-top: 40px;
  }
}

.price__btn a {
  margin: 0 auto;
  border-color: var(--black-2);
}

.error__content {
  padding-top: 80px;
  height: 100vh;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error__content {
    padding-top: 100px;
  }
}

.error__content::before {
  position: absolute;
  content: "";
  width: 38px;
  height: 38px;
  top: 70%;
  left: 10%;
  background-image: url(../imgs/icon/6.png?v=0.1.8);
  background-repeat: no-repeat;
  -webkit-animation: wcZoom 2s infinite;
  animation: wcZoom 2s infinite;
}

.error__content::after {
  position: absolute;
  content: "";
  width: 38px;
  height: 38px;
  top: 10%;
  right: 10%;
  /* background-image: url(../imgs/icon/3.png?v=0.1.8); */
  background-repeat: no-repeat;
  -webkit-animation: wcZoom 2s infinite;
  animation: wcZoom 2s infinite;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error__content::after {
    top: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .error__content::after {
    top: 15%;
  }
}

.error__content h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.5;
  color: var(--black-2);
  padding-top: 25px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .error__content h2 {
    font-size: 30px;
    line-height: 1.3;
  }
}

.error__content p {
  max-width: 340px;
  margin: 0 auto;
  font-size: 1.125rem;
  line-height: 1.4;
  padding-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error__content p {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .error__content p {
    padding-bottom: 30px;
  }
}

.error__content a {
  margin: 0 auto;
}

.error__content img {
  max-width: 100%;
}

.error__content #btn_wrapper,
.error__content .btn_wrapper {
  margin-left: 0;
  margin-top: -40px;
}

@media (max-width: 1400px) {
  .error__content #btn_wrapper,
  .error__content .btn_wrapper {
    margin-top: 0;
  }
}

:root {
  --white-6: #d7d7d7;
  --white-7: #b7b7c5;
  --black-14: #323438;
  --lime: #745eff;
  --lime-2: #5547f5;
  --gray-16: #6b6b6b;
  --gray-17: #f7f7fc;
  --gray-18: #999999;
  --gray-20: #d7d7d7;
  --gray-21: #f2f2f8;
  --gray-22: #fcfcfe;
  --gray-23: #ececf3;
  --gray-24: #ececf4;
  --gray-25: #d3d3d3;
}

.btn-together {
  font-weight: 400;
  line-height: 1.3;
  color: var(--white);
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 1.125rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .btn-together {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-together {
    font-size: 15px;
  }
}

.btn-together i {
  padding-left: 10px;
}

.btn-together::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--white);
  content: "";
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.btn-together:hover::after {
  left: 0;
  width: 1%;
}

.btn-together:hover {
  color: var(--gray);
}

.header__inner-5 {
  padding: 25px 50px 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .header__inner-5 {
    padding: 20px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__inner-5 {
    padding: 25px 15px 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__inner-5 {
    padding: 25px 15px 22px;
  }
}

.header__area-5 {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}

.header__area-5.sticky-5 {
  background-color: var(--white);
  border-bottom: 1px solid var(--white-2);
}

.header__area-5.sticky-5 .header__inner-5 {
  padding: 20px 50px;
}

@media (max-width: 1200px) {
  .header__area-5.sticky-5 .header__inner-5 {
    padding: 20px 15px;
  }
}

.menu-text-5 {
  color: var(--black-2);
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  padding-right: 10px;
}

.menu-text-pp {
  color: var(--black-2);
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  padding-right: 10px;
}

.hero__area-5 {
  padding: 190px 35px 113px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .hero__area-5 {
    padding: 135px 0px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-5 {
    padding: 145px 0px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-5 {
    padding: 155px 0px 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-5 {
    padding: 166px 0px 80px;
    background-size: 90%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__area-5 {
    padding: 185px 0px 80px;
    background-size: 90%;
  }
}

.hero__area-5 img {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-filter: invert(1);
  filter: invert(1);
  z-index: 0;
}

.hero__content-5 p {
  font-size: 1.125rem;
  color: var(--black-2);
  font-weight: 400;
  line-height: 1.4;
  max-width: 330px;
}

@media only screen and (max-width: 767px) {
  .hero__content-5 p {
    font-size: 1rem;
  }
}

.hero__text-5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-top: 25px;
}

.hero__title-5 {
  font-size: 80px;
  font-weight: 500;
  line-height: 1;
  color: var(--black-2);
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .hero__title-5 {
    font-size: 30px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title-5 {
    font-size: 36px;
    line-height: 1.2;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title-5 {
    font-size: 40px;
    line-height: 1.4;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title-5 {
    font-size: 45px;
    line-height: 1.4;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__title-5 {
    font-size: 50px;
    line-height: 1.4;
  }
}

.portfolio__area-5 {
  /* border-top: 1px solid var(--gray-25); */
  /* border-bottom: 1px solid var(--gray-25); */
  padding: 30px 0 30px 0;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .portfolio__area-5 {
    padding: 30px 0 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-5 {
    padding: 30px 0 30px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-5 {
    padding: 30px 0 30px 0;
  }
}

/* .portfolio__area-5::before {
  position: absolute;
  left: 33.3%;
  top: 0;
  width: 1px;
  height: 100%;
  content: "";
  background-color: var(--dark-theme2);
  -webkit-filter: invert(1);
  filter: invert(1);
} */

/* .portfolio__area-5::after {
  position: absolute;
  left: 66.6%;
  top: 0;
  width: 1px;
  height: 100%;
  content: "";
  background-color: var(--dark-theme2);
  -webkit-filter: invert(1);
  filter: invert(1);
} */

/* .portfolio__area-5 .sec-line {
  position: absolute;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 1px;
  content: "";
  background-color: var(--secondary);
  -webkit-filter: invert(1);
  filter: invert(1);
} */

.portfolio__inner-5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /* justify-content: space-between; */
  gap: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* @media only screen and (max-width: 767px) {
  .portfolio__inner-5 {
    gap: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__inner-5 {
    gap: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__inner-5 {
    gap: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__inner-5 {
    gap: 70px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__inner-5 {
    gap: 80px;
  }
} */

.portfolio__item-5 {
  width: 29%;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 767px) {
  .portfolio__item-5 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__item-5 {
    width: 48.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__item-5 {
    width: 48%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__item-5 {
    width: 32%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__item-5 {
    width: 32%;
  }
}

.portfolio__item-5 h2 {
  color: var(--gray-2);
}

.portfolio__item-5:hover h2 {
  color: var(--white);
}

.portfolio__item-5:hover .portfolio__content-5 {
  /* border-radius: 1rem; */
  opacity: 1;
  right: -50px;
}

.portfolio__item-5 img {
  /* border-radius: 1rem; */
  width: 80%;
  margin-bottom: 0.3rem;
  max-width: 100%;
}

.portfolio__content-5-bottom {
  bottom: 100% !important;
}
.portfolio__content-5 {
  text-align: start;
  min-width: 22rem;
  /* border-radius: 1rem; */
  background-color: var(--dark-theme2);
  padding: 20px 30px 22px 30px;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 65%;
  z-index: 9;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  transform: translate(-30%, 50%);
}

/* @media only screen and (max-width: 1368px) {
  .portfolio__content-5 {
    margin-top: 2rem;
    opacity: 1;
    position: unset;
    transform: none;
  }
} */

@media only screen and (max-width: 767px) {
  .portfolio__content-5 {
    right: 0;
    /* opacity: 1; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__content-5 {
    right: 0;
    /* opacity: 1; */
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__content-5 {
    right: -70px;
  }
}

@media (max-width: 450px) {
  .portfolio__content-5 {
    min-width: 19rem;
  }
}

@media (max-width: 280px) {
  .portfolio__content-5 {
    position: unset;
    margin-top: 0.5rem;
    opacity: 1;
    min-width: 16rem;
    transform: none;
  }
}

.portfolio__name-5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  padding-bottom: 2px;
  color: var(--white);
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .portfolio__name-5 {
    font-size: 12px;
  }
}

.portfolio__title-5 {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--white);
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .portfolio__title-5 {
    font-size: 1.125rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__title-5 {
    font-size: 1.125rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__title-5 {
    font-size: 1.25rem;
  }
}

.cta__area-5 {
  position: relative;
  border-bottom: 1px solid var(--secondary);
  -webkit-filter: invert(1);
  filter: invert(1);
}

.cta__area-5::before {
  position: absolute;
  top: 0;
  left: 57%;
  width: 1px;
  height: 100%;
  content: "";
  background-color: var(--secondary);
}

.cta__inner-5 {
  max-width: 1410px;
  margin: 0 auto;
}

.cta__title-5 {
  font-size: 60px;
  color: var(--white);
  font-weight: 500;
  line-height: 1.2;
  padding-bottom: 28px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .cta__title-5 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__title-5 {
    font-size: 36px;
  }

  .cta__title-5 br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__title-5 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__title-5 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .cta__title-5 {
    font-size: 50px;
  }
}

.cta__list-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 230px 30px auto;
  grid-template-columns: 230px auto;
  gap: 30px;
}

@media only screen and (max-width: 767px) {
  .cta__list-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__list-wrapper {
    -ms-grid-columns: 180px 20px auto;
    grid-template-columns: 180px auto;
    gap: 20px;
  }
}

.cta__list-left {
  padding-left: 15px;
}

.cta__list-left li {
  position: relative;
}

.cta__list-left li::before {
  position: absolute;
  top: 0;
  left: -15px;
  width: auto;
  height: auto;
  content: "+";
}

.cta__item-5 h3 {
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--white);
  padding-bottom: 10px;
  text-transform: uppercase;
}

.cta__item-5 li {
  font-size: 1.125rem;
  color: var(--gray-2);
  line-height: 1.5;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .cta__item-5 li {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__item-5 li {
    font-size: 1rem;
  }
}

.cta__btn-5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .cta__btn-5 {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.footer__area-5 {
  padding: 79px 0px 72px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__area-5 {
    padding: 60px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__area-5 {
    padding: 50px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__area-5 {
    padding: 50px 0px;
  }
}

.footer__menu-5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .footer__menu-5 {
    gap: 25px;
  }
}

.footer__menu-5 li a {
  color: var(--gray-2);
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.footer__menu-5 li a .menu-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--black-2);
}

.footer__menu-5 li a .menu-text span {
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-style: normal;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translateY(var(--m)) translateZ(0);
  transform: translateY(var(--m)) translateZ(0);
}

.footer__menu-5 li a .menu-text span:nth-child(1) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.footer__menu-5 li a .menu-text span:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.footer__menu-5 li a .menu-text span:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.footer__menu-5 li a .menu-text span:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.footer__menu-5 li a .menu-text span:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.footer__menu-5 li a .menu-text span:nth-child(6) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.footer__menu-5 li a .menu-text span:nth-child(7) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.footer__menu-5 li a .menu-text span:nth-child(8) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.footer__menu-5 li a .menu-text span:nth-child(9) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.footer__menu-5 li a .menu-text span:nth-child(10) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.footer__menu-5 li a .menu-text span:nth-child(11) {
  -webkit-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

.footer__menu-5 li a .menu-text span:nth-child(12) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.footer__menu-5 li a .menu-text span:nth-child(13) {
  -webkit-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.footer__menu-5 li a .menu-text span:nth-child(14) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.footer__menu-5 li a .menu-text span:nth-child(15) {
  -webkit-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.footer__menu-5 li a .menu-text span:nth-child(16) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.footer__menu-5 li a .menu-text span:nth-child(17) {
  -webkit-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

.footer__menu-5 li a .menu-text span:nth-child(18) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.footer__menu-5 li a .menu-text span:nth-child(19) {
  -webkit-transition-delay: 0.95s;
  transition-delay: 0.95s;
}

.footer__menu-5 li a .menu-text span:nth-child(20) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.footer__menu-5 li a .menu-text span:nth-child(21) {
  -webkit-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

.footer__menu-5 li a .menu-text span:nth-child(22) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.footer__menu-5 li a .menu-text span:nth-child(23) {
  -webkit-transition-delay: 1.15s;
  transition-delay: 1.15s;
}

.footer__menu-5 li a .menu-text span:nth-child(24) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.footer__menu-5 li a .menu-text span:nth-child(25) {
  -webkit-transition-delay: 1.25s;
  transition-delay: 1.25s;
}

.footer__menu-5 li a .menu-text span:nth-child(26) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.footer__menu-5 li a .menu-text span:nth-child(27) {
  -webkit-transition-delay: 1.35s;
  transition-delay: 1.35s;
}

.footer__menu-5 li a .menu-text span:nth-child(28) {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.footer__menu-5 li a .menu-text span:nth-child(29) {
  -webkit-transition-delay: 1.45s;
  transition-delay: 1.45s;
}

.footer__menu-5 li a .menu-text span:nth-child(30) {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.footer__menu-5 li a .menu-text span:nth-child(31) {
  -webkit-transition-delay: 1.55s;
  transition-delay: 1.55s;
}

.footer__menu-5 li a .menu-text span:nth-child(32) {
  -webkit-transition-delay: 1.6s;
  transition-delay: 1.6s;
}

.footer__menu-5 li a .menu-text span:nth-child(33) {
  -webkit-transition-delay: 1.65s;
  transition-delay: 1.65s;
}

.footer__menu-5 li a .menu-text span:nth-child(34) {
  -webkit-transition-delay: 1.7s;
  transition-delay: 1.7s;
}

.footer__menu-5 li a .menu-text span:nth-child(35) {
  -webkit-transition-delay: 1.75s;
  transition-delay: 1.75s;
}

.footer__menu-5 li a .menu-text span:nth-child(36) {
  -webkit-transition-delay: 1.8s;
  transition-delay: 1.8s;
}

.footer__menu-5 li a .menu-text span:nth-child(37) {
  -webkit-transition-delay: 1.85s;
  transition-delay: 1.85s;
}

.footer__menu-5 li a .menu-text span:nth-child(38) {
  -webkit-transition-delay: 1.9s;
  transition-delay: 1.9s;
}

.footer__menu-5 li a .menu-text span:nth-child(39) {
  -webkit-transition-delay: 1.95s;
  transition-delay: 1.95s;
}

.footer__menu-5 li a .menu-text span:nth-child(40) {
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}

.footer__menu-5 li a .menu-text span:nth-child(41) {
  -webkit-transition-delay: 2.05s;
  transition-delay: 2.05s;
}

.footer__menu-5 li a .menu-text span:nth-child(42) {
  -webkit-transition-delay: 2.1s;
  transition-delay: 2.1s;
}

.footer__menu-5 li a .menu-text span:nth-child(43) {
  -webkit-transition-delay: 2.15s;
  transition-delay: 2.15s;
}

.footer__menu-5 li a .menu-text span:nth-child(44) {
  -webkit-transition-delay: 2.2s;
  transition-delay: 2.2s;
}

.footer__menu-5 li a .menu-text span:nth-child(45) {
  -webkit-transition-delay: 2.25s;
  transition-delay: 2.25s;
}

.footer__menu-5 li a .menu-text span:nth-child(46) {
  -webkit-transition-delay: 2.3s;
  transition-delay: 2.3s;
}

.footer__menu-5 li a .menu-text span:nth-child(47) {
  -webkit-transition-delay: 2.35s;
  transition-delay: 2.35s;
}

.footer__menu-5 li a .menu-text span:nth-child(48) {
  -webkit-transition-delay: 2.4s;
  transition-delay: 2.4s;
}

.footer__menu-5 li a .menu-text span:nth-child(49) {
  -webkit-transition-delay: 2.45s;
  transition-delay: 2.45s;
}

.footer__menu-5 li a:hover {
  --y: -4px;
}

.footer__menu-5 li a:hover span {
  --m: calc(16px * -1);
}

.footer__copyright-4 {
  color: var(--gray-2);
  font-size: 1rem;
  font-weight: 400;
}

.footer__copyright-4 a {
  color: var(--black-2);
}

.footer__copyright-4 a:hover {
  color: var(--gray);
}

.footer__area {
  overflow: hidden;
}

.footer__area-2 {
  background-image: url(../imgs/shape/4.png?v=0.1.8);
  background-repeat: no-repeat;
  background-position: top right;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__area-2 {
    background-size: 380px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__area-2 {
    background-size: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__area-2 {
    background-size: 200px;
  }
}

.footer__area-3 {
  background-color: var(--black-2);
}

.footer__row {
  margin-left: -500px;
}

@media only screen and (max-width: 767px) {
  .footer__row {
    margin-left: 0;
    margin-right: 0;
  }
}

.footer__top {
  text-align: right;
}

.footer__top img {
  margin-bottom: -380px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__top img {
    margin-bottom: -300px;
    width: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__top img {
    margin-bottom: -250px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer__top img {
    margin-bottom: 0;
    width: 100%;
  }
}

@media (max-width: 720px) {
  .footer__top img {
    display: none;
  }
}

.footer__top-2 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .footer__top-2 {
    padding-bottom: 50px;
  }
}

.footer__top-3 {
  border-bottom: 1px solid var(--black-6);
}

.footer__top-wrapper-3 {
  padding: 0 50px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 30% 10% auto;
  grid-template-columns: 30% 10% auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__top-wrapper-3 {
    -ms-grid-columns: 35% 20px 15% 20px auto;
    grid-template-columns: 35% 15% auto;
    gap: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__top-wrapper-3 {
    -ms-grid-columns: 35% 20px 20% 20px auto;
    grid-template-columns: 35% 20% auto;
    gap: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__top-wrapper-3 {
    -ms-grid-columns: 35% 25% auto;
    grid-template-columns: 35% 25% auto;
  }
}

@media only screen and (max-width: 767px) {
  .footer__top-wrapper-3 {
    padding: 0 15px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.footer__middle-2 {
  border-top: 1px solid var(--white-2);
  border-bottom: 1px solid var(--white-2);
  padding: 95px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__middle-2 {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__middle-2 {
    padding: 50px 0;
  }
}

.footer__location-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__location-2 {
    gap: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__location-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__location-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
  }
}

.footer__location-2 .location h3 {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.5;
  color: var(--black-2);
  padding-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__btm {
    padding-left: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__btm {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__btm {
    background-color: #141f3d;
  }

  .footer__btm .container {
    /* padding: 0; */
    background-color: #141f3d;
  }
}

.footer__btm-2 {
  padding: 50px 0;
}

@media only screen and (max-width: 767px) {
  .footer__btm-2 {
    padding: 30px 0;
  }

  .footer__btm-2 .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.footer__btm-3 {
  padding: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__btm-3 {
    padding: 40px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__btm-3 {
    padding: 40px 15px;
  }

  .footer__btm-3 .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .footer__btm-3 {
    padding: 30px 10px;
  }

  .footer__btm-3 .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.footer__inner {
  background-color: var(--black-2);
  display: -ms-grid;
  display: grid;
  /* -ms-grid-columns: (1fr) [12]; */
  grid-template-columns: repeat(12, 1fr);
  padding-left: 350px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer__inner {
    padding-left: 512px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__inner {
    padding-left: 450px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__inner {
    padding-top: 30px;
    padding-left: 410px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__inner {
    padding-left: 520px;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__inner {
    padding-left: 0;
    padding-top: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

/* @media (max-width: 720px) {
  .footer__inner {
    padding-left: 0;
    padding-top: 60px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
} */

.footer__logo {
  margin-bottom: 30px;
}

.footer__logo-3 img {
  margin-bottom: 30px;
}

.footer__logo-3 p {
  max-width: 310px;
  color: var(--gray-2);
}

.footer__sub-title {
  max-width: 850px;
  font-weight: 300;
  font-size: 36px;
  line-height: 1.3;
  color: var(--black-3);
  padding-top: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__sub-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__sub-title {
    font-size: 1.5rem;
  }
}

.footer__widget {
  -ms-grid-column-span: 4;
  grid-column: span 4;
  padding: 30px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__widget {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column: 1/5;
    padding: 0 0 50px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__widget {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1/6;
    padding: 0 0 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__widget {
    padding: 0 0 50px 0;
    -ms-grid-column-span: 4;
    grid-column: span 4;
  }
}

.footer__widget p {
  max-width: 310px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--gray-2);
  margin-bottom: 30px;
}

.footer__widget-title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.3;
  color: var(--white);
  padding-bottom: 20px;
}

.footer__widget-2 {
  -ms-grid-column-span: 2;
  grid-column: span 2;
  padding: 30px 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__widget-2 {
    -ms-grid-column: 6;
    -ms-grid-column-span: 3;
    grid-column: 6/9;
    padding: 0 0 50px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__widget-2 {
    grid-column: 7/-1;
    padding: 0 0 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__widget-2 {
    padding: 0 0 50px 0;
    -ms-grid-column-span: 4;
    grid-column: span 4;
  }
}

.footer__widget-3 {
  -ms-grid-column-span: 2;
  grid-column: span 2;
  padding: 30px 0;
  margin-right: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__widget-3 {
    grid-column: 9/-1;
    padding: 0 50px 50px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__widget-3 {
    -ms-grid-column-span: 6;
    grid-column: span 6;
    padding: 30px 60px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__widget-3 {
    padding: 0 0 50px 0;
  }
}

.footer__widget-4 {
  -ms-grid-column-span: 4;
  grid-column: span 4;
  -ms-grid-row-span: 2;
  grid-row: span 2;
  background-color: #1a2b56;
  padding: 30px 70px 30px;
  margin-left: 60px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer__widget-4 {
    padding: 30px 30px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__widget-4 {
    padding: 30px 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__widget-4 {
    grid-column: 7/-1;
    padding: 30px;
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__widget-4 {
    -ms-grid-column-span: 6;
    grid-column: span 6;
    padding: 30px 30px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__widget-4 {
    padding: 30px;
    margin-left: 0;
  }
}

@media (max-width: 720px) {
  .footer__widget-4 {
    background-color: #141f3d;
  }
}

.footer__widget-4 .project-title {
  font-weight: 400;
  font-size: 36px;
  line-height: 1.3;
  color: var(--white);
  padding-bottom: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__widget-4 .project-title {
    font-size: 30px;
  }
}

.footer__widget-4 a {
  color: var(--gray-2);
  border-color: var(--secondary);
  margin: 0 auto;
  margin-bottom: 20px;
}

.footer__widget-4 .contact-time {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3;
  color: var(--white);
  padding-bottom: 3px;
}

.footer__widget-4 .contact-day {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--gray-2);
}

.footer__social li {
  display: inline-block;
  padding-right: 15px;
}

.footer__social li a {
  display: inline-block;
  color: var(--gray-2);
  font-size: 1.25rem;
}

.footer__social li a:hover {
  color: var(--white);
}

.footer__social-3 {
  border-left: 1px solid var(--black-6);
  border-right: 1px solid var(--black-6);
}

@media only screen and (max-width: 767px) {
  .footer__social-3 {
    margin-top: 50px;
    border-top: 1px solid var(--black-6);
    border-bottom: 1px solid var(--black-6);
  }
}

.footer__social-3 li {
  display: block;
  border-bottom: 1px solid var(--black-6);
}

.footer__social-3 li:last-child {
  border-bottom: 0;
}

.footer__social-3 li a {
  display: block;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
  padding: 38px 0;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__social-3 li a {
    padding: 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__social-3 li a {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__social-3 li a {
    padding: 30px 0;
  }
}

.footer__social-3 li a:hover {
  color: var(--black-2);
  background-color: var(--white);
}

.footer__link li {
  display: block;
  padding-bottom: 15px;
}

.footer__link li a {
  font-weight: 400;
  font-size: 1rem;
  color: var(--gray-2);
  line-height: 18px;
  text-transform: capitalize;
}

.footer__link li a:hover {
  color: var(--white);
}

.footer__contact {
  display: block;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: var(--gray-2);
}

.footer__contact li {
  padding-bottom: 15px;
}

.footer__contact li a {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: var(--gray-2);
}

.footer__contact li a.phone {
  color: var(--white);
  position: relative;
}

.footer__contact li a.phone::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0px;
  background-color: var(--white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer__contact li a.phone:hover::after {
  width: 0%;
}

.footer__contact li a:hover {
  color: var(--white);
}

.footer__contact-3 {
  text-align: right;
  padding-top: 90px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__contact-3 {
    padding-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__contact-3 {
    padding-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__contact-3 {
    padding-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 767px) {
  .footer__contact-3 {
    text-align: center;
    margin-bottom: 50px;
    padding-top: 60px;
  }
}

.footer__contact-3 a {
  font-weight: 400;
  font-size: 180px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer__contact-3 a {
    font-size: 140px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__contact-3 a {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__contact-3 a {
    font-size: 72px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__contact-3 a {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__contact-3 a {
    font-size: 50px;
  }
}

.footer__copyright {
  -ms-grid-column-span: 4;
  grid-column: span 4;
  border-top: 1px solid var(--gray-6);
  margin-left: -66px;
  padding: 35px 0 35px 66px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer__copyright {
    margin-left: -35px;
    padding: 35px 0 35px 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__copyright {
    margin-left: -54px;
    padding: 35px 0 35px 54px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__copyright {
    border-top: 0;
    margin-left: 0;
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column: 1/5;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__copyright {
    grid-column: 1/-1;
    text-align: center;
    border-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__copyright {
    grid-column: 1/-1;
    -ms-grid-row: 7;
    grid-row: 7;
    text-align: center;
    border-top: 0;
    padding-top: 0;
  }
}

.footer__copyright p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: var(--gray-2);
  max-width: 233px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__copyright p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer__copyright p {
    max-width: 100%;
  }
}

.footer__copyright p a {
  color: var(--white);
}

.footer__copyright p a:hover {
  color: var(--gray);
}

.footer__copyright-2 p {
  max-width: 235px;
}

@media only screen and (max-width: 767px) {
  .footer__copyright-2 p {
    max-width: 100%;
    padding-top: 50px;
    text-align: center;
  }
}

.footer__copyright-2 p a {
  color: var(--black-2);
}

.footer__copyright-2 p a:hover {
  color: var(--gray-2);
}

.footer__copyright-3 p {
  color: var(--white);
  max-width: 235px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__copyright-3 p {
    max-width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer__copyright-3 p {
    max-width: 100%;
    text-align: center;
  }
}

.footer__copyright-3 p a {
  color: var(--white);
}

.footer__copyright-3 p a:hover {
  color: var(--gray-2);
}

.footer__copyright-8 {
  padding: 20px 100px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer__copyright-8 {
    padding: 20px 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__copyright-8 {
    padding: 20px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__copyright-8 {
    padding: 20px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__copyright-8 {
    padding: 20px 15px;
  }
}

.footer__copyright-8 p {
  max-width: 235px;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .footer__copyright-8 p {
    max-width: 100%;
    text-align: center;
  }
}

.footer__copyright-8 p a {
  color: var(--black-2);
}

.footer__copyright-8 p a:hover {
  color: var(--gray-2);
}

.footer__nav {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .footer__nav {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.footer__nav-2 {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__nav-2 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer__nav-2 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.footer__subscribe {
  -ms-grid-column-span: 4;
  grid-column: span 4;
  border-top: 1px solid var(--gray-6);
  padding: 35px 0;
  margin-right: -60px;
  padding-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__subscribe {
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    grid-column: 1/7;
    -ms-grid-row: 2;
    grid-row: 2;
    padding: 0 40px 0 0;
    border-top: 0;
    margin-right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe {
    -ms-grid-column-span: 6;
    grid-column: span 6;
    -ms-grid-row: 3;
    grid-row: 3;
    border-top: 0;
    padding: 0 20px 0 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__subscribe {
    margin-right: 0;
    padding-right: 0;
    border-top: 0;
  }
}

.footer__subscribe form {
  position: relative;
  max-width: 450px;
  width: 100%;
  margin-left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe form {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer__subscribe form {
    max-width: 100%;
  }
}

.footer__subscribe input {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: var(--gray-2);
  background: transparent;
  width: 100%;
  border: 2px solid var(--white);
  border-radius: 0;
  outline: none;
  padding: 21px 50px 21px 15px;
}

.footer__subscribe input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--gray-2);
}

.footer__subscribe input::-moz-placeholder {
  opacity: 1;
  color: var(--gray-2);
}

.footer__subscribe input:-ms-input-placeholder {
  opacity: 1;
  color: var(--gray-2);
}

.footer__subscribe input::-ms-input-placeholder {
  opacity: 1;
  color: var(--gray-2);
}

.footer__subscribe input::placeholder {
  opacity: 1;
  color: var(--gray-2);
}

.footer__subscribe .subs-btn {
  position: absolute;
  right: 20px;
  top: 16px;
  background-color: transparent;
  border: none;
  color: var(--white);
}

.footer__subscribe-2 {
  position: relative;
  padding-left: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe-2 {
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer__subscribe-2 {
    padding-left: 0;
    margin-top: 50px;
  }
}

.footer__subscribe-2::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 120px;
  left: -20px;
  top: -15px;
  background-color: var(--white-2);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe-2::before {
    height: 100%;
    top: 0;
  }
}

.footer__subscribe-2 input {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--black-2);
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--black-2);
  padding: 20px 80px 20px 0;
  outline: none;
}

@media only screen and (max-width: 767px) {
  .footer__subscribe-2 input {
    font-size: 1.125rem;
  }
}

.footer__subscribe-2 input::-webkit-input-placeholder {
  color: var(--black-2);
  opacity: 1;
}

.footer__subscribe-2 input::-moz-placeholder {
  color: var(--black-2);
  opacity: 1;
}

.footer__subscribe-2 input:-ms-input-placeholder {
  color: var(--black-2);
  opacity: 1;
}

.footer__subscribe-2 input::-ms-input-placeholder {
  color: var(--black-2);
  opacity: 1;
}

.footer__subscribe-2 input::placeholder {
  color: var(--black-2);
  opacity: 1;
}

.footer__subscribe-2 form {
  position: relative;
}

.footer__subscribe-2 .submit {
  position: absolute;
  right: 0;
  top: 10px;
  background: transparent;
  border: none;
}

@media only screen and (max-width: 767px) {
  .footer__subscribe-2 .submit {
    top: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__subscribe-2 .submit img {
    width: 45px;
  }
}

footer #btn_wrapper,
footer .btn_wrapper {
  margin: 0 auto;
}

.contact_title span {
  padding-left: 30px;
}

.footer_categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-11);
  padding-bottom: 58px;
  margin: 0 100px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .footer_categories {
    margin: 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer_categories {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer_categories {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_categories {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_categories {
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.footer_categories::before {
  content: "";
  position: absolute;
  top: 12%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--gray-11);
}

@media only screen and (max-width: 767px) {
  .footer_categories::before {
    top: 14%;
  }
}

.footer_categories h4 {
  font-size: 1rem;
  color: var(--black-2);
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_item {
    width: 100%;
  }
}

.categories_item .cat_title {
  margin-bottom: 74px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_item .cat_title {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_item .cat_title {
    margin-bottom: 50px;
    margin-top: 30px;
  }
}

.categories_item input {
  background-color: transparent;
  padding: 5px 80px 20px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_item input {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 767px) {
  .categories_item input {
    padding: 15px 80px 15px 0;
  }
}

.categories_item .footer__subscribe-2 {
  padding-left: 0;
}

@media only screen and (max-width: 767px) {
  .categories_item .footer__subscribe-2 {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_item .footer__subscribe-2 input {
    width: inherit;
  }
}

.categories_item .footer__subscribe-2::before {
  display: none;
}

.cat_links li {
  padding-bottom: 12px;
}

.cat_links li a {
  display: inline-block;
  padding: 1px 0;
  font-size: 1.5rem;
  line-height: 16px;
  color: var(--black-2);
  text-transform: uppercase;
}

.cat_links li a .menu-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-shadow: 0 16px 0 var(--black-3);
}

.cat_links li a .menu-text span {
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-style: normal;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translateY(var(--m)) translateZ(0);
  transform: translateY(var(--m)) translateZ(0);
}

.cat_links li a .menu-text span:nth-child(1) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.cat_links li a .menu-text span:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.cat_links li a .menu-text span:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.cat_links li a .menu-text span:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.cat_links li a .menu-text span:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.cat_links li a .menu-text span:nth-child(6) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.cat_links li a .menu-text span:nth-child(7) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.cat_links li a .menu-text span:nth-child(8) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.cat_links li a .menu-text span:nth-child(9) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.cat_links li a .menu-text span:nth-child(10) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.cat_links li a .menu-text span:nth-child(11) {
  -webkit-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

.cat_links li a .menu-text span:nth-child(12) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.cat_links li a .menu-text span:nth-child(13) {
  -webkit-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.cat_links li a .menu-text span:nth-child(14) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.cat_links li a .menu-text span:nth-child(15) {
  -webkit-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.cat_links li a .menu-text span:nth-child(16) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.cat_links li a .menu-text span:nth-child(17) {
  -webkit-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

.cat_links li a .menu-text span:nth-child(18) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.cat_links li a .menu-text span:nth-child(19) {
  -webkit-transition-delay: 0.95s;
  transition-delay: 0.95s;
}

.cat_links li a .menu-text span:nth-child(20) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.cat_links li a .menu-text span:nth-child(21) {
  -webkit-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

.cat_links li a .menu-text span:nth-child(22) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.cat_links li a .menu-text span:nth-child(23) {
  -webkit-transition-delay: 1.15s;
  transition-delay: 1.15s;
}

.cat_links li a .menu-text span:nth-child(24) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.cat_links li a .menu-text span:nth-child(25) {
  -webkit-transition-delay: 1.25s;
  transition-delay: 1.25s;
}

.cat_links li a .menu-text span:nth-child(26) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.cat_links li a .menu-text span:nth-child(27) {
  -webkit-transition-delay: 1.35s;
  transition-delay: 1.35s;
}

.cat_links li a .menu-text span:nth-child(28) {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.cat_links li a .menu-text span:nth-child(29) {
  -webkit-transition-delay: 1.45s;
  transition-delay: 1.45s;
}

.cat_links li a .menu-text span:nth-child(30) {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.cat_links li a .menu-text span:nth-child(31) {
  -webkit-transition-delay: 1.55s;
  transition-delay: 1.55s;
}

.cat_links li a .menu-text span:nth-child(32) {
  -webkit-transition-delay: 1.6s;
  transition-delay: 1.6s;
}

.cat_links li a .menu-text span:nth-child(33) {
  -webkit-transition-delay: 1.65s;
  transition-delay: 1.65s;
}

.cat_links li a .menu-text span:nth-child(34) {
  -webkit-transition-delay: 1.7s;
  transition-delay: 1.7s;
}

.cat_links li a .menu-text span:nth-child(35) {
  -webkit-transition-delay: 1.75s;
  transition-delay: 1.75s;
}

.cat_links li a .menu-text span:nth-child(36) {
  -webkit-transition-delay: 1.8s;
  transition-delay: 1.8s;
}

.cat_links li a .menu-text span:nth-child(37) {
  -webkit-transition-delay: 1.85s;
  transition-delay: 1.85s;
}

.cat_links li a .menu-text span:nth-child(38) {
  -webkit-transition-delay: 1.9s;
  transition-delay: 1.9s;
}

.cat_links li a .menu-text span:nth-child(39) {
  -webkit-transition-delay: 1.95s;
  transition-delay: 1.95s;
}

.cat_links li a .menu-text span:nth-child(40) {
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}

.cat_links li a .menu-text span:nth-child(41) {
  -webkit-transition-delay: 2.05s;
  transition-delay: 2.05s;
}

.cat_links li a .menu-text span:nth-child(42) {
  -webkit-transition-delay: 2.1s;
  transition-delay: 2.1s;
}

.cat_links li a .menu-text span:nth-child(43) {
  -webkit-transition-delay: 2.15s;
  transition-delay: 2.15s;
}

.cat_links li a .menu-text span:nth-child(44) {
  -webkit-transition-delay: 2.2s;
  transition-delay: 2.2s;
}

.cat_links li a .menu-text span:nth-child(45) {
  -webkit-transition-delay: 2.25s;
  transition-delay: 2.25s;
}

.cat_links li a .menu-text span:nth-child(46) {
  -webkit-transition-delay: 2.3s;
  transition-delay: 2.3s;
}

.cat_links li a .menu-text span:nth-child(47) {
  -webkit-transition-delay: 2.35s;
  transition-delay: 2.35s;
}

.cat_links li a .menu-text span:nth-child(48) {
  -webkit-transition-delay: 2.4s;
  transition-delay: 2.4s;
}

.cat_links li a .menu-text span:nth-child(49) {
  -webkit-transition-delay: 2.45s;
  transition-delay: 2.45s;
}

.cat_links li a:hover {
  --y: -4px;
}

.cat_links li a:hover span {
  --m: calc(16px * -1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cat_links li a {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .cat_links li a {
    font-size: 1rem;
  }
}

.categories_item.l_item {
  -ms-flex-preferred-size: 36%;
  flex-basis: 36%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_item.l_item .cat_title {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .categories_item.l_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .categories_item.l_item .cat_title {
    margin-top: 50px;
    margin-bottom: 0;
  }
}

@-webkit-keyframes wcLoading {
  0% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: var(--white);
  }

  50% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    background: var(--primary);
  }

  100% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: transparent;
  }
}

@keyframes wcLoading {
  0% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: var(--white);
  }

  50% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    background: var(--primary);
  }

  100% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: transparent;
  }
}

.preloader {
  width: 100%;
  height: 100%;
  background-color: var(--dark-theme);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.loading svg {
  fill: var(--white);
}

.loading span {
  color: var(--white);
}

.loading .bar {
  width: 6px;
  height: 60px;
  background: var(--white);
  display: inline-block;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-animation: wcLoading 1.5s ease-in-out infinite;
  animation: wcLoading 1.5s ease-in-out infinite;
}

.loading .bar1 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.loading .bar2 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loading .bar3 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.loading .bar4 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.loading .bar5 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loading .bar6 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.loading .bar7 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.loading .bar8 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

:root {
  --white-6: #d7d7d7;
  --white-7: #b7b7c5;
  --black-14: #323438;
  --black-15: #2c2c2c;
  --lime: #745eff;
  --lime-2: #5547f5;
  --gray-16: #6b6b6b;
  --gray-17: #f7f7fc;
  --gray-18: #999999;
  --gray-20: #d7d7d7;
  --gray-21: #f2f2f8;
  --gray-22: #fcfcfe;
  --gray-23: #ececf3;
  --gray-24: #ececf4;
}

main {
  overflow: hidden;
}

.line_4 {
  position: relative;
}

.line-col-4 div {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--white-2);
}

.line-col-4 div:nth-child(2) {
  left: 33.33%;
}

.line-col-4 div:nth-child(3) {
  left: 66.66%;
}

.line-col-4 div:nth-child(4) {
  left: 100%;
}

.portfolio6__line .line-col-4 div {
  height: 1000px;
  top: unset;
  bottom: 0;
}

.btn-started {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3;
  padding: 17px 36px 16px;
  color: var(--white);
  border-radius: 5px;
  background: var(--lime);
  display: inline-block;
}

.btn-started i {
  padding-left: 10px;
}

.btn-started:hover {
  color: var(--white);
  background-color: #ff977b;
}

.btn-view {
  font-weight: 500;
  line-height: 1.3;
  color: var(--black-2);
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.btn-view i {
  padding-left: 10px;
}

.btn-view::after {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--black-2);
  content: "";
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.btn-view:hover::after {
  left: 0;
  width: 1%;
}

.btn-view:hover {
  color: var(--lime-2);
}

.btn-extra {
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  line-height: 1.3;
  padding: 9px 14px 11px;
  border-radius: 5px;
  background-color: var(--black-2);
  border: 1px solid var(--black-2);
}

.btn-extra:hover {
  color: var(--black);
  background-color: transparent;
}

.title-wrapper-6 p {
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-3);
}

@media only screen and (max-width: 767px) {
  .title-wrapper-6 p {
    font-size: 1rem;
  }
}

.sec-subtile-6 {
  font-size: 1rem;
  color: var(--black-2);
  line-height: 1.3;
  padding: 2px 14px 3px;
  font-weight: 400;
  border-radius: 16px;
  border: 1px solid var(--gray-16);
  display: inline-block;
  margin-bottom: 17px;
}

@media only screen and (max-width: 767px) {
  .sec-subtile-6 {
    margin-bottom: 10px;
  }
}

.sec-title-6 {
  font-size: 28px;
  color: var(--black-2);
  font-weight: 500;
  line-height: 1.13;
  /* padding-bottom: 20px; */
}

.hero__area-6 {
  position: relative;
}

.hero__area-6 .shape-4 {
  position: absolute;
  bottom: -60px;
  left: 180px;
  z-index: 0;
}

@media only screen and (max-width: 767px) {
  .hero__area-6 .shape-4 {
    max-width: 260px;
    left: 0;
  }
}

.hero__title-6 {
  font-size: 100px;
  line-height: 1;
  font-weight: 600;
  color: var(--black-2);
  padding-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .hero__title-6 {
    font-size: 40px;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title-6 {
    font-size: 45px;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title-6 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title-6 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__title-6 {
    font-size: 80px;
  }
}

.hero__arrow-icon {
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 767px) {
  .hero__arrow-icon {
    top: 83%;
    right: 10%;
    -webkit-transform: rotate(62deg);
    transform: rotate(62deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__arrow-icon {
    right: -15%;
  }
}

.hero__content-6 {
  padding-top: 260px;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__content-6 {
    padding-top: 210px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__content-6 {
    padding-top: 130px;
  }
}

.hero__content-6 p {
  font-size: 1.25rem;
  padding-bottom: 48px;
  line-height: 1.5;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .hero__content-6 p {
    font-size: 1rem;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content-6 p br {
    display: none;
  }
}

.hero__right-6 {
  padding-top: 130px;
}

@media only screen and (max-width: 767px) {
  .hero__right-6 {
    padding-top: 40px;
  }
}

.hero__right-6 img {
  margin-left: 3px;
  max-width: inherit;
}

@media (max-width: 1365px) {
  .hero__right-6 img {
    max-width: 100%;
  }
}

.hero__shape {
  position: absolute;
  top: 22%;
  left: 0;
}

@media only screen and (max-width: 767px) {
  .hero__shape {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__shape {
    top: 13%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__shape {
    top: 9%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__shape {
    top: 10%;
  }
}

.hero__shape-2 {
  position: absolute;
  right: 7%;
  bottom: 30%;
}

@media only screen and (max-width: 767px) {
  .hero__shape-2 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__shape-2 {
    bottom: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__shape-2 {
    bottom: -8%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__shape-2 {
    right: 3%;
    bottom: 7%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__shape-2 {
    bottom: -7%;
    right: 7%;
  }
}

.hero__shape-3 {
  position: absolute;
  left: 4%;
  bottom: 7%;
}

@media only screen and (max-width: 767px) {
  .hero__shape-3 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__shape-3 {
    left: 7%;
    bottom: -20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__shape-3 {
    bottom: -13%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .hero__shape-3 {
    left: 7%;
    bottom: 7%;
  }
}

.workflow__area-4 {
  overflow: hidden;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .workflow__area-4 .pb-1230 {
    padding-bottom: 60px;
  }
}

.workflow__item-4 {
  padding-top: 64px;
}

@media only screen and (max-width: 767px) {
  .workflow__item-4 {
    padding-top: 60px;
  }
}

.workflow__item-4 img {
  padding-bottom: 30px;
}

.workflow__item-4 p {
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-3);
  padding: 0px 55px;
}

@media only screen and (max-width: 767px) {
  .workflow__item-4 p {
    padding: 0px;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workflow__item-4 p {
    padding: 0px;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workflow__item-4 p {
    padding: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .workflow__item-4 p {
    padding: 0px;
  }
}

.workflow__title-4 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--black);
  padding-bottom: 19px;
}

@media only screen and (max-width: 767px) {
  .workflow__title-4 {
    font-size: 1.25rem;
    padding-bottom: 15px;
  }
}

.feature__area-6 {
  overflow: hidden;
  background-color: var(--gray-17);
}

.feature__content img {
  margin-top: -90px;
}

@media only screen and (max-width: 767px) {
  .feature__content img {
    margin-top: 0;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__content img {
    width: 100%;
  }
}

.feature__content-right {
  background-color: #1e2024;
  padding: 143px 60px 0px 110px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .feature__content-right {
    padding: 20px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__content-right {
    padding: 80px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__content-right {
    padding: 100px 20px 0px 25px;
  }
}

.feature__content-right p {
  font-size: 1.125rem;
  color: var(--gray-18);
  line-height: 1.5;
  padding-bottom: 25px;
  padding-right: 242px;
}

@media only screen and (max-width: 767px) {
  .feature__content-right p {
    padding-right: 0;
    padding-bottom: 15px;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__content-right p {
    padding-right: 0;
    padding-bottom: 15px;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__content-right p {
    padding-right: 140px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .feature__content-right p {
    padding-right: 145px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .feature__content-right p {
    padding-right: 225px;
  }
}

.feature__content-right p span {
  color: var(--white);
}

.feature__content-left {
  padding-top: 143px;
}

@media only screen and (max-width: 767px) {
  .feature__content-left {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__content-left {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__content-left {
    padding-top: 100px;
  }
}

.feature__title-6 {
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.3;
  color: var(--white);
  padding-bottom: 22px;
  padding-left: 60px;
}

.feature__title-6::before {
  position: absolute;
  top: 13px;
  left: 0;
  width: 50px;
  height: 1px;
  content: "";
  background: var(--white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__content img {
    width: 100%;
    margin-top: 0;
  }
}

.feature__content-btm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 114px;
  height: 100%;
  margin-left: 110px;
}

@media only screen and (max-width: 767px) {
  .feature__content-btm {
    gap: 20px;
    margin-left: 0;
    padding: 30px 0px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__content-btm {
    gap: 20px;
    margin-left: 0;
    padding: 30px 0px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__content-btm {
    gap: 20px;
    margin-left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .feature__content-btm {
    gap: 20px;
  }
}

.feature__content-item img {
  padding-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .feature__content-item img {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .feature__content-item img {
    max-width: 100%;
  }
}

.feature__content-item h5 {
  font-size: 1.125rem;
  color: var(--black-2);
  font-weight: 500;
  line-height: 1.4;
  padding-bottom: 15px;
}

.feature__content-item p {
  font-weight: 300;
  color: var(--black-3);
}

.feature__img-1 {
  position: absolute;
  top: 22%;
  right: 10%;
}

@media only screen and (max-width: 767px) {
  .feature__img-1 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__img-1 {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__img-1 {
    top: 9%;
    right: 4%;
  }
}

.feature__img-2 {
  margin-left: 100px;
}

@media only screen and (max-width: 767px) {
  .feature__img-2 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__img-2 {
    display: none;
  }
}

.feature__img-3 {
  position: absolute;
  right: 6%;
  bottom: 5%;
}

@media only screen and (max-width: 767px) {
  .feature__img-3 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__img-3 {
    display: none;
  }
}

.service__title-wrapper-4 {
  width: 75%;
}

.service__right-4 p {
  font-size: 1.125rem;
  color: var(--black-3);
  padding-bottom: 30px;
  padding-right: 195px;
}

@media only screen and (max-width: 767px) {
  .service__right-4 p {
    font-size: 1rem;
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__right-4 p {
    padding-right: 0;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__right-4 p {
    padding-right: 30px;
  }
}

.service__right-4 p span {
  color: var(--black-2);
}

.service__img-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .service__img-left img {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__img-left img {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__img-left img {
    width: 100%;
  }
}

.service__inner-4 {
  margin-top: -20px;
}

@media only screen and (max-width: 767px) {
  .service__inner-4 {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__inner-4 {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__inner-4 {
    margin-top: 40px;
  }
}

.service__item-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 62px;
  padding: 12px 0px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-bottom: 1px solid var(--gray-20);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-4 {
    gap: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service__item-4 {
    gap: 20px;
  }
}

.service__item-4:first-child {
  border-top: 1px solid var(--gray-20);
}

.service__item-4:hover .service__item-icon {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 1;
  visibility: visible;
}

.service__item-4:hover .service__item-title {
  color: var(--lime);
}

.service__item-number {
  font-size: 1.125rem;
  font-weight: 300;
  color: var(--lime-2);
}

.service__item-title {
  font-size: 60px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--black-2);
}

@media only screen and (max-width: 767px) {
  .service__item-title {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-title {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item-title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item-title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__item-title {
    font-size: 50px;
  }
}

.service__item-icon {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 767px) {
  .service__item-icon {
    opacity: 1;
  }
}

.service__item-icon i {
  font-size: 23px;
  color: var(--black-2);
}

.faq__area-4 {
  background-image: url(../imgs/bg/4/s1.png?v=0.1.8);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  overflow: hidden;
  background-color: #1e2024;
}

.faq__area-4 .sec-title-6 {
  color: var(--white);
  max-width: 630px;
  line-height: 1.15;
}

.faq__area-4 .sec-subtile-6 {
  color: var(--white);
}

.faq__list-4 {
  padding-top: 30px;
}

.faq__list-4 .accordion-body {
  padding-left: 0;
  min-height: 100px;
}

.faq__list-4 .accordion-body p {
  color: var(--gray-2);
  font-size: 1.125rem;
}

@media (max-width: 1023px) {
  .faq__list-4 .accordion-body p {
    font-size: 1rem;
  }
}

.faq__list-4 .accordion-body p span {
  color: var(--white);
}

.faq__list-4 .accordion-item {
  border: none;
  border-radius: 0;
  background-color: transparent;
}

.faq__list-4 .accordion-button {
  background-color: transparent;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--white);
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.faq__list-4 .accordion-button.collapsed::after {
  background-image: none;
  content: "+";
  font-weight: 300;
  font-size: 30px;
  top: 1px;
  position: absolute;
  left: 0;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.faq__list-4 .accordion-button:not(.collapsed) {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.faq__list-4 .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "-";
  font-weight: 300;
  font-size: 30px;
  top: 1px;
  position: absolute;
  left: 0;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.faq__list-4 .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.faq__sec-title p {
  font-size: 1.125rem;
  color: var(--gray-2);
  padding-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .faq__sec-title p {
    font-size: 1rem;
  }
}

.faq__sec-title .btn-view {
  color: var(--white);
}

.faq__sec-title .btn-view::after {
  background-color: var(--white);
}

.faq__sec-title-title-4 {
  padding-right: 105px;
}

.faq__img6-1 {
  position: absolute;
  bottom: 0;
  left: 35%;
  z-index: 0;
}

.faq__img6-2 {
  position: absolute;
  top: 0;
  left: 51px;
  z-index: 0;
}

@media only screen and (max-width: 767px) {
  .faq__img6-2 {
    display: none;
  }
}

.solution__area-4 {
  overflow: hidden;
  background-color: #1e2024;
  /* background-image: url(../imgs/bg/4/solution-bg.png?v=0.1.8); */
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 179px 0px 170px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .solution__area-4 {
    padding: 60px 0px;
  }

  .solution__area-4::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: var(--black-2);
    opacity: 0.6;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__area-4 {
    padding: 80px 0px;
    background-size: 380px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__area-4 {
    padding: 120px 0px;
    background-size: 500px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .solution__area-4 {
    padding: 90px 0px;
    background-size: 52%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .solution__area-4 {
    padding: 150px 0px 110px;
    background-size: 55%;
  }
}

.solution__content .sec-title-6 {
  color: var(--white);
}

.solution__content .sec-subtile-6 {
  color: var(--white);
}

.solution__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 270px auto;
  grid-template-columns: 270px auto;
  border-top: 1px solid var(--black-14);
  margin-top: 16px;
  padding-top: 24px;
}

@media only screen and (max-width: 767px) {
  .solution__list {
    -ms-grid-columns: 180px auto;
    grid-template-columns: 180px auto;
    border-top: 1px solid var(--white-6);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    border-top: 1px solid var(--white-6);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__list {
    -ms-grid-columns: 250px 300px;
    grid-template-columns: 250px 300px;
    border-top: 1px solid var(--white-6);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .solution__list {
    -ms-grid-columns: 250px 300px;
    grid-template-columns: 250px 300px;
    border-top: 1px solid var(--white-6);
  }
}

.solution__list li {
  font-size: 1.125rem;
  color: var(--white);
  font-weight: 400;
  position: relative;
  padding-left: 15px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .solution__list li {
    font-size: 14px;
  }
}

.solution__list li::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  content: "+";
}

.blog__area-4 {
  overflow: hidden;
}

.blog__area-4 .sec-title-wrapper p {
  font-size: 1.125rem;
  color: var(--black-13);
  padding-right: 80px;
  display: inline-block;
  margin-bottom: 55px;
}

@media only screen and (max-width: 767px) {
  .blog__area-4 .sec-title-wrapper p {
    font-size: 1rem;
    margin-bottom: 20px;
    padding-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-4 .sec-title-wrapper p {
    font-size: 1rem;
    margin-bottom: 20px;
    padding-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__area-4 .sec-title-wrapper p {
    padding-right: 30px;
  }
}

.blog__img-4 img {
  width: 100%;
}

.blog__meta-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  padding-top: 35px;
}

.blog__meta-4 a {
  font-size: 14px;
  color: var(--black-2);
  line-height: 1.3;
  padding: 3px 8px;
  background-color: var(--gray-21);
  font-weight: 400;
}

.blog__meta-4 a:hover {
  color: var(--lime);
}

.blog__meta-4 span {
  font-size: 14px;
  color: var(--black-2);
  line-height: 1.3;
  padding: 3px 8px;
  background-color: var(--gray-21);
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .blog__item-4 {
    margin-top: 50px;
  }
}

.blog__btn-4 {
  font-weight: 500;
  line-height: 1.3;
  color: var(--black-2);
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.blog__btn-4 i {
  padding-left: 10px;
}

.blog__btn-4::after {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--black-2);
  content: "";
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.blog__btn-4:hover::after {
  left: 0;
  width: 1%;
}

.blog__btn-4:hover {
  color: var(--lime-2);
}

.blog__title-4 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--black-2);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  padding: 10px 0px 20px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .blog__title-4 {
    font-size: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__title-4 {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__title-4 {
    font-size: 1.25rem;
  }
}

.blog__title-4:hover {
  color: var(--lime-2);
}

.cta__area-4 {
  overflow: hidden;
}

.cta__area-4 .btn-started {
  padding: 16px 28px;
}

.cta__inner-4 {
  background-color: #f1e9e3;
  padding: 66px 60px 0px 60px;
  position: relative;
  border-radius: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__inner-4 {
    padding: 60px 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__inner-4 {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .cta__inner-4 {
    padding: 40px 20px;
  }
}

.cta__inner-4 img {
  max-width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__inner-4 img {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .cta__inner-4 img {
    max-width: 80%;
  }
}

.cta__inner-4 .cta-shape {
  position: absolute;
  bottom: 0;
  right: 460px;
  z-index: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__inner-4 .cta-shape {
    bottom: 30px;
    right: 250px;
    max-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cta__inner-4 .cta-shape {
    bottom: 30px;
    right: 20px;
    max-width: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__inner-4 .cta-shape {
    max-width: 120px;
    right: 360px;
  }
}

.cta__inner-4 .cta-shape-2 {
  position: absolute;
  bottom: 60px;
  right: 110px;
  z-index: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__inner-4 .cta-shape-2 {
    right: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__inner-4 .cta-shape-2 {
    right: 10px;
    bottom: 120px;
  }
}

.cta__content-4 {
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__content-4 img {
    width: 100%;
  }
}

.cta__content-4 p {
  font-size: 1.125rem;
  font-weight: 400;
  padding-bottom: 34px;
  margin-top: 15px;
  color: var(--black-2);
}

@media only screen and (max-width: 767px) {
  .cta__content-4 p {
    font-size: 1rem;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__content-4 p {
    font-size: 1rem;
    padding-bottom: 20px;
  }
}

.cta__title-4 {
  font-size: 50px;
  font-weight: 500;
  color: var(--black-2);
  line-height: 1.2;
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  .cta__title-4 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__title-4 {
    padding-top: 30px;
    font-size: 28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta__title-4 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cta__title-4 {
    font-size: 40px;
  }
}

.portfolio__area-4 {
  padding: 0px 45px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .portfolio__area-4 {
    padding: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-4 {
    padding: 0px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-4 {
    padding: 0px 31px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__area-4 {
    padding: 0px 60px;
  }
}

.portfolio__area-4 .cursor {
  position: absolute;
}

.portfolio__area-4 .cursor.large {
  width: 120px;
  height: 120px;
  font-size: 14px;
}

.portfolio__wrapper-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 30px;
}

@media only screen and (max-width: 767px) {
  .portfolio__wrapper-4 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__wrapper-4 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.portfolio__item-4 img {
  width: 100%;
}

.portfolio__item-4:hover .portfolio__icon-link {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.portfolio__icon-link {
  font-size: 23px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.portfolio__item-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 20px;
}

.portfolio__title-4 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.3;
  color: var(--black-2);
  padding-bottom: 5px;
}

.portfolio__subtitle-4 {
  font-size: 14px;
  color: var(--black-3);
  font-weight: 400;
}

.portfolio__icon-4 {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 23px;
  color: var(--black-2);
}

.portfolio__sec-title {
  position: absolute;
  bottom: 140px;
}

@media only screen and (max-width: 767px) {
  .portfolio__sec-title {
    position: unset;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__sec-title {
    position: unset;
  }
}

.portfolio__sec-title p {
  font-size: 1.125rem;
  color: var(--black-3);
  max-width: 300px;
}

.testimonial__area-4 {
  overflow: hidden;
  padding: 30px 0 30px 0;
  text-align: center;
}

/* .testimonial__sec-title {
  padding-bottom: 20px;
} */

.testimonial__sec-title p {
  font-size: 1.125rem;
  color: var(--black-3);
  max-width: 500px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .testimonial__sec-title p {
    width: 100%;
    font-size: 1rem;
  }
}

.testimonial__slide-4 {
  background-color: var(--gray-22);
  padding: 30px;
  text-align: left;
  /* border-radius: 2rem; */
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__slide-4 {
    padding: 35px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__slide-4 {
    padding: 35px 30px 30px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__slide-4 {
    padding: 35px 30px;
  }
}

.testimonial__slider-4 {
  position: relative;
  height: 100%;
  width: 98%;
  margin-left: 11px;
}

.testimonial__slider-4 .story-nav {
  width: 96%;
  color: var(--white);
  position: absolute;
  top: 33%;
  z-index: 9;
}

.testimonial__slider-4 .story-nav img {
  filter: drop-shadow(2px 4px 6px black);
}

.testimonial__slider-4 .prev-button {
  animation-name: horizontalShake;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.testimonial__slider-4 .next-button {
  animation-name: horizontalShakes;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

/* .testimonial__slider-4 .prev-button,
  background: #141f3da8;
  padding: 2rem;
  display: inline;
  border-radius: 50%;
} */

@media only screen and (max-width: 767px) {
  .testimonial__slider-4 {
    width: 93%;
    /* padding-bottom: 60px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__slider-4 {
    width: 97%;
    /* padding-bottom: 80px; */
  }
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__slider-4 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial__slider-4 {
    padding-bottom: 110px;
  }
} */

.testimonial__img-4 {
  margin-bottom: 20px;
  border-radius: 100px;
  width: 85px;
  height: auto;
}

.testimonial__feedback-4 {
  font-size: 14px;
  font-weight: 400;
  color: var(--black-2);
  border-radius: 5px;
  padding: 3px 10px;
  margin-bottom: 8px;
  display: inline-block;
  line-height: 1.3;
  background-color: var(--gray-23);
}

.testimonial__info-4 p {
  color: var(--black-3);
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 8px;
  text-align: left;
}

.testimonial__name-4 {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--black);
  line-height: 1.3;
}

.testimonial__role-4 {
  font-size: 14px;
  color: var(--black-3);
  font-weight: 400;
}

.testimonial__btn-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 160px;
  padding-top: 68px;
  position: relative;
  z-index: 999;
}

@media only screen and (max-width: 767px) {
  .testimonial__btn-4 {
    padding-top: 35px;
  }
}

.testimonial__btn-4 i {
  font-size: 1.125rem;
}

.testimonial__pagination-4 {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 30px;
  bottom: 1px;
  z-index: -1;
}

.footer__area-6 {
  overflow: hidden;
  background-color: var(--gray-17);
}

.footer__top-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .footer__top-6 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__top-6 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 5px;
  }
}

.footer__social-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

.footer__social-6 li a {
  color: var(--gray-2);
  font-size: 1.125rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer__social-6 li a:hover {
  color: var(--black-2);
}

.footer__info-6 li {
  color: var(--black-13);
  font-size: 1rem;
  margin-bottom: 15px;
}

.footer__info-6 li a {
  color: var(--black-13);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.footer__info-6 li a:hover {
  color: var(--black-2);
}

.footer__info-6 li a.phone {
  color: var(--black-2);
  position: relative;
}

.footer__info-6 li a.phone::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0px;
  background-color: var(--white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer__info-6 li a.phone:hover::after {
  width: 0%;
}

.footer__info-6 li a.phone::after {
  background-color: var(--black-2);
}

.footer__link-6 li {
  margin-bottom: 8px;
}

.footer__link-6 li a {
  color: var(--black-13);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.footer__link-6 li a:hover {
  color: var(--black-2);
}

.footer__item-title {
  font-size: 1.25rem;
  color: var(--black-2);
  font-weight: 600;
  line-height: 1.3;
  padding-bottom: 20px;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .footer__item-6 {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__item-6 {
    margin-bottom: 50px;
  }
}

.footer__item-6:nth-child(1) {
  width: 25%;
}

@media only screen and (max-width: 767px) {
  .footer__item-6:nth-child(1) {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__item-6:nth-child(1) {
    width: 50%;
    padding-right: 30px;
  }
}

.footer__item-6:nth-child(2) {
  width: 14%;
}

@media only screen and (max-width: 767px) {
  .footer__item-6:nth-child(2) {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__item-6:nth-child(2) {
    width: 50%;
  }
}

.footer__item-6:nth-child(3) {
  width: 20%;
}

@media only screen and (max-width: 767px) {
  .footer__item-6:nth-child(3) {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__item-6:nth-child(3) {
    width: 50%;
    padding-right: 30px;
  }
}

.footer__item-6:nth-child(4) {
  width: 28%;
}

@media only screen and (max-width: 767px) {
  .footer__item-6:nth-child(4) {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__item-6:nth-child(4) {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__item-6:nth-child(4) {
    width: 33%;
  }
}

.footer__item-6 p {
  padding-bottom: 20px;
  color: var(--gray-2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__item-6 p br {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__item-6 p br {
    display: none;
  }
}

.footer__item-6-social a:hover {
  color: var(--black-2);
}

.footer__btm-6 {
  border-top: 1px solid var(--gray-24);
  padding-top: 40px;
  padding-bottom: 43px;
}

.footer__newsletter-6 {
  background-color: var(--gray-24);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  position: relative;
}

.footer__newsletter-6 input {
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 16px 50px 16px 20px;
}

.footer__newsletter-6 input::-webkit-input-placeholder {
  font-size: 14px;
  color: var(--white-7);
  font-weight: 400;
}

.footer__newsletter-6 input::-moz-placeholder {
  font-size: 14px;
  color: var(--white-7);
  font-weight: 400;
}

.footer__newsletter-6 input:-ms-input-placeholder {
  font-size: 14px;
  color: var(--white-7);
  font-weight: 400;
}

.footer__newsletter-6 input::-ms-input-placeholder {
  font-size: 14px;
  color: var(--white-7);
  font-weight: 400;
}

.footer__newsletter-6 input::placeholder {
  font-size: 14px;
  color: var(--white-7);
  font-weight: 400;
}

.footer__newsletter-6 button {
  position: absolute;
  right: 20px;
  top: 15px;
}

.footer__copyright-6 {
  color: var(--black-13);
}

.footer__copyright-6 p a {
  color: var(--black-2);
}

.footer__copyright-6 p a:hover {
  color: var(--lime);
}

.footer__chekbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  margin-top: 18px;
}

.footer__chekbox input {
  width: 30px;
  height: 30px;
}

.footer__chekbox label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--black-3);
}

@-webkit-keyframes leftmove {
  0% {
    margin-left: 0;
  }

  50% {
    margin-left: -20px;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes leftmove {
  0% {
    margin-left: 0;
  }

  50% {
    margin-left: -20px;
  }

  100% {
    margin-left: 0;
  }
}

@-webkit-keyframes rightmove {
  0% {
    margin-right: 0;
  }

  50% {
    margin-right: 30px;
  }

  100% {
    margin-right: 0;
  }
}

@keyframes rightmove {
  0% {
    margin-right: 0;
  }

  50% {
    margin-right: 30px;
  }

  100% {
    margin-right: 0;
  }
}

@-webkit-keyframes move {
  0% {
    margin-bottom: 0;
  }

  50% {
    margin-bottom: -20px;
  }

  100% {
    margin-bottom: 0;
  }
}

@keyframes move {
  0% {
    margin-bottom: 0;
  }

  50% {
    margin-bottom: -20px;
  }

  100% {
    margin-bottom: 0;
  }
}

@keyframes move {
  0% {
    margin-top: 0;
  }

  50% {
    margin-top: -20px;
  }

  100% {
    margin-top: 0;
  }
}

@-webkit-keyframes rotate-icon {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-icon {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes horizontalShake {
  3%,
  12%,
  21%,
  30%,
  39%,
  48%,
  57%,
  65%,
  74%,
  83%,
  92% {
    transform: translateX(5px);
  }

  6%,
  15%,
  24%,
  33%,
  42%,
  51%,
  60%,
  68%,
  77%,
  86%,
  95% {
    transform: translateX(3px);
  }

  9%,
  18%,
  27%,
  36%,
  45%,
  54%,
  63%,
  71%,
  80%,
  89%,
  98% {
    transform: translateX(-5px);
  }
}

@keyframes horizontalShakes {
  3%,
  12%,
  21%,
  30%,
  39%,
  48%,
  57%,
  65%,
  74%,
  83%,
  92% {
    transform: translateX(-5px);
  }

  6%,
  15%,
  24%,
  33%,
  42%,
  51%,
  60%,
  68%,
  77%,
  86%,
  95% {
    transform: translateX(-3px);
  }

  9%,
  18%,
  27%,
  36%,
  45%,
  54%,
  63%,
  71%,
  80%,
  89%,
  98% {
    transform: translateX(5px);
  }
}

.hero__shape {
  -webkit-animation-name: leftmove;
  animation-name: leftmove;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation: leftmove infinite 4s;
  animation: leftmove infinite 4s;
}

.hero__arrow-icon {
  -webkit-animation-name: rightmove;
  animation-name: rightmove;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation: rightmove infinite 3s;
  animation: rightmove infinite 3s;
}

.hero__shape-2 {
  -webkit-animation-name: move;
  animation-name: move;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation: move infinite 4s;
  animation: move infinite 4s;
}

.hero__shape-3 {
  -webkit-animation: rotate-icon 5s linear 0s infinite normal;
  animation: rotate-icon 5s linear 0s infinite normal;
}

.faq__img6-1 {
  -webkit-animation-name: move;
  animation-name: move;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation: move infinite 4s;
  animation: move infinite 4s;
}

.faq__img6-2 {
  -webkit-animation-name: move;
  animation-name: move;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation: move infinite 4s;
  animation: move infinite 4s;
}

.dark .line-col-4 div {
  background: var(--bg-line);
}

.dark .btn-view {
  color: var(--white);
}

.dark .btn-view::after {
  background-color: var(--white);
}

.dark .sec-subtile-6,
.dark .sec-title-6,
.dark .workflow__title-4,
.dark .feature__content-item h5,
.dark .service__item-title,
.dark .service__right-4 p span,
.dark .portfolio__title-4,
.dark .portfolio__icon-4,
.dark .footer__item-title,
.dark .footer__newsletter-6 button {
  color: var(--white);
  padding-bottom: 20px;
}

.dark .title-wrapper-6 p,
.dark .workflow__item-4 p,
.dark .feature__content-item p,
.dark .service__right-4 p,
.dark .portfolio__subtitle-4,
.dark .portfolio__sec-title p,
.dark .testimonial__sec-title p,
.dark .blog__area-4 .sec-title-wrapper p,
.dark .testimonial__info-4 p,
.dark .testimonial__role-4,
.dark .footer__info-6 li,
.dark .footer__chekbox label {
  color: var(--gray-2);
}

.dark .hero__area-6 .shape-4 {
  -webkit-filter: invert(0.93);
  filter: invert(0.93);
}

.dark .hero__title-6 {
  color: var(--white);
}

.dark .feature__area-6 {
  background-color: var(--d-black-2);
}

.dark .testimonial__slider-4 {
  color: var(--white);
}

.dark .solution__area-4 {
  overflow: hidden;
  background-color: var(--d-black-2);
}

.dark .blog__title-4 {
  color: var(--white);
}

.dark .blog__title-4:hover {
  color: var(--lime);
}

.dark .blog__btn-4 {
  color: var(--white);
}

.dark .blog__btn-4:hover {
  color: var(--lime);
}

.dark .blog__btn-4::after {
  background-color: var(--white);
}

.dark .testimonial__slide-4 {
  min-height: 350px;
  background: var(--dark-theme2);
}

.dark .testimonial__feedback-4 {
  color: var(--gray-2);
  background-color: var(--dark-theme);
}

.dark .blog__meta-4 span {
  color: var(--white-6);
  background-color: var(--bg-line);
}

.dark .blog__meta-4 a {
  color: var(--white-6);
  background-color: var(--bg-line);
}

.dark .blog__meta-4 a:hover {
  color: var(--lime);
}

.dark .footer__area-6 {
  background-color: var(--d-black-2);
}

.dark .footer__link-6 li a {
  color: var(--gray-2);
}

.dark .footer__link-6 li a:hover {
  color: var(--white);
}

.dark .footer__info-6 li a {
  color: var(--gray-2);
}

.dark .footer__info-6 li a:hover {
  color: var(--white);
}

.dark .footer__info-6 li a.phone {
  color: var(--white);
}

.dark .footer__info-6 li a.phone::after {
  background-color: var(--white);
}

.dark .footer__newsletter-6 input {
  color: var(--white-2);
  background-color: var(--black-2);
}

.dark .footer__btm-6 {
  border-color: var(--bg-line);
}

.dark .footer__copyright-6 p a {
  color: var(--white);
}

.dark .footer__copyright-6 p a:hover {
  color: var(--lime);
}

.dark .service__item-4 {
  border-color: var(--bg-line);
}

.dark .service__item-4:first-child {
  border-color: var(--bg-line);
}

.dark .service__item-icon i {
  color: var(--white);
}

.header__area-7.sticky-7 {
  background: var(--white);
  border-bottom: 1px solid var(--white-2);
}

.header__area-7.sticky-7 .contact-area {
  display: none;
}

.hero__area-7 {
  padding-top: 180px;
  padding-bottom: 140px;
  background-image: url(../imgs/home-7/hero-bg.png?v=0.1.8);
  background-repeat: repeat;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-7 {
    padding-top: 140px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-7 {
    padding-top: 140px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-7 {
    padding-top: 160px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-7 {
    padding-top: 160px;
    padding-bottom: 60px;
  }
}

.hero__area-7 .shape-1 {
  position: absolute;
  right: 100px;
  bottom: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__area-7 .shape-1 {
    right: 60px;
    max-width: 260px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__area-7 .shape-1 {
    max-width: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-7 .shape-1 {
    max-width: 200px;
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-7 .shape-1 {
    max-width: 120px;
    bottom: unset;
    top: 330px;
    right: 0;
  }
}

.hero__title-7 {
  font-weight: 500;
  font-size: 130px;
  line-height: 1;
  color: var(--black-2);
  max-width: 1060px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__title-7 {
    font-size: 110px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__title-7 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__title-7 {
    font-size: 70px;
  }

  .hero__title-7 img {
    max-width: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__title-7 {
    font-size: 48px;
  }

  .hero__title-7 img {
    max-width: 60px;
  }
}

.hero__text-7 {
  margin-left: 210px;
  margin-top: 60px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 200px 20px auto;
  grid-template-columns: 200px auto;
  grid-gap: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__text-7 {
    margin-left: 60px;
    -ms-grid-columns: 160px 20px auto;
    grid-template-columns: 160px auto;
  }
}

@media only screen and (max-width: 767px) {
  .hero__text-7 {
    margin-left: 0;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.hero__text-7 .about {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-2);
  position: relative;
  text-transform: capitalize;
}

.hero__text-7 .about::after {
  position: absolute;
  content: "";
  width: 90px;
  height: 1px;
  top: 14px;
  left: 60px;
  background-color: var(--black-2);
  padding-left: 9px;
}

.hero__text-7 p {
  max-width: 350px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-3);
}

.video__area img {
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 1920px) {
  .video__area img {
    height: 800px;
  }
}

.video__area.about__img-2 {
  margin-bottom: 0;
  max-width: 100%;
  width: 100%;
}

.about__area-7 {
  padding: 30px 0 30px 0;
  position: relative;
}

.about__area-7 a {
  color: #141f3d;
}

.about__area-7 .sec-title-wrapper {
  text-align: center;
  padding-bottom: 70px;
}

.about__area-7 .shape-1 {
  position: absolute;
  left: 100px;
  top: 280px;
}

.about__left-7 {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 520px;
}

.about__left-7 img {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 120%;
  margin-left: auto;
  margin-right: auto;
}

.about__left-7 img {
  max-width: 100%;
}

.about__mid-7 {
  padding-left: 80px;
  padding-top: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__mid-7 {
    padding-left: 30px;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__mid-7 {
    padding-left: 10px;
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__mid-7 {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .about__mid-7 {
    padding-left: 0;
    padding-top: 40px;
  }
}

.about__mid-7 p {
  max-width: 440px;
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--black-3);
  padding-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__mid-7 p {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .about__mid-7 p {
    font-size: 1rem;
  }
}

.about__mid-7 img {
  display: block;
}

.about__mid-7 .signature {
  margin-top: 30px;
}

.about__mid-7 .image-1 {
  margin-left: auto;
  margin-top: 35px;
  -webkit-transform: translateX(25px);
  transform: translateX(25px);
}

@media only screen and (max-width: 767px) {
  .about__mid-7 .image-1 {
    margin-left: 0;
  }
}

.about__right-7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 140px;
}

@media only screen and (max-width: 767px) {
  .about__right-7 {
    margin-top: 10px;
    padding-bottom: 10px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.about__right-7 img {
  max-width: 100%;
}

.award__area-7 {
  background-color: #f9f9f9;
}

.award__area-7 .inherit_row {
  margin-left: -350px;
}

.award__area-7 .award-video-7 video {
  width: 100%;
}

.award__top-7 {
  position: relative;
}

.award__counter {
  width: 330px;
  height: 330px;
  background: var(--black-2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 6px;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .award__counter {
    width: 260px;
    height: 260px;
  }
}

@media only screen and (max-width: 767px) {
  .award__counter {
    width: 150px;
    height: 150px;
  }
}

.award__counter .counter__number {
  font-weight: 500;
  font-size: 100px;
  line-height: 1;
  color: var(--white);
}

@media only screen and (max-width: 767px) {
  .award__counter .counter__number {
    font-size: 50px;
  }
}

.award__counter p {
  font-size: 1.25rem;
  line-height: 1.4;
  color: var(--white);
  padding-top: 15px;
}

@media only screen and (max-width: 767px) {
  .award__counter p {
    font-size: 1rem;
    padding-top: 5px;
  }
}

.award__mid-7 p {
  font-size: 1.125rem;
  color: var(--black-2);
  max-width: 300px;
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .award__mid-7 p {
    font-size: 1rem;
    margin-left: 15px;
    padding-top: 15px;
    padding-bottom: 40px;
  }
}

.award__btm-7 {
  background-color: #eee5e2;
  position: relative;
  padding-left: 110px;
  padding-bottom: 70px;
  padding-top: 420px;
  margin-top: -330px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .award__btm-7 {
    padding-left: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .award__btm-7 {
    padding-left: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .award__btm-7 {
    padding-left: 30px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .award__btm-7 {
    padding-left: 30px;
    padding-bottom: 40px;
    padding-top: 370px;
  }
}

.award__btm-7::before {
  position: absolute;
  content: "";
  width: 500px;
  height: 100%;
  right: 100%;
  top: 0;
  background-color: var(--white);
}

.award__btm-7::after {
  position: absolute;
  content: "";
  width: 500px;
  height: 100%;
  left: 99%;
  top: 0;
  background-color: #eee5e2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .award__right-7 {
    padding-top: 60px;
  }
}

.award__list-7 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .award__list-7 {
    grid-gap: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .award__list-7 {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.award__item-7 {
  text-align: center;
  margin-bottom: 20px;
}

.award__item-7 img {
  max-width: 100%;
  margin-bottom: 20px;
}

.award__item-7 .title {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black-2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .award__item-7 .title {
    font-size: 14px;
  }
}

.team__area-7 .sec-text {
  padding-left: 60%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__area-7 .sec-text {
    padding-left: 45%;
  }
}

@media only screen and (max-width: 767px) {
  .team__area-7 .sec-text {
    padding-left: 60px;
    padding-top: 20px;
  }
}

.team__area-7 .sec-text p {
  max-width: 300px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-3);
}

@media only screen and (max-width: 767px) {
  .team__area-7 .sec-text p {
    font-size: 1rem;
  }
}

.team__title-7 {
  font-weight: 500;
  font-size: 700px;
  line-height: 0.9;
  text-align: center;
  color: var(--black-2);
  text-transform: uppercase;
  position: relative;
  z-index: 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .team__title-7 {
    font-size: 520px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__title-7 {
    font-size: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__title-7 {
    font-size: 360px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__title-7 {
    font-size: 260px;
  }
}

@media only screen and (max-width: 767px) {
  .team__title-7 {
    font-size: 120px;
  }
}

.team__items-7 a {
  display: block;
  border-bottom: 1px solid var(--white-6);
}

.team__items-7 a:first-child {
  border-top: 1px solid var(--white-6);
}

.team__item-7 {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.9fr 1fr 0.1fr;
  grid-template-columns: 0.9fr 1fr 0.1fr;
  padding: 18px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__item-7 {
    -ms-grid-columns: 1.5fr 1fr 0.1fr;
    grid-template-columns: 1.5fr 1fr 0.1fr;
  }
}

@media only screen and (max-width: 767px) {
  .team__item-7 {
    padding: 12px 0;
    -ms-grid-columns: 1.5fr 1fr 0.1fr;
    grid-template-columns: 1.5fr 1fr 0.1fr;
  }
}

.team__item-7:hover .team__hover-7 {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .team__item-7:hover .team__hover-7 {
    opacity: 0;
  }
}

.team__item-7:hover .tm-serial {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .team__item-7:hover .tm-serial {
    opacity: 0;
  }
}

.team__item-7:hover .tm-name {
  padding-left: 90px;
}

@media only screen and (max-width: 767px) {
  .team__item-7:hover .tm-name {
    padding-left: 0;
  }
}

.team__item-7:hover .tm-name::after {
  width: 50px;
}

@media only screen and (max-width: 767px) {
  .team__item-7:hover .tm-name::after {
    width: 0;
  }
}

.team__item-7:hover .tm-link i {
  color: var(--black-2);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team__item-7 .tm-name {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.3;
  color: var(--black-2);
  text-transform: capitalize;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .team__item-7 .tm-name {
    font-size: 1.25rem;
  }
}

.team__item-7 .tm-name::after {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  left: 25px;
  top: 18px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-color: var(--black-2);
}

.team__item-7 .tm-serial {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.team__item-7 .tm-role {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.7;
  color: var(--black-3);
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .team__item-7 .tm-role br {
    display: block;
  }
}

.team__item-7 .tm-link {
  color: var(--gray-2);
  font-size: 1.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.team__item-7 .tm-link i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.team7__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.team7__img-wrap {
  width: 20vw;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  padding-bottom: 25vw;
}

@media only screen and (max-width: 767px) {
  .team7__img-wrap {
    display: none;
    position: unset;
  }
}

.team__hover-7 {
  width: 300px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 60%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 75%;
  opacity: 0;
  -webkit-transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  margin: -200px 0 0 -150px;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
}

@media (max-width: 1200px) {
  .team__hover-7 {
    width: 0;
    display: none;
  }
}

.service__area-7 {
  background-color: #f9f9f9;
  padding-bottom: 50px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-7 {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-7 {
    padding-bottom: 0;
  }
}

.service__area-7::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: 36%;
  bottom: 0;
  background: var(--white-6);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__area-7::before {
    left: 32%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__area-7::before {
    left: 35%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-7::before {
    left: 33%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-7::before {
    left: 34%;
  }
}

@media only screen and (max-width: 767px) {
  .service__area-7::before {
    display: none;
  }
}

.service__area-7::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: 59%;
  bottom: 0;
  background: var(--white-6);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .service__area-7::after {
    left: 62%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__area-7::after {
    left: 62%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__area-7::after {
    left: 63%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area-7::after {
    left: 64%;
  }
}

@media only screen and (max-width: 767px) {
  .service__area-7::after {
    display: none;
  }
}

.service__items-7 {
  display: -ms-grid;
  display: grid;
  /* -ms-grid-columns: (1fr) [3]; */
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 767px) {
  .service__items-7 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.service__item-7 {
  padding-bottom: 90px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__item-7 {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service__item-7 {
    padding-bottom: 60px;
  }
}

.service__item-7::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 35px;
  background: var(--white-6);
}

@media only screen and (max-width: 767px) {
  .service__item-7::after {
    bottom: 25px;
  }
}

.service__item-7:nth-last-child(-n + 3)::after {
  position: unset;
}

@media only screen and (max-width: 767px) {
  .service__item-7:nth-last-child(-n + 3)::after {
    position: absolute;
  }
}

.service__item-7 p {
  max-width: 340px;
  font-size: 1.125rem;
  padding-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-7 p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .service__item-7 p {
    font-size: 1rem;
  }
}

.service__item-7 ul li {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black-2);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-7 ul li {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .service__item-7 ul li {
    font-size: 1rem;
  }
}

.service__title-7 {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.3;
  color: var(--black-2);
  padding-bottom: 25px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-transform: capitalize;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__title-7 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__title-7 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service__title-7 {
    font-size: 30px;
  }
}

.service__title-7:hover span {
  padding-left: 0;
}

@media only screen and (max-width: 767px) {
  .service__title-7:hover span {
    padding-left: 50px;
  }
}

.service__title-7:hover span::before {
  width: 0;
}

.service__title-7 span {
  display: block;
  position: relative;
  padding-left: 50px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service__title-7 span::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 1px;
  left: 0;
  top: 25px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-color: var(--black-2);
}

.portfolio__area-7 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-7 .pb-140 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-7 {
    margin-bottom: 90px;
  }
}

.portfolio__area-7 .sec-title {
  padding-bottom: 50px;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-7 .sec-title {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-7 .sec-title {
    padding-bottom: 30px;
  }
}

.portfolio__area-7 p {
  font-size: 1.125rem;
  max-width: 300px;
}

@media only screen and (max-width: 767px) {
  .portfolio__area-7 p {
    font-size: 1rem;
  }
}

.portfolio__slider-7 {
  margin-right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__slider-7 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__slider-7 {
    margin-right: 0;
  }
}

.portfolio__slide-7 {
  text-align: center;
}

.portfolio__slide-7:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.portfolio__slide-7 .slide-img {
  overflow: hidden;
  margin-bottom: 20px;
}

.portfolio__slide-7 img {
  width: 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.portfolio__slide-7 .title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.2;
  color: var(--black-2);
  padding-bottom: 5px;
}

.portfolio__slide-7 .date {
  font-size: 14px;
  color: var(--black-3);
  font-weight: 400;
  line-height: 1.5;
}

.cta__area-7 {
  background-color: var(--black-2);
}

.cta__area-7 .cta__sub-title {
  color: var(--gray-2);
}

.cta__area-7 .cta__title {
  color: var(--white);
}

.cta__area-7 .wc-btn-primary,
.cta__area-7 .wc-btn-secondary,
.cta__area-7 .wc-btn-pink,
.cta__area-7 .wc-btn-light,
.cta__area-7 .wc-btn-black {
  border-color: var(--secondary);
  color: var(--gray-2);
}

.cta__area-7 .wc-btn-primary:hover,
.cta__area-7 .wc-btn-secondary:hover,
.cta__area-7 .wc-btn-pink:hover,
.cta__area-7 .wc-btn-light:hover,
.cta__area-7 .wc-btn-black:hover {
  color: var(--black-2);
}

.blog__area-7 .sec-title-wrapper {
  padding-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .blog__area-7 .sec-title-wrapper {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area-7 .pb-140 {
    padding-bottom: 20px;
  }
}

.dark .header__area-7.sticky-7 {
  border-color: var(--dark-theme);
}

.dark .award__left-7 .sec-title {
  color: var(--black-2);
}

 .dark .main-menu-4 li a {
  padding-left: 5px;
  color: var(--white);
}

.dark .main-menu-4 li button {
  color: var(--white);
}

.dark .main-menu-4 li a .menu-text {
  text-shadow: 0 16px 0 var(--white);
}

.dark .header__nav-icon-7 img {
  width: 2rem;
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .header__area-7.sticky-7 {
  background: var(--dark-theme);
}

.dark .hero__area-7 {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .header__area-7 .contact-area {
  border-bottom: 1px solid #32436e;
}

.dark .header__area-7 .contact-area .contact-details li:last-child {
  border-left: 2px solid var(--white);
}

.dark .header__area-7 .contact-area ul li {
  color: var(--white);
}

.dark .header__area-7 .contact-area ul li a {
  color: var(--white);
}

.dark .header__area-7 .contact-area ul li button {
  color: var(--white);
}

.dark .about__mid-7 p,
.dark .service__item-7 ul li {
  color: var(--gray-2);
}

.dark .service__area-7 {
  background-color: var(--d-black-2);
}

.dark .service__area-7::before,
.dark .service__area-7::after {
  background-color: var(--secondary);
}

.dark .service__item-7::after {
  background-color: var(--secondary);
}

.dark .service__title-7 {
  color: var(--white);
}

.dark .service__title-7 span::before {
  background-color: var(--white);
}

.dark .award__area-7 {
  background-color: var(--dark-theme);
}

.dark .award__btm-7::before {
  background-color: var(--black-2);
}

.dark .award__counter {
  background-color: var(--dark-theme2);
}

.dark .portfolio__slide-7 .date {
  color: var(--gray-2);
}

.dark .portfolio__slide-7 .title {
  color: var(--white);
}

.dark .team__area-7 .sec-text p {
  color: var(--gray-2);
}

.dark .team__title-7 {
  color: var(--white);
}

.dark .team__items-7 a {
  border-color: var(--secondary);
}

.dark .team__item-7:hover .tm-link i {
  color: var(--white);
}

.dark .team__item-7 .tm-name {
  color: var(--white);
}

.dark .team__item-7 .tm-name::after {
  background-color: var(--white);
}

.dark .team__item-7 .tm-role {
  color: var(--gray-2);
}

.dark .cta__area-7 {
  background-color: var(--d-black-2);
}

.portfolio__area-11 {
  text-align: center;
  position: relative;
}

.portfolio__area-12 {
  padding: 180px 35px 110px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__area-12 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-12 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-12 {
    padding: 150px 15px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-12 {
    padding: 130px 0 40px;
  }
}

.portfolio__area-12 .sec-title-wrapper .sub-title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--black-2);
  position: relative;
}

.portfolio__area-12 .sec-title-wrapper .sub-title::after {
  position: absolute;
  content: "";
  width: 150px;
  height: 1px;
  background-color: var(--black-2);
  top: 13px;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .portfolio__area-12 .sec-title-wrapper .sub-title::after {
    width: 70px;
  }
}

.portfolio__area-12 .sec-title-wrapper .title {
  font-weight: 500;
  font-size: 350px;
  line-height: 0.7;
  text-transform: uppercase;
  color: var(--black-2);
  padding-bottom: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__area-12 .sec-title-wrapper .title {
    font-size: 260px;
    padding-bottom: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-12 .sec-title-wrapper .title {
    font-size: 220px;
    padding-bottom: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-12 .sec-title-wrapper .title {
    font-size: 150px;
    line-height: 0.9;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-12 .sec-title-wrapper .title {
    font-size: 60px;
    line-height: 1;
    padding-bottom: 20px;
  }
}

.portfolio__area-12 .sec-title-wrapper .title span {
  color: transparent;
  -webkit-text-stroke: 2px var(--black-2);
}

.portfolio__area-12 .sec-title-wrapper p {
  max-width: 320px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  text-indent: 60px;
  color: var(--black-2);
  margin-left: 500px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__area-12 .sec-title-wrapper p {
    margin-left: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-12 .sec-title-wrapper p {
    margin-left: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-12 .sec-title-wrapper p {
    margin-left: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-12 .sec-title-wrapper p {
    text-indent: 30px;
    margin-left: 30px;
  }
}

.portfolio__slider-11 img {
  height: 100vh;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.portfolio__slider-11-title {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  pointer-events: none;
}

.portfolio__slider-11-title .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.portfolio__slider-11-title .swiper-slide-thumb-active .portfolio__title-11 {
  font-size: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__slider-11-title .swiper-slide-thumb-active .portfolio__title-11 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__slider-11-title .swiper-slide-thumb-active .portfolio__title-11 {
    font-size: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__slider-11-title .swiper-slide-thumb-active .portfolio__title-11 {
    font-size: 36px;
  }
}

.portfolio__title-11 {
  font-weight: 500;
  font-size: 50px;
  line-height: 1.1;
  color: var(--white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding-bottom: 10px;
  text-transform: uppercase;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__title-11 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__title-11 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__title-11 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__title-11 {
    font-size: 1.5rem;
  }
}

.portfolio__title-11 span {
  font-weight: 400;
  font-family: "newYork";
}

.portfolio__title-12 {
  font-weight: 500;
  font-size: 150px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--black-2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.portfolio__title-12 span {
  font-weight: 400;
  font-family: "newYork";
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__title-12 {
    font-size: 110px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__title-12 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__title-12 {
    font-size: 72px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__title-12 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__title-12 {
    font-size: 36px;
  }
}

.portfolio__sub-title-11 {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
}

.portfolio__list-12 {
  padding-top: 140px;
  border-bottom: 1px solid var(--gray-11);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__list-12 {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__list-12 {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__list-12 {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__list-12 {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__list-12 {
    padding-top: 60px;
  }
}

.portfolio__item-12 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 160px auto 200px;
  grid-template-columns: 160px auto 200px;
  padding: 55px 0 50px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-top: 1px solid var(--gray-11);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__item-12 {
    -ms-grid-columns: 130px auto 120px;
    grid-template-columns: 130px auto 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__item-12 {
    padding: 40px 0;
    -ms-grid-columns: 100px auto 100px;
    grid-template-columns: 100px auto 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__item-12 {
    -ms-grid-columns: 90px auto 90px;
    grid-template-columns: 90px auto 90px;
    padding: 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__item-12 {
    -ms-grid-columns: 40px 20px auto 20px 80px;
    grid-template-columns: 40px auto 80px;
    gap: 20px;
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__item-12 {
    padding: 20px 0;
    grid-gap: 15px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.portfolio__item-12:hover .portfolio__title-12 {
  letter-spacing: 1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.portfolio__item-12:hover .portfolio__hover-12 {
  opacity: 1;
}

.portfolio__item-12 p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: var(--black-3);
  text-transform: uppercase;
}

.portfolio__hover-12 {
  width: 300px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 75%;
  opacity: 0;
  -webkit-transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  margin: -200px 0 0 -150px;
  overflow: hidden;
  pointer-events: none;
}

@media (max-width: 1200px) {
  .portfolio__hover-12 {
    width: 0;
    display: none;
  }
}

.portfolio__no-12 {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 32px;
  text-transform: uppercase;
  color: var(--black-3);
}

.portfolio__nitems {
  display: block;
}

.portfolio__wrapper-11 {
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  padding-top: 70px;
}

.portfolio__wrapper-11::-webkit-scrollbar {
  width: 0px;
}

.portfolio__nitem.active .portfolio__title-11 {
  font-size: 150px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__nitem.active .portfolio__title-11 {
    font-size: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__nitem.active .portfolio__title-11 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__nitem.active .portfolio__title-11 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__nitem.active .portfolio__title-11 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__nitem.active .portfolio__title-11 {
    font-size: 30px;
  }
}

.portfolio__nitem.active .pitem-bg {
  opacity: 1;
  visibility: visible;
}

.portfolio__nitem a {
  padding: 30px 0;
  display: inline-block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .portfolio__nitem a {
    padding: 20px 0;
  }
}

.portfolio__nitem .pitem-bg {
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.hover_img {
  width: 300px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 75%;
  opacity: 0;
  -webkit-transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  margin: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

@media (max-width: 1200px) {
  .hover_img {
    width: 0;
    display: none;
  }
}

.hover_wrap {
  position: relative;
}

.hover_wrap:hover .hover_img {
  opacity: 1;
}

.hover_8_wrap {
  position: relative;
}

.hover_8_img,
.hover_8_img_2 {
  width: 300px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
  margin-left: -250px;
  opacity: 0;
}

.hover_8_img.active,
.active.hover_8_img_2 {
  opacity: 1;
}

@media (max-width: 1200px) {
  .hover_8_img,
  .hover_8_img_2 {
    width: 0;
    display: none;
  }
}

.hover_8_img_2 {
  top: 100px;
}

.work__area-8 .about-sub-right {
  color: var(--black-3);
  padding-bottom: 10px;
}

.scroll-top-2 {
  width: 115px;
  position: fixed;
  right: 100px;
  bottom: 20px;
  z-index: 9991;
  padding: 10px;
  cursor: pointer;
  display: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.scroll-top-2 i {
  font-size: 1.125rem;
  color: var(--black-2);
}

.scroll-top-2:hover {
  color: var(--gray-2);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .scroll-top-2 {
    right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .scroll-top-2 {
    right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .scroll-top-2 {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .scroll-top-2 {
    right: 10px;
    bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .scroll-top-2 {
    right: 10px;
    bottom: 15px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    padding: 5px;
    background-color: var(--white);
    mix-blend-mode: exclusion;
  }
}

.scroll-top-2 p {
  color: var(--black-2);
  text-align: left;
}

.scroll-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .scroll-inner {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .scroll-inner p {
    display: none;
  }

  .scroll-inner img {
    max-width: 100%;
    height: 30px;
  }
}

#main .part .section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.part p {
  opacity: 0;
}

.part h2 {
  opacity: 0;
}

.part.showed h2 {
  opacity: 1;
  padding-left: 30px;
  padding-bottom: 30px;
  font-weight: 500;
  font-size: 36px;
  line-height: 1.3;
  color: var(--white);
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .part.showed h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .part.showed h2 {
    font-size: 30px;
    padding-left: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .part.showed h2 {
    font-size: 1.5rem;
    padding-left: 15px;
    padding-bottom: 20px;
  }
}

.part.showed h2 span {
  display: block;
  position: relative;
  padding-left: 90px;
  font-family: "newYork";
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .part.showed h2 span {
    padding-left: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .part.showed h2 span {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .part.showed h2 span {
    padding-left: 30px;
  }
}

.part.showed h2 span::before {
  position: absolute;
  content: "";
  width: 70px;
  height: 2px;
  left: 0;
  top: 22px;
  background-color: var(--white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .part.showed h2 span::before {
    width: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .part.showed h2 span::before {
    width: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .part.showed h2 span::before {
    width: 25px;
  }
}

.part.showed p {
  opacity: 1;
  padding-left: 120px;
  padding-bottom: 60px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .part.showed p {
    padding-left: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .part.showed p {
    padding-left: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .part.showed p {
    padding-left: 20px;
  }
}

.showcase__area-4 {
  padding: 130px 50px 110px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .showcase__area-4 {
    padding: 120px 50px 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .showcase__area-4 {
    padding: 90px 50px 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .showcase__area-4 {
    padding: 80px 30px 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .showcase__area-4 {
    padding: 80px 20px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase__area-4 {
    padding: 60px 0 80px;
  }
}

.showcase__area-4 .row {
  --bs-gutter-x: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .showcase__area-4 .row {
    --bs-gutter-x: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .showcase__area-4 .row {
    --bs-gutter-x: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase__area-4 .row {
    --bs-gutter-x: 30px;
  }
}

.showcase__items-4 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 90px 1fr 90px 1fr 90px 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 90px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .showcase__items-4 {
    grid-gap: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .showcase__items-4 {
    grid-gap: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .showcase__items-4 {
    grid-gap: 15px;
    -ms-grid-columns: 1fr 15px 1fr 15px 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .showcase__items-4 {
    grid-gap: 15px;
    -ms-grid-columns: 1fr 15px 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
}

.showcase__item-4 {
  overflow: hidden;
}

.showcase__item-4:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.showcase__item-4 img {
  -webkit-transition: all 3s;
  transition: all 3s;
  max-width: 100%;
}

.showcase__menu-4 {
  gap: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .showcase__menu-4 {
    display: inline-block;
    z-index: 9;
    text-align: center;
    position: relative;
    background-color: var(--white);
  }
}

.showcase__menu-4 button {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1;
  color: var(--black);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: capitalize;
}

.showcase__menu-4 button:hover {
  letter-spacing: 1px;
  color: var(--black-2);
}

@media only screen and (max-width: 767px) {
  .showcase__menu-4 button {
    margin: 0 5px 10px 0;
  }
}

.showcase5__content img {
  -webkit-animation: wcSpinner 5s infinite linear;
  animation: wcSpinner 5s infinite linear;
}

.showcase_16_hover {
  width: 300px;
  height: 400px;
  position: absolute;
  top: 100px;
  left: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 75%;
  -webkit-transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s,
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
}

.showcase_16_hover.active {
  opacity: 1;
}

@media (max-width: 1365px) {
  .showcase_16_hover {
    display: none;
  }
}

.mixtup_btn#btn_wrapper,
.mixtup_btn.btn_wrapper {
  margin: 0 auto;
}

.mixtup_btn .wc-btn {
  display: inline-block;
  width: 170px;
  height: 170px;
  border: 1px solid #c2c2c2;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #555555;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 auto;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 1365px) {
  .mixtup_btn .wc-btn {
    width: 150px;
    height: 150px;
  }
}

.mixtup_btn .wc-btn span {
  display: block;
  font-size: 1.125rem;
  margin-bottom: -15px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media only screen and (max-width: 767px) {
  .mixtup_btn .wc-btn span {
    margin-bottom: -5px;
  }
}

.mixtup_btn .wc-btn:hover {
  color: var(--white);
  background-color: var(--black-2);
}

@media (max-width: 1365px) {
  .mixtup_btn {
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (max-width: 767px) {
  .mixtup_btn {
    margin-bottom: 30px;
  }
}

.dark .portfolio__area-12 .sec-title-wrapper .title {
  color: var(--white);
}

.dark .portfolio__area-12 .sec-title-wrapper .title span {
  -webkit-text-stroke: 2px var(--white);
}

.dark .portfolio__area-12 .sec-title-wrapper .sub-title {
  color: var(--white);
}

.dark .portfolio__area-12 .sec-title-wrapper .sub-title::after {
  background-color: var(--white);
}

.dark .portfolio__area-12 .sec-title-wrapper p {
  color: var(--white);
}

.dark .portfolio__title-12,
.dark .showcase__menu-4 button {
  color: var(--white);
}

.dark .rollslide_title {
  color: var(--white);
}

.dark .rollslide_title:hover {
  color: var(--black-3);
}

.dark .cat_links li a,
.dark .work__area-8 .about-sub-right,
.dark .footer__copyright-8 p a {
  color: var(--white);
}

.dark .cat_links li a:hover,
.dark .work__area-8 .about-sub-right:hover,
.dark .footer__copyright-8 p a:hover {
  color: var(--black-3);
}

.dark .scroll-top-2 {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.dark .portfolio__item-12 p,
.dark .portfolio__no-12 {
  color: var(--gray);
}

.dark .portfolio__list-12,
.dark .portfolio__item-12,
.dark .footer_categories,
.dark .footer__subscribe-2 input {
  border-color: var(--black-3);
}

.dark .footer_categories::before {
  background-color: var(--black-3);
}

.dark .mixtup_btn .wc-btn {
  color: var(--white);
  border-color: var(--secondary);
}

.dark .mixtup_btn .wc-btn:hover {
  color: var(--black-2);
  background-color: var(--white);
}

@media only screen and (max-width: 767px) {
  .dark .showcase__menu-4 {
    background-color: var(--black-2);
  }
}

body {
  position: relative;
}

.body-bg {
  position: fixed;
  z-index: 99999;
  pointer-events: none;
  top: 0;
  opacity: 1;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-repeat: repeat;
  background-position: top left;
  background-image: url(../imgs/essential-img/bg-axtra.png?v=0.1.8);
}

.portfolio__area-11 {
  overflow: hidden;
}

.portfolio__area-11 .gallary11__thumb {
  height: 100%;
}

.portfolio__area-11 .gallary11__thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.portfolio__area-11 .gallary11__thumb img:hover {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
  -webkit-transition: all 10s;
  transition: all 10s;
}

.portfolio__area-11 .swiper-container {
  width: 100%;
  height: 100vh;
  float: left;
  -webkit-transition: opacity 0.6s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.6s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.6s ease, transform 0.3s ease;
  transition: opacity 0.6s ease, transform 0.3s ease,
    -webkit-transform 0.3s ease;
}

.portfolio__area-11 .main-slider11 .swiper-image {
  width: 100%;
  height: 100%;
}

.portfolio__area-11 .swiper-slide {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.portfolio__area-11
  .swiper-slide.swiper-slide-active
  .gallary__thumb
  .portfolio__title-11 {
  font-size: 150px;
  line-height: 0.9;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .portfolio__area-11
    .swiper-slide.swiper-slide-active
    .gallary__thumb
    .portfolio__title-11 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__area-11
    .swiper-slide.swiper-slide-active
    .gallary__thumb
    .portfolio__title-11 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-11
    .swiper-slide.swiper-slide-active
    .gallary__thumb
    .portfolio__title-11 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-11
    .swiper-slide.swiper-slide-active
    .gallary__thumb
    .portfolio__title-11 {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-11
    .swiper-slide.swiper-slide-active
    .gallary__thumb
    .portfolio__title-11 {
    font-size: 30px;
  }
}

.portfolio__area-11 .swiper-container.thumb-slider11 {
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  pointer-events: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-11 .swiper-container.thumb-slider11 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-11 .swiper-container.thumb-slider11 {
    margin-top: 80px;
  }
}

.portfolio__area-11 .swiper-container.thumb-slider11 .swiper-slide {
  cursor: pointer;
}

.portfolio__area-11 .swiper-container.thumb-slider11 .swiper-slide-active {
  height: 175px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio__area-11 .swiper-container.thumb-slider11 .swiper-slide-active {
    height: 160px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio__area-11 .swiper-container.thumb-slider11 .swiper-slide-active {
    height: 150px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-11 .swiper-container.thumb-slider11 .swiper-slide-active {
    height: 125px !important;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-11 .swiper-container.thumb-slider11 .swiper-slide-active {
    height: 100px !important;
  }
}

.portfolio__area-11 .gallary12 {
  -webkit-box-orient: unset;
  -webkit-box-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__area-11 .gallary12 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__area-11 .gallary12 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.dir-rtl {
  direction: rtl;
}

.dir-rtl li {
  text-align: right;
}

.dir-rtl .scroll-top {
  right: unset;
  left: 20px;
}

.dir-rtl .wc-btn-primary i,
.dir-rtl .wc-btn-black i,
.dir-rtl .wc-btn-light i,
.dir-rtl .wc-btn-pink i,
.dir-rtl .wc-btn-secondary i {
  -webkit-transform: rotate(-130deg);
  transform: rotate(-130deg);
}

.dir-rtl .shape {
  left: 0;
  right: -90px;
}

.dir-rtl .shape .primary {
  margin-left: 0;
  margin-right: 24px;
}

.dir-rtl .btn-view i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .sec-title-4 span {
  padding-left: 0;
  padding-right: 298px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .sec-title-4 span {
    padding-right: 0;
  }
}

.dir-rtl .sec-title-5 {
  padding-left: 0;
  padding-right: 98px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .sec-title-5 {
    padding-right: 0;
  }
}

.dir-rtl .menu-text-pp {
  padding-right: 0;
  padding-left: 10px;
}

.dir-rtl .circle-pagination-2 {
  left: unset !important;
  right: 20px !important;
}

@media (max-width: 1919px) {
  .dir-rtl .circle-pagination-2 {
    right: 0 !important;
  }
}

.dir-rtl .menu-text-5 {
  padding-right: 0;
  padding-left: 10px;
}

.dir-rtl .btn-together i {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dir-rtl .header__area {
  right: 0;
  left: unset;
  border: none;
  border-left: 1px solid rgba(240, 240, 240, 0.07);
}

.dir-rtl .header__search {
  right: unset;
  left: 60px;
}

.dir-rtl .header__nav-2 {
  text-align: left;
}

.dir-rtl .header__nav-icon-7 .menu-icon-2::after {
  left: -60px;
  right: unset;
}

.dir-rtl .offcanvas__close {
  right: unset;
  left: 40px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .offcanvas__close {
    left: 15px;
  }
}

.dir-rtl .offcanvas__logo {
  padding: 55px 50px 55px 0;
}

.dir-rtl .offcanvas__social {
  padding-left: 0;
  padding-right: 50px;
}

.dir-rtl .offcanvas__links {
  padding-left: unset;
  padding-right: 50px;
}

.dir-rtl .offcanvas__left {
  border: none;
  border-left: 1px solid var(--black-4);
}

.dir-rtl .offcanvas__search button {
  right: unset;
  left: 0;
}

.dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 100px 20px 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    padding-right: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    padding-right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li a {
    padding-right: 20px;
  }
}

.dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li a.mean-expand {
  left: 0;
  right: unset;
  height: 120px;
  width: 84px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl
    .offcanvas__menu-wrapper.mean-container
    .mean-nav
    ul
    li
    a.mean-expand {
    height: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl
    .offcanvas__menu-wrapper.mean-container
    .mean-nav
    ul
    li
    a.mean-expand {
    height: 88px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl
    .offcanvas__menu-wrapper.mean-container
    .mean-nav
    ul
    li
    a.mean-expand {
    width: 70px;
    height: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl
    .offcanvas__menu-wrapper.mean-container
    .mean-nav
    ul
    li
    a.mean-expand {
    width: 60px;
    height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl
    .offcanvas__menu-wrapper.mean-container
    .mean-nav
    ul
    li
    a.mean-expand {
    width: 60px;
    height: 60px;
  }
}

.dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
  padding: 20px 150px 20px 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-right: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-right: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-right: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .offcanvas__menu-wrapper.mean-container .mean-nav ul li li a {
    padding-right: 40px;
  }
}

.dir-rtl
  .offcanvas__menu-wrapper.mean-container
  .mean-nav
  ul
  li
  li
  a.mean-expand {
  height: 63px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl
    .offcanvas__menu-wrapper.mean-container
    .mean-nav
    ul
    li
    li
    a.mean-expand {
    height: 59px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl
    .offcanvas__menu-wrapper.mean-container
    .mean-nav
    ul
    li
    li
    a.mean-expand {
    height: 57px;
  }
}

.dir-rtl .hero__area-3::after {
  right: unset;
  left: 50px;
}

.dir-rtl .hero__area-3::before {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.dir-rtl .hero__area-3 .hero3-img {
  right: unset;
  left: 0;
}

.dir-rtl .hero__area-3 .video-info {
  left: unset;
  right: 500px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .hero__area-3 .video-info {
    right: 330px;
  }
}

.dir-rtl .hero__area-3 .intro-title {
  left: 0;
}

.dir-rtl .hero__area-3 .title-right {
  right: unset;
  left: 0;
}

.dir-rtl .hero__area-3 .sec-sub-title {
  padding-right: 0;
  padding-left: 30px;
}

.dir-rtl .hero__area-3 .sec-sub-title::after {
  left: unset;
  right: 100%;
}

.dir-rtl .hero__area-2 .hero2-shape {
  max-width: 38%;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .hero__area-2 .hero2-shape {
    max-width: 35%;
  }
}

@media (max-width: 1400px) {
  .dir-rtl .hero__area-2 .hero2-shape {
    max-width: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .hero__area-2 .hero2-shape {
    max-width: 90%;
  }
}

.dir-rtl .hero__area-2 .sec-title-3:last-child {
  text-align: left;
  padding-top: 110px;
  padding-right: 0;
  margin-top: 30px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .hero__area-2 .sec-title-3:last-child {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .hero__area-2 .sec-title-3:last-child {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .hero__area-2 .sec-title-3:last-child {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .hero__area-2 .sec-title-3:last-child {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .hero__area-2 .sec-title-3:last-child {
    padding-top: 0;
  }
}

.dir-rtl .hero__area-7 .shape-1 {
  right: unset;
  left: 100px;
}

.dir-rtl .hero__area-10 .hero10_activition .swiper-pagination-total {
  width: 30px;
  margin-right: 10px;
}

.dir-rtl .hero__area-10 .hero10_activition .swiper-pagination-total::before {
  left: 20px;
}

.dir-rtl .hero__area-10 .hero10_activition .swiper-pagination-current {
  width: 30px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .hero__area-10 .hero10_activition .swiper-pagination-current {
    margin-left: 0;
  }
}

.dir-rtl .hero__area-10 .hero10_activition .swiper-pagination-current::before {
  left: 20px;
}

.dir-rtl .hero__area-10 .hero10_activition .swiper-button-next.hero_next {
  left: 0;
  right: unset;
}

.dir-rtl .hero__area-10 .hero10_activition .swiper-button-next.hero_next img {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .hero__area-15 .swiper-container {
  float: right;
}

.dir-rtl .hero__sub-title {
  left: 60px;
  right: unset;
}

.dir-rtl .hero__text-2 {
  padding-left: 0;
  padding-right: 32%;
  background-position: 35% 58%;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .hero__text-2 {
    padding-right: 30%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .hero__text-2 {
    padding-right: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .hero__text-2 {
    padding-right: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .hero__text-2 {
    padding-right: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .hero__text-2 {
    padding-right: 0%;
  }
}

.dir-rtl .hero__content .experience {
  right: unset;
  left: 30px;
}

.dir-rtl .hero__content a:hover i {
  -webkit-transform: translate(-18px, -18px) rotate(-135deg);
  transform: translate(-18px, -18px) rotate(-135deg);
}

.dir-rtl .hero__content a:hover span::after {
  -webkit-transform: translate(20px, -20px) rotate(-135deg);
  transform: translate(20px, -20px) rotate(-135deg);
}

.dir-rtl .hero__content a span {
  right: unset;
  left: 0;
}

.dir-rtl .hero__content a span i {
  -webkit-transform: translate(0, 0) rotate(-135deg);
  transform: translate(0, 0) rotate(-135deg);
}

.dir-rtl .hero__content a span::after {
  left: -20px;
  -webkit-transform: translate(45px, 5px) rotate(-135deg);
  transform: translate(45px, 5px) rotate(-135deg);
}

.dir-rtl .hero__content-8 .scroll-down {
  padding-left: unset;
  padding-right: 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .hero__content-8 .scroll-down {
    padding-right: 160px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .hero__content-8 .scroll-down {
    padding-right: 160px;
  }
}

.dir-rtl .hero__title::after {
  right: unset;
  left: -80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .hero__title::after {
    left: -30px;
  }
}

.dir-rtl .hero__arrow-icon {
  right: 95%;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .hero__arrow-icon {
    right: 70%;
  }
}

.dir-rtl .hero__about-text p {
  padding-left: 0;
  padding-right: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .hero__about-text p {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .hero__about-text p {
    padding-right: 0;
  }
}

.dir-rtl .hero__about-award::before {
  left: unset;
  right: 75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .hero__about-award::before {
    right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .hero__about-award::before {
    right: 20px;
  }
}

.dir-rtl .hero__about-row {
  position: inherit;
  margin-right: initial;
  margin-left: -310px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .hero__about-row {
    margin-left: -150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .hero__about-row {
    margin-left: -120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .hero__about-row {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .hero__about-row {
    margin-left: 0;
  }
}

.dir-rtl .hero__text-7 {
  margin-left: 0;
  margin-right: 210px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .hero__text-7 {
    margin-right: 60px;
    grid-gap: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .hero__text-7 {
    margin-right: 0;
  }
}

.dir-rtl .hero__text-7 .about::after {
  left: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .hero__text-7 .about::after {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .hero__text-7 .about::after {
    left: 54%;
  }
}

.dir-rtl .hero__area-10 .swiper-button-prev.hero_prev img {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .header__nav-icon-3 .search-icon {
  padding-left: 50px;
}

.dir-rtl .header__nav-icon-3 .search-icon::after {
  right: unset;
  left: 25px;
}

.dir-rtl .story__area .from-text {
  right: unset;
  left: -220px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .story__area .from-text {
    left: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .story__area .from-text {
    left: -100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .story__area .from-text {
    left: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .story__area .from-text {
    left: -10px;
  }
}

.dir-rtl .feature__content-right {
  padding: 143px 110px 0px 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .feature__content-right {
    padding: 60px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .feature__content-right {
    padding: 20px;
  }
}

.dir-rtl .feature__content-right p {
  padding-right: 0;
  padding-left: 242px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .feature__content-right p {
    padding-left: 225px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .feature__content-right p {
    padding-left: 145px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .feature__content-right p {
    padding-left: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .feature__content-right p {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .feature__content-right p {
    padding-left: 0;
  }
}

.dir-rtl .feature__content-btm {
  margin-left: 0;
  margin-right: 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .feature__content-btm {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .feature__content-btm {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .feature__content-btm {
    margin-right: 0;
  }
}

.dir-rtl .feature__text {
  padding-left: 0;
  padding-right: 85px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .feature__text {
    padding-right: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .feature__text {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .feature__text {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .feature__text {
    padding-right: 0;
  }
}

.dir-rtl .feature__title-6 {
  padding-left: 0;
  padding-right: 60px;
}

.dir-rtl .feature__title-6::before {
  left: unset;
  right: 0;
}

.dir-rtl .feature__img-1 {
  right: unset;
  left: 10%;
}

.dir-rtl .feature__img-3 {
  right: unset;
  left: 6%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .feature__item {
    border-right: 1px solid var(--black-2);
  }

  .dir-rtl .feature__item:nth-child(odd) {
    border-right: 0;
  }
}

.dir-rtl .feature__item:first-child {
  border-right: none;
}

.dir-rtl .feature__item:last-child {
  padding: 145px 30px;
  border-right: 1px solid var(--black-2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .feature__item:last-child {
    padding: 90px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .feature__item:last-child {
    padding: 90px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .feature__item:last-child {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .feature__item:last-child {
    padding: 0;
    border: 0;
    padding-bottom: 40px;
  }
}

.dir-rtl .about__area-3::after {
  left: 40%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .about__area-3::after {
    left: 45%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .about__area-3::after {
    left: 50%;
  }
}

.dir-rtl .about__area-3 .sec-text-wrapper {
  padding-right: 210px;
  padding-left: 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .about__area-3 .sec-text-wrapper {
    padding-right: 160px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .about__area-3 .sec-text-wrapper {
    padding-right: 160px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .about__area-3 .sec-text-wrapper {
    padding-right: 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .about__area-3 .sec-text-wrapper {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .about__area-3 .sec-text-wrapper {
    padding-right: 0;
  }
}

.dir-rtl .about__area-2 .sec-text-wrapper {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.dir-rtl .about__area-8 .about-sub-right {
  text-align: left !important;
}

.dir-rtl .about__title-wrapper {
  padding-left: 0;
  padding-right: 190px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .about__title-wrapper {
    padding-right: 0;
  }
}

.dir-rtl .about__img-right {
  right: unset;
  left: 0;
}

.dir-rtl .about__mid-7 {
  padding-left: 0;
  padding-right: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .about__mid-7 {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .about__mid-7 {
    padding-right: 10px;
  }
}

@media (max-width: 1023px) {
  .dir-rtl .about__mid-7 {
    padding-right: 0;
  }
}

.dir-rtl .about__mid-7 .image-1 {
  margin-left: 0;
  margin-right: auto;
}

.dir-rtl .about8__content {
  float: left;
}

.dir-rtl .work__area-8 .about-sub-right {
  text-align: left !important;
}

.dir-rtl .work8__title {
  margin-left: 0;
  margin-right: 440px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .work8__title {
    margin-right: 20px;
  }
}

.dir-rtl .service__area-3 .sec-title-wrapper {
  padding-left: 0;
  padding-right: 330px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .service__area-3 .sec-title-wrapper {
    padding-right: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__area-3 .sec-title-wrapper {
    padding-right: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .service__area-3 .sec-title-wrapper {
    padding-right: 0;
  }
}

.dir-rtl .service__area-3 .sec-title-wrapper::after {
  left: 62%;
}

.dir-rtl .service__area-3 .sec-title-wrapper p {
  margin-left: 0;
  margin-right: 220px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .service__area-3 .sec-title-wrapper p {
    margin-right: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__area-3 .sec-title-wrapper p {
    margin-right: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .service__area-3 .sec-title-wrapper p {
    margin-right: 20px;
  }
}

.dir-rtl .service__area-6 {
  padding-left: 0;
}

.dir-rtl .service__area-6 .inherit-row {
  margin-right: -180px;
  margin-left: 0;
}

@media (max-width: 1919px) {
  .dir-rtl .service__area-6 .inherit-row {
    margin-right: calc(-0.5 * var(--bs-gutter-x));
  }
}

.dir-rtl .service__area-6 .right-content {
  padding-left: 0;
  padding-right: 80px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .service__area-6 .right-content {
    padding-right: 40px;
  }
}

@media (max-width: 1400px) {
  .dir-rtl .service__area-6 .right-content {
    padding-right: 0;
  }
}

.dir-rtl .service__area-7::before {
  left: 42%;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .service__area-7::before {
    left: 39%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .service__area-7::before {
    left: 38%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .service__area-7::before {
    left: 34%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__area-7::before {
    left: 36%;
  }
}

.dir-rtl .service__area-7::after {
  left: 64%;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .service__area-7::after {
    left: 69%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .service__area-7::after {
    left: 65%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__area-7::after {
    left: 66%;
  }
}

.dir-rtl .service__top-btn #btn_wrapper,
.dir-rtl .service__top-btn .btn_wrapper {
  margin-left: -40px;
  margin-right: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__top-btn #btn_wrapper,
  .dir-rtl .service__top-btn .btn_wrapper {
    margin-left: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .service__top-btn #btn_wrapper,
  .dir-rtl .service__top-btn .btn_wrapper {
    margin-left: auto;
    margin-right: 0;
  }
}

.dir-rtl .service__link {
  text-align: left;
}

.dir-rtl .service__link p::after {
  -webkit-transform: translate(80px, 34px) rotate(-135deg);
  transform: translate(80px, 34px) rotate(-135deg);
}

.dir-rtl .service__link i {
  -webkit-transform: translate(0, 0) rotate(-135deg);
  transform: translate(0, 0) rotate(-135deg);
}

.dir-rtl .service__item-icon i {
  font-size: 23px;
  color: var(--black-2);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .service__item:hover .service__link p::after {
  -webkit-transform: translate(30px, -20px) rotate(-135deg);
  transform: translate(30px, -20px) rotate(-135deg);
}

.dir-rtl .service__item:hover .service__link i {
  -webkit-transform: translate(-38px, -40px) rotate(-135deg);
  transform: translate(-38px, -40px) rotate(-135deg);
}

.dir-rtl .service__item-2::before {
  left: -30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__item-2::before {
    left: 0;
  }
}

.dir-rtl .service__item-2:nth-child(3n + 1)::before {
  position: absolute;
}

.dir-rtl .service__item-2:last-child::before {
  position: unset;
}

.dir-rtl .service__item-2:nth-child(3)::before {
  position: unset;
}

/* .dir-rtl .service__item-4:hover .service__item-icon {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
} */

/* .dir-rtl .service__img {
  -webkit-transform: rotate(0deg) translateX(100px);
  transform: rotate(0deg) translateX(100px);
} */

/* .dir-rtl .service__img.active {
  -webkit-transform: rotate(-15deg) translateX(-60px) translateY(30px);
  transform: rotate(-15deg) translateX(-60px) translateY(30px);
} */

.dir-rtl .service__img-wrapper span {
  left: unset;
  right: 0;
  -webkit-transform: rotate(26deg) translate(-100%, -100%);
  transform: rotate(26deg) translate(-100%, -100%);
}

.dir-rtl .service__img-wrapper span.current {
  -webkit-transform: rotate(26deg) translate(170px, -100%);
  transform: rotate(26deg) translate(170px, -100%);
}

.dir-rtl .service__right-4 p {
  padding-right: 0;
  padding-left: 195px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .service__right-4 p {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__right-4 p {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .service__right-4 p {
    padding-left: 0;
  }
}

.dir-rtl .service__hero-2 .shape-1 {
  right: unset;
  left: 165px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__hero-2 .shape-1 {
    left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .service__hero-2 .shape-1 {
    left: 5px;
  }
}

.dir-rtl .service__hero-left-2 .image-1,
.dir-rtl .service__hero-left-2 .image-3 {
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .service__hero-left-2 .image-1,
  .dir-rtl .service__hero-left-2 .image-3 {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .service__hero-left-2 .image-1,
  .dir-rtl .service__hero-left-2 .image-3 {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__hero-left-2 .image-1,
  .dir-rtl .service__hero-left-2 .image-3 {
    -webkit-transform: translateX(25px);
    transform: translateX(25px);
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .service__hero-left-2 .image-1,
  .dir-rtl .service__hero-left-2 .image-3 {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.dir-rtl .service__hero-left-2 .image-2 {
  -webkit-transform: translateX(-50px);
  transform: translateX(-50px);
}

@media only screen and (max-width: 767px) {
  .dir-rtl .service__hero-left-2 .image-2 {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.dir-rtl .service__hero-left-2 .image-4 {
  right: 318px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .service__hero-left-2 .image-4 {
    right: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .service__hero-left-2 .image-4 {
    right: 268px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__hero-left-2 .image-4 {
    right: 215px;
  }
}

.dir-rtl .service__hero-right-2 p {
  margin-left: 0;
  margin-right: 350px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .service__hero-right-2 p {
    margin-right: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__hero-right-2 p {
    margin-right: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .service__hero-right-2 p {
    margin-right: 30px;
  }
}

.dir-rtl .service__hero-right-2 .title span {
  padding-left: 0;
  padding-right: 170px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .service__hero-right-2 .title span {
    padding-right: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .service__hero-right-2 .title span {
    padding-right: 40px;
  }
}

.dir-rtl .service__hero-right-2 .scroll {
  margin-left: 0;
  margin-right: 350px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .service__hero-right-2 .scroll {
    margin-right: 30px;
  }
}

.dir-rtl .service__title-7:hover span {
  padding-right: 0;
}

.dir-rtl .service__title-7 span {
  padding-left: 0;
  padding-right: 50px;
}

.dir-rtl .service__title-7 span::before {
  left: unset;
  right: 0;
}

.dir-rtl .service__detail-circle span::after {
  left: unset;
  right: 50%;
}

.dir-rtl .accordion-button {
  text-align: right;
}

.dir-rtl .faq__list-4 .accordion-button.collapsed::after {
  left: unset;
  right: 0;
}

.dir-rtl .faq__list-4 .accordion-button:not(.collapsed)::after {
  left: unset;
  right: 0;
}

.dir-rtl .faq__img6-2 {
  left: unset;
  right: 51px;
}

.dir-rtl .faq__img {
  padding-right: 0;
  padding-left: 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .faq__img {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .faq__img {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .faq__img {
    padding-left: 0;
  }
}

.dir-rtl .award__right > p {
  margin-left: 0;
  margin-right: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .award__right > p {
    margin-right: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .award__right > p {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .award__right > p {
    margin-right: 0;
  }
}

.dir-rtl .award__text-8 {
  right: unset;
  left: 50px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .award__text-8 {
    left: 10px;
  }
}

.dir-rtl .award__text-8 a:hover i {
  -webkit-transform: translate(-20px, -20px) rotate(-135deg);
  transform: translate(-20px, -20px) rotate(-135deg);
}

.dir-rtl .award__text-8 a:hover span::after {
  -webkit-transform: translate(20px, -20px) rotate(-135deg);
  transform: translate(20px, -20px) rotate(-135deg);
}

.dir-rtl .award__text-8 a span {
  right: unset;
  left: 0;
}

.dir-rtl .award__text-8 a span i {
  -webkit-transform: translate(0, 0) rotate(-135deg);
  transform: translate(0, 0) rotate(-135deg);
}

.dir-rtl .award__text-8 a span::after {
  -webkit-transform: translate(40px, 0) rotate(-135deg);
  transform: translate(40px, 0) rotate(-135deg);
}

.dir-rtl .award__item p:last-child {
  text-align: left;
}

.dir-rtl .award__mid-7 p {
  margin-left: 0;
  margin-right: 30px;
}

.dir-rtl .award__area-7 .inherit_row {
  margin-left: 0;
  margin-right: -350px;
}

.dir-rtl .award__counter {
  right: unset;
  left: 0;
}

.dir-rtl .award__btm-7 {
  padding-left: 0;
  padding-right: 110px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .award__btm-7 {
    padding-right: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .award__btm-7 {
    padding-right: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .award__btm-7 {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .award__btm-7 {
    padding-right: 30px;
  }
}

.dir-rtl .award__btm-7::after {
  left: unset;
  right: 99%;
}

.dir-rtl .award__btm-7::before {
  right: unset;
  left: 100%;
}

.dir-rtl .awards__area-8 .about-sub-right {
  text-align: left !important;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .team__area {
    padding-left: 0;
    padding-right: 62px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .team__area {
    padding-left: 0;
    padding-right: 62px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .team__area-6 {
    padding-left: 0;
    padding-right: 62px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .team__area-6 {
    padding-left: 0;
    padding-right: 62px;
  }
}

.dir-rtl .team__area-7 .sec-text {
  padding-left: 0;
  padding-right: 60%;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .team__area-7 .sec-text {
    padding-right: 60px;
  }
}

.dir-rtl .team__item-7:hover .tm-name {
  padding-left: 0;
  padding-right: 90px;
}

.dir-rtl .team__item-7:hover .tm-link i {
  -webkit-transform: rotate(215deg);
  transform: rotate(215deg);
}

.dir-rtl .team__item-7 .tm-serial {
  left: unset;
  right: 0;
}

.dir-rtl .team__item-7 .tm-name::after {
  left: unset;
  right: 25px;
}

.dir-rtl .team__item-7 .tm-link i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .team__hover-7 {
  left: -60%;
}

.dir-rtl .team__join-btn a i {
  right: unset;
  left: 130px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .team__join-btn a i {
    left: 50px;
  }
}

.dir-rtl .team__member-img {
  left: unset;
  right: -192px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .team__member-img {
    right: -150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .team__member-img {
    right: -228px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .team__member-img {
    right: 50%;
    -webkit-transform: translateX(25%);
    transform: translateX(25%);
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .team__member-img {
    right: 0;
  }
}

.dir-rtl .team__member-role-7::after {
  left: unset;
  right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .team__member-role-7::after {
    right: calc(50% - 75px);
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .team__member-role-7::after {
    right: calc(50% - 75px);
  }
}

.dir-rtl .cta__area-3 .wc-btn-black {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.dir-rtl .cta__area-3 .wc-btn-black i {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dir-rtl .circle-pagination.right {
  left: unset;
  right: calc(100% - 135px) !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .circle-pagination.right {
    right: calc(100% - 90px) !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .circle-pagination.right {
    right: calc(100% - 75px) !important;
  }
}

.dir-rtl .portfolio__area-2::after {
  left: 15.3%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__area-2 .line::before {
    left: unset;
    right: 0;
  }
}

.dir-rtl .portfolio__area-2 .sec-title-3 {
  padding-right: 0;
  padding-left: 320px;
  background-position: 25% 25px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__area-2 .sec-title-3 {
    padding-left: 0;
  }
}

.dir-rtl .portfolio__area-2 .sec-title-3 span {
  padding-left: 0;
  padding-right: 450px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .portfolio__area-2 .sec-title-3 span {
    padding-right: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__area-2 .sec-title-3 span {
    padding-right: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__area-2 .sec-title-3 span {
    padding-right: 0;
  }
}

.dir-rtl .portfolio__area-2 .sec-title-wrapper .sec-text {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__area-3 .swiper-pagination {
    left: 10px;
  }
}

.dir-rtl .portfolio__area-6 .sec-title-wrapper p {
  padding-left: 0;
  padding-right: 110px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__area-6 .sec-title-wrapper p {
    padding-right: 70px;
  }
}

.dir-rtl .portfolio__area-6 .sec-title-wrapper p::before {
  left: unset;
  right: 0;
}

.dir-rtl .portfolio__area-12 .sec-title-wrapper .sub-title::after {
  margin-left: unset;
  margin-right: 10px;
}

.dir-rtl .portfolio__area-12 .sec-title-wrapper p {
  margin-left: 0;
  margin-right: 500px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__area-12 .sec-title-wrapper p {
    margin-right: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__area-12 .sec-title-wrapper p {
    margin-right: 20px;
  }
}

.dir-rtl .portfolio__slide-3 {
  text-align: left;
  padding-right: 0;
  padding-left: 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .portfolio__slide-3 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__slide-3 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__slide-3 {
    padding-left: 0;
  }
}

.dir-rtl .portfolio__slide-2 .sec-title span {
  padding-left: 0;
  padding-right: 60px;
}

.dir-rtl .portfolio__slide-2 .sec-title span::before {
  left: unset;
  right: -10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__slide-2 .sec-title span::before {
    right: 0;
    width: 40px;
  }
}

.dir-rtl .portfolio__slide-2 .slide-img {
  padding-right: 0;
  padding-left: 15px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__slide-2 .slide-img {
    padding-left: 0;
  }
}

.dir-rtl .portfolio__slide-2 .slide-content {
  padding-left: 0;
  padding-right: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .portfolio__slide-2 .slide-content {
    padding-right: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__slide-2 .slide-content {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__slide-2 .slide-content {
    padding-right: 40px;
  }
}

.dir-rtl .portfolio__slide-2 .btn-common i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .portfolio__title-3 {
  text-align: right;
}

.dir-rtl .portfolio__title-3 span {
  padding-left: 0;
  padding-right: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__title-3 span {
    padding-right: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__title-3 span {
    padding-right: 60px;
  }
}

.dir-rtl .portfolio__hero-area::after {
  left: unset;
}

.dir-rtl .portfolio__about .sec-title span {
  padding-left: 0;
  padding-right: 120px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__about .sec-title span {
    display: inline-block;
    padding-right: 0;
  }
}

.dir-rtl .portfolio__about .sec-title span::before {
  left: unset;
  right: 0;
}

.dir-rtl .portfolio__about .brand-title-wrap {
  border: none;
  border-left: 1px solid var(--purple-2);
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__about .brand-title-wrap {
    border: none;
  }
}

.dir-rtl .portfolio__about .brand-list {
  padding-left: 0;
  padding-right: 80px;
}

@media (max-width: 1023px) {
  .dir-rtl .portfolio__about .brand-list {
    padding-right: 0;
  }
}

.dir-rtl .portfolio__about-left img {
  right: unset;
  left: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__about-left img {
    display: none;
  }
}

.dir-rtl .portfolio__page .swiper-slide-active .portfolio__about-left img {
  -webkit-transform: rotateY(180deg) !important;
  transform: rotateY(180deg) !important;
}

.dir-rtl .portfolio__project::after {
  top: 100px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .portfolio__project .pp-prev {
    right: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .portfolio__project .pp-prev {
    right: 60px;
  }
}

.dir-rtl .portfolio__project .pp-slider-wrapper {
  padding-left: 100px;
  padding-right: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .portfolio__project .pp-slider-wrapper {
    padding-left: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__project .pp-slider-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__project .pp-slider-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.dir-rtl .portfolio__project .pp-slide-thumb {
  margin-left: unset;
  margin-right: auto;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .portfolio__project .pp-slide-thumb {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .portfolio__project .pp-slide-thumb {
    margin-right: 20px;
  }
}

.dir-rtl .portfolio__project .pp-slide-thumb p {
  text-align: left;
}

.dir-rtl .portfolio__project .pp-slide-img img {
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}

.dir-rtl .portfolio__project .pp-slide-title {
  text-align: left;
}

.dir-rtl .portfolio__project-thumbs {
  margin-right: 0;
  margin-left: -150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .portfolio__project-thumbs {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__project-thumbs {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__project-thumbs {
    margin-left: 0;
  }
}

.dir-rtl .portfolio__project .pp-slide {
  padding: 80px 80px 80px 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .portfolio__project .pp-slide {
    padding: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__project .pp-slide {
    padding: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__project .pp-slide {
    padding: 60px;
  }
}

.dir-rtl .portfolio__hero .title.shape-circle::after {
  left: -50px;
  right: unset;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .portfolio__hero .title.shape-circle::after {
    left: -10px;
  }
}

@media (max-width: 1023px) {
  .dir-rtl .portfolio__hero .title.shape-circle::after {
    left: 0;
  }
}

.dir-rtl .portfolio__service .sec-text {
  background-image: url(../imgs/shape/25.png?v=0.1.8);
  background-position: right 80px;
}

@media (max-width: 1400px) {
  .dir-rtl .portfolio__service .sec-text {
    background-position: right 0;
  }
}

.dir-rtl .portfolio__footer-area::after {
  left: unset;
  right: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .portfolio__footer-area::after {
    right: 20px;
  }
}

.dir-rtl .portfolio__footer-area .pf-social h3::after {
  right: unset;
  left: -60px;
}

.dir-rtl .portfolio__icon-link {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .portfolio__item-4:hover .portfolio__icon-link {
  -webkit-transform: rotate(215deg);
  transform: rotate(215deg);
}

.dir-rtl .portfolio__item-12 p {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__item-12 p {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.dir-rtl .portfolio__detail .block-img-text p {
  padding-left: 0;
  padding-right: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .portfolio__detail .block-img-text p {
    padding-right: 0;
  }
}

.dir-rtl .portfolio__wrapper-6 {
  padding-left: 0;
  padding-right: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .portfolio__wrapper-6 {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .portfolio__wrapper-6 {
    padding-right: 0;
  }
}

.dir-rtl .testimonial__item.item-3 {
  text-align: left;
}

.dir-rtl .testimonial__slide {
  margin-left: -1px;
}

.dir-rtl .testimonial__slide-4 {
  text-align: right;
}

.dir-rtl .testimonial__img.b-left::before {
  left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .testimonial__img.b-left::before {
    left: 20px;
  }
}

.dir-rtl .testimonial__title-2 {
  padding: 100px 0px 20px 100px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .testimonial__title-2 {
    padding: 20px 0;
  }
}

.dir-rtl .testimonial__text-2 {
  padding-right: 0;
  padding-left: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .testimonial__text-2 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .testimonial__text-2 {
    padding-left: 0;
  }
}

.dir-rtl .testimonial__info-4 p {
  text-align: right;
}

.dir-rtl .testimonial__btn-4 i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .testimonial__video {
  margin-right: 0;
  margin-left: -112px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .testimonial__video {
    margin-left: 0;
  }
}

.dir-rtl .testimonial__pagination {
  left: 0;
  right: unset;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .testimonial__pagination {
    -webkit-transform: translate(-60%, -50%);
    transform: translate(-60%, -50%);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .testimonial__pagination {
    -webkit-transform: translate(-48%, -50%);
    transform: translate(-48%, -50%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .testimonial__pagination {
    -webkit-transform: translate(-40%, -50%);
    transform: translate(-40%, -50%);
  }
}

.dir-rtl .testimonial__inner-2 {
  background-position: 89% 80px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .testimonial__inner-2 {
    background-position: 50% 10px;
  }
}

.dir-rtl .testimonial__img.b-right::after {
  left: unset;
  right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .testimonial__img.b-right::after {
    right: 20px;
  }
}

.dir-rtl .price__icon span i {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

@media only screen and (max-width: 767px) {
  .dir-rtl .price__amount {
    right: unset;
    left: 30px;
  }
}

.dir-rtl .solution__area {
  padding-left: 0;
  padding-right: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .solution__area {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .solution__area {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.dir-rtl .solution__shape .shape-3 {
  left: 20%;
  top: 20%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .solution__shape .shape-3 {
    left: 5%;
    top: 15%;
  }
}

.dir-rtl .solution__list li {
  padding-left: 0;
  padding-right: 15px;
}

.dir-rtl .solution__list li::after {
  left: unset;
  right: 0;
}

.dir-rtl .solution__mid p {
  padding-left: 0;
  padding-right: 190px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .solution__mid p {
    padding-right: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .solution__mid p {
    padding-right: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .solution__mid p {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .solution__mid p {
    padding-right: 80px;
  }
}

.dir-rtl .solution__mid p::before {
  left: unset;
  right: 0;
}

.dir-rtl .solution__img-1::after {
  left: unset;
  right: 40px;
}

.dir-rtl .solution__img-3 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .solution__img-3 {
    float: left;
    -webkit-transform: rotate(180deg) translateY(60px);
    transform: rotate(180deg) translateY(60px);
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .solution__img-3 {
    float: left;
    -webkit-transform: rotate(180deg) translateY(60px);
    transform: rotate(180deg) translateY(60px);
  }
}

.dir-rtl .solution__btm ul {
  text-align: left;
}

.dir-rtl .brand__list-1 {
  border-left: 1px solid var(--white-3);
}

.dir-rtl .brand__list-2,
.dir-rtl .brand__list-3 {
  border: none;
}

.dir-rtl .research__area {
  background-position: calc(100% - 300px) calc(100% - 220px);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .research__area {
    background-position: calc(100% - 140px) calc(100% - 220px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .research__area {
    background-position: calc(100% - 100px) calc(100% - 140px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .research__area {
    background-position: calc(100% - 100px) calc(100% - 140px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .research__area {
    background-position: 98% 250px;
  }
}

.dir-rtl .research__area .row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.dir-rtl .research__area .sec-title-wrapper {
  text-align: right;
}

.dir-rtl .research__item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.dir-rtl .research__item:nth-child(odd) {
  padding-left: 0;
  padding-right: 100px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .research__item:nth-child(odd) {
    padding-right: 0;
  }
}

.dir-rtl .research__info {
  text-align: right;
}

.dir-rtl .research__tools {
  padding-left: 0;
  text-align: right;
  padding-right: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .research__tools {
    padding-right: 108px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .research__tools {
    padding-right: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .research__tools {
    padding-right: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .research__tools {
    padding-right: 0;
  }
}

.dir-rtl .counter__area-3 .sec-title-wrapper {
  text-align: right;
}

.dir-rtl .counter__wrapper-3 {
  padding-right: 30px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .counter__wrapper-3 {
    padding-right: 0;
  }
}

.dir-rtl .counter__item-3 {
  text-align: right;
}

.dir-rtl .counter__item {
  margin-left: 0;
  margin-right: -67px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .counter__item {
    margin-right: -60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .counter__item {
    margin-right: -25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .counter__item {
    margin-right: -27px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .counter__item {
    margin-right: 0;
  }
}

.dir-rtl .counter__item:first-child {
  margin-right: 0;
}

.dir-rtl .faq__list .accordion-button:not(.collapsed)::after,
.dir-rtl .faq__list-3 .accordion-button:not(.collapsed)::after,
.dir-rtl .faq__list-6 .accordion-button:not(.collapsed)::after,
.dir-rtl .faq__list .accordion-button.collapsed::after,
.dir-rtl .faq__list-3 .accordion-button.collapsed::after,
.dir-rtl .faq__list-6 .accordion-button.collapsed::after {
  right: unset;
  left: 0;
}

.dir-rtl .faq__list-3 {
  padding-right: 0;
  padding-left: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .faq__list-3 {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .faq__list-3 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .faq__list-3 {
    padding-left: 0;
  }
}

.dir-rtl .faq__list-3 .accordion-button:not(.collapsed)::after,
.dir-rtl .faq__list-3 .accordion-button.collapsed::after {
  right: unset;
  left: 0;
}

.dir-rtl .faq__list-6 .accordion-button:not(.collapsed)::after,
.dir-rtl .faq__list-6 .accordion-button.collapsed::after {
  right: unset;
  left: 0;
}

.dir-rtl .blog__area-2 #btn_wrapper,
.dir-rtl .blog__area-2 .btn_wrapper {
  margin-left: 0;
  margin-right: auto;
}

.dir-rtl .blog__area-3 .sec-title-wrapper {
  padding-left: 0;
  padding-right: 330px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .blog__area-3 .sec-title-wrapper {
    padding-right: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .blog__area-3 .sec-title-wrapper {
    padding-right: 0;
  }
}

.dir-rtl .blog__area-3 .sec-title-wrapper::after {
  left: 62%;
}

.dir-rtl .blog__area-3 .sec-title-wrapper p {
  margin-left: 0;
  margin-right: 220px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .blog__area-3 .sec-title-wrapper p {
    margin-right: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .blog__area-3 .sec-title-wrapper p {
    margin-right: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .blog__area-3 .sec-title-wrapper p {
    margin-right: 0;
  }
}

.dir-rtl .blog__area-4 .sec-title-wrapper p {
  padding-right: 0;
  padding-left: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .blog__area-4 .sec-title-wrapper p {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .blog__area-4 .sec-title-wrapper p {
    padding-left: 0;
  }
}

.dir-rtl .blog__detail-content ul li,
.dir-rtl .blog__detail-content ol li {
  padding-left: 0;
  padding-right: 25px;
}

.dir-rtl .blog__info-3 {
  width: 63%;
  left: 0;
  right: unset;
  margin-left: 0px;
  margin-right: -50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .blog__info-3 {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .blog__info-3 {
    width: 90%;
  }
}

.dir-rtl .blog__btn span {
  left: -28px;
  right: unset;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.dir-rtl .blog__detail-content ul li::before,
.dir-rtl .blog__detail-content ol li::before {
  left: unset;
  right: 0;
}

.dir-rtl .blog__detail-date span {
  padding-left: 0;
  padding-right: 25px;
}

.dir-rtl .blog__detail-date span::before {
  left: 0;
  right: 8px;
}

.dir-rtl .btn-started i,
.dir-rtl .blog__btn-4 i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .cta__list-left {
  padding-left: 0;
  padding-right: 15px;
}

.dir-rtl .cta__list-left li::before {
  left: unset;
  right: -15px;
}

.dir-rtl .cta__area-5::before {
  left: 43%;
}

.dir-rtl .footer__area-2 {
  background-image: unset;
  position: relative;
}

.dir-rtl .footer__area-2::before {
  position: absolute;
  content: "";
  height: 508px;
  width: 500px;
  left: 0px;
  top: 0;
  background-image: url(../imgs/shape/4.png?v=0.1.8);
  background-repeat: no-repeat;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .footer__widget-3 {
    padding: 50px 30px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .footer__widget-3 {
    margin-right: 0;
  }
}

.dir-rtl .footer__contact-3 {
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .footer__contact-3 {
    text-align: center;
  }
}

.dir-rtl .footer__subscribe {
  margin-right: 0;
  margin-left: -60px;
  padding-right: 0;
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .footer__subscribe {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .footer__subscribe {
    margin-left: -30px;
  }
}

.dir-rtl .footer__subscribe input {
  padding: 21px 15px 21px 50px;
}

.dir-rtl .footer__subscribe .subs-btn {
  right: unset;
  left: 20px;
}

.dir-rtl .footer__subscribe .subs-btn i {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.dir-rtl .footer__subscribe-2 {
  padding-left: 0;
  padding-right: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .footer__subscribe-2 {
    padding-right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .footer__subscribe-2 {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .footer__subscribe-2 {
    padding-right: 0;
  }
}

.dir-rtl .footer__subscribe-2::before {
  right: -20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .footer__subscribe-2::before {
    right: -80px;
  }
}

.dir-rtl .footer__subscribe-2 input {
  padding: 20px 0 20px 80px;
}

.dir-rtl .footer__subscribe-2 .submit {
  left: 0;
  right: unset;
}

.dir-rtl .footer__subscribe-2 .submit img {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .footer-line::after {
  right: 0;
  left: unset;
}

.dir-rtl .footer__inner {
  padding-left: 0;
  padding-right: 350px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .footer__inner {
    padding-right: 0;
  }
}

.dir-rtl .footer__top {
  text-align: left;
}

.dir-rtl .footer__row {
  margin-left: 0;
  margin-right: -500px;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .dir-rtl .footer__row {
    margin-right: -305px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .footer__row {
    margin-right: -330px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .footer__row {
    margin-right: -340px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .footer__row {
    margin-right: -375px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .footer__row {
    margin-right: 0;
  }
}

.dir-rtl .footer__copyright {
  margin-left: 0;
  margin-right: -67px;
  padding: 35px 66px 35px 0;
}

.dir-rtl .footer__newsletter-6 button {
  right: unset;
  left: 20px;
}

.dir-rtl .footer__newsletter-6 button i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dir-rtl .footer__newsletter-6 input {
  padding: 16px 20px 16px 50px;
}

.dir-rtl .footer__widget-4 {
  margin-left: 0;
  margin-right: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .footer__widget-4 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .footer__widget-4 {
    margin-right: 0;
  }
}

.dir-rtl .footer-menu li,
.dir-rtl .footer-menu-2 li {
  padding-left: 0;
  padding-right: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .footer-menu li,
  .dir-rtl .footer-menu-2 li {
    padding-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .footer-menu li,
  .dir-rtl .footer-menu-2 li {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .footer-menu li,
  .dir-rtl .footer-menu-2 li {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.dir-rtl .section_wrapper::before {
  left: unset;
  right: 60px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .section_wrapper::before {
    right: 48px;
  }
}

.dir-rtl .hover_img {
  left: unset;
  right: 100%;
}

.dir-rtl .awards__items .date {
  text-align: left;
}

.dir-rtl .awards__content p {
  margin-left: 0;
  margin-right: 170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .awards__content p {
    margin-right: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .awards__content p {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .awards__content p {
    margin-right: 0;
  }
}

.dir-rtl .client__area-8 .about-sub-right {
  text-align: left !important;
}

.dir-rtl .hero10:hover .hero10__content i {
  -webkit-transform: translateY(-50px) rotate(-135deg);
  transform: translateY(-50px) rotate(-135deg);
}

.dir-rtl .hero10__content {
  left: unset;
  right: 40px;
}

.dir-rtl .hero10__content i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media (max-width: 1365px) {
  .dir-rtl .hero10__content i {
    -webkit-transform: rotate(215deg);
    transform: rotate(215deg);
  }
}

.dir-rtl .scroll-top-2 {
  right: unset;
  left: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .scroll-top-2 {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .scroll-top-2 {
    left: 20px;
  }
}

.dir-rtl .dis_port_4 {
  margin-left: 0;
  margin-right: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .dis_port_4 {
    margin-right: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .dis_port_4 {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .dis_port_4 {
    margin-right: 20px;
  }
}

.dir-rtl .section_title_wrapper .title_right__inner__left {
  margin-right: 0;
  margin-left: 60px;
}

.dir-rtl .section_title_wrapper .title_right__inner__left span::before {
  right: unset;
  left: 80px;
}

.dir-rtl .gallary12__content img {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.dir-rtl .showcase__top-16 .title-right {
  margin-left: 0;
  margin-right: auto;
}

.dir-rtl .showcase__info-16 {
  left: unset;
  right: 50px;
}

.dir-rtl .showcase__title-16 span::before {
  left: unset;
  right: 0;
}

.dir-rtl .showcase__sub-title-16 {
  padding-left: unset;
  padding-right: 100px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .showcase__sub-title-16 {
    padding-right: 30px;
  }
}

.dir-rtl .mixtup_btn .wc-btn span {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

@media only screen and (min-width: 1920px) {
  .dir-rtl .team__slider {
    margin-left: 0;
    margin-right: 100px;
  }
}

.dir-rtl .job__detail-wrapper {
  padding-right: 0;
  padding-left: 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dir-rtl .job__detail-wrapper {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .job__detail-wrapper {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .job__detail-wrapper {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .job__detail-wrapper {
    padding-left: 0;
  }
}

.dir-rtl .job__detail-meta li {
  padding-right: 0;
  padding-left: 70px;
}

.dir-rtl .job__detail-content ul,
.dir-rtl .job__detail-content ol {
  padding-left: 0;
  padding-right: 30px;
}

.dir-rtl .job__detail-content ul li::before,
.dir-rtl .job__detail-content ol li::before {
  left: unset;
  right: -18px;
}

.dir-rtl .job__link {
  text-align: left;
}

.dir-rtl .job__link span::after {
  right: unset;
  left: 45px;
  -webkit-transform: translate(0, 0) rotate(215deg);
  transform: translate(0, 0) rotate(215deg);
}

.dir-rtl .job__link i,
.dir-rtl .job__link span {
  -webkit-transform: rotate(215deg);
  transform: rotate(215deg);
}

.dir-rtl .job__item:hover .job__link i {
  -webkit-transform: rotate(215deg) translate(60px, 2px);
  transform: rotate(215deg) translate(60px, 2px);
}

.dir-rtl .job__item:hover .job__link span::after {
  right: unset;
  left: 0;
}

.dir-rtl .modal__apply .form-btn button {
  margin-left: inherit;
  margin-right: auto;
}

.dir-rtl .modal__close-2 {
  right: unset;
  left: 30px;
}

.dir-rtl .career__thumb .inherit {
  margin-right: 0;
  margin-left: -320px;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .career__thumb .inherit {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}

.dir-rtl .career__benefits-list li {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .dir-rtl .career__benefits-list li {
    margin-left: 0;
    margin-right: -10px;
  }

  .dir-rtl .career__benefits-list li:nth-child(2n + 1) {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .career__benefits-list li:nth-child(3n + 1) {
    margin-left: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .about__area-2 #btn_wrapper,
  .dir-rtl .about__area-2 .btn_wrapper {
    margin-right: 0;
  }

  .dir-rtl .section_title_wrapper .title_right {
    margin-right: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .career__benefits-list li:nth-child(2n + 1) {
    margin-left: -20px;
  }
}

.dir-rtl .faq__content {
  padding-left: 0;
  padding-right: 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .faq__content {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .faq__content {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .faq__content {
    padding-right: 0;
  }
}

.dir-rtl .img-box img {
  left: unset;
  right: 0;
}

.dir-rtl .hero_layer_9 {
  direction: ltr;
}

.dir-rtl .part.showed {
  direction: rtl;
}

.dir-rtl .part.showed p {
  padding-left: 0;
  padding-right: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .part.showed p {
    padding-right: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .part.showed p {
    padding-right: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .part.showed p {
    padding-right: 45px;
  }
}

.dir-rtl .part.showed h2 span {
  padding-left: 0;
  padding-right: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dir-rtl .part.showed h2 span {
    padding-right: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dir-rtl .part.showed h2 span {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .dir-rtl .part.showed h2 span {
    padding-right: 35px;
  }
}

.dir-rtl .part.showed h2 span::before {
  left: unset;
  right: 0;
}

.dir-rtl .roll__area-2 {
  direction: ltr;
}

.dir-rtl .switcher__icon,
.dir-rtl .switcher__items {
  z-index: 9999;
}

.dark.dir-rtl .portfolio__about .brand-title-wrap {
  border-left: 1px solid var(--secondary);
}

.modal-body {
  background: var(--dark-theme);
}

.etp-img {
  width: 25%;
  margin-top: 10%;
}

.etp-text h3 {
  font-weight: 700;
  color: var(--theme-primary-color);
  line-height: 1.3;
}

.etp-text h3 span {
  font-size: 3rem;
  color: var(--theme-text-color);
}
.gallery__thumb img {
  width: 100%;
  border: 8px solid #1a2b56;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.42);
}
.story-nav {
  position: absolute;
  top: 40%;
  z-index: 1000;
}
.gallery-slider {
  position: relative;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 0px;
  margin: 0px;
  border-radius: 5px;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
}
a {
  text-decoration: none !important;
}
.success-popup {
  background-color: #4c5a77; /* Green color for success */
}

.failure-popup {
  background-color: #4c5a77; /* Red color for failure */
}
.Linkactive {
  background: rgba(42, 43, 95, 0.179);
  color: red !important;
  text-decoration: none;
}
.HeadLinkactive {
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
}
#margin-top p {
  margin-top: 0px !important;
  /* margin-bottom: 02px !important; */
}

@media (min-width: 1920px) {
  .footer__btm {
    margin-left: 15rem;
  }
}

@media (max-width: 1200px) {
  .portfolio__content-5 {
    display: none;
  }
}
.scroll-top:hover {
  background-color: #4c5a77 !important;
}
